import { FC, useState } from 'react';
import {ModalProps} from '../../types/modal';
import { Button, Checkbox, Icon, Modal } from 'semantic-ui-react';
import '../../styles/_modals.scss';

const DeleteAccountModal: FC<ModalProps> = ({
  showModal,
  setShowModal
}) => {
  const [confirmDelete, setConfirmDelete] = useState<boolean>(false)

  const handleDeleteAccount = () => {
    // TODO: Add service call to delete account
  }

  const handleCancelDelete = () => {
    setConfirmDelete(false)
    setShowModal(false)
  }

  return (
    <>
      {showModal && (
        <Modal
          dimmer="blurring"
          className="default-modal delete-account-modal"
          open={showModal}
          onClose={() => setShowModal(false)}
          onOpen={() => setShowModal(true)}
        >
          <Icon
            name="close"
            className="close-icon"
            onClick={() => setShowModal(false)}
          />
          <div className="modal-container">
            <span className="modal-header">Delete Account</span>
            <div className="modal-content">
              <label>Are you sure you want to delete your account?</label>
              <br />
              <div className="checkbox-container">
                <Checkbox
                  label="I understand this action cannot be undone and all my data will be lost."
                  checked={confirmDelete}
                  onChange={() => setConfirmDelete(confirmDelete => !confirmDelete)}
                />
              </div>
            </div>
            <div className="modal-footer">
              <Button
                type="button"
                className="alternate-button"
                onClick={() => handleCancelDelete()}
              >
                Cancel
              </Button>
              <Button
                disabled={!confirmDelete}
                type="button"
                className="alternate-button blue delete-button"
                onClick={() => handleDeleteAccount()}
              >
                Delete My Account
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default DeleteAccountModal
