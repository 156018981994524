import React, {FC, useContext, useEffect, useState} from 'react';
import * as Yup from 'yup';
import LoaderOverlay from '../../components/LoaderOverlay/LoaderOverlay';
import { Button, Form, Input } from 'semantic-ui-react';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { DataContext } from '../../context/dataContext';
import { useStore } from '@jmjfinancial/apis/lib';
import { handleError } from '../../helpers/response-handler';
import MaskedInput from 'react-text-mask';
import {scrollToDefaultOptions} from '../../helpers/scroll-to-options';
import { PHONE_INPUT_MASK, PHONE_MASK, PHONE_MATCH } from '../../helpers/regex-helper';
import PasswordValidation from '../../components/PasswordValidation/PasswordValidation';
import { getLastUpdatedLoan } from '../../helpers/loan-helper';
import { ApplicationRoutes } from '../LoanApplication';

const Registration: FC = () => {
  const store = useStore();
  const history = useHistory();
  const { authService, loansService } = store;
  const {
    user,
    setUser,
    setActiveLoan,
    referrer
  } = useContext(DataContext)

  const queryParams = new URLSearchParams(window.location.search)
  const token = queryParams.get('token') || '';
  const firstNameURL = queryParams.get('first_name') || '';
  const lastNameURL = queryParams.get('last_name') || '';
  const emailURL = queryParams.get('email')?.replace(' ', '+') || '';
  const mobilePhoneURL = queryParams.get('mobile_phone') || '';

  const [isLoading, setIsLoading] = useState(true);
  const [showErrorContainer, setShowErrorContainer] = useState<boolean>(false)
  const [characterVal, setCharacterVal] = useState<boolean>(false)
  const [numberVal, setNumberVal] = useState<boolean>(false)
  const [uppercaseVal, setUppercaseVal] = useState<boolean>(false)
  const [lowercaseVal, setLowercaseVal] = useState<boolean>(false)
  const [symbolVal, setSymbolVal] = useState<boolean>(false)
  const [isCoborrower, setIsCoborrower] = useState<boolean>(!!(token && firstNameURL && lastNameURL && emailURL && mobilePhoneURL));



  const startNewLoan = () => {
    loansService.createLoan({}, referrer).then(newLoan => {
      loansService.setActiveLoan(newLoan.data.loan_id);
      setActiveLoan(newLoan.data.loan_id)
      setUser(authService.getCurrentUser());
      setIsLoading(false)

      history.push('/application/start')
    })
  }

  // Ran when a coborrower registers their account
  const resumeLoan = (email: string, password: string, setStatus: Function, setSubmitting: Function) => {
    // Logs the user in using the email and password they submitted
    authService.login(email, password).then(res => {
      if (res.data.token) {
        setStatus({ sucess: true });
        setUser(authService.getCurrentUser());

        // Gets the customer and current loan data
        loansService.getCustomer().then(customerData => {
          const customerLoans = customerData.data.loans;
          loansService.getAllLoans(customerLoans).then(loans => {
            const currentLoan = getLastUpdatedLoan(loans);
            loansService.setActiveLoan(currentLoan.loan.id);
            setActiveLoan(currentLoan.loan.id);
            setSubmitting(false);
            setIsLoading(false);

            // If the loan they are associated with has been submitted, route them to the dashboard
            if (currentLoan.application.submitted) {
              console.log('loan submitted, routing to dashboard')
              history.push('/dashboard');
            }
            // Otherwise route them to the most recently completed step in the loan app.
            else {
              const currentStep = currentLoan.application.current_step;
              history.push(ApplicationRoutes[currentStep]);
            }
          })
        })
      }
    })
  }

  const handleValidatePassword = (password: string) => {
    setCharacterVal(characterVal => password.length >= 8);
    setNumberVal(numberVal => /\d/.test(password));
    setUppercaseVal(uppercaseVal => /[A-Z]/.test(password));
    setLowercaseVal(lowercaseVal => /[a-z]/.test(password));
    setSymbolVal(symbolVal => /\W|_/g.test(password))
  }

  useEffect(() => {
    if (user) {
      loansService.getCustomer()
        .then(async customerData => {
          if (!customerData.data) {
            setUser(null)
            authService.logout()
          }
          else {
            history.push('/dashboard');
          }

          setIsLoading(false)
        })
    }
    else {
      setIsLoading(false)
    }
  }, [authService, loansService, setUser])

  useEffect(() => {
    window.scrollTo(scrollToDefaultOptions)
  }, [])

  return (
    <>
      <LoaderOverlay active={isLoading} label="Creating your account..." dark />
      <div className="landing-container">
        <div className="landing-content">
          <h1 className="title">Let's get started with some info.</h1>
          <Formik
            initialValues={{
              firstName: firstNameURL,
              lastName: lastNameURL,
              email: emailURL,
              mobilePhone: mobilePhoneURL,
              password: '',
              confirmPassword: '',
              submit: null
            }}
            validationSchema={Yup.object().shape({
              firstName:
                Yup.string()
                  .max(255)
                  .required('First Name is required'),
              lastName:
                Yup.string()
                  .max(255)
                  .required('Last Name is required'),
              email:
                Yup.string()
                  .email('Must be a valid email')
                  .max(255)
                  .required('Email is required'),
              mobilePhone:
                Yup.string()
                  .matches(PHONE_MASK, 'Phone number must be 10 digits')
                  .matches(PHONE_MATCH, 'Area code cannot start with 1')
                  .required('Mobile Phone is required'),
              password: Yup
                .string()
                .min(8, 'Invalid password')
                .max(255)
                .matches(
                  /^(?:(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*["!#$%&'()*+,\-.\\/:;<=>?@[\]^_`{|}~]).*)$/,
                  'Invalid Password'
                )
                .required('Password is required'),
              confirmPassword: Yup
                .string()
                .min(8, 'Invalid password confirmation')
                .max(255)
                .oneOf([Yup.ref('password'), null], 'Passwords must match.')
                .required('Password confirmation is required'),
            })}
            validateOnBlur={false}
            onSubmit={async (values, {
              setErrors,
              setStatus,
              setSubmitting
            }) => {
              try {
                setIsLoading(true)

                const userData = {
                  first_name: values.firstName,
                  last_name: values.lastName,
                  mobile_phone: values.mobilePhone.replace(/\D/g, ''),
                  email: values.email,
                  password: values.password
                }

                // Make a different API/Service call if a coborrower is registering
                // It should take in a password and token
                // Then upon successful update, route the user into the loan app.
                if (isCoborrower) {
                  await authService.registerCoborrower(userData.password, token).then(async response => {
                    if (response.data.message === 'password updated') {
                      setStatus({ success: true });
                      resumeLoan(values.email, values.password, setStatus, setSubmitting)
                    } else {
                      setIsLoading(false);
                      setStatus({ success: false });
                      setErrors({ submit: response.data.errors[0] });
                      setSubmitting(false);
                    }
                  })
                } else {
                  await authService.register(userData).then(async res => {
                    if (res.data.success === true) {
                      setStatus({ success: true });
                      setSubmitting(false);
                      await authService.login(values.email, values.password).then(res => {
                        if (res.data.token) {
                          setStatus({ success: true });
                          setSubmitting(false);
                          startNewLoan()
                        }
                      });
                    } else {
                      setIsLoading(false)
                      setStatus({ success: false });
                      // @ts-ignore
                      // TODO: handle more than one error
                      setErrors({ submit: res.data.errors[0] });
                      setSubmitting(false);
                    }
                  });
                }

              } catch (err: any) {
                handleError(err);
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }}
          >
            {({
                errors,
                handleBlur,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values
              }) => (
              <form
                noValidate
                onSubmit={handleSubmit}
                className="auth-form"
              >
                <div className={`error-container ${Object.keys(errors).length > 0}`}>
                  {(touched.email && errors.email)
                    || (touched.mobilePhone && errors.mobilePhone)
                    || (touched.firstName && errors.firstName)
                    || (touched.lastName && errors.lastName)
                    || (touched.password && errors.password)
                    || (touched.confirmPassword && errors.confirmPassword)
                    || errors.submit
                  }
                </div>
                <Form.Field>
                  <Input
                    name="email"
                    placeholder="Your Email"
                    type="email"
                    className="auth-field"
                    value={values.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={!!(touched.email && errors.email) || !!(showErrorContainer && values.email === '')}
                    disabled={isCoborrower}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    className="auth-field"
                    error={!!(touched.mobilePhone && errors.mobilePhone) || !!(showErrorContainer && values.mobilePhone === '')}
                    disabled={isCoborrower}
                  >
                    <MaskedInput
                      mask={PHONE_INPUT_MASK}
                      guide={false}
                      name="mobilePhone"
                      placeholderChar={`\u2000`}
                      placeholder="Your Mobile Phone"
                      type="text"
                      value={values.mobilePhone}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </Input>
                </Form.Field>
                <Form.Field>
                  <Input
                    name="firstName"
                    placeholder="Your First Name"
                    type="text"
                    className="auth-field"
                    value={values.firstName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={!!(touched.firstName && errors.firstName) || !!(showErrorContainer && values.firstName === '')}
                    disabled={isCoborrower}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    name="lastName"
                    placeholder="Your Last Name"
                    type="text"
                    className="auth-field"
                    value={values.lastName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={!!(touched.lastName && errors.lastName) || !!(showErrorContainer && values.lastName === '')}
                    disabled={isCoborrower}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    name="password"
                    placeholder="Password"
                    type="password"
                    className="auth-field"
                    value={values.password}
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e)
                      handleValidatePassword(e.target.value)
                    }}
                    error={!!(touched.password && errors.password) || !!(showErrorContainer && values.password === '')}
                  />
                </Form.Field>
                <Form.Field>
                  <Input
                    name="confirmPassword"
                    placeholder="Re-Enter Your Password"
                    type="password"
                    className="auth-field"
                    value={values.confirmPassword}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={!!(touched.confirmPassword && errors.confirmPassword) || !!(showErrorContainer && values.confirmPassword === '')}
                  />
                </Form.Field>
                <PasswordValidation
                  characterVal={characterVal}
                  numberVal={numberVal}
                  uppercaseVal={uppercaseVal}
                  lowercaseVal={lowercaseVal}
                  symbolVal={symbolVal}
                  headerText="Password must contain at least:"
                />
                <div className="landing-controls form-controls">
                  <Button
                    disabled={isSubmitting}
                    type="submit"
                    className="landing-button auth-button"
                    onClick={() => setShowErrorContainer(showErrorContainer => true)}
                  >
                    Create Account
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className="landing-graphic" />
      </div>
    </>
  )
}

export default Registration;
