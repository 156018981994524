import React, {FC, useCallback, useContext, useEffect, useRef, useState} from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';
import './file-upload-modal.scss';
import { useDropzone } from 'react-dropzone';
import { useStore } from '@jmjfinancial/apis/lib';
import { DataContext } from '../../../context/dataContext';
import ProgressOverlay from '../../ProgressOverlay';

interface FileUploadModalProps {
  borrowerPairId: number;
}

const FileUploadModal: FC<FileUploadModalProps> = ({
  borrowerPairId
}) => {
  const store = useStore();
  const { tasksService } = store;

  const {
    showUploadModal,
    setShowUploadModal,
    modalHeader,
    modalSubheader,
    taskId,
  } = useContext(DataContext)

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    acceptedFiles
  } = useDropzone({
    accept: 'image/jpeg, image/png, application/pdf',
    onDropRejected: (fileRejections, event) => setErrorMessage(fileRejections[0].errors[0].message)
  })

  // files are the file objects
  const [files, setFiles] = useState<File[]>([])
  // attachments are objects that contain encoded file string and file name.
  const [attachments, setAttachments] = useState<Array<any>>([])
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0)
  const [progressOverlayLabel, setProgressOverlayLabel] = useState<string>()
  const [progressOverlayOffset, setProgressOverlayOffset] = useState<number>(0)

  const progressRef = useRef<number>(progress)
  const controllerRef = useRef(new AbortController())
  const uploadContainerRef = useRef<HTMLDivElement>(null)

  const createAttachments = useCallback(async (files: any) => {
    let newAttachments = [...attachments];

    for (let file of files) {
      await new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onabort = () => {
          let errMessage = 'file reading was aborted';
          console.log(errMessage)
          reject(errMessage)
        }
        reader.onerror = () => {
          let errMessage = 'file reading has failed';
          console.log(errMessage)
          reject(errMessage)
        }
        reader.onloadend = () => {
          let base64 = reader.result
          newAttachments.push({ filestring: base64, filename: file.name });
          resolve(true);
        }
        reader.readAsDataURL(file);
      });
    }

    setAttachments(newAttachments);
    console.log('attachments = ', attachments)
    console.log('newattachments', newAttachments)
  }, [])

  const removeFile = (file: any) => {
    setFiles(files => files.filter(a => a !== file))
  }

  const updateFiles = () => {
    let newFiles = [...files]
    acceptedFiles.forEach(acceptedFile => {
      if (!files.some(file => file.name === acceptedFile.name)) {
        newFiles.push(acceptedFile)
      } else {
        console.log('duplicate file found: ', acceptedFile.name)
        setErrorMessage(`Duplicate file found: ${acceptedFile.name} has already been added.`)
      }
    })
    setFiles(files => newFiles)
    createAttachments(newFiles)
  }

  /**
   * Resets all upload related UI and `AbortController` to start a new download
   * @param label progressOverlayLabel
   */
  const resetUpload = (label: string) => {
    controllerRef.current = new AbortController()
    setErrorMessage(errorMessage => '')
    setProgress(progress => 0)
    setIsUploading(isUploading => true)
    setProgressOverlayLabel(progressOverlayLabel => label)
  }

  /**
   * Handles cancelling the upload, and resetting the progress bar
   */
  const handleCancelUpload = () => {
    setIsUploading(isUploading => false)
    controllerRef.current.abort()
    setProgress(progress => 0)
  }

  /**
   * Updates progress bar while uploading an attachment(s)
   * @param progressEvent event passed from axios `onUploadProgress` callback
   */
  const handleUploadProgress = (progressEvent: ProgressEvent) => {
    const currentProgress = Math.round((progressEvent.loaded / progressEvent.total) * 100)
    setProgress(progress => currentProgress)
  }

  const handleSubmit = async () => {
    resetUpload(attachments.length > 1 ? 'Uploading all attachments...' : 'Uploading attachment...')
    try {
      const res: any = await tasksService.addAttachmentToTask(
        taskId,
        borrowerPairId,
        attachments,
        (progressEvent) => handleUploadProgress(progressEvent),
        controllerRef.current
      )
      // @ts-ignore
      if (res.attachments[0].some((doc: any) => doc.errors.includes("Attachment failed Document attachment must be less than")) ) {
        const errorFileIndex = res.attachments[0].indexOf(res.attachments[0].find( ( doc: any ) => doc.errors.includes("Attachment failed Document attachment must be less than")));
        setErrorMessage(`There was a problem uploading ${res.attachments[0][errorFileIndex].filename}. Max File Size 25MB.`)
      }
      else if (res.success) {
        setShowUploadModal(false)
      }
      else {
        setErrorMessage('There was a problem uploading your files. Please try again.')
      }
    }
    catch (err: any) {
      console.error('Application error: ', err.message)
      setErrorMessage('There was a problem uploading your files. Please try again.')
    }

    setIsUploading(false)
  }

  useEffect(() => {
    progressRef.current = progress

    // Needed to prevent type errors
    const uploadNode = uploadContainerRef.current

    // Listens for scrolling of progressOverlay parents and updates offset values
    uploadNode?.addEventListener('scroll',
      () => setProgressOverlayOffset(progressOverlayOffset => uploadNode?.scrollTop)
    )

    // Removes listeners when component is closed
    return () => {
      uploadNode?.removeEventListener('scroll', () => {})
    }
  })

  useEffect(() => {
    if (acceptedFiles.length > 0) {
      setErrorMessage('')
      updateFiles()
    }

    // Cancels upload on modal close
    return () => {
      handleCancelUpload()
    }
  }, [acceptedFiles])

  return (
    showUploadModal ?
      <Modal
        dimmer="blurring"
        className={`
          default-modal upload-modal
          ${isDragActive ? ' is-dragging' : ''}
          ${files.length > 0 ? ' has-files' : ''}
        `}
        open={showUploadModal}
        onClose={() => setShowUploadModal(false)}
        onOpen={() => setShowUploadModal(true)}
      >
        <Icon
          name="close"
          className="close-icon"
          onClick={() => setShowUploadModal(false)}
        />
        <span className="modal-header">{modalHeader}</span>
        <span className="modal-subheader">{modalSubheader}</span>
        <div
          ref={uploadContainerRef}
          className="upload-container"
        >
          <ProgressOverlay
            active={isUploading}
            percent={progressRef.current}
            label={progressOverlayLabel}
            top={progressOverlayOffset}
          />
          {files.length > 0 &&
            <div className="uploaded-files-container">
              {files.map((file, index) => (
                <div
                  key={`${file.name}-${index}`}
                  className="uploaded-file"
                >
                  <label>{file.name}</label>
                  <Icon
                    name="remove circle"
                    onClick={() => removeFile(file)}
                  />
                </div>
              ))}
            </div>
          }
          <div
            className="dropzone"
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <div className="dropzone-header">
              <Icon
                name="upload"
                className="blue"
              />
              <label>
                <span className="text-light-blue">Select</span> or&nbsp;
                <label className="drag-n-drop-ref">drag and drop&nbsp;</label>
                <label className="mobile-upload-ref">take a photo of&nbsp;</label>
                {attachments.length > 0 ? 'additional' : 'your'} files here
              </label>
            </div>

          </div>
        </div>
        <div className="modal-footer upload-modal-footer">
          <span className="error-message">{errorMessage}</span>
          <Button
            className="cancel-button"
            onClick={() => isUploading ? handleCancelUpload() : setShowUploadModal(false)}
          >
            Cancel
          </Button>
          <Button
            disabled={(acceptedFiles.length === 0 && files.length === 0) || isUploading}
            type="button"
            className="alternate-button blue"
            onClick={() => handleSubmit()}
          >
            Submit {files.length > 0 ? `(${files.length})` : ''}
          </Button>
        </div>
      </Modal>
      : null
  )
}

export default FileUploadModal
