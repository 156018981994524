import React, { FC, useContext, useEffect, useRef } from 'react';
import { Button, Form, Radio } from 'semantic-ui-react';
import FormikRadio from '../../../components/Formik/FormikRadio';
import { Formik } from 'formik';
import * as Yup from 'yup';
import FormikErrorMessage from '../../../components/Formik/ErrorMessage';
import { useHistory } from 'react-router';
import { ApplicationRoutes } from '../index';
import _ from 'lodash';
import { useStore } from '@jmjfinancial/apis/lib';
import { DataContext } from '../../../context/dataContext';
import {scrollIntoViewDefaultOptions} from '../../../helpers/scroll-to-options';

interface DuplicateREOFormProps {
  loanData: any;
  incrementREOIndex: Function;
  addREO: Function;
  setRefreshREOView: Function;
  incompleteREOs: Array<any>;
}

const DuplicateREOForm: FC<DuplicateREOFormProps> = ({
  loanData,
  incrementREOIndex,
  addREO,
  setRefreshREOView,
  incompleteREOs
}) => {
  const store = useStore();
  const history = useHistory();
  const { loansService } = store;

  const { activeLoan, pathname, reviewMode } = useContext(DataContext)

  const formRef = useRef<HTMLFormElement>(null)

  useEffect(() => {
    if (formRef && formRef.current) {
      formRef.current.scrollIntoView(scrollIntoViewDefaultOptions)
    }
  }, [])

  return (
    <Formik
      initialValues={{
        additionalProperty: null,
        submit: null
      }}
      validationSchema={Yup.object().shape({
        additionalProperty: Yup.string().nullable().required('This field is required')
      })}
      onSubmit={(values, {
        setErrors,
        setStatus,
        setSubmitting,
        resetForm
      }) => {
        try {
          if (values.additionalProperty === 'true') {
            // If user selects 'Yes', re-render a blank form.
            addREO();
          } else if (values.additionalProperty === 'false' && incompleteREOs.length === 0) {
            if (reviewMode) {
              history.push('/application/review-and-submit')
            }
            else {
              const updatedLoan = {
                application: {
                  current_step: ApplicationRoutes[pathname as keyof typeof ApplicationRoutes] + 1,
                  completed_steps: {
                    reo_done: true
                  }
                }
              }

              const dataSubmit = _.merge(loanData, updatedLoan)
              loansService.updateLoan(activeLoan, dataSubmit).then(() => history.push('/application/declarations'))
            }
          } else {
            incrementREOIndex();
            setRefreshREOView(true);
          }
          resetForm();

        } catch (error: any) {
          setStatus({ success: false });
          setErrors({ submit: error.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        handleSubmit,
        handleChange,
        isSubmitting,
        values
      }) => (
        <form
          ref={formRef}
          className="application-form"
          onSubmit={handleSubmit}
        >
          <h1 className="title text-light-blue reo">Owned Real Estate</h1>
          <div className="form-step">
            <Form.Field>
              {/*TODO: Revisit language*/}
              <label>{loanData.reo.length > 0 ? 'Great! ' : ''}{loanData.borrower.first_name}, do you currently own any {loanData.reo.length > 0 ? 'other ' : ''}real estate?</label>
              <div className="radio-group">
                <Radio
                  id="additionalPropertyTrue"
                  label="Yes"
                  name="additionalProperty"
                  value="true"
                  checked={values.additionalProperty === 'true'}
                  onChange={handleChange}
                />
                <Radio
                  id="additionalPropertyFalse"
                  label="No"
                  name="additionalProperty"
                  value="false"
                  checked={values.additionalProperty === 'false'}
                  onChange={handleChange}
                />
              </div>
              <FormikErrorMessage name="additionalProperty" />
            </Form.Field>
          </div>
          <div className="button-container additional-sources">
            <Button
              disabled={isSubmitting || values.additionalProperty === null}
              color="blue"
              type="submit"
            >
              Save &amp; Continue
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}

export default DuplicateREOForm;
