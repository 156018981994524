import React, {FC, useEffect, useState} from 'react';
import {Button, Icon} from 'semantic-ui-react';
import {CircularProgressbarWithChildren} from 'react-circular-progressbar';
import {ProgressBarStep} from '../../types/progress-bar-step';

interface MobileProgressBarProps {
  steps: ProgressBarStep[]
  visibleSteps: ProgressBarStep[]
  activeStep: ProgressBarStep
  activeStepIndex: number
  allowClickNavigation: Function
  handleUpdateActiveStep: Function
}

const MobileProgressBar: FC<MobileProgressBarProps> = ({
  steps,
  visibleSteps,
  activeStep,
  activeStepIndex,
  allowClickNavigation,
  handleUpdateActiveStep
}) => {
  const [previousStepIndex, setPreviousStepIndex] = useState<number>(activeStepIndex - 1)
  const [nextStepIndex, setNextStepIndex] = useState<number>(activeStepIndex + 1)
  const [previousStep, setPreviousStep] = useState<ProgressBarStep>(steps[previousStepIndex])
  const [nextStep, setNextStep] = useState<ProgressBarStep>(steps[nextStepIndex])
  const [disablePreviousStep, setDisablePreviousStep] = useState<boolean | undefined>(false)
  const [disableNextStep, setDisableNextStep] = useState<boolean | undefined>(false)
  const [previousStepTitle, setPreviousStepTitle] = useState<string>('')
  const [nextStepTitle, setNextStepTitle] = useState<string>('')
  const [stepsCompleted, setStepsCompleted] = useState<number>(visibleSteps.filter((step: any) => step.completed).length)

  const handlePreviousClick = () => {
    if (previousStep.hidden) {
      handleUpdateActiveStep(activeStepIndex - 2)
    }
    else {
      handleUpdateActiveStep(previousStepIndex)
    }
  }

  const handleNextClick = () => {
    if (nextStep.hidden) {
      handleUpdateActiveStep(activeStepIndex + 2)
    }
    else {
      handleUpdateActiveStep(nextStepIndex)
    }
  }

  const handleSetDisablePreviousStep = () => {
    const disabled = (previousStep?.hidden && steps[previousStepIndex - 1]?.hidden) || false

    setDisablePreviousStep(disablePreviousStep => disabled)
  }

  const handleSetDisableNextStep = () => {
    const disabled = !allowClickNavigation(nextStepIndex) || nextStepIndex === steps.length

    setDisableNextStep(disableNextStep => disabled)
  }

  const handleSetPreviousStepTitle = () => {
    let title = ''

    if (previousStep?.hidden) {
      if (!disablePreviousStep) {
        title = steps[previousStepIndex - 1]?.title
      }
    }
    else {
      title = previousStep?.title
    }

    setPreviousStepTitle(previousStepTitle => title)
  }

  const handleSetNextStepTitle = () => {
    let title = ''

    if (nextStep?.hidden) {
      title = steps[nextStepIndex + 1]?.title || ''
    }
    else {
      title = nextStep?.title || ''
    }

    setNextStepTitle(nextStepTitle => title)
  }

  useEffect(() => {
    setPreviousStepIndex(previousStepIndex => activeStepIndex - 1)
    setNextStepIndex(nextStepIndex => activeStepIndex + 1)
    setPreviousStep(previousStep => steps[previousStepIndex])
    setNextStep(nextStep => steps[nextStepIndex])

    handleSetDisablePreviousStep()
    handleSetDisableNextStep()
    handleSetPreviousStepTitle()
    handleSetNextStepTitle()
    setStepsCompleted(stepsCompleted => visibleSteps.filter((step: any) => step.completed).length)
  }, [
    steps,
    activeStep,
    activeStepIndex,
    handleSetDisablePreviousStep,
    handleSetDisableNextStep
  ])

  return (
    <div className="circular-progress-bar-container">
      <Button
        className="no-padding borderless progress-bar-button"
        disabled={disablePreviousStep}
        onClick={() => handlePreviousClick()}
        aria-label="previous step"
      >
        <Icon
          circular
          name="angle left"
        />
        <span>{previousStepTitle}</span>
      </Button>
      <CircularProgressbarWithChildren
        className="circular-progress-bar"
        strokeWidth={4}
        minValue={0}
        maxValue={visibleSteps.length}
        value={stepsCompleted}
      >
        <span className="active-step-title">{activeStep.title}</span>
        <span className={`steps-completed ${stepsCompleted === visibleSteps.length ? 'is-complete' : ''}`}>
          {stepsCompleted} / {visibleSteps.length}<br />
          Completed
        </span>
      </CircularProgressbarWithChildren>
      <Button
        className="no-padding borderless progress-bar-button"
        disabled={disableNextStep}
        onClick={() => handleNextClick()}
        aria-label="next step"
      >
        <Icon
          circular
          name="angle right"
        />
        <span>{nextStepTitle}</span>
      </Button>
    </div>
  )
}

export default MobileProgressBar
