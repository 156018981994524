import React, {FC, useContext, useEffect, useState} from 'react';
import AddRemoveTable, { HeaderLabels } from '../../../components/AddRemoveTable/AddRemoveTable';
import { useStore } from '@jmjfinancial/apis/lib';
import { DataContext } from '../../../context/dataContext';
import { useHistory } from 'react-router-dom';
import { getHeaderText, reduceDisplayedTableData } from '../../../helpers/table-helper';
import { Button } from 'semantic-ui-react';
import EditAssetForm from './EditAssetForm';
import { ApplicationRoutes } from '../index';
import _ from 'lodash';
import {getPluralizedName} from '../../../helpers/pluralize-name';
import {scrollToDefaultOptions} from '../../../helpers/scroll-to-options';
import {AssetTypeOption} from './dropdown-data';
import {AssetType, AssetTypeEnum, BasicAssetType} from './asset-types';
import {simpleAssets} from './index';

interface AssetsTableViewProps {
  loanData: any
  assetTypeOptions: AssetTypeOption[]
  assetTypes: AssetType[]
  setAssetTypes: Function
  assetIndex: number
  setAssetIndex: Function
  setRefreshAssetsView: Function
  hasCoborrower: boolean
  conciergeMode?: boolean
  formData?: any
}

const HEADER_LABELS: HeaderLabels[] = [
  {
    text: 'Account Type',
    value: 'basic_asset_type'
  },
  {
    text: 'Estimated Balance',
    value: 'amount'
  }
]

const AssetsTableView: FC<AssetsTableViewProps> = ({
  loanData,
  assetTypeOptions,
  assetTypes,
  setAssetTypes,
  assetIndex,
  setAssetIndex,
  setRefreshAssetsView,
  hasCoborrower,
  conciergeMode
}) => {
  const store = useStore();
  const { loansService } = store;

  const {
    activeLoan,
    pathname,
    reviewMode,
    setDarkThemeOverride,
    setOverrideTrackerVisibility
  } = useContext(DataContext);
  const [tableData, setTableData] = useState<any>([]);
  const [showForm, setShowForm] = useState(false);
  const [editFormData, setEditFormData] = useState<any>();
  const [editRowIndex, setEditRowIndex] = useState(0);

  const history = useHistory();

  setDarkThemeOverride(false)
  setOverrideTrackerVisibility(false)

  let pluralizedOwnerName: string = ''

  if (!hasCoborrower) {
    pluralizedOwnerName = getPluralizedName(loanData.borrower.first_name)
  }

  const getSubmitButtonLabel = (): string => {
    if (reviewMode) {
      return 'Back to Review'
    }
    else {
      return 'Save & Continue'
    }
  }

  const handlePreviousClick = () => {
    history.push('/application/employment-and-income')
  }

  const handleNextClick = () => {
    if (reviewMode) {
      history.push('/application/review-and-submit')
    }
    else {
      history.push('/application/reo')
    }
  }

  const handleAddRow = (row: any) => {
    let newTableData = [...tableData, row];
    setTableData(newTableData);
  }

  const handleRemoveRow = (index: number) => {
    let newTableData = [...tableData];
    newTableData[index].to_be_deleted = true;
    setTableData(newTableData);
  }

  const handleShowForm = (visible: boolean) => {
    const emptyForm = { ...{ basic_asset_type: '' } }
    setEditFormData(emptyForm);
    setShowForm(visible);
  }

  const handleEditRow = (index: number) => {
    setEditRowIndex(index);
    setEditFormData({ ...tableData[index] });
    setShowForm(true);
  }

  const handleUpdateRow = (row: any) => {
    const updatedTableData = [...tableData];
    updatedTableData[editRowIndex] = row;
    setTableData(updatedTableData);
  }

  const getAssetAmount = (key: BasicAssetType, tableData: any) => {
    return tableData.find((row: any) => row.basic_asset_type === key && !row.to_be_deleted)?.amount || null
  }

  const handleSubmit = async () => {
    const purchaseData = {
      application: {
        current_step: ApplicationRoutes[pathname as keyof typeof ApplicationRoutes] + 1,
        completed_steps: {
          assets_done: true
        }
      },
      borrower: {
        estimated_bank_accounts_amount: getAssetAmount('checkingOrSavings', tableData),
        estimated_stocks_and_bonds_amount: getAssetAmount('stocksOrBonds', tableData),
        estimated_retirement_accounts_amount: getAssetAmount('retirement', tableData),
        estimated_gifts_amount: getAssetAmount('gift', tableData),
      }
    }

    const dataSubmit = _.merge(loanData, purchaseData);
    await loansService.updateLoan(activeLoan, dataSubmit);

    handleNextClick();
  };

  useEffect(() => {
    // Iterate over `simpleAssets` and search for each value in `loanData.borrower`
    // and return an object with both type and amount, filtering out any undefined entry
    const assetsArrayV2 = simpleAssets.map(key => {
      // check if the key exists in loanData.borrower
      if (key in loanData.borrower) {
        return {
          basic_asset_type: AssetTypeEnum[key as keyof typeof AssetTypeEnum],
          amount: loanData.borrower[key]
        }
      }
    }).filter(value => value !== undefined)

    // set the tableData to the data stored in assetsArray
    setTableData(assetsArrayV2);
  }, [loanData])

  useEffect(() => {
    if (!showForm && tableData.filter((row: any) => row.to_be_deleted).length === 0) {
      window.scrollTo(scrollToDefaultOptions)
    }
  }, [showForm])

  return (
    <>
      <div className="application-step-container">
        <h1 className="title text-light-blue">{pluralizedOwnerName}Assets</h1>
        <AddRemoveTable
          headerLabels={getHeaderText(HEADER_LABELS)}
          table={reduceDisplayedTableData(tableData, HEADER_LABELS)}
          addButtonLabel={tableData.length !== assetTypeOptions.length ? 'Add Asset' : undefined}
          removeTableRow={handleRemoveRow}
          showForm={showForm}
          setShowForm={handleShowForm}
          editTableRow={handleEditRow}
          loanData={loanData}
          form={
            <div className="application-step-container assets-step-container">
              <EditAssetForm
                loanData={loanData}
                assetTypeOptions={assetTypeOptions}
                assetTypes={assetTypes}
                setAssetTypes={setAssetTypes}
                assetIndex={assetIndex}
                setAssetIndex={setAssetIndex}
                setRefreshAssetsView={setRefreshAssetsView}
                conciergeMode={conciergeMode}
                hasCoborrower={hasCoborrower}
                setShowForm={handleShowForm}
                saveForm={editFormData?.basic_asset_type === '' ? handleAddRow : handleUpdateRow}
                formData={editFormData}
                tableData={tableData}
              />
            </div>
          }
        />
        {showForm ? null : (
          <div className="application-step-footer">
            <Button
              onClick={() => handlePreviousClick()}
              className="form-previous-button"
            >
              Previous
            </Button>
            <Button
              className="save"
              color="blue"
              onClick={() => handleSubmit()}
            >
              {getSubmitButtonLabel()}
            </Button>
          </div>
        )}
      </div>
    </>
  )
}

export default AssetsTableView
