import React, {FC, useState} from 'react';
import { Icon } from 'semantic-ui-react';
import DashActionComponent, { DashAction } from './DashAction';
import {isMobile} from 'react-device-detect';

export interface DashSubItem {
  label: any
  tasksRemaining?: number
  actions?: DashAction[]
  v2?: boolean
  completedTasks?: number
}

const DashSubItemComponent: FC<DashSubItem> = ({
  label,
  tasksRemaining,
  actions,
  v2,
  completedTasks
}) => {
  const [expanded, setExpanded] = useState(v2 || !!(tasksRemaining && tasksRemaining > 0))

  const handleOnClick = () => {
    if (actions) {
      if (!v2 || isMobile) {
        setExpanded(!expanded)
      }
    }
  }

  return (
    <div className={`dash-sub-item-and-action-wrapper ${v2 ? 'is-v2' : ''}`}>
      <div
        className={`
          dash-sub-item
          ${actions ? 'has-actions' : ''}
          ${isMobile ? 'is-mobile' : ''}
          ${v2 ? 'is-v2' : ''}
        `}
        onClick={handleOnClick}
      >
        <div className="name-container">
          <Icon
            name={`angle ${expanded && actions ? 'down' : 'right'}`}
            className={`blue`}
          />
          <label>{label}</label>
        </div>
        {tasksRemaining ? <div className="tasks-remaining">{tasksRemaining}</div> : null}
      </div>
      {actions && expanded && (
        <div className={`
          dash-action-container dash-sub-item-action-container
          ${v2 ? 'is-v2' : ''}
        `}>
          {actions.map((action, index) => (
            <DashActionComponent
              key={`${action.label}+${index}`}
              v2={v2}
              {...action}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default DashSubItemComponent
