import React, {FC, ReactNode} from 'react';
import PageHeaderLink, {PageHeaderLinkProps} from '../PageHeader/PageHeaderLink';
import {Select, Tab} from 'semantic-ui-react';
import './section.scss';
import {isMobile} from 'react-device-detect';

export interface Tab {
  menuItem?: any
  render?: () => React.ReactNode
}

export interface Section {
  headerLabel: string
  tabs?: Tab[]
  links?: PageHeaderLinkProps[]
  v2?: boolean
  children?: ReactNode
}

const SectionComponent: FC<Section> = ({
  headerLabel,
  tabs,
  links,
  v2,
  children
}) => {
  const selectOptions = (v2 && isMobile) && links ? links.map(link => ({
    text: link.label,
    value: link.label
  })) : []

  return (
    <div className="section-component">
      <h3 className="section-header">{headerLabel}</h3>
      {links && (
        <div className={`
          links-container
          ${tabs ? 'has-tabs' : ''}
          ${isMobile ? 'has-dropdown' : ''}
        `}>
          {!isMobile && links.map((link: PageHeaderLinkProps, index: number) => (
            <PageHeaderLink
              key={`${link.label}+${index}`}
              {...link}
            />
          ))}
          {(v2 && isMobile) && (
            <Select
              name="linksDrop"
              options={selectOptions}
              defaultValue={selectOptions[0].value}
              placeholder={selectOptions[0].value}
              onChange={(e, v) => {(
                links.find(link => link.label === v.value ? link.onClick() : undefined)
              )}}
            />
          )}
        </div>
      )}
      {tabs && (
        <Tab
          menu={{ secondary: true, pointing: true}}
          panes={tabs}
        />
      )}
      {children}
    </div>
  )
}

export default SectionComponent
