import React, {FC, SyntheticEvent, useEffect, useRef, useState} from 'react';
import * as Yup from 'yup';
import {VISA_MASTERCARD_MATCH} from '../../helpers/regex-helper';
import {AuthNetEnvironment, CardData, useAcceptJs} from 'react-acceptjs';
import {Button, Icon, Input, InputProps} from 'semantic-ui-react';
import visaMastercardLogos from '../../assets/images/visa-mastercard-logos.png';
import {Field, Formik, FormikValues, useField} from 'formik';
import AddressInput from '../../components/AddressInput/AddressInput';
import {observer} from 'mobx-react-lite';
import {clearValues} from '../../helpers/clear-values-helper';
import {useStore} from '@jmjfinancial/apis/lib';
import {InvoiceProps} from './index';
import {useHistory} from 'react-router-dom';

interface PaymentProps extends InvoiceProps {
  setRefreshInvoicePage: Function
  setIsLoading: Function
}

const authData = {
  apiLoginID: process.env.REACT_APP_AUTH_NET_API_LOGIN_ID as string,
  clientKey: process.env.REACT_APP_AUTH_NET_PUBLIC_CLIENT_KEY as string
}

const authEnv = process.env.REACT_APP_AUTH_NET_ENVIRONMENT as AuthNetEnvironment

const PaymentComponent: FC<PaymentProps> = observer(({
  loanData,
  invoice,
  invoiceId,
  setRefreshInvoicePage,
  setIsLoading
}) => {
  const store = useStore()
  const { invoicesService } = store

  const { dispatchData } = useAcceptJs({ authData, environment: authEnv })

  const [showErrorContainer, setShowErrorContainer] = useState<boolean>(false)
  const [showSuccessState, setShowSuccessState] = useState<boolean>(false)
  const [redirectCountdown, setRedirectCountdown] = useState<number>(5)
  const [showCountdown, setShowCountdown] = useState<boolean>(true)

  const history = useHistory()

  const redirectTimeoutRef = useRef<any>();
  const redirectCountdownIntervalRef = useRef<any>();

  const handleUsePrimaryClick = (e: any, values: FormikValues) => {
    if (e.target.checked) {
      values.street = loanData?.property.address_street_line
      values.unit = loanData?.property.address_unit
      values.city = loanData?.property.address_city
      values.state = loanData?.property.address_state
      values.postal = loanData?.property.address_postal
    }
    else {
      clearValues(values, [
        'street',
        'unit',
        'city',
        'state',
        'postal'
      ])
    }
  }

  const CardExpirationInput = (props: InputProps) => {
    const [field, meta, helpers] = useField(props.name)

    return (
      <Input
        {...props}
        onChange={(e, v) => {
          const autoSlashedValue = v.value.length === 3 && !v.value.includes("/")
            ? `${v.value.substring(0, 2)}/${v.value.substring(2)}`
            : v.value

          helpers.setValue(autoSlashedValue)
        }}
      />
    )
  }

  const handleShowSuccessState = () => {
    setIsLoading((isLoading: boolean) => false)
    setShowSuccessState(showSuccessState => true)
    if (redirectCountdown > 0) {
      redirectCountdownIntervalRef.current = setInterval(() => setRedirectCountdown(redirectCountdown => redirectCountdown - 1), 1000)
    }
    redirectTimeoutRef.current = setTimeout(() => history.push('/dashboard'), 5000)
  }

  const handleCancelRedirect = () => {
    setShowCountdown(showCountdown => false)
    clearTimeout(redirectTimeoutRef.current)
    clearInterval(redirectCountdownIntervalRef.current)
  }

  return (
    <Formik
      initialValues={{
        cardNumber: '',
        cardExpiration: '',
        cardCode: '',
        firstName: '',
        lastName: '',
        company: '',
        usePrimary: '',
        street: '',
        unit: '',
        city: '',
        state: '',
        postal: '',
        submit: null
      }}
      validationSchema={Yup.object().shape({
        cardNumber: Yup.string()
          .required('Card number is required.')
          .matches(VISA_MASTERCARD_MATCH, 'Only Visa or Mastercard are accepted at this time.'),
        cardExpiration: Yup.string()
          .required('Expiration date is required')
          .typeError('Not a valid expiration date. Example: MM/YY')
          .matches(/([0-9]{2})\/([0-9]{2})/, 'Not a valid expiration date. Example: MM/YY')
          .test('test-exp-date', 'Invalid Expiration Date. Date has passed.', expirationDate => {
              if (!expirationDate) {
                return false
              }

              const today = new Date()
              const monthToday = today.getMonth() + 1
              const yearToday = today.getFullYear().toString().substr(-2)

              const [expMonth, expYear] = expirationDate.split('/')

              if (Number(expYear) < Number(yearToday)) {
                return false
              } else if (
                Number(expMonth) < monthToday &&
                Number(expYear) <= Number(yearToday)
              ) {
                return false
              }

              return true
            }
          )
          .test('test-exp-date', 'Invalid Expiration Month.', expirationDate => {
              if (!expirationDate) {
                return false
              }

              const [expMonth] = expirationDate.split('/')

              return Number(expMonth) <= 12
            }
          ),
        cardCode: Yup.string()
          .min(3, 'CVC code is invalid.')
          .required('CVC code is required.'),
        firstName: Yup.string().required('First name is required.'),
        lastName: Yup.string().required('Last name is required.'),
        company: Yup.string(),
        usePrimary: Yup.boolean(),
        street: Yup.string().min(2, 'Invalid address entered, minimum of 2 characters required').required('Street address is required.'),
        unit: Yup.string(),
        city: Yup.string().required('City is required.'),
        state: Yup.string().required('State is required.'),
        postal: Yup.string()
          .nullable()
          .matches(/^[0-9]{5}$/, 'Zipcode must be exactly 5 digits')
          .required('Zipcode is required.'),
      })}
      onSubmit={(values, {
        setErrors,
        setStatus,
        setSubmitting,
        resetForm
      }) => {
        setIsLoading((isLoading: boolean) => true)

        const [expMonth, expYear] = values.cardExpiration.split('/')

        const cardData: CardData = {
          cardNumber: values.cardNumber,
          month: expMonth,
          year: `20${expYear}`,
          cardCode: values.cardCode,
          zip: values.postal,
          fullName: `${values.firstName} ${values.lastName}`
        }

        // Dispatch CC data to Authorize.net and receive payment nonce for use on your server
        dispatchData({cardData}).then(res => {
          if (res.messages.message[0].text === 'Successful.') {
            const authNonce = res.opaqueData
            const lastFour = values.cardNumber.substring(values.cardNumber.length - 4)

            const updatedInvoice = {
              ...invoice,
              first_name: values.firstName,
              last_name: values.lastName,
              company: values.company,
              billing_address_street_line: values.street,
              billing_address_city: values.city,
              billing_address_state: values.state,
              billing_address_postal: values.postal,
              cc_last_four: lastFour,
              auth_net_data_descriptor: authNonce.dataDescriptor,
              auth_net_data_value: authNonce.dataValue
            }

            invoicesService.updateInvoice(
              loanData?.borrower.borrower_pair_id,
              invoiceId,
              updatedInvoice
            ).then((res: any) => {
              console.log('submitHandler res:', res)
              if (res.data.success) {
                setIsLoading((isLoading: boolean) => false)
                setStatus({ success: true })
                setSubmitting(false)
                resetForm()
                handleShowSuccessState()
              }
              // TODO: Look into if this should be a catch statement
              else {
                setIsLoading((isLoading: boolean) => false)
                setStatus({ success: false })
                setErrors({
                  submit: res.data.error_code
                    ? res.data.error_message
                    : res.data.errors?.aasm[0]
                })
                setSubmitting(false)
              }
            })
          }
        })
        .catch(res => {
          setIsLoading((isLoading: boolean) => false)
          setStatus({ success: false })
          setErrors({ submit: res.messages?.message[0].text})
          setSubmitting(false)
        })
      }}
    >
      {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values
        }) => {
        return (
          <form
            className="payment-container"
            onSubmit={handleSubmit}
          >
            {showSuccessState && (
              <div className="success-container">
                <div className="success-message">
                  <label>Thanks for your payment!</label>
                  <Icon name="check circle"/>
                </div>
                <div className="countdown-container">
                  {showCountdown &&
                    <>
                      <label>Redirecting to the Dashboard in {redirectCountdown} seconds...</label>
                      <div className="button-container">
                        <Button
                          type="button"
                          className="form-previous-button"
                          onClick={() => handleCancelRedirect()}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="button"
                          className="save"
                          color="blue"
                          onClick={() => history.push('/dashboard')}
                        >
                          Go Now
                        </Button>
                      </div>
                    </>
                  }
                  {!showCountdown &&
                    <div className="button-container has-no-countdown">
                      <Button
                        type="button"
                        className="save"
                        color="blue"
                        onClick={() => history.push('/dashboard')}
                      >
                        Go to Dashboard
                      </Button>
                    </div>
                  }
                </div>
              </div>
            )}
            <span className="invoice-title">Payment</span>
            <span className="section-title">Card Information</span>
            <div className="payment-field-container">
              <div className="payment-row">
                <Input
                  className="payment-field"
                  name="cardNumber"
                  placeholder="1234 1234 1234 1234"
                  type="text"
                  maxLength={16}
                  value={values.cardNumber}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!errors.cardNumber && touched.cardNumber}
                />
                <img src={visaMastercardLogos} className="visa-mastercard-logo" alt="visa and mastercard logos" />
              </div>
              <div className="payment-row">
                <CardExpirationInput
                  id="cardExpiration"
                  className="payment-field expiration-field"
                  name="cardExpiration"
                  placeholder="MM / YY"
                  type="text"
                  maxLength={5}
                  value={values.cardExpiration}
                  onBlur={handleBlur}
                  error={!!errors.cardExpiration && touched.cardExpiration}
                />
                <Input
                  className="payment-field cvc-field"
                  name="cardCode"
                  placeholder="CVC"
                  type="text"
                  maxLength={4}
                  value={values.cardCode}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!errors.cardCode && touched.cardCode}
                />
              </div>
            </div>
            <span className="section-title">Name on Card</span>
            <div className="payment-field-container">
              <div className="payment-row">
                <Input
                  className="payment-field first-name-field"
                  name="firstName"
                  placeholder="First Name*"
                  type="text"
                  value={values.firstName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!errors.firstName && touched.firstName}
                />
                <Input
                  className="payment-field last-name-field"
                  name="lastName"
                  placeholder="Last Name*"
                  type="text"
                  value={values.lastName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!errors.lastName && touched.lastName}
                />
              </div>
              <div className="payment-row">
                <Input
                  className="payment-field"
                  name="company"
                  placeholder="Company (Optional)"
                  type="text"
                  value={values.company}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!errors.company && touched.company}
                />
              </div>
            </div>
            <span className="section-title no-bottom-margin">Billing Address</span>
            {loanData?.property.address_city && (
              <div className="checkbox-container">
                <Field
                  type="checkbox"
                  id="usePrimary"
                  name="usePrimary"
                  className="primary-checkbox"
                  onClick={(e: SyntheticEvent) => handleUsePrimaryClick(e, values)}
                />
                <label htmlFor="usePrimary">Use primary address as billing address</label>
              </div>
            )}
            <AddressInput
              handleBlur={handleBlur}
              handleChange={handleChange}
              touched={touched}
              errors={errors}
              showLabel={false}
              showErrorContainer={showErrorContainer}
              setFieldValue={setFieldValue}
              values={values}
              disableFormikError
              disabled={!!values.usePrimary}
              paymentStyle
              autoFillOverride
            />
            <div className="payment-footer">
              <Button
                disabled={isSubmitting || invoice?.status === 'paid'}
                className="save"
                type="submit"
                color="blue"
              >
                {invoice?.status === 'paid' ? 'Already Paid' : 'Submit'}
              </Button>
            </div>
            {/*Checks if there are errors on a field that has already been touched, and then displays the first error in the `errors` object */}
            {/*@ts-ignore*/}
            {errors[Object.keys(errors)[0]] && touched[Object.keys(errors)[0]] && <span className="error-message">{errors[Object.keys(errors)[0]]}</span>}
          </form>
        )}
      }
    </Formik>
  )
})

export default PaymentComponent
