import React, { FC, useEffect, useState } from 'react';
import { Form, Radio } from 'semantic-ui-react';
import { propertyUsageOptions, propertyUsageLessPrimaryOptions } from '../reo-options';
import { currencyMask } from '../../../../helpers/currency-mask-input';
import FormikMaskedInput from '../../../../components/Formik/FormikMaskedInput';
import { clearValues } from '../../../../helpers/clear-values-helper';
import FormikDropdown from '../../../../components/Formik/FormikDropdown';
import { lastYear, twoYearsAgo } from '../../../../helpers/date-helpers';
import {taxReturnOptions} from '../../../../data/tax-return-options';
import {REOSubPathProps} from './index';

const PropertyUsagePath: FC<REOSubPathProps> = ({
  values,
  errors,
  handleBlur,
  handleChange,
  touched,
  showErrorContainer,
  hasPrimaryResidence
}) => {
  const [isPrimaryResidence, setIsPrimaryResidence] = useState<boolean>(false)

  useEffect(() => {
    setIsPrimaryResidence(isPrimaryResidence => values.propertyUsageType === 'primary')
  }, [values])

  return (
    <>
      <Form.Field>
        <label>How is this property used?</label>
        <FormikDropdown
          className={`small ${!!(touched.propertyUsageType && errors.propertyUsageType) ? 'error' : ''}`}
          name="propertyUsageType"
          placeholder="Property Usage"
          options={hasPrimaryResidence && !isPrimaryResidence ? propertyUsageLessPrimaryOptions : propertyUsageOptions}
          value={values.propertyUsageType}
          onFocus={() => clearValues(values, [
            'rentalIncomeGrossAmount',
            'propertyOnCurrentTaxReturn',
            'propertyOnPriorTaxReturn',
          ])}
          error={
            !!(touched.propertyUsageType && errors.propertyUsageType)
            || (showErrorContainer && !values.propertyUsageType)
          }
        />
      </Form.Field>
      {values.propertyUsageType === 'investment' && (
        <>
          <Form.Field className="nested">
            <FormikMaskedInput
              className={`small ${!!(touched.rentalIncomeGrossAmount && errors.rentalIncomeGrossAmount) ? 'error' : ''}`}
              label="How much rent do you receive monthly?"
              name="rentalIncomeGrossAmount"
              placeholder="Gross Rental Income"
              type="text"
              value={values.rentalIncomeGrossAmount}
              onBlur={handleBlur}
              onChange={handleChange}
              mask={currencyMask}
              error={
                !!(touched.rentalIncomeGrossAmount && errors.rentalIncomeGrossAmount)
                || (showErrorContainer && !values.rentalIncomeGrossAmount)
              }
            />
          </Form.Field>
          <Form.Field className="nested">
            <label>What's the most recent year of personal taxes you filed?</label>
            <FormikDropdown
              className="small"
              name="personalTaxFiled"
              placeholder="Select a year"
              options={taxReturnOptions}
              value={values.personalTaxFiled}
              error={
                !!(touched.personalTaxFiled && errors.personalTaxFiled)
                || (showErrorContainer && !values.personalTaxFiled)
              }
              onFocus={() => clearValues(values, [
                'propertyOnCurrentTaxReturn',
                'propertyOnPriorTaxReturn'
              ])}
            />
          </Form.Field>
          {values.personalTaxFiled === lastYear.toString() && (
            <Form.Field className="nested">
              <label>{values.personalTaxFiled ? "Was"  : "Great, was "} this property listed on your {lastYear} tax return for the full year?</label>
              <div className={`radio-group ${showErrorContainer && !values.propertyOnCurrentTaxReturn && 'radio-error'}`}>
                <Radio
                  id="onCurrentTaxReturnTrue"
                  label="Yes"
                  name="propertyOnCurrentTaxReturn"
                  value="true"
                  checked={values.propertyOnCurrentTaxReturn === "true"}
                  className={
                    (errors.propertyOnCurrentTaxReturn || (showErrorContainer && !values.propertyOnCurrentTaxReturn))
                      ? 'has-error' : undefined
                  }
                  onChange={(e) => handleChange(e)}
                />
                <Radio
                  id="onCurrentTaxReturnFalse"
                  label="No"
                  name="propertyOnCurrentTaxReturn"
                  value="false"
                  checked={values.propertyOnCurrentTaxReturn === "false"}
                  className={
                    (errors.propertyOnCurrentTaxReturn || (showErrorContainer && !values.propertyOnCurrentTaxReturn))
                      ? 'has-error' : undefined
                  }
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </Form.Field>
          )}
          {values.personalTaxFiled === twoYearsAgo.toString() && (
            <Form.Field className="nested">
              <label>{values.personalTaxFiled ? "Was"  : "Great, was "} this property listed on your {twoYearsAgo} tax return for the full year?</label>
              <div className={`radio-group ${showErrorContainer && !values.propertyOnPriorTaxReturn && 'radio-error'}`}>
                <Radio
                  id="propertyOnPriorTaxReturnTrue"
                  label="Yes"
                  name="propertyOnPriorTaxReturn"
                  value="true"
                  checked={values.propertyOnPriorTaxReturn === 'true'}
                  className={
                    (errors.propertyOnPriorTaxReturn || (showErrorContainer && !values.propertyOnPriorTaxReturn))
                      ? 'has-error' : undefined
                  }
                  onChange={(e) => handleChange(e)}
                />
                <Radio
                  id="propertyOnPriorTaxReturnFalse"
                  label="No"
                  name="propertyOnPriorTaxReturn"
                  value="false"
                  checked={values.propertyOnPriorTaxReturn === "false"}
                  className={
                    (errors.propertyOnPriorTaxReturn || (showErrorContainer && !values.propertyOnPriorTaxReturn))
                      ? 'has-error' : undefined
                  }
                  onChange={(e) => handleChange(e)}
                />
              </div>
            </Form.Field>
          )}
        </>
      )}
    </>
  )
}

export default PropertyUsagePath
