import React, { FC } from 'react';
import { Form, Select } from 'semantic-ui-react';
import { incomeTypeOptions } from '../dropdown-data';
import ApplicationFormFooter from '../../shared/ApplicationFormFooter';
import { IncomeType } from '../index';
import { OwnerType } from '../../../../types/loan';

interface OwnerTypeSelectionProps {
  loanData: any
  ownerType: OwnerType
  setOwnerType: Function
  incomeType: IncomeType
  setIncomeType: Function
  onCancelClick: Function
  formData?: any
}

const OwnerTypeSelection: FC<OwnerTypeSelectionProps> = ({
  loanData,
  ownerType,
  setOwnerType,
  incomeType,
  setIncomeType,
  onCancelClick,
  formData
}) => {
  let normalizedIncomeType: IncomeType = incomeType

  if (
    incomeType === 'social_security' ||
    incomeType === 'pension' ||
    incomeType === 'annuity' ||
    incomeType === 'retirement_account'
  ) {
    normalizedIncomeType = 'retirement_account'
  }

  return (
    <form className="application-form form-view owner-type-selection-form">
      {loanData.coborrower ? (
        <div className="form-step">
          <Form.Field className="owner-label">
            <label>Please select an owner for this entry: </label>
            <Select
              name="owner"
              placeholder="Select from the List"
              disabled={!!formData.id}
              options={[
                {
                  text: loanData?.borrower.first_name,
                  value: 'borrower'
                },
                {
                  text: loanData?.coborrower.first_name,
                  value: 'coborrower'
                }
              ]}
              value={ownerType}
              // @ts-ignore
              onChange={(e, { value }) => setOwnerType(value)}
            />
          </Form.Field>
        </div>
      ) : null}
      <div className="form-step">
        <Form.Field>
          <label>Income Source</label>
          <Select
            clearable
            selectOnBlur={false}
            disabled={!!formData.id}
            name="incomeSource"
            placeholder="Income Source..."
            options={incomeTypeOptions}
            value={normalizedIncomeType}
            // @ts-ignore
            onChange={(e, { value }) => setIncomeType(value)}
          />
        </Form.Field>
      </div>
      {incomeType === '' && (
        <ApplicationFormFooter
          onCancelClick={onCancelClick}
          nextButtonVisible={false}
        />
      )}
    </form>
  )
}

export default OwnerTypeSelection
