import React, { FC } from 'react';
import { Form, Input, Radio } from 'semantic-ui-react';
import FormikRadio from '../../../components/Formik/FormikRadio';
import { currencyMask } from '../../../helpers/currency-mask-input';
import MaskedInput from 'react-text-mask';
import { propertyTypeOptions } from './data';
import FormikDatePicker from '../../../components/Formik/FormikDatePicker';
import { BorrowerInfoProps } from './index';
import { loanCalculator } from './loanCalcs';
import AddressInput from '../../../components/AddressInput/AddressInput';
import FormikDropdown from '../../../components/Formik/FormikDropdown';
import { percentMask } from '../../../helpers/percent-mask-input';
import { clearValues } from '../../../helpers/clear-values-helper';
import { futureDate, generateYearOptions } from '../../../helpers/date-helpers';
import HelperPopup from '../../../components/Popup/Popup';
import FormikErrorMessage from '../../../components/Formik/ErrorMessage';
import FormikInput from '../../../components/Formik/FormikInput';
import { PHONE_INPUT_MASK } from '../../../helpers/regex-helper';

const PurchaseContractOffer: FC<BorrowerInfoProps> = ({
  errors,
  values,
  touched,
  handleBlur,
  handleChange,
  setFieldValue,
  showErrorContainer,
  coapplicantMode
}) => {

  return values.purchasePlan === 'contract' || values.purchasePlan === 'offerMade' ? (
    <>
      <div className='form-step'>
        <Form.Field id="realtor-fields">
          <label>Is a Realtor helping you with this process?</label>
          <div className={`radio-group ${showErrorContainer && values.realtorExists === '' && 'radio-error'}`}>
            <Radio
              id="realtorExistsTrue"
              className={errors.realtorExists && 'has-error'}
              disabled={coapplicantMode}
              label="Yes"
              name="realtorExists"
              value="true"
              checked={values.realtorExists === "true"}
              onClick={() => clearValues(values, ['requestRealtorReferral'])}
              onChange={(e: any) => handleChange(e)}
            />
            <Radio
              id="realtorExistsFalse"
              className={errors.realtorExists && 'has-error'}
              disabled={coapplicantMode}
              label="No"
              name="realtorExists"
              value="false"
              checked={values.realtorExists === "false"}
              onClick={() => clearValues(values, [
                'realtorFirstName',
                'realtorLastName',
                'realtorNumber',
                'realtorEmail'
              ])}
              onChange={(e: any) => handleChange(e)}
            />
          </div>
        </Form.Field>
        {values.realtorExists === 'true' && (
          <Form.Field>
            <Input
              className="realtor"
              disabled={coapplicantMode}
              name="realtorFirstName"
              placeholder="Realtor First Name"
              type="text"
              value={values.realtorFirstName}
              onBlur={handleBlur}
              onChange={(e: any) => handleChange(e)}
              error={!!(touched.realtorFirstName && errors.realtorFirstName) || !!(showErrorContainer && values.realtorFirstName === '')}
            />
            <Input
              className="realtor"
              disabled={coapplicantMode}
              name="realtorLastName"
              placeholder="Realtor Last Name"
              type="text"
              value={values.realtorLastName}
              onBlur={handleBlur}
              onChange={(e: any) => handleChange(e)}
              error={!!(touched.realtorLastName && errors.realtorLastName) || !!(showErrorContainer && values.realtorLastName === '')}
            />
            <Input
              className="realtor"
              error={!!(touched.realtorNumber && errors.realtorNumber) || !!(showErrorContainer && values.realtorNumber === '')}
            >
              <MaskedInput
                disabled={coapplicantMode}
                mask={PHONE_INPUT_MASK}
                guide={false}
                name="realtorNumber"
                placeholder="Realtor Phone #"
                type="text"
                value={values.realtorNumber}
                onBlur={handleBlur}
                onChange={(e: any) => handleChange(e)}
              />
              {
                !!(touched.realtorNumber && values.realtorNumber !== '') || !!(showErrorContainer && values.realtorNumber !== '')
                  ? <FormikErrorMessage name="realtorNumber" /> : null
              }
            </Input>
            <FormikInput
              className="realtor"
              disabled={coapplicantMode}
              name="realtorEmail"
              placeholder="Realtor Email"
              type="text"
              value={values.realtorEmail}
              onBlur={handleBlur}
              onChange={handleChange}
              error={!!(touched.realtorEmail && errors.realtorEmail) || !!(showErrorContainer && values.realtorEmail === '')}
            />
          </Form.Field>
        )}
        {values.realtorExists === "false" && (
          <Form.Field className="nested">
            <label>Got it. Would you like us to refer a Realtor to you? </label>
            <div className={`radio-group ${showErrorContainer && values.requestRealtorReferral === '' && 'radio-error'}`}>
              <Radio
                id="requestRealtorReferralTrue"
                disabled={coapplicantMode}
                label="Yes"
                name="requestRealtorReferral"
                value="true"
                checked={values.requestRealtorReferral === "true"}
                className={errors.requestRealtorReferral && 'has-error'}
                onChange={(e) => handleChange(e)}
              />
              <Radio
                id="requestRealtorReferralFalse"
                disabled={coapplicantMode}
                label="No"
                name="requestRealtorReferral"
                value="false"
                checked={values.requestRealtorReferral === "false"}
                className={errors.requestRealtorReferral && 'has-error'}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </Form.Field>
        )}
        <Form.Field>
          <label className="address-field">What is the address of the home you are purchasing?</label>
        </Form.Field>
        <AddressInput
          handleBlur={handleBlur}
          handleChange={handleChange}
          touched={touched}
          errors={errors}
          showLabel={false}
          showErrorContainer={showErrorContainer}
          disabled={coapplicantMode}
          setFieldValue={setFieldValue}
          values={values}
        />
        <Form.Field>
          <label>What type of property are you looking to buy?</label>
          <FormikDropdown
            className="large"
            disabled={coapplicantMode}
            key={propertyTypeOptions}
            name="propertyType"
            placeholder="Property Type"
            options={propertyTypeOptions}
            value={values.propertyType}
            onFocus={() => clearValues(values, ['commonWalls'])}     
            error={!!(touched.propertyType && errors.propertyType) || !!(showErrorContainer && values.propertyType === '')}
          />
        </Form.Field>
        {values.propertyType === 'sfr-planned-development'
          || values.propertyType === 'condo' ? (
            <>
              <Form.Field className="nested">
                <label>Okay, is the {values.propertyType === 'condo' ? 'condo' : 'unit'} attached or detached?</label>
                <div className={`radio-group ${showErrorContainer && values.commonWalls === '' && 'radio-error'}`}>
                  <Radio
                    id="commonWallsTrue"
                    label="Attached"
                    disabled={coapplicantMode}
                    name="commonWalls"
                    value="attached"
                    checked={values.commonWalls === 'attached'}
                    className={errors.commonWalls && 'has-error'}
                    onChange={(e) => handleChange(e)}
                  />
                  <Radio
                    id="commonWallsFalse"
                    label="Detached"
                    disabled={coapplicantMode}
                    name="commonWalls"
                    value="detached"
                    checked={values.commonWalls === 'detached'}
                    className={errors.commonWalls && 'has-error'}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </Form.Field>
            </>) : null}
        {/* <Form.Field >
          <label>In what year was the property structure built?</label>
            <FormikDropdown
              className={`small ${!!(touched.structureYearBuilt && errors.structureYearBuilt) ? 'error' : ''}`}
              search
              placeholder="YYYY"
              disabled={coapplicantMode}
              name="structureYearBuilt"
              options={generateYearOptions(1920)}
              value={values.structureYearBuilt}
              onBlur={handleBlur}
              onChange={handleChange}
              error={!!(touched.structureYearBuilt && errors.structureYearBuilt) || !!(showErrorContainer && values.structureYearBuilt === '')}
            />
        </Form.Field> */}
        <Form.Field >
          <label>What's the estimated purchase price?</label>
          <Input
            className="small"
            error={!!(touched.purchasePrice && errors.purchasePrice) || !!(showErrorContainer && values.purchasePrice === '')}
          >
            <MaskedInput
              name="purchasePrice"
              disabled={coapplicantMode}
              placeholder="Amount"
              type="text"
              value={values.purchasePrice}
              onBlur={(e: any) => {
                handleBlur(e)
                loanCalculator('blur', setFieldValue, values.purchasePrice, values.downPaymentAmount, values.downPaymentPercent, values.loanAmount)
              }}
              onChange={(e) => {
                loanCalculator('purchasePrice', setFieldValue, values.downPaymentPercent, values.downPaymentAmount, e.target.value, values.loanAmount)
                handleChange(e)
              }}
              mask={currencyMask}
              guide={false}
            />
          </Input>
        </Form.Field>
        {/* {values.purchasePlan === 'contract' && (
          <Form.Field >
            <label>What is the appraised home value?</label>
            <Input
              className="small"
              error={!!(touched.propertyAppraisedValueAmount && errors.propertyAppraisedValueAmount) || !!(showErrorContainer && values.propertyAppraisedValueAmount === '')}
            >
              <MaskedInput
                name="propertyAppraisedValueAmount"
                disabled={coapplicantMode}
                placeholder="Amount"
                type="text"
                value={values.propertyAppraisedValueAmount}
                onBlur={handleBlur}
                onChange={(e: any) => handleChange(e)}
                mask={currencyMask}
                guide={false}
              />
            </Input>
          </Form.Field>
        )} */}
        <Form.Field className="down-payment">
          <label>What's the estimated down payment?</label>
          <span className='text-blue'>Amount will adjust to rounded percentage.</span>
          <Input
            className="small"
            error={!!(touched.downPaymentPercent && errors.downPaymentPercent) || !!(showErrorContainer && values.downPaymentPercent === '')}
          >
            <MaskedInput
              name="downPaymentPercent"
              disabled={coapplicantMode}
              placeholder="Percentage"
              type="text"
              value={`${values.downPaymentPercent}%`}
              onBlur={(e: any) => {
                handleBlur(e)
                loanCalculator('blur', setFieldValue, values.purchasePrice, values.downPaymentAmount, values.downPaymentPercent, values.loanAmount)
              }}
              onChange={(e) => {
                loanCalculator('downPaymentPercent', setFieldValue, values.purchasePrice, values.downPaymentAmount, e.target.value, values.loanAmount)
                handleChange(e)
              }}
              mask={percentMask}
              guide={false}
            />
            <div className="popup-icon">
              <HelperPopup
                headerLabel="Down Payment Percent"
                content={
                  <p className="popup-text">
                    This is the estimated, rounded percent you will pay down on your purchase. It will be use to calculate your down payment amount.
                  </p>
                }
              />
            </div>
          </Input >
          <Input
            className="small"
            error={!!(touched.downPaymentAmount && errors.downPaymentAmount) || !!(showErrorContainer && values.downPaymentAmount === '')}
          >
            <MaskedInput
              name="downPaymentAmount"
              disabled={coapplicantMode}
              placeholder="Amount"
              type="text"
              value={`$ ${values.downPaymentAmount}`}
              onBlur={(e) => {
                handleBlur(e)
                loanCalculator('blur', setFieldValue, values.purchasePrice, values.downPaymentAmount, values.downPaymentPercent, values.loanAmount)
              }}
              onChange={(e) => {
                loanCalculator('downPaymentAmount', setFieldValue, values.purchasePrice, e.target.value, values.downPaymentPercent, values.loanAmount)
                handleChange(e)
              }}
              mask={currencyMask}
              guide={false}
            />
            <div className="popup-icon">
              <HelperPopup
                headerLabel="Down Payment Amount"
                content={
                  <p>
                    This is the estimated amount you will pay down on your purchase based on the rounded percentage.
                  </p>
                }
              />
            </div>
          </Input >
        </Form.Field>
        {values.purchasePlan === 'contract' ?
          <Form.Field>
            <label>What is the closing date?</label>
            <div className="future-date">
              <FormikDatePicker
                className="small"
                disabled={coapplicantMode}
                name="closingDate"
                placeholder="MM/DD/YYYY"
                format="MM/DD/YYYY"
                minDate={futureDate()}
                icon="calendar alternate outline"
                value={values.closingDate}
                error={!!(touched.closingDate && errors.closingDate) || !!(showErrorContainer && values.closingDate === '')}
              />
              {showErrorContainer && values.closingDate !== null ? <span className="error-message">{errors.closingDate}</span> : null}
            </div>
          </Form.Field>
          : null}
      </div>
    </>
  ) : null;
}

export default PurchaseContractOffer;
