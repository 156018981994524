import React, {FC} from 'react';
import DashItemComponent, {DashItem} from '../../DashItem';

interface TaskTabProps {
  items?: DashItem[]
  v2?: boolean
  isCompletedTab?: boolean
}

const TaskTab: FC<TaskTabProps> = ({
  items,
  v2,
  isCompletedTab
}) => {
  return (
    <>
    {/* totalTasksRemaining and completed will show only tasks that have tasks remaining or have been completed */}
    {/* Solves for issue when a task is canceled, i.e. it has been removed and is not marked as completed */}
      {items && items.map((item, index) => item.totalTasksRemaining || item.completed ? (
        <DashItemComponent
          key={`${item.name}-${index}`}
          v2={v2}
          {...item}
          isCompletedTab={isCompletedTab}
        />
      ) : null)}
    </>
  )
}

export default TaskTab
