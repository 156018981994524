import React, {FC} from 'react';
import {Button, Table} from 'semantic-ui-react';
import {useHistory} from 'react-router-dom';
import {SectionTable} from './index';
import { tableValuesPipe } from '../../../helpers/table-values-pipe';

interface SummaryTableProps {
  loanData: any;
  title: string;
  sectionTable: SectionTable;
  route?: string;
}

const SummaryTable: FC<SummaryTableProps> = ({
  loanData,
  title,
  sectionTable,
  route
}) => {
  const history = useHistory();

  return (
    <div className="summary-section summary-table">
      <h3 className="summary-section-title">{title}</h3>
      <Table
        basic='very'
        fixed
      >
        <Table.Header>
          <Table.Row>
            {sectionTable.headerLabels.map((label, index) => (
              <Table.HeaderCell key={`${label}-${index}`}>{label}</Table.HeaderCell>
            ))}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sectionTable.tableData.map((row, index) => (
            <Table.Row key={`${JSON.stringify(row)}-${index}`}>
              {Object.entries(row).map(([key, value], index) => {
                // Only display as many table cells as there are header cells
                return index < sectionTable.headerLabels.length
                  ? <Table.Cell key={`${key?.toString()}-${value?.toString()}-${index}`}>
                    {tableValuesPipe(key, value, loanData)}
                    </Table.Cell>
                  : null
              })}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
      <div className="summary-section-mobile-container">
        {sectionTable.tableData.map((row, rowIndex) => (
          <div
            key={`${JSON.stringify(row)}-${rowIndex}`}
            className="summary-section"
          >
            <div className="details-container">
              {Object.entries(row).map(([key, value], objectIndex) => {
                return objectIndex < sectionTable.headerLabels.length ? (
                  <div
                    key={`${key?.toString()}-${objectIndex}`}
                    className="detail"
                  >
                    <label>{sectionTable.headerLabels[objectIndex]}</label>
                    <span className="value">{tableValuesPipe(key, value, loanData) || '-'}</span>
                  </div>
                ) : null
              })}
            </div>
          </div>
        ))}
      </div>
      {sectionTable.tableData.length === 0 && (
        <div className="empty-table-container">
          <h3 className="summary-section-title">No {title[title.length - 1] === 's' ? title : `${title}s`} have been added</h3>
        </div>
      )}
      <div className="summary-section-footer ">
        <Button
          type="button"
          className="alternate-button"
          onClick={() => history.push(`${route}`)}
          aria-label={`Edit ${title}`}
        >
          Edit
        </Button>
      </div>
    </div>
  )
}

export default SummaryTable
