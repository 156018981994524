import React, { FC } from 'react';
import { Form, Select } from 'semantic-ui-react';
import {AssetTypeOption} from '../dropdown-data';
import ApplicationFormFooter from '../../shared/ApplicationFormFooter';
import {AssetType} from '../asset-types';

interface AssetTypeSelectionProps {
  assetTypeOptions: AssetTypeOption[]
  assetType: AssetType
  setAssetType: Function
  onCancelClick: Function
  tableData?: any
}

const AssetTypeSelection: FC<AssetTypeSelectionProps> = ({
  assetTypeOptions,
  assetType,
  setAssetType,
  onCancelClick,
  tableData
}) => {
  const assetOptions = assetTypeOptions.filter(option => !tableData.find((row: any) => row.basic_asset_type === option.value))

  return (
    <form className="application-form form-view asset-type-selection-form">
      <div className="form-step">
        <Form.Field>
          <label>Asset Type</label>
          <Select
            selectOnBlur={false}
            className="large"
            clearable
            name="assetType"
            placeholder="Asset Type..."
            options={assetOptions}
            value={assetType}
            // @ts-ignore
            onChange={(e, { value }) => setAssetType(value)}
          />
        </Form.Field>
      </div>
      {assetType === '' && (
        <ApplicationFormFooter
          onCancelClick={onCancelClick}
          nextButtonVisible={false}
        />
      )}
    </form>
  )
}

export default AssetTypeSelection
