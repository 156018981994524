import { useField, ErrorMessage } from 'formik';

const FormikErrorMessage = (props: any) => {
  const [field, meta, helpers] = useField(props.name);

  return (
    <span className="error-message"><ErrorMessage name={props.name} /></span>
  )
}

export default FormikErrorMessage;
