import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import ProgressBarStepper from '../../components/ProgressBarStepper/ProgressBarStepper';
import { ProgressBarStep } from '../../types/progress-bar-step';
import './loan-application.scss';
import { DataContext } from '../../context/dataContext';
import { useStore } from '@jmjfinancial/apis/lib';
import { observer } from 'mobx-react-lite';

export enum ApplicationRoutes {
  '/application/start',
  '/application/purchase',
  '/application/refinance',
  '/application/borrower-info',
  '/application/auth-and-consent',
  '/application/add-co-applicant',
  '/application/auth-and-consent-co-borrower',
  '/application/co-borrower-info',
  '/application/residences',
  '/application/loan-details',
  '/application/employment-and-income',
  '/application/assets',
  '/application/reo',
  '/application/declarations',
  '/application/declarations-coborrower',
  '/application/demographics',
  '/application/demographics-coborrower',
  '/application/review-and-submit',
}

export enum ApplicationCompletedSteps {
  'borrower_info_done',
  'auth_and_consent_done',
  'add_co_applicant_done',
  'co_borrower_auth_and_consent_done',
  'co_borrower_info_done',
  'residences_done',
  'loan_details_done',
  'employment_and_income_done',
  'assets_done',
  'reo_done',
  'declarations_done',
  'declarations_coborrower_done',
  'demographics_done',
  'demographics_coborrower_done',
  'review_and_submit_done',
}

const DEFAULT_APPLICATION_STEPS: ProgressBarStep[] = [
  {
    title: 'Start',
    hidden: true,
    hideTracker: true
  },
  {
    title: 'Purchase',
    hidden: true,
    hideTracker: true
  },
  {
    title: 'Refinance',
    hidden: true,
    hideTracker: true
  },
  {
    title: 'Borrower Information'
  },
  {
    title: 'Authorization and Consent'
  },
  {
    title: 'Co-Applicant',
    hideTracker: true
  },
  {
    title: 'Co-borrower Authorization and Consent',
    hidden: true
  },
  {
    title: 'Co-borrower',
    hidden: true
  },
  {
    title: 'Residences',
    hideTracker: true
  },
  {
    title: 'Loan Details'
  },
  {
    title: 'Employment and Income',
    hideTracker: true
  },
  {
    title: 'Assets',
    hideTracker: true
  },
  {
    title: 'Real Estate Owned',
    hideTracker: true
  },
  {
    title: 'Declarations'
  },
  {
    title: 'Co-borrower Declarations',
    hidden: true
  },
  {
    title: 'Demographics'
  },
  {
    title: 'Co-borrower Demographics',
    hidden: true
  },
  {
    title: 'Review and Submit'
  }
]

const APPLICATION_STEPS_BLACK_LIST: string[] = ['Start', 'Purchase', 'Refinance']

export enum LoanType {
  purchase = 'purchase',
  refinance = 'refinance'
}

const LoanApplication: FC = observer((props) => {
  const store = useStore()
  const { loansService } = store

  const {
    pathname,
    activeLoan,
    clearStaleUser
  } = useContext(DataContext)

  const [loanData, setLoanData] = useState<any>()
  const [overrideTrackerVisibility, setOverrideTrackerVisibility] = useState<boolean>()
  const [reviewMode, setReviewMode] = useState(false)
  const [coapplicantMode, setCoapplicantMode] = useState(false)
  const [appSteps, setAppSteps] = useState<Array<ProgressBarStep>>(DEFAULT_APPLICATION_STEPS)

  const refLoanType = useRef<LoanType | undefined>()

  const loanApplicationContext = {
    ...useContext(DataContext),
    overrideTrackerVisibility,
    setOverrideTrackerVisibility,
    reviewMode,
    setReviewMode,
    coapplicantMode,
    setCoapplicantMode,
    refLoanType
  }

  useEffect(() => {
    console.log('LoanApplication useEffect()')
    loansService.getLoan(activeLoan).then(loan => {
      if (loan?.data?.form_data) {
        setLoanData(loan.data.form_data)
      }
      else {
        clearStaleUser()
      }

      if (loan?.data?.form_data.application.submitted) {
        setReviewMode(true)
      }

      if (loan?.data?.form_data.borrower.id !== loan?.data?.form_data.loan.principal_borrower_id) {
        setCoapplicantMode(coapplicantMode => true)
      }
    })
    setOverrideTrackerVisibility(false)
  }, [pathname, activeLoan])

  // Updates `refLoanType.current` to latest state
  useEffect(() => {
    refLoanType.current = loanData?.loan.loan_purpose_type
    let updatedAppSteps = [...appSteps]
    let loanDetailsStep = updatedAppSteps[9]

    //Hides or shows the Loan Details step based on the loan purpose type
    if  (refLoanType.current === LoanType.refinance) {
      loanDetailsStep.hidden = true
    }
    else {
      loanDetailsStep.hidden = false
    }

    setAppSteps(appSteps => updatedAppSteps)
  }, [loanData])

  return (
    <DataContext.Provider value={loanApplicationContext}>
      <div className="loan-application-page">
        <ProgressBarStepper
          loanData={loanData}
          stepsList={appSteps}
          stepsBlackList={APPLICATION_STEPS_BLACK_LIST}
        />
        {props.children}
      </div>
    </DataContext.Provider>
  )
});

export default LoanApplication;
