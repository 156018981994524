import { useField } from 'formik';
import SemanticDatepicker from 'react-semantic-ui-datepickers';

const FormikDatePicker = (props: any) => {
  const [field, meta, helpers] = useField(props.name);

  return (
    <div className="date-picker">
      <SemanticDatepicker
        {...props}
        value={field.value}
        onChange={(e, v) =>
          helpers.setValue(v.value)
        }
      />
    </div>
  )
}

export default FormikDatePicker;
