import React, {FC, useContext} from 'react';
import {Popup, StrictPopupProps} from 'semantic-ui-react';
import {DataContext} from '../../context/dataContext';

export interface LabelPopup {
  label: string
  verboseLabel?: string
  position?: StrictPopupProps['position']
}

const LabelPopupComponent: FC<LabelPopup> = ({
  label,
  verboseLabel = label,
  position
}) => {
  const {
    isMobileOnly,
    isTablet
  } = useContext(DataContext)

  const defaultPosition: StrictPopupProps['position'] = isMobileOnly || isTablet ? 'bottom center' : 'bottom right'

  return (
    <Popup
      className={`task-label-popup ${isMobileOnly ? 'is-mobile' : ''} ${isTablet ? 'is-tablet' : ''}`}
      trigger={
        <span className="label-text">{label}</span>
      }
      basic
      wide
      on={['hover']}
      hideOnScroll
      offset={[0, 20]}
      position={position || defaultPosition}
    >
      <span className="verbose-label">{verboseLabel}</span>
    </Popup>
  )
}

export default LabelPopupComponent
