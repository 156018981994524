import React, { FC, useContext } from "react";
import { Icon } from "semantic-ui-react";
import { DataContext } from "../../context/dataContext";
import { useHistory } from "react-router-dom";

export interface DashAction {
  label: any;
  // TODO: Replace `upload` with `type`, and add unique returns for each `type`
  type?: string;
  completed?: boolean;
  rejected: boolean;
  href?: string;
  task?: any;
  location?: string;
  v2?: boolean;
}

const DashActionComponent: FC<DashAction> = ({
  label,
  type,
  completed,
  rejected,
  href,
  task,
  location,
  v2,
}) => {
  const {
    setShowUploadModal,
    setShowDownloadModal,
    setShowDisclosureModal,
    setModalHeader,
    setModalSubheader,
    setTaskId,
    setActiveTask,
    setIsDisclosureTask,
  } = useContext(DataContext);
  const history = useHistory();

  if (task.action === "asset_forms") {
    console.log(task);
  }

  const handleActionClick = () => {
    if (task.status === "canceled") {
      return;
    } else if (type === "ESignEnvelope" && task.status === "completed") {
      handleDownload();
    } else if (type === "ESignEnvelope") {
      handleSign();
    } else if (task.action === "asset_forms") {
      history.push(`/worksheet/assets?taskId=${task.id}`);
    } else if (task.action === "pay_fees") {
      history.push(`/invoice/${task.deliverable_id}`);
    } else {
      !completed ? handleUpload() : handleDownload();
    }
  };

  const handleUpload = () => {
    setModalHeader(label);
    setModalSubheader(`Upload ${task.description}`);
    setTaskId(task.id);
    setShowUploadModal(true);
  };

  const handleDownload = () => {
    setTaskId(task.id);
    setModalSubheader(`Documents for ${task.description}`);
    setShowDownloadModal((showDownloadModal: boolean) => !showDownloadModal);
    setIsDisclosureTask(task.deliverable_type === "ESignEnvelope");
  };

  const handleSign = () => {
    setActiveTask((activeTask: any) => task);
    setShowDisclosureModal(
      (showDisclosureModal: boolean) => !showDisclosureModal
    );
  };

  const DashActionIcon: FC = () => {
    if (!v2) {
      if (!completed) {
        return (
          <Icon
            name={type === "upload" ? "upload" : "pencil"}
            className="white"
          />
        );
      } else {
        return <Icon name="check" className="white" />;
      }
    } else {
      return null;
    }
  };

  if (href) {
    return (
      <a
        target={location ? "_self" : "_blank"}
        href={href}
        className="dash-action contact-action"
      >
        <label>{label}</label>
      </a>
    );
  } else {
    return (
      <>
        <div
          className={`dash-action ${completed ? "is-completed" : ""} ${
            rejected ? "is-rejected" : ""
          }`}
          onClick={() => handleActionClick()}
        >
          <DashActionIcon />
          <label>{label}</label>
        </div>
      </>
    );
  }
};

export default DashActionComponent;
