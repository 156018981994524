import React, {FC, useContext, useEffect, useState} from 'react';
import { Button, Form, Input, Modal } from 'semantic-ui-react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Redirect, useHistory } from 'react-router-dom';
import LoaderOverlay from '../../components/LoaderOverlay/LoaderOverlay';
import { DataContext } from '../../context/dataContext';
import { useStore } from '@jmjfinancial/apis/lib';
import { observer } from 'mobx-react-lite';
import { handleError } from '../../helpers/response-handler';
import {scrollToDefaultOptions} from '../../helpers/scroll-to-options';
import ForgotPasswordModal from '../../components/Modals/ForgotPasswordModal/ForgotPasswordModal';
import { clearValues } from '../../helpers/clear-values-helper';

const ForgotPassword: FC = observer(() => {
  const store = useStore();
  const { authService } = store;
  const history = useHistory();
  const { user } = useContext(DataContext)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [errorModal, setErrorModal] = useState<string>('')

  useEffect(() => {
    window.scrollTo(scrollToDefaultOptions)
  }, [])

  return (
    !user ? (
      <div className="landing-container">
        <div className="landing-content">
          <h1 className="title">Enter your E-Mail Address</h1>
          <Formik
            initialValues={{
              email: '',
              submit: null
            }}
            validationSchema={Yup.object().shape({
              email:
                Yup.string()
                  .email('Must be a valid email')
                  .max(255)
                  .required('Email is required')
            })}
            validateOnBlur={false}
            onSubmit={async (values, {
              setErrors,
              setStatus,
              setSubmitting
            }) => {
              try {
                await authService.forgot(values.email).then(res => {
                  if (res.data.message === "email sent") {
                    setStatus({ success: true });
                    setSubmitting(false);
                    setErrorModal('');
                  }
                  else if (res.data.error.some((message: string) => message === "Email address not found. Please check and try again.")) {
                    setErrorModal(res.data.error[0])
                  }
                  clearValues(values, ['email'])
                  console.log({res})

                  setShowModal(true)
                });
              } catch (err: any) {
                handleError(err)
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <>
                <LoaderOverlay
                  active={isSubmitting}
                  label="Sending password reset confirmation to your email..."
                />
                <form
                  noValidate
                  onSubmit={handleSubmit}
                  className="auth-form"
                >
                  <div className="error-container">
                    {(touched.email && errors.email)
                      || errors.submit
                    }
                  </div>
                  <Form.Field>
                    <Input
                      name="email"
                      placeholder="Your Email"
                      type="email"
                      className="auth-field"
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={Boolean(touched.email && errors.email)}
                    />
                  </Form.Field>
                  <ForgotPasswordModal
                    showModal={showModal}
                    setShowModal={setShowModal}
                    email={true}
                  />
                  <div className="landing-controls form-controls">
                    <Button
                      type="submit"
                      className="landing-button auth-button"
                    >
                      Submit
                    </Button>
                  </div>
                </form>
              </>
            )}
          </Formik>
        </div>
        <div className="landing-graphic" />
      </div>
    ) : <Redirect to={'/dashboard'} />
  )
});

export default ForgotPassword;
