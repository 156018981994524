import React, {FC, useContext, useEffect, useState} from 'react';
import {Step} from 'semantic-ui-react';
import {ProgressBarStep} from '../../types/progress-bar-step';
import 'react-circular-progressbar/dist/styles.css';
import './progress-bar-stepper.scss';
import {useHistory} from 'react-router';
import {ApplicationRoutes, ApplicationCompletedSteps} from '../../views/LoanApplication';
import {findLastIndex} from '../../helpers/data-index';
import {DataContext} from '../../context/dataContext';
import MobileProgressBar from './MobileProgressBar';

interface ProgressBarStepperProps {
  loanData: any;
  stepsList: ProgressBarStep[];
  stepsBlackList?: string[];
}

const ProgressBarStepper: FC<ProgressBarStepperProps> = ({
  loanData,
  stepsList,
  stepsBlackList = []
}) => {
  const history = useHistory()

  const { pathname, overrideTrackerVisibility, reviewMode } = useContext(DataContext)

  const [steps, setSteps] = useState<ProgressBarStep[]>(stepsList);
  const [activeStepIndex, setActiveStepIndex] = useState(ApplicationRoutes[pathname as keyof typeof ApplicationRoutes])
  const [activeStep, setActiveStep] = useState<ProgressBarStep>(steps[activeStepIndex]);
  const [previousStepIndex, setPreviousStepIndex] = useState<number>()

  // Filters out "hidden" steps from being visible, but still allows navigation
  const visibleSteps = steps.filter(step => !step.hidden);

  const getActiveStepIndex = (): number => {
    return steps.findIndex(step => step === activeStep);
  }

  /**
   * Returns true if index is less than activeStep index,
   * or if index is equal to the previous step,
   * or if the step is completed,
   * or if the the index is equal to the first incomplete step,
   * or if in review mode
   * @param index value of current iteration
   */
  const allowClickNavigation = (index: number): boolean => {
    let firstIncompleteStep = findLastIndex(steps, (step => step.completed === true)) + 1

    return index < getActiveStepIndex()
    || index === previousStepIndex
    || steps[index]?.completed
    || index === firstIncompleteStep
    || reviewMode
  }

  /**
   * Updates active step index, and sets `previousStepIndex` when active
   * steps index is greater than the last step completed, then routes
   * to the correct path based on index
   * @param index value of current iteration
   */
  const handleUpdateActiveStep = (index: number) => {
    const activeStepIndex = getActiveStepIndex()

    setActiveStep(steps[index])

    if (activeStepIndex > findLastIndex(steps, (step => step.completed === true))) {
      setPreviousStepIndex(activeStepIndex)
    }

    history.push(ApplicationRoutes[index])
  }

  /**
   * Updates `completed` values of steps array to match hive values of
   * `application.completed_steps` for all steps NOT included in the
   * `blackList` array
   * @param steps array of progress bar steps
   */
  const updateCompletedSteps = (steps: ProgressBarStep[]): ProgressBarStep[] => {
    steps.forEach((step, index) => {
      const skipStep = stepsBlackList.includes(step.title)
      const filteredIndex = index - stepsBlackList.length

      if (!skipStep && loanData?.application?.completed_steps && loanData.application.completed_steps[ApplicationCompletedSteps[filteredIndex]]) {
        steps[index].completed = true
      }
      else {
        steps[index].completed = false
      }
    })

    return steps
  }

  /**
   * Updates active step and it's settings based on `loanData`
   * and `overrideTrackerVisibility`
   */
  const updateActiveSteps = () => {
    let updatedSteps: ProgressBarStep[] = [...steps]

    const stepIndex: number = ApplicationRoutes[pathname as keyof typeof ApplicationRoutes]

    if (overrideTrackerVisibility != undefined) {
      updatedSteps[stepIndex].hideTracker = overrideTrackerVisibility
    }
    else {
      updatedSteps[stepIndex].hideTracker = false
    }

    setSteps(updateCompletedSteps(updatedSteps))
    setActiveStepIndex(stepIndex)
    setActiveStep(steps[stepIndex])
  }

  useEffect(() => {
    updateActiveSteps()
  }, [overrideTrackerVisibility, loanData, pathname])

  return (
    !steps[ApplicationRoutes[pathname as keyof typeof ApplicationRoutes]]?.hideTracker ? (
      <>
        <Step.Group className="progress-bar-stepper">
          {steps.map((step, index) => (
            !step.hidden ? (
              <Step
                key={step.title}
                active={step.title === activeStep.title}
                completed={step.completed}
                style={{width: 1 / visibleSteps.length}}
                className={step.hasError ? 'has-error' : ''}
                onClick={allowClickNavigation(index) ? (e) => handleUpdateActiveStep(index) : undefined}
              >
                <Step.Content>
                  <Step.Title>{step.title}</Step.Title>
                  <Step.Description>{step.subTitle}</Step.Description>
                </Step.Content>
              </Step>
            ) : null
          ))}
        </Step.Group>
        <MobileProgressBar
          steps={steps}
          visibleSteps={visibleSteps}
          activeStep={activeStep}
          activeStepIndex={activeStepIndex}
          allowClickNavigation={allowClickNavigation}
          handleUpdateActiveStep={handleUpdateActiveStep}
        />
      </>
    ) : null
  );
}

export default ProgressBarStepper;
