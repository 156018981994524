import React, { FC, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import _ from 'lodash';
import { Formik, Field } from 'formik';
import { Form, Button } from 'semantic-ui-react';
import { DataContext } from '../../../context/dataContext';
import { observer } from 'mobx-react-lite';
import LoaderOverlay from '../../../components/LoaderOverlay/LoaderOverlay';
import { useStore } from '@jmjfinancial/apis/lib';
import { ApplicationRoutes } from '../index';
import './auth-and-consent.scss'
import HelperPopup from '../../../components/Popup/Popup';
import ErrorContainer from '../../../components/ErrorContainer';
import {scrollToDefaultOptions} from '../../../helpers/scroll-to-options';
import {OwnerType} from '../../../types/loan';

const AuthorizationAndConsent: FC = observer(() => {
  const store = useStore();
  const { loansService } = store;

  const {
    activeLoan,
    pathname,
    reviewMode,
  } = useContext(DataContext)
  const [loanData, setLoanData] = useState<any>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [showErrorContainer, setShowErrorContainer] = useState<boolean>(false)
  const [disableOnErrors, setDisableOnErrors] = useState<boolean>(false)
  const [owner, setOwner] = useState<OwnerType>('borrower')
  const [ownerLabel, setOwnerLabel] = useState<string>('Borrower')

  const history = useHistory();

  const handlePreviousClick = () => {
    if (owner === 'borrower') {
      history.push('/application/borrower-info')
    }
    else {
      history.push('/application/add-co-applicant')
    }
  }

  const handleNextClick = () => {
    if (reviewMode) {
      history.push('/application/review-and-submit')
    }
    else {
      if (owner === 'borrower') {
        history.push('/application/add-co-applicant')
      }
      else {
        history.push('/application/co-borrower-info')
      }
    }
  }

  useEffect(() => {
    loansService.getLoan(activeLoan)
      .then(loan => {
        setLoanData(loan?.data?.form_data)
        setIsLoading(false)
      })
    if (pathname.includes('co-borrower')) {
      setOwner(owner => 'coborrower')
      setOwnerLabel(ownerLabel => 'Co-Borrower')
    }
    else {
      setOwner(owner => 'borrower')
      setOwnerLabel(ownerLabel => 'Borrower')
    }
  }, [activeLoan, loansService])

  useEffect(() => {
    window.scrollTo(scrollToDefaultOptions)
  }, [])

  return (
    <div className="application-step-container auth-and-consent">
      <LoaderOverlay active={isLoading} />
      {!isLoading && (
        <Formik
          initialValues={{
            creditCheck: owner === 'borrower'
              ? loanData.borrower.authorized_credit_report || null
              : loanData.coborrower.authorized_credit_report || null,
            econsent: owner === 'borrower'
              ? loanData.borrower.authorized_econsent || null
              : loanData.coborrower.authorized_econsent || null,
            submit: null
          }}
          validationSchema={Yup.object().shape({
            creditCheck: Yup.boolean().oneOf([true], 'Must agree to all fields in order to continue'),
            econsent: Yup.boolean().oneOf([true], 'Must agree to all fields in order to continue'),
          })}
          onSubmit={async (values, {
            setErrors,
            setStatus,
            setSubmitting
          }) => {
            try {

              let changedData;

              if (owner === 'borrower') {
                changedData = {
                  application: {
                    current_step: ApplicationRoutes[pathname as keyof typeof ApplicationRoutes] + 1,
                    completed_steps: {
                      auth_and_consent_done: true
                    }
                  },
                  borrower: {
                    authorized_credit_report: values.creditCheck,
                    authorized_econsent: values.econsent
                  }
                }
              } else {
                changedData = {
                  application: {
                    current_step: ApplicationRoutes[pathname as keyof typeof ApplicationRoutes] + 1,
                    completed_steps: {
                      co_borrower_auth_and_consent_done: true
                    }
                  },
                  coborrower: {
                    authorized_credit_report: values.creditCheck,
                    authorized_econsent: values.econsent
                  }
                }
              }

              const dataSubmit = _.merge(loanData, changedData)

              await loansService.updateLoan(activeLoan, dataSubmit)

              setStatus({ success: true });
              setSubmitting(false);

              handleNextClick();
            } catch (err: any) {
              console.error('Application error: ', err.message);
              setStatus({ success: false });
              setErrors({ submit: err.message })
              setSubmitting(false)
            }
          }}
        >
          {({
            errors,
            handleSubmit,
            isSubmitting,
          }) => (
            <>
              <form
                onSubmit={handleSubmit}
                className="application-form auth-and-consent-form"
              >
                <div className="auth-question-container econsent">
                  <div className="title-container">
                    <h1 className="title text-light-blue auth-and-consent">{ownerLabel} eConsent</h1>
                    <HelperPopup
                      additionalClass="auth-popup"
                      headerLabel={`${ownerLabel} eConsent`}
                      content={
                        <p>
                          The loan for which you are applying involves various disclosures, records, and documents
                          (‘Loan Documents’), including this eDisclosure Agreement. The purpose of this eDisclosure
                          Agreement is to obtain your consent to receive certain Loan Documents from us in electronic form,
                          rather than paper. With your consent, you will also be able to sign and authorize these Loan
                          Documents electronically, rather than on paper.
                          <br />
                          <br />
                          Before we can engage in this transaction electronically, it is important that you understand
                          your rights and responsibilities. Please read the following and affirm your consent to conduct
                          business with us electronically. For the purposes of this eDisclosure Agreement, ‘eDisclosures’ means
                          the Loan Documents related to this transaction that are provided electronically; ‘You’ and ‘Your’
                          mean the borrower(s) under the applicable loan to which such Loan Documents apply; and, ‘We’,
                          ‘Our’, and ‘Us’ mean the applicable mortgage broker(s), loan processor(s), or mortgage banker(s)
                          with whom you are transacting business for such loan(s).
                        </p>
                      }
                      offset={[0, 20]}
                    />
                  </div>
                  <Form.Field>
                    <div className="auth-checkbox-container">
                      <Field
                        type="checkbox"
                        id="econsent"
                        name="econsent"
                        className="auth-checkbox"
                      />
                      <label htmlFor="econsent">
                        <span className="text-light-blue">"I Agree"</span> to consent to do business electronically <br /><br /> and to view Loan Documents electronically.
                      </label>
                    </div>
                  </Form.Field>
                </div>
                <div className="auth-question-container">
                  <div className="title-container">
                    <h1 className="title text-light-blue auth-and-consent">{ownerLabel} Credit Authorization</h1>
                    <HelperPopup
                      additionalClass="auth-popup"
                      headerLabel={`${ownerLabel} Credit Authorization`}
                      content={
                        <p className="auth-content">
                          During your mortgage loan application process, this institution performs a credit check
                          that requires us to obtain and confirm information regarding your personal and financial
                          background. This credit check includes, but is not limited to, your marital status, number
                          of dependents, current and past employers, current deposit accounts, current and past
                          consumer credit accounts, and your mortgage and/or rental history.
                        </p>
                      }
                      offset={[0, 20]}
                    />
                  </div>
                  <Form.Field>
                    <div className="auth-checkbox-container">
                      <Field
                        type="checkbox"
                        id="creditCheck"
                        name="creditCheck"
                        className="auth-checkbox"
                      />
                      <label htmlFor="creditCheck">
                        <span className="text-light-blue">"I authorize"</span> this credit check.
                      </label>
                    </div>
                  </Form.Field>
                </div>
                <div className="application-step-footer">
                  <Button
                    type="button"
                    className="form-previous-button"
                    onClick={handlePreviousClick}
                  >
                    Previous
                  </Button>
                  <ErrorContainer
                    errors={errors}
                    showErrorContainer={showErrorContainer}
                    setDisableOnErrors={setDisableOnErrors}
                    errorMessage="Must agree to all fields to continue"
                  />
                  <Button
                    disabled={isSubmitting || disableOnErrors}
                    color="blue"
                    type="submit"
                    className="save form-save-button"
                    onClick={() => setShowErrorContainer(showErrorContainer => true)}
                  >
                    Next
                  </Button>
                </div>
              </form>
            </>
          )}
        </Formik>
      )}
    </div>
  )
})

export default AuthorizationAndConsent;
