import { Field, Formik } from 'formik';
import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { Button, Icon, Input, Modal, Tab, Table } from 'semantic-ui-react';
import { DataContext } from '../../../context/dataContext';
import { useStore } from '@jmjfinancial/apis/lib';
import { ModalProps } from '../../../types/modal';
import DocumentPreview from '../../DocumentPreview';
import HelperPopup from '../../Popup/Popup';

interface RejectedTasksModalProps extends ModalProps {
  borrowerPairId: number;
  taskId?: number;
  rejectedTasksArray: any;
  setRejectedModalViewed: Function
}

const RejectedTasksModal: FC<RejectedTasksModalProps> = observer(({
  showModal,
  setShowModal,
  taskId,
  borrowerPairId,
  rejectedTasksArray,
  setRejectedModalViewed
}) => {
  const { activeLoan } = useContext(DataContext)
  const { loansService, tasksService } = useStore();

  const [property, setProperty] = useState<string>('');
  const [refreshModal, setRefreshModal] = useState<boolean>(false);
  const [progressOverlayTableOffset, setProgressOverlayTableOffset] = useState<number>(0);
  const [progressOverlayMobileOffset, setProgressOverlayMobileOffset] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string>();

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const mobileContainerRef = useRef<HTMLDivElement>(null);

  const handleDisableSubmit = (values: any): boolean => {
    return !(values.rejectedTasks.some((value: any) => value.acknowledge_rejection === true))
  }

  const handleCloseModal = () => {
    setRejectedModalViewed(true)
    setShowModal(false)
  }

  useEffect(() => {
    if (!taskId) {
      loansService.getLoan(activeLoan).then(l => {
        const loan = l?.data?.form_data

        if (loan) {
          setProperty(property =>
            loan.property?.address_street_line !== ""
              ? loan.property?.address_street_line
              : `${loan.property?.address_city}, ${loan.property?.address_state}`
          )
        }
      })
    }
    setRefreshModal(false)
  }, [activeLoan, refreshModal]);

  useEffect(() => {
    const tableNode = tableContainerRef.current
    const mobileNode = mobileContainerRef.current

    tableNode?.addEventListener('scroll',
      () => setProgressOverlayTableOffset(progressOverlayOffset => tableNode?.scrollTop)
    )
    mobileNode?.addEventListener('scroll',
      () => setProgressOverlayMobileOffset(progressOverlayOffset => mobileNode?.scrollTop)
    )

    return () => {
      tableNode?.removeEventListener('scroll', () => {})
      mobileNode?.removeEventListener('scroll', () => {})
    }
  })

  return (
    <Formik
      initialValues={{ rejectedTasks: rejectedTasksArray }}
      onSubmit={async (values) => {
        try {
          const changedData = values.rejectedTasks.map((value: any) => value.acknowledge_rejection === true ? value.id : null)
          console.log({changedData})

          await tasksService.dismissRejectedTasks(borrowerPairId, changedData).then(res => {
            if (res.data.success === false) {
              setErrorMessage(errorMessage => `There's been a problem dismissing your tasks. Please try again.`)
            }
          })
          setShowModal(false);

        } catch (error) {
          console.error('Dismiss Rejected Tasks Error', error)
        }
        setRefreshModal(true)
      }}
    >
      {({
        values,
        handleSubmit
      }) => (
        <Modal
          className={`
            default-modal
            download-documents-modal
            rejected-tasks-modal
            ${rejectedTasksArray.length > 1 ? ' has-multiple-documents' : ''}
            ${errorMessage && ' has-error'}
          `}
          open={showModal}
          onClose={() => handleCloseModal()}
          onOpen={() => setShowModal(true)}
        >
          <Icon
            name="close"
            className="close-icon"
            onClick={() => setShowModal(false)}
          />
          <span className='modal-header'>Rejected Tasks</span>
          <span className='modal-subheader'>The following tasks have been rejected with the provided reason</span>
          <div
            ref={tableContainerRef}
            className="downloads-table-container"
          >
            <Table basic="very" unstackable>
              <Table.Header>
                <Table.Row className='header-row'>
                  <Table.HeaderCell>Task Name</Table.HeaderCell>
                  <Table.HeaderCell>Upload Date</Table.HeaderCell>
                  <Table.HeaderCell>Reason</Table.HeaderCell>
                  <Table.HeaderCell>Dismiss</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {rejectedTasksArray.map((task: any, index: number) => {
                  return (
                    <Table.Row key={`${task.id}`}>
                      <Table.Cell>{task.description}</Table.Cell>
                      <Table.Cell>{new Date(task.updated_at).toLocaleDateString()}</Table.Cell>
                      <Table.Cell>{task.reason}</Table.Cell>
                      <Table.Cell className='dismiss-rejection'>
                        <Field
                          type="checkbox"
                          name={`${task.acknowledge_rejection}`}
                          checked={task.acknowledge_rejection}
                          value={task.acknowledge_rejection}
                          onClick={() => task.acknowledge_rejection = !task.acknowledge_rejection}
                        />
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
          </div>
          <div
            ref={mobileContainerRef}
            className="downloads-modal-mobile-container"
          >
            {rejectedTasksArray.map((task: any) => (
              <div className="details-container" key={task.id}>
                <div className="details">
                  <label>Task Name</label>
                  <span className='value'>{task.description}</span>
                </div>
                <div className="details">
                  <label>Upload Date</label>
                  <span className='value'>{new Date(task.updated_at).toLocaleDateString()}</span>
                </div>
                <div className="details">
                  <label>Reason</label>
                  <span className='value'>{task.reason}</span>
                </div>
                <div className="details">
                  <label>Dismiss</label>
                  <Field
                    type="checkbox"
                    name={`${task.acknowledge_rejection}`}
                    checked={task.acknowledge_rejection}
                    value={task.acknowledge_rejection}
                    onClick={() => task.acknowledge_rejection = !task.acknowledge_rejection}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="download-documents-modal-footer">
            <span className='error-message'>{errorMessage}</span>
            <Button
              className='cancel-button'
              onClick={() => setShowModal(false)}
            >
              Cancel
            </Button>
            <Button
              className='download-all-button'
              type="submit"
              disabled={handleDisableSubmit(values)}
              onClick={() => handleSubmit()}
            >
              Dismiss
            </Button>
          </div>
        </Modal>
      )}
    </Formik>
  )
})

export default RejectedTasksModal;
