export const yesNoOptions = [
  {
    text: 'Yes',
    value: 'yes',
    key: 'yes'
  },
  {
    text: 'No',
    value: 'no',
    key: 'no'
  }
]

export const housingTypeOptions = [
  {
    text: 'Rent',
    value: 'rent',
    key: 'rent'
  },
  {
    text: 'Rent Free',
    value: 'free',
    key: 'free'
  }
]

export const yearsOptions = [
  {
    text: '0',
    value: 0
  },
  {
    text: '1',
    value: 1
  },
  {
    text: '2',
    value: 2
  },
  {
    text: '3',
    value: 3
  },
  {
    text: '4',
    value: 4
  },
  {
    text: '5',
    value: 5
  },
  {
    text: '6',
    value: 6
  },
  {
    text: '7',
    value: 7
  },
  {
    text: '8',
    value: 8
  },
  {
    text: '9',
    value: 9
  },
  {
    text: '10+',
    value: 10
  },
]

export const monthsOptions = [
  {
    text: '0',
    value: 0
  },
  {
    text: '1',
    value: 1
  },
  {
    text: '2',
    value: 2
  },
  {
    text: '3',
    value: 3
  },
  {
    text: '4',
    value: 4
  },
  {
    text: '5',
    value: 5
  },
  {
    text: '6',
    value: 6
  },
  {
    text: '7',
    value: 7
  },
  {
    text: '8',
    value: 8
  },
  {
    text: '9',
    value: 9
  },
  {
    text: '10',
    value: 10
  },
  {
    text: '11',
    value: 11
  }
]
