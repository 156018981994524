import React, {FC, useContext, useEffect, useState} from 'react';
import { Button } from 'semantic-ui-react';
import {Link, useParams} from 'react-router-dom';
import {scrollToDefaultOptions} from '../../helpers/scroll-to-options';
import {DataContext} from '../../context/dataContext';
import EmployeeDisclosureModal from '../../components/Modals/DisclosureModal/EmployeeDisclosureModal';


const EmployeeEsign: FC = () => {
  const {
    setDarkThemeOverride,
    setReferrer
  } = useContext(DataContext);
  const { referrer } = useParams<any>()

  const [showDisclosureModal, setShowDisclosureModal] = useState<boolean>(false)

  const esignContext = {
    ...useContext(DataContext),
    showDisclosureModal,
    setShowDisclosureModal,
  }

  setDarkThemeOverride(true)

  useEffect(() => {
    window.scrollTo(scrollToDefaultOptions)
    setShowDisclosureModal(true)
    setReferrer(referrer)
  }, [])

  return (
    <DataContext.Provider value={esignContext}>
      <div className="landing-container">
        <div className="landing-content">
          <h1 className="title">Employee Esign</h1>
        </div>
        <div className="landing-graphic" />
        {showDisclosureModal && <EmployeeDisclosureModal />}
      </div>
    </DataContext.Provider>
  );
}

export default EmployeeEsign;
