import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import { Button, Form, Radio } from 'semantic-ui-react';
import { Formik } from 'formik';
import FormikRadio from '../../../components/Formik/FormikRadio';
import FormikErrorMessage from '../../../components/Formik/ErrorMessage';
import * as Yup from 'yup';
import { IncomeCopy, IncomeType } from './index';
import { observer } from 'mobx-react-lite';
import { useStore } from '@jmjfinancial/apis/lib';
import { DataContext } from '../../../context/dataContext';
import LoaderOverlay from '../../../components/LoaderOverlay/LoaderOverlay';
import moment from 'moment';
import {scrollIntoViewDefaultOptions, scrollToDefaultOptions} from '../../../helpers/scroll-to-options';
import { clearValues } from '../../../helpers/clear-values-helper';
import ErrorContainer from '../../../components/ErrorContainer';

interface DuplicateIncomeFormProps {
  loanData: any
  incomeType: IncomeType
  incomeTypes: IncomeType[]
  addIncomeType: Function
  updateIncomeFormView: Function
  coborrowerConcierge: boolean | undefined
  ownerName: string;
  showEndDate?: boolean
  setShowEndDate: Function
  setIncomeTypes: Function
  refIncomeIndex: any
}

const DuplicateIncomeForm: FC<DuplicateIncomeFormProps> = observer(({
  loanData,
  incomeType,
  incomeTypes,
  addIncomeType,
  updateIncomeFormView,
  coborrowerConcierge,
  ownerName,
  showEndDate,
  setShowEndDate,
  setIncomeTypes,
  refIncomeIndex
}) => {
  const store = useStore();
  const { loansService } = store;
  const { activeLoan } = useContext(DataContext);

  const [isLoading, setIsLoading] = useState(true);
  const [askForPreviousEmployer, setAskForPreviousEmployer] = useState(true);
  const [allEmployments, setAllEmployments] = useState<any>();
  const [showErrorContainer, setShowErrorContainer] = useState<boolean>(false);
  const [disableOnErrors, setDisableOnErrors] = useState<boolean>(false);
  const formRef = useRef<HTMLFormElement>(null)

  let borrowerEmploymentHistory: number = 0;
  let coBorrowerEmploymentHistory: number = 0;

  const askForAdditionalEmploymentLabel = (coborrowerConcierge: boolean | undefined, incomeType: IncomeType) => {
    const prefix = coborrowerConcierge ? `Is ${ownerName}` : 'Are you'
    const pronoun = coborrowerConcierge ? 'they' : 'you'

    let firstPhrase = `${prefix} currently employed by another company`;
    let lastPhrase = (incomeType === 'W-2 Wage Earner') ? ` that ${pronoun} receive a W2 from but have no ownership in` : ` that ${pronoun} own`;
    return firstPhrase + lastPhrase + '?';
  }

  // Check if borrower incomes array job history is greater than 24 months.
  const twoYearHistory = (employments: []) => {

    employments.forEach((income: any) => {
      if (income.owner === 'borrower' && income.end_date) {
        borrowerEmploymentHistory += moment(moment(income.end_date)).diff(income.start_date, 'months')
      }
      if (income.owner === 'borrower' && !income.end_date) {
        borrowerEmploymentHistory += moment(moment(new Date())).diff(income.start_date, 'months')
      }
      if (income.owner === 'coborrower' && income.end_date) {
        coBorrowerEmploymentHistory += moment(moment(income.end_date)).diff(income.start_date, 'months')
      }
      if (income.owner === 'coborrower' && !income.end_date) {
        coBorrowerEmploymentHistory += moment(moment(new Date())).diff(income.start_date, 'months')
      }
    })
  }

  const addPreviousIncomeType = (previousIncome: any) => {
    // Set a const value to the incomeTypes array
    const updatedIncomeTypes = [...incomeTypes];
    // Splice a new incomeType into the incomeTypes array
    updatedIncomeTypes.splice((refIncomeIndex.current + 1), 0, previousIncome);
    // Set the incomeTypes array to the updatedIncomeTypes array
    setIncomeTypes(updatedIncomeTypes);
  }

  useEffect(() => {
    setIsLoading(true);
    loansService.getLoan(activeLoan).then(l => {
      const loan = l.data.form_data;
      setAllEmployments(loan.employments);
      const checkForEmploymentHistory = (incomeType === 'W-2 Wage Earner' || incomeType === 'Self Employed');

      twoYearHistory(loan.employments)

      // Check if borrowerMonths is less than 24.
      // Check if coBorrowerMonths is less than 24 && a coborrower already exists on the loan.
      // Check if the incomeType is for a W-2 Wage Earner
      setAskForPreviousEmployer(
        (borrowerEmploymentHistory < 24
        || coBorrowerEmploymentHistory < 24
          && loan.employments.find((name: any) => name.owner === 'coborrower'))
        && checkForEmploymentHistory);

      setIsLoading(false);
    })

  }, [loanData, incomeTypes, incomeType, refIncomeIndex.current]);

  useEffect(() => {
    if (formRef && formRef.current) {
      formRef.current.scrollIntoView(scrollIntoViewDefaultOptions)
    }
  }, [])

  return isLoading ? <LoaderOverlay active={isLoading} /> : (
    <Formik
      initialValues={{
        isTwoYears: '',
        additionalSource: '',
        twoYearCheck: '',
        priorOccupation: '',
        currentEmployment: '',
        askForPreviousEmployer: askForPreviousEmployer,

        submit: null
      }}
      validationSchema={Yup.object().shape({
        isTwoYears: Yup.string().when('askForPreviousEmployment', {
          is: true,
          then: Yup.string().required('This field is required')
        }),
        twoYearCheck: Yup.string().when('isTwoYears', {
          is: 'true',
          then: Yup.string().required('This field is required')
        }),
        priorOccupation: Yup.string().when('isTwoYears', {
          is: 'false',
          then: Yup.string().required('This field is required')
        }),
        additionalSource: Yup.string().when(['priorOccupation', 'askForPreviousEmployer'], {
          is: (priorOccupation: string, askForPreviousEmployer: boolean) => (((priorOccupation === 'Student' || priorOccupation === 'none') && askForPreviousEmployer === true) || askForPreviousEmployer === false),
          then: Yup.string().required('This field is required')
        }),
        currentEmployment: Yup.string().when('additionalSource', {
          is: 'true',
          then: Yup.string().required('This field is required')
        })
      })}
      onSubmit={(values, {
        setErrors,
        setStatus,
        setSubmitting,
        resetForm,
      }) => {
        try {
          if (values.additionalSource === 'true') {
            addPreviousIncomeType(values.currentEmployment)
            setShowEndDate(false)
          }
          if (values.additionalSource === 'false') {
            setShowEndDate(false);
          }
          if (values.twoYearCheck === 'W-2 Wage Earner' || values.twoYearCheck === 'Self Employed') {
            addPreviousIncomeType(values.twoYearCheck)
            setShowEndDate(true)
          }
          resetForm()
          // Stop showing this form, if no more income types, show table, otherwise increment index
          updateIncomeFormView()
        } catch (err: any) {
          console.error('DuplicateIncomeForm: ', err.message);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleSubmit,
        handleChange,
        isSubmitting,
        values
      }) => (
        <form
          ref={formRef}
          onSubmit={handleSubmit}
          className="application-form"
        >
          <h1 className="title text-light-blue">Great!</h1>
          {askForPreviousEmployer && (
            <div className="form-step">
              <Form.Field>
                <label>
                  {allEmployments?.length === 1
                    ? `We noticed ${coborrowerConcierge ? ownerName : 'you'} started working for
                        ${loanData?.employments[0].employer_name} more recently than 2 years ago. Did ${coborrowerConcierge ? 'they' : 'you'} have another ${incomeType === 'W-2 Wage Earner' ? 'W-2' : 'Self Employed'} job before working at ${loanData?.employments[0].employer_name}?`
                    : `We're still missing a complete two year employment history. Can you provide any additional previous employment?`}
                </label>
                <div className={`radio-group ${showErrorContainer && values.isTwoYears === '' && 'radio-error'}`}>
                  <FormikRadio
                    label="Yes"
                    name="isTwoYears"
                    value="true"
                    checked={values.isTwoYears === 'true'}
                    onClick={() => {
                      clearValues(values, ['priorOccupation', 'additionalSource', 'currentEmployment'])
                      incomeType === "W-2 Wage Earner" ? values.twoYearCheck = "W-2 Wage Earner" : values.twoYearCheck = "Self Employed"
                    }}
                    className={errors.isTwoYears && 'has-error'}
                  />
                  <FormikRadio
                    label="No"
                    name="isTwoYears"
                    value="false"
                    checked={values.isTwoYears === 'false'}
                    onClick={() => clearValues(values, ['twoYearCheck'])}
                    className={errors.isTwoYears && 'has-error'}
                  />
                </div>
              </Form.Field>
            </div>
          )}
          {values.isTwoYears === 'false' && (
            <div className="form-step">
              <Form.Field>
                <label htmlFor="">{`In that case, what was your occupation prior to ${loanData?.employments[0].employer_name}?`}</label>
                <div className={`radio-group ${showErrorContainer && values.priorOccupation === '' && 'radio-error'}`}>
                  <FormikRadio
                    label="Student"
                    name="priorOccupation"
                    value="Student"
                    checked={values.priorOccupation === 'Student'}
                    className={errors.priorOccupation && 'has-error'}
                  />
                  <FormikRadio
                    label="Not Applicable"
                    name="priorOccupation"
                    value="none"
                    checked={values.priorOccupation === 'none'}
                    className={errors.priorOccupation && 'has-error'}
                  />
                </div>
              </Form.Field>
            </div>
          )}
          {((values.priorOccupation && askForPreviousEmployer) || (!askForPreviousEmployer)) && (
            <div className="form-step">
              <Form.Field>
                <label>{ askForAdditionalEmploymentLabel(coborrowerConcierge, incomeType) }</label>
                <div className={`radio-group ${showErrorContainer && values.additionalSource === '' && 'radio-error'}`}>
                  <Radio
                    label="Yes"
                    id="additionalSourceYes"
                    name="additionalSource"
                    value="true"
                    checked={values.additionalSource === 'true'}
                    onClick={() => (values.currentEmployment = incomeType)}
                    className={errors.additionalSource && 'has-error'}
                    onChange={handleChange}
                    />
                  <Radio
                    label="No"
                    id="additionalSourceNo"
                    name="additionalSource"
                    value="false"
                    checked={values.additionalSource === 'false'}
                    onClick={() => clearValues(values, ['currentEmployment'])}
                    className={errors.additionalSource && 'has-error'}
                    onChange={handleChange}
                  />
                </div>
              </Form.Field>
            </div>
          )}
          <div className="button-container additional-sources">
            <Button
              disabled={isSubmitting || disableOnErrors}
              color="blue"
              type="submit"
              onClick={() => setShowErrorContainer(true)}
            >
              Save &amp; Continue
            </Button>
            <ErrorContainer
              errors={errors}
              showErrorContainer={showErrorContainer}
              setDisableOnErrors={setDisableOnErrors}
            />
          </div>
        </form>
      )}
    </Formik>
  )
})

export default DuplicateIncomeForm
