import {AxiosError, AxiosResponse} from 'axios';

/**
 * Response handler during HTTP requests for when the expected response is unknown.
 */
export const handleResponse = (response: AxiosResponse, message?: string, robust = false): void => {
  (response.data.success || response.status < 400) ? handleSuccess(response, message, robust) : handleFailure(response, message, robust)
}

/**
 * Error handler for catchError operator during HTTP requests.
 */
export const handleError = (error: AxiosError, message?: string, robust = false): void => {
  if (message) {
    console.error(message);
  }

  if (robust) {
    // Show the entire error object
    console.error('Error: ', error);
  }
  else {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.error('Error status: ', error.response.status);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.error('Error request: ', error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error('Error message: ', error.message);
    }

    if (error.config) {
      console.error('Error config: ', error.config);
    }
  }
}

/**
 * Response handler during HTTP requests for when the response fails.
 */
export const handleFailure = (response: AxiosResponse, message?: string, robust = false): void => {
  if (message) {
    console.error(message);
  }

  if (robust) {
    console.error('Response: ', response)
  }
  else {
    if (response.status) {
      console.error('Response status: ', response.status)
    }

    if (response.config) {
      console.error('Response config: ', response.config)
    }

    if (response.data) {
      console.error('Response data: ', response.data)
    }
  }
}

/**
 * Response handler during HTTP requests for when the response is successful.
 */
export const handleSuccess = (response: AxiosResponse, message?: string, robust = false): void => {
  if (message) {
    console.log(message);
  }

  if (robust) {
    console.log('Response: ', response)
  }
  else {
    if (response.status) {
      console.log('Response status: ', response.status)
    }

    if (response.config) {
      console.log('Response config: ', response.config)
    }

    if (response.data) {
      console.log('Response data: ', response.data)
    }
  }

}
