import React, {FC, useEffect, useState} from 'react';
import './dash-item.scss';
import DashSubItemComponent, { DashSubItem } from './DashSubItem';
import DashActionComponent, { DashAction } from './DashAction';
import DashDetailComponent, { DashDetail } from './DashDetail';
import DashProgressStatus, { DashProgressCounter } from './DashProgressCounter';
import CompletedIcon from './CompletedIcon';
import DashSubDetailsComponent, { DashSubDetails } from './DashSubDetails';
import MyLoansEditForm from '../../views/MyLoans/MyLoansEditForm';
import {Loader} from 'semantic-ui-react';
export interface DashItem {
  name: string
  subtext?: string
  progressCounter?: DashProgressCounter
  completed?: boolean
  subItems?: DashSubItem[]
  actions?: DashAction[]
  subDetails?: DashSubDetails[]
  showEditForm?: boolean
  setShowEditForm?: Function
  clickOnText?: boolean
  details?: DashDetail[]
  onClick?: Function
  isLoan?: boolean
  pastLoan?: boolean
  activeLoan?: boolean
  isLoading?: boolean
  v2?: boolean
  totalTasksRemaining?: number
  completedTasks?: number
  isCompletedTab?: boolean
}
const DashItemComponent: FC<DashItem> = ({
  name,
  subtext,
  progressCounter,
  completed,
  subItems,
  actions,
  showEditForm,
  clickOnText,
  setShowEditForm,
  subDetails,
  details,
  onClick,
  isLoan,
  pastLoan,
  activeLoan,
  isLoading,
  v2,
  totalTasksRemaining,
  completedTasks,
  isCompletedTab,
}) => {
  const [expanded, setExpanded] = useState(v2 ? true : !completed)
  useEffect(() => setExpanded(expanded => v2 ? true : !completed), [completed]);

  const handleOnClick = () => {
    if (subItems && !v2) {
      setExpanded(!expanded)
    }
    else if (onClick) {
      console.log('onclicked')
      onClick()
    }
  }

  return (
    <>
      <div
        className={`
          dash-item
          ${subItems ? 'has-sub-item' : ''}
          ${actions ? 'has-action' : ''}
          ${subDetails ? 'has-sub-details' : ''}
          ${details ? 'has-details' : ''}
          ${expanded ? 'expanded' : ''}
          ${(onClick && !pastLoan && !clickOnText) ? 'is-clickable' : ''}
          ${isLoan ? 'is-loan' : ''}
          ${activeLoan ? 'is-active' : ''}
          ${isLoading ? 'is-loading' : ''}
          ${v2 ? 'is-v2' : ''}
        `}
        onClick={!clickOnText ? () => handleOnClick() : () => { }}
      >
        {(showEditForm && activeLoan) ? (
          <MyLoansEditForm />
        ) : (
          <>
            {(v2 && !completed && totalTasksRemaining) ? <span className="tasks-remaining">{isCompletedTab ? completedTasks : totalTasksRemaining}</span> : null}
            {completed && v2 &&  <CompletedIcon />}
            <div className="dash-item-name">
              <label
                className={`dash-name ${clickOnText ? 'is-clickable' : ''}`}
                onClick={clickOnText ? () => handleOnClick() : () => { }}
              >
                {name}
              </label>
              {subtext && <span className="dash-subtext">{subtext}</span>}
            </div>
            {progressCounter && !completed && <DashProgressStatus {...progressCounter} />}
            {isLoading && <Loader />}
            {completed && !v2 &&  <CompletedIcon />}
            {details && (
              <div className="dash-detail-container">
                {details.map((detail, index) => (
                  <DashDetailComponent
                    key={`${detail.label}+${index}`}
                    {...detail}
                  />
                ))}
              </div>
            )}
          </>
        )}
      </div>
      {subItems && expanded && (
        <div className="dash-sub-item-container">
          {subItems.map((subItem, index) => (
            <DashSubItemComponent
              key={`${subItem.label}+${index}`}
              v2={v2}
              {...subItem}
            />
          ))}
        </div>
      )}
      {actions && (
        <div className="dash-action-container">
          {actions.map((action, index) => (
            <DashActionComponent
              key={`${action.label}+${index}`}
              v2={v2}
              {...action}
            />
          ))}
        </div>
      )}
      {subDetails && (
        <div className="dash-sub-detail-container">
          {subDetails.map((subDetail, index) => (
            <DashSubDetailsComponent
            key={`${subDetail.label}+${index}`}
            {...subDetail}
            />
          ))}
        </div>
      )}
    </>
  )
}
export default DashItemComponent
