import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import { ModalProps } from '../../../types/modal';
import {Button, Icon, Modal, Table} from 'semantic-ui-react';
import {DataContext} from '../../../context/dataContext';
import './download-documents-modal.scss'
import { useStore } from '@jmjfinancial/apis/lib';
import LoaderOverlay from '../../LoaderOverlay/LoaderOverlay';
import HelperPopup from '../../Popup/Popup';
import {Document} from '../../../types/document';
import DocumentPreview from '../../DocumentPreview';
import { Link } from 'react-router-dom';
import {saveAttachment} from '../../../helpers/save-attachment';
import ProgressOverlay from '../../ProgressOverlay';
import {AxiosResponse} from 'axios';

interface DownloadDocumentsModalProps extends ModalProps {
  borrowerPairId: number
  taskId?: number
  subHeaderOverride?: string
  isDisclosureTask?: boolean
}

const DownloadDocumentsModal: FC<DownloadDocumentsModalProps> = ({
  showModal,
  setShowModal,
  borrowerPairId,
  taskId,
  subHeaderOverride,
  isDisclosureTask,
}) => {
  const { activeLoan } = useContext(DataContext)
  const { loansService, tasksService } = useStore();

  const [documents, setDocuments] = useState<Array<Document>>([]);
  const [property, setProperty] = useState<string>('')
  const [subHeader, setSubHeader] = useState<string>('')
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isDownloading, setIsDownloading] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0)
  const [progressOverlayLabel, setProgressOverlayLabel] = useState<string>()
  const [errorMessage, setErrorMessage] = useState<string>()
  const [progressOverlayTableOffset, setProgressOverlayTableOffset] = useState<number>(0)
  const [progressOverlayMobileOffset, setProgressOverlayMobileOffset] = useState<number>(0)

  const progressRef = useRef<number>(progress)
  const controllerRef = useRef(new AbortController())
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const mobileContainerRef = useRef<HTMLDivElement>(null)

  /**
   * Returns either the document `fileName` or `deliverableName
   * depending on if a `taskId` is passed to modal
   * @param document
   */
  const getDocumentName = (document: Document): string => {
    return taskId ? document.fileName : document.deliverableName
  }

  /**
   * Maps attachments array from get response to `documents` array
   * @param res
   */
  const mapAttachments = (res: AxiosResponse) => {
    if (res?.data?.success) {
      const attachments: Array<Document> = res.data.attachments.map((att: any) => {
        return {
          deliverableName: att.deliverable_name,
          fileName: att.file_name,
          uploadDate: new Date(att.uploaded_date).toLocaleDateString(),
          documentSize: `${Math.round(att.byte_size / 1024)}kb`,
          url: att.url,
          type: att.content_type
        }
      })
      setDocuments(documents => attachments)
    }
    setIsLoading(false)
  }

  /**
   * Updates progress bar while downloading an attachment
   * @param progressEvent event passed from axios `onDownloadProgress` callback
   * @param isZip changes where the total size of the attachment is read from
   */
  const handleDownloadProgress = (progressEvent: ProgressEvent, isZip = false) => {
    // @ts-ignore
    const total = isZip ? progressEvent.target.getResponseHeader('X-Content-Size') : progressEvent.total
    const currentProgress = Math.round((progressEvent.loaded / total) * 100)
    setProgress(progress => currentProgress)
  }

  /**
   * Handles downloading a single loan or deliverable document attachment
   * @param document
   */
  const handleDownloadAttachment = (document: Document) => {
    resetDownload(`Downloading ${document.fileName}...`)
    tasksService.getAttachment(
      document,
      (progressEvent) => handleDownloadProgress(progressEvent),
      controllerRef.current
    )
      // @ts-ignore
      .then(res => {
        if (res.status === 200) {
          console.log('getAttachment res.data: ', res.data)
          saveAttachment(res.data, document.fileName)
        }
        // @ts-ignore
        else if (res.message !== 'canceled') {
          setErrorMessage(errorMessage => 'There was a problem downloading your file. Please try again.')
        }
      })
      .then(() => setIsDownloading(isDownloading => false))
  }

  /**
   * Handles downloading all loan or deliverable documents as a single zip file
   * @param taskId dictates if loan or deliverable documents are downloaded
   */
  const handleDownloadAttachmentsZip = (taskId?: number) => {
    resetDownload('Downloading all documents...')
    tasksService.getAttachmentsZip(
      borrowerPairId,
      (progressEvent) => handleDownloadProgress(progressEvent, true),
      controllerRef.current,
      taskId
    )
      // @ts-ignore
      .then(res => {
        if (res.status === 200) {
          saveAttachment(res.data, taskId ? subHeader : `${property}-documents`, true)
        }
        // @ts-ignore
        else if (res.message !== 'canceled') {
          setErrorMessage(errorMessage => 'There was a problem downloading your files. Please try again.')
        }
      })
      .then(() => setIsDownloading(isDownloading => false))
  }

  /**
   * Resets all download related UI and `AbortController` to start a new download
   * @param label progressOverlayLabel
   */
  const resetDownload = (label: string) => {
    controllerRef.current = new AbortController()
    setErrorMessage(errorMessage => '')
    setProgress(progress => 0)
    setIsDownloading(isDownloading => true)
    setProgressOverlayLabel(progressOverlayLabel => label)
  }

  /**
   * Handles cancelling the download, and resetting the progress bar
   */
  const handleCancelDownload = () => {
    setIsDownloading(isDownloading => false)
    controllerRef.current.abort()
    setProgress(progress => 0)
  }

  useEffect(() => {
    progressRef.current = progress

    // Needed to prevent type errors
    const tableNode = tableContainerRef.current
    const mobileNode = mobileContainerRef.current

    // Listens for scrolling of progressOverlay parents and updates offset values
    tableNode?.addEventListener('scroll',
      () => setProgressOverlayTableOffset(progressOverlayOffset => tableNode?.scrollTop)
    )
    mobileNode?.addEventListener('scroll',
      () => setProgressOverlayMobileOffset(progressOverlayOffset => mobileNode?.scrollTop)
    )

    // Removes listeners when component is closed
    return () => {
      tableNode?.removeEventListener('scroll', () => {})
      mobileNode?.removeEventListener('scroll', () => {})
    }
  })

  useEffect(() => {
    setSubHeader(subHeader => subHeaderOverride || property)
  }, [property])

  useEffect(() => {

    if (!taskId) {
      loansService.getLoan(activeLoan).then(l => {
        const loan = l?.data?.form_data

        if (loan) {
          setProperty(property =>
            loan.property?.address_street_line !== ""
              ? loan.property?.address_street_line
              : `${loan.property?.address_city}, ${loan.property?.address_state}`
          )
        }
      })
    }

    tasksService.getAttachmentsArray(borrowerPairId, taskId).then((res: any) => mapAttachments(res))

    // Cancels download on modal close
    return () => {
      handleCancelDownload()
    }
  }, [activeLoan])

  return (
    <Modal
      className={`
        default-modal
        download-documents-modal
        ${documents.length > 1 ? ' has-multiple-documents' : ''}
        ${isDownloading && ' is-downloading'}
        ${errorMessage && ' has-error'}
      `}
      open={showModal}
      onClose={() => setShowModal(false)}
      onOpen={() => setShowModal(true)}
    >
      <LoaderOverlay
        active={isLoading}
        modal
      />
      <Icon
        name="close"
        className="close-icon"
        onClick={() => setShowModal(false)}
      />
      <span className="modal-header">Review Documents</span>
      <span className={`modal-subheader ${property === '' && !subHeaderOverride && 'is-hidden'}`}>
        {subHeader}
      </span>
      {documents.length > 0 && (
        <>
          {/*Desktop Variation*/}
          <div
            ref={tableContainerRef}
            className="downloads-table-container"
          >
            <ProgressOverlay
              active={isDownloading}
              percent={progressRef.current}
              label={progressOverlayLabel}
              top={progressOverlayTableOffset}
            />
            <Table basic="very" unstackable>
              <Table.Header>
                <Table.Row className="header-row">
                  <Table.HeaderCell />
                  <Table.HeaderCell>{taskId ? 'File Name' : 'Task Name'}</Table.HeaderCell>
                  <Table.HeaderCell>Upload Date</Table.HeaderCell>
                  <Table.HeaderCell>Size</Table.HeaderCell>
                  <Table.HeaderCell>Preview</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {documents.map((document: any, index: number) => {
                  return (
                    <Table.Row key={`${document.deliverableName.toString()}-${index}`}>
                      <Table.Cell collapsing>
                        <Icon
                          className="download download-icon"
                          onClick={() => handleDownloadAttachment(document)}
                        />
                      </Table.Cell>
                      <Table.Cell>{getDocumentName(document)}</Table.Cell>
                      <Table.Cell>{document.uploadDate}</Table.Cell>
                      <Table.Cell>{document.documentSize}</Table.Cell>
                      <Table.Cell>
                        <HelperPopup
                          defaultIcon="eye"
                          iconClass="preview-icon"
                          additionalClass="document-preview-popup"
                          content={
                            <DocumentPreview document={document} />
                          }
                        />
                      </Table.Cell>
                    </Table.Row>
                  )
                })}
              </Table.Body>
            </Table>
          </div>

          {/*Mobile Variation*/}
          <div
            ref={mobileContainerRef}
            className="downloads-modal-mobile-container"
          >
            <ProgressOverlay
              active={isDownloading}
              percent={progressRef.current}
              label={progressOverlayLabel}
              top={progressOverlayMobileOffset}
            />
            {documents.map((document: any, index: number) => (
              <div
                key={`${document.deliverableName.toString()}-${index}`}
                className="details-container"
              >
                <div className="details">
                  <label>Document Name</label>
                  <span className="value">{getDocumentName(document)}</span>
                </div>
                <div className="details">
                  <label>Upload Date</label>
                  <span className="value">{document.uploadDate}</span>
                </div>
                <div className="details">
                  <label>Download Size</label>
                  <span className="value">{document.documentSize}</span>
                </div>
                <HelperPopup
                  defaultIcon="eye"
                  iconClass="preview-icon"
                  additionalClass="document-preview-popup"
                  trigger={
                    <Button className="preview-button">
                      <Icon className="eye"/>
                      Preview
                    </Button>
                  }
                  content={
                    <DocumentPreview document={document} />
                  }
                />
                <Button
                  className="download-button"
                  onClick={() => handleDownloadAttachment(document)}
                >
                  <Icon className="download"/>
                  Download
                </Button>
              </div>
            ))}
          </div>
        </>
      )}
      {documents.length === 0 && !isLoading && (
        <div className="no-documents-container">
          {isDisclosureTask ? (
            <>
              <h3>Awaiting final signatures from your Loan Officer</h3>
              <h4>Once all parties have signed and completed this document, it will be available for you to view/download.</h4>
            </>
          ) : (
            <>
              <h3>You haven't uploaded any documents yet.</h3>
              <h4>Once you have completed a <Link to={'/dashboard'}>task</Link>, you can review your uploaded documents here.</h4>
            </>
          )}
        </div>
      )}
      <div className="download-documents-modal-footer">
        <span className="error-message">{errorMessage}</span>
        <Button
          className="cancel-button"
          onClick={() => isDownloading ? handleCancelDownload() : setShowModal(false)}
        >
          Cancel
        </Button>
        {documents.length > 1 && (
          <Button
            className="download-all-button"
            disabled={documents.length === 0 || isDownloading}
            onClick={() => handleDownloadAttachmentsZip(taskId)}
          >
            <Icon className="download download-icon"/>
            {isDownloading ? 'Downloading' : 'Download All'}
          </Button>
        )}
      </div>
    </Modal>
  )
}

export default DownloadDocumentsModal
