import React, {FC} from 'react';
import {Icon, Menu, Sidebar} from 'semantic-ui-react';
import {PageHeaderProps} from './index';
import PageHeaderControls from './PageHeaderControls';

interface MobileSidebarProps extends PageHeaderProps {
  visible: boolean
  setVisible: Function
}

const MobileSidebar: FC<MobileSidebarProps> = ({
  visible,
  setVisible,
  darkTheme,
  splitTheme
}) => {
  const handleCloseMenu = () => {
    setVisible(false)
  }

  return (
    <Sidebar
      as={Menu}
      animation="overlay"
      direction="right"
      inverted={darkTheme}
      vertical
      onHide={handleCloseMenu}
      visible={visible}
      width='thin'
    >
      <Menu.Item
        as='a'
        onClick={handleCloseMenu}
      >
        <Icon name='close' />
        Close Menu
      </Menu.Item>
      <div onClick={handleCloseMenu}>
        <PageHeaderControls
          darkTheme={darkTheme}
          splitTheme={splitTheme}
        />
      </div>
    </Sidebar>
  )
}

export default MobileSidebar
