import React, { FC } from 'react';
import { Button } from 'semantic-ui-react';
import ErrorContainer from '../../../components/ErrorContainer';

interface ApplicationFormFooterProps {
  onCancelClick: Function
  nextDisabled?: boolean
  nextButtonVisible?: boolean
  nextButtonLabel?: string
  onNextClick?: Function
  errors?: any
  showErrorContainer?: any
  setShowErrorContainer?: any
  setDisableOnErrors?: any
  errorMessage?: string
}

const ApplicationFormFooter: FC<ApplicationFormFooterProps> = ({
  onCancelClick,
  nextDisabled,
  nextButtonVisible = true,
  nextButtonLabel = 'Next',
  onNextClick,
  errors,
  showErrorContainer,
  setShowErrorContainer,
  setDisableOnErrors,
  errorMessage
}) => {
  const handleNextClick = () => {
    if (setShowErrorContainer) {
      setShowErrorContainer(true)
    }

    if (onNextClick) {
      onNextClick()
    }
  }

  return (
    <div className="application-step-footer">
      <Button
        type="button"
        className="form-previous-button"
        onClick={() => onCancelClick()}
      >
        Cancel
      </Button>
      {errors ? (
        <ErrorContainer
          errors={errors}
          showErrorContainer={showErrorContainer}
          setDisableOnErrors={setDisableOnErrors}
          errorMessage={errorMessage}
        />
      ) : null}
      {nextButtonVisible && (
        <Button
          disabled={nextDisabled}
          className="save"
          type={onNextClick ? 'button' : 'submit'}
          color="blue"
          onClick={() => handleNextClick()}
        >
          {nextButtonLabel}
        </Button>
      )}
    </div>
  )
}

export default ApplicationFormFooter
