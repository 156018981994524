import {FC, useEffect, useState} from 'react';

interface ErrorContainerProps {
  errors: any
  showErrorContainer: boolean
  setDisableOnErrors: Function
  errorMessage?: string | {}
  successMessage?: string
}

const ErrorContainer: FC<ErrorContainerProps> = ({
  errors,
  showErrorContainer,
  setDisableOnErrors,
  errorMessage,
  successMessage
}) => {
  const [messageComponent, setMessageComponent] = useState<any>(<></>)

  const ErrorComponent: FC = () => <span>{errorMessage || 'Please review all the sections highlighted in red'}</span>
  const SuccessComponent: FC = () => successMessage ? <span className="success-message">{successMessage}</span> : <></>

  useEffect(() => {
    if (showErrorContainer && Object.keys(errors).length > 0) {
      setDisableOnErrors((disabledOnErrors: boolean) => true)
      setMessageComponent((messageComponent: any) => <ErrorComponent />)
    }
    else {
      setDisableOnErrors((disabledOnErrors: boolean) => false)
      setMessageComponent((messageComponent: any) => <SuccessComponent />)
    }
  }, [showErrorContainer, errors])

  return (
    <div className="message-container">{messageComponent}</div>
  )
}

export default ErrorContainer;
