import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button } from 'semantic-ui-react';
import { ApplicationRoutes } from '..';
import AddRemoveTable, { HeaderLabels } from '../../../components/AddRemoveTable/AddRemoveTable';
import { DataContext } from '../../../context/dataContext';
import { getHeaderText, reduceDisplayedTableData } from '../../../helpers/table-helper';
import { useStore } from '@jmjfinancial/apis/lib';
import EditREOForm from './EditREOForm';
import { scrollToDefaultOptions } from '../../../helpers/scroll-to-options';

interface RealEstateOwnedTableViewProps {
  loanData: any;
  reoIndex: number;
  setReoIndex: Function;
  incompleteREOs: Array<any>;
  setIncompleteREOs: Function;
  setRefreshREOView: Function;
  conciergeMode: boolean;
  setFormData: Function;
}

const HEADER_LABELS: HeaderLabels[] = [
  {
    text: 'Property Address',
    value: 'address_street_line',
  },
  {
    text: 'City',
    value: 'address_city'
  },
  {
    text: 'State',
    value: 'address_state'
  },
  {
    text: 'Property Type',
    value: 'gse_property_type'
  }
];

const RealEstateOwnedTableView: FC<RealEstateOwnedTableViewProps> = observer(({
  loanData,
  reoIndex,
  setReoIndex,
  incompleteREOs,
  setIncompleteREOs,
  setRefreshREOView,
  conciergeMode,
  setFormData
}) => {

  const store = useStore();
  const { loansService } = store;

  const {
    activeLoan,
    pathname,
    reviewMode,
    setDarkThemeOverride,
    setOverrideTrackerVisibility
  } = useContext(DataContext);
  const [tableData, setTableData] = useState<any>([]);
  const [showForm, setShowForm] = useState(false);
  const [editFormData, setEditFormData] = useState<any>();
  const [editRowIndex, setEditRowIndex] = useState(0);
  // Used for REO `tax_filed_year`
  const [borrowerData, setBorrowerData] = useState(loanData.borrower)

  const history = useHistory();

  setDarkThemeOverride(false)
  setOverrideTrackerVisibility(false)

  const handlePreviousClick = () => {
    history.push('/application/assets')
  }

  const handleNextClick = () => {
    if (reviewMode) {
      history.push('/application/review-and-submit')
    }
    else {
      history.push('/application/declarations')
    }
  }

  const handleAddRow = (row: any, borrowerData?: any) => {
    let newTableData = [...tableData];
    newTableData.push(row);
    setTableData(newTableData);

    if (borrowerData) {
      setBorrowerData(borrowerData)
    }
  }

  const handleRemoveRow = (index: number) => {
    let newTableData = [...tableData];
    newTableData[index].to_be_deleted = true
    setTableData(newTableData);
  }

  const handleShowForm = (visible: boolean) => {
    const emptyForm = { ...{ id: '' } };
    setEditFormData(emptyForm);
    setShowForm(visible);
  }

  const handleEditRow = (index: number) => {
    setEditRowIndex(index);
    setEditFormData({ ...tableData[index] });
    setShowForm(true);
  }

  const handleUpdateRow = (row: any, borrowerData?: any) => {
    const updatedTableData = [...tableData];
    updatedTableData[editRowIndex] = row;
    setTableData(updatedTableData);

    if (borrowerData) {
      setBorrowerData(borrowerData)
    }
  }

  const handleSubmit = async () => {
    const updatedLoan = {
      application: {
        current_step: ApplicationRoutes[pathname as keyof typeof ApplicationRoutes] + 1,
        completed_steps: {
          reo_done: true
        }
      },
      borrower: borrowerData,
      reo: tableData
    }

    const dataSubmit = _.merge(loanData, updatedLoan)
    await loansService.updateLoan(activeLoan, dataSubmit)

    handleNextClick();
  }

  useEffect(() => {
    setTableData(loanData.reo)
  }, [loanData])

  useEffect(() => {
    if (!showForm && tableData.filter((row: any) => row.to_be_deleted).length === 0) {
      window.scrollTo(scrollToDefaultOptions)
    }
  }, [showForm])

  return (
    <div>
      <h1 className="title text-light-blue">Owned Real Estate</h1>
      <AddRemoveTable
        headerLabels={getHeaderText(HEADER_LABELS)}
        table={reduceDisplayedTableData(tableData, HEADER_LABELS)}
        removeTableRow={handleRemoveRow}
        addButtonLabel="Add additional property"
        showForm={showForm}
        setShowForm={handleShowForm}
        editTableRow={handleEditRow}
        loanData={loanData}
        tableData={tableData}
        form={
          <div className="application-step-container">
            <EditREOForm
              loanData={loanData}
              setRefreshREOView={setRefreshREOView}
              incompleteREOs={incompleteREOs}
              setIncompleteREOs={setIncompleteREOs}
              reoIndex={reoIndex}
              setReoIndex={setReoIndex}
              conciergeMode={conciergeMode}
              setShowForm={handleShowForm}
              // If the form contains an ID then it has already been submitted to the REO array and needs to be udpated. If the ID value does not exist it is a new entry and needs to be added to the array.
              saveForm={editFormData?.id ? handleUpdateRow : handleAddRow}
              formData={editFormData}
              tableData={tableData}
              borrowerData={borrowerData}
              setFormData={setFormData}
            />
          </div>
        }
      />
      {!showForm && (
        <div className="application-step-footer">
          <Button
            onClick={() => handlePreviousClick()}
            className="form-previous-button"
          >
            Previous
          </Button>
          <Button
            onClick={() => handleSubmit()}
            className="save"
            color="blue"
          >
            {reviewMode ? "Back to Review" : "Save & Continue"}
          </Button>
        </div>
      )}
    </div>
  )
})

export default RealEstateOwnedTableView;
