import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import W2IncomeForm from './IncomeForms/W2';
import DuplicateIncomeForm from './DuplicateIncomeForm';
import SelfEmployedIncomeForm from './IncomeForms/SelfEmployedForm';
import { IncomeType } from './index';
import RetirementIncomeForm from './IncomeForms/Retirement';
import AlimonyIncomeForm from './IncomeForms/Alimony';
import ChildSupportIncomeForm from './IncomeForms/ChildSupport';
import OtherIncomeForm from './IncomeForms/Other';
import { AddRemoveFormProps } from '../../../components/AddRemoveTable/AddRemoveTable';
import { useHistory } from 'react-router-dom';
import OwnerTypeSelection from './IncomeForms/OwnerTypeSelection';
import { useStore } from '@jmjfinancial/apis/lib';
import { DataContext } from '../../../context/dataContext';
import { OwnerType } from '../../../types/loan';
import { ApplicationRoutes } from '../index';
import _ from 'lodash';
import {scrollIntoViewDefaultOptions} from '../../../helpers/scroll-to-options';

export interface IncomeFormProps extends AddRemoveFormProps {
  loanData: any
  onCancelClick: Function
  updateIncomeFormView: Function
  owner: string
  ownerName: string
  incomeType: IncomeType
  duplicateIncomeType?: boolean
  conciergeMode?: boolean
  formData?: any
  showEndDate?: boolean
  setShowEndDate?: Function
  tableData?: any
}

interface EditIncomeFormProps extends AddRemoveFormProps {
  loanData: any
  incomeTypes: IncomeType[]
  setIncomeTypes: Function
  setIncomeIndex: Function
  showDuplicateIncomeForm: boolean
  setShowDuplicateIncomeForm: Function
  setRefreshIncomesView: Function
  conciergeMode?: boolean
  hasCoborrower?: boolean
  coborrowerConcierge?: boolean
  setCoborrowerConcierge: Function
  formData?: any
  showEndDate?: boolean
  setShowEndDate: Function
  tableData?: any
  refIncomeIndex: any
  borrowerData?: any
  coborrowerData?: any
}

const EditIncomeForm: FC<EditIncomeFormProps> = ({
  loanData,
  incomeTypes,
  setIncomeTypes,
  setIncomeIndex,
  showDuplicateIncomeForm,
  setShowDuplicateIncomeForm,
  setRefreshIncomesView,
  conciergeMode,
  hasCoborrower,
  coborrowerConcierge,
  setCoborrowerConcierge,
  setShowForm,
  saveForm,
  formData,
  showEndDate,
  setShowEndDate,
  tableData,
  refIncomeIndex,
  borrowerData,
  coborrowerData
}) => {
  const store = useStore();
  const history = useHistory();
  const { loansService } = store;
  const {
    activeLoan,
    pathname,
    reviewMode,
    setDarkThemeOverride,
    setOverrideTrackerVisibility
  } = useContext(DataContext);

  const [incomeType, setIncomeType] = useState<IncomeType>('')
  const [ownerType, setOwnerType] = useState<OwnerType>(formData?.owner || 'borrower')
  const [ownerName, setOwnerName] = useState<string>('')
  const [duplicateIncomeType, setDuplicateIncomeType] = useState(false);
  const formRef = useRef<HTMLDivElement>(null)

  setDarkThemeOverride(false)
  setOverrideTrackerVisibility(false)

  const handleCancelClick = () => {
    if (formData) {
      setShowForm(false)
    }
    else {
      // Clears all selected incomeTypes
      const updatedIncomeTypes: any = []
      setIncomeTypes((incomeTypes: any) => updatedIncomeTypes)
      // Force a soft refresh from main index view
      setCoborrowerConcierge(false)
      setRefreshIncomesView(true)
    }
  }

  // Add new income type as next incomeType
  const addIncomeType = () => {
    const updatedIncomeTypes = [...incomeTypes]
    updatedIncomeTypes.splice((refIncomeIndex.current + 1), 0, incomeTypes[refIncomeIndex.current])
    console.log('addIncomeType: ', updatedIncomeTypes)
    setIncomeTypes(updatedIncomeTypes)
  }

  // Remove current income type
  const removeIncomeType = () => {
    const updatedIncomeTypes = [...incomeTypes]
    updatedIncomeTypes.splice((refIncomeIndex.current), 1)
    console.log('removeIncomeType: ', updatedIncomeTypes)
    setIncomeTypes(updatedIncomeTypes)
  }

  const updateIncomeFormView = (incrementIndex = true) => {
    // This sets showDuplicateIncomeForm to !showDuplicateIncomeForm only if in conciergeMode and if the incomeType is W2 or Self Employed
    if (conciergeMode && (incomeType === 'W-2 Wage Earner' || incomeType === 'Self Employed')) {
      setShowDuplicateIncomeForm(!showDuplicateIncomeForm)
    }


    // If current income index is outside of the range of incomeTypes, initiate a soft reload from index file
    if (refIncomeIndex.current >= incomeTypes.length) {

      // Reset coborrowerConcierge flag
      if (coborrowerConcierge) {
        setCoborrowerConcierge((coborrowerConcierge: boolean) => false)
      }

      // Force a soft refresh from main index view
      setRefreshIncomesView(true)
    }
    if (incrementIndex) {
      incrementIncomeIndex()
    }
  }

  // Advances iteration over `incomeTypes` array
  const incrementIncomeIndex = () => {
    const nextIndex = refIncomeIndex.current + 1
    console.log('incrementIncomeIndex: nextIndex', nextIndex)
    setIncomeIndex((incomeIndex: number) => nextIndex)
  }

  // Switches view to concierge for coborrower
  const enableCoborrowerConcierge = () => {
    console.log('enableCoborrowerConcierge()')
    setIncomeTypes([])
    setIncomeIndex(0)
    setOwnerType('coborrower')
    setCoborrowerConcierge(true)
  }

  const navigateToNextStep = () => {
    if (reviewMode) {
      history.push('/application/review-and-submit')
    }
    else {
      console.log('routing to assets')

      const updatedLoan = {
        application: {
          current_step: ApplicationRoutes[pathname as keyof typeof ApplicationRoutes] + 1,
          completed_steps: {
            employment_and_income_done: true
          }
        }
      }

      const dataSubmit = _.merge(loanData, updatedLoan)
      loansService.updateLoan(activeLoan, dataSubmit).then(() => history.push('/application/assets'))
    }
  }

  const IncomeForms: FC = () => {
    switch (incomeType) {
      case 'W-2 Wage Earner':
        return (
          <W2IncomeForm
            loanData={loanData}
            onCancelClick={handleCancelClick}
            updateIncomeFormView={updateIncomeFormView}
            owner={ownerType}
            ownerName={ownerName}
            incomeType={incomeType}
            duplicateIncomeType={duplicateIncomeType}
            conciergeMode={conciergeMode}
            setShowForm={setShowForm}
            saveForm={saveForm}
            formData={formData}
            showEndDate={showEndDate}
            setShowEndDate={setShowEndDate}
            tableData={tableData}
            incomeTypes={incomeTypes}
          />
        )
      case 'Self Employed':
        return (
          <SelfEmployedIncomeForm
            loanData={loanData}
            onCancelClick={handleCancelClick}
            updateIncomeFormView={updateIncomeFormView}
            owner={ownerType}
            ownerName={ownerName}
            incomeType={incomeType}
            duplicateIncomeType={duplicateIncomeType}
            conciergeMode={conciergeMode}
            setShowForm={setShowForm}
            saveForm={saveForm}
            formData={formData}
            showEndDate={showEndDate}
            setShowEndDate={setShowEndDate}
            tableData={tableData}
            incomeTypes={incomeTypes}
            borrowerData={borrowerData}
            coborrowerData={coborrowerData}
          />
        )
      case 'retirement_account':
      case 'social_security':
      case 'pension':
      case 'annuity':
      case 'fund_401k':
        return (
          <RetirementIncomeForm
            loanData={loanData}
            onCancelClick={handleCancelClick}
            updateIncomeFormView={updateIncomeFormView}
            owner={ownerType}
            ownerName={ownerName}
            incomeType={incomeType}
            duplicateIncomeType={duplicateIncomeType}
            conciergeMode={conciergeMode}
            setShowForm={setShowForm}
            saveForm={saveForm}
            formData={formData}
          />
        )
      case 'alimony':
        return (
          <AlimonyIncomeForm
            loanData={loanData}
            onCancelClick={handleCancelClick}
            updateIncomeFormView={updateIncomeFormView}
            owner={ownerType}
            ownerName={ownerName}
            incomeType={incomeType}
            duplicateIncomeType={duplicateIncomeType}
            conciergeMode={conciergeMode}
            setShowForm={setShowForm}
            saveForm={saveForm}
            formData={formData}
          />
        )
      case 'child_support':
        return (
          <ChildSupportIncomeForm
            loanData={loanData}
            onCancelClick={handleCancelClick}
            updateIncomeFormView={updateIncomeFormView}
            owner={ownerType}
            ownerName={ownerName}
            incomeType={incomeType}
            duplicateIncomeType={duplicateIncomeType}
            conciergeMode={conciergeMode}
            setShowForm={setShowForm}
            saveForm={saveForm}
            formData={formData}
          />
        )
      case 'other':
        return (
          <OtherIncomeForm
            loanData={loanData}
            onCancelClick={handleCancelClick}
            updateIncomeFormView={updateIncomeFormView}
            owner={ownerType}
            ownerName={ownerName}
            incomeType={incomeType}
            duplicateIncomeType={duplicateIncomeType}
            conciergeMode={conciergeMode}
            setShowForm={setShowForm}
            saveForm={saveForm}
            formData={formData}
          />
        )
      default:
        return <></>
    }
  }

  useEffect(() => {
    // Forces ownerType if using coborrower concierge view
    if (coborrowerConcierge) {
      setOwnerType('coborrower')
    }
    // Updates owner name if owner type changes
    setOwnerName(ownerType === 'borrower' && !coborrowerConcierge ? loanData.borrower.first_name : loanData.coborrower.first_name)
    // Sets incomeType from formData when editing/adding from table view
    if (formData) {
      console.log('formData: ', formData);
      if (formData.end_date) {
        console.log('formData.end_date: ', formData.end_date)
      }
      setIncomeType(formData.income_type)
    }
    else {
      // Checks to see if all income types have been iterated through
      if (refIncomeIndex.current >= incomeTypes.length) {
        console.log('refIncomeIndex.current >= incomeTypes.length')

        if (coborrowerConcierge) {
          setCoborrowerConcierge(false)
        }

        // Enables coborrower concierge flow if a coborrower exists,
        // current owner is the borrower, and no coborrower incomes are found
        if (hasCoborrower && ownerType === 'borrower') {
          // Gets updated loan data and checks for coborrower specific incomes
          // to exit coborrower concierge loop
          loansService.getLoan(activeLoan).then(loan => {
            const exitCoborrowerConcierge = loan.data.form_data.incomes.find((income: any) => income.owner === 'coborrower')

            if (!exitCoborrowerConcierge) {
              enableCoborrowerConcierge()
            }
          })
        }

        setRefreshIncomesView(true)
      }

      // Checks if there are no income types
      if (incomeTypes[refIncomeIndex.current] === 'None') {
        console.log("incomeTypes[refIncomeIndex.current] === 'None'")
        removeIncomeType()

        // Advances to Assets step if both borrower and coborrower select "none" for incomes
        if (coborrowerConcierge) {
          console.log('setting coborrower concierge to false')
          setCoborrowerConcierge(false)

          // Check for both incomes && employments arrays
          if (loanData.incomes.length === 0 && loanData.employments.length === 0) {
            navigateToNextStep()
          }
          else {
            console.log('refreshing index view')
            setRefreshIncomesView(true)
          }
        }
        else {
          // Advances to Assets step if borrower selects "none" for incomes, and no coborrower exists
          if (hasCoborrower && ownerType === 'borrower') {
            enableCoborrowerConcierge()
          }
          else {
            navigateToNextStep()
          }
        }
      }
      // Sets new incomeType anytime incomeTypes or refIncomeIndex.current is updated
      else {
        setIncomeType(incomeTypes[refIncomeIndex.current])
      }
    }
    // Simple flag to change copy for form header or duplicate income form
    setDuplicateIncomeType(incomeTypes[refIncomeIndex.current - 1] === incomeTypes[refIncomeIndex.current])
  }, [incomeTypes, refIncomeIndex.current, formData, ownerType])

  useEffect(() => {
    if (formRef && formRef.current) {
      formRef.current.scrollIntoView(scrollIntoViewDefaultOptions)
    }
  }, [handleCancelClick])

  // From concierge mode, show duplicate income from, but only for W2 or Self Employed
  return showDuplicateIncomeForm && (incomeType === 'W-2 Wage Earner' || incomeType === 'Self Employed') ? (
    <DuplicateIncomeForm
      loanData={loanData}
      incomeType={incomeType}
      incomeTypes={incomeTypes}
      refIncomeIndex={refIncomeIndex}
      addIncomeType={addIncomeType}
      updateIncomeFormView={updateIncomeFormView}
      coborrowerConcierge={coborrowerConcierge}
      ownerName={ownerName}
      showEndDate={showEndDate}
      setShowEndDate={setShowEndDate}
      setIncomeTypes={setIncomeTypes}
    />
  ) : (
      // Only show OwnerTypeSelection form if not in concierge mode
      <div ref={formRef}>
        {!conciergeMode && (
          <OwnerTypeSelection
            loanData={loanData}
            ownerType={ownerType}
            setOwnerType={setOwnerType}
            incomeType={incomeType}
            setIncomeType={setIncomeType}
            onCancelClick={handleCancelClick}
            formData={formData}
          />
        )}
        <div className="form-fields-container income-form-container">
          <IncomeForms />
        </div>
      </div>
    )
}

export default EditIncomeForm
