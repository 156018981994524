export const homeUseOptions = [
  {
    text: 'Primary Residence',
    value: 'primary',
    key: 'primary'
  },
  {
    text: 'Secondary Residence',
    value: 'secondary',
    key: 'secondary'
  },
  {
    text: 'Investment',
    value: 'investment',
    key: 'investment'
  }
]

export const currentHomeUseOptions = [
  {
    text: 'Second Home',
    value: 'secondHome'
  },
  {
    text: 'Rental',
    value: 'rental'
  }
]

export const propertyTypeOptions = [
  {
    text: 'Single Family Residence',
    value: 'sfr'
  },
  {
    text: 'Single Family Residence within a planned unit development (has an HOA)',
    value: 'sfr-planned-development'
  },
  {
    text: 'Condominium',
    value: 'condo'
  },
  {
    text: '2-4 Unit',
    value: '2-4unit'
  },
  {
    text: 'Manufactured Home',
    value: 'manufactured'
  }
]

export const outstandingLoansType = [
  {
    text: 'None property is currently free and clear',
    value: 'none',

  },
  {
    text: 'First mortgage only',
    value: 'firstMortgageOnly',

  },
  {
    text: 'First and second mortgage',
    value: 'firstAndSecondMortgage',

  },
  {
    text: 'First, second and third mortgage',
    value: 'firstSecondAndThirdMortgage',

  }
]

export const mortgageImpoundedOptions = [
  {
    text: 'Not impounded',
    value: 'no-impound'
  },
  {
    text: 'Taxes and insurance',
    value: 'taxes-and-insurance'
  },
  {
    text: 'Taxes only',
    value: 'taxes-only'
  },
  {
    text: 'Insurance only',
    value: 'insurance-only'
  }
]
