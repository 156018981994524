type PropertyType = '' | 'sfr' | 'condo' | 'multi_unit' | 'manufactured' | 'commercial' |
  'farm' | 'land' | 'mobile_home' | 'mixed_use' | 'townhouse'

interface PropertyTypeOptions {
  text: string
  value: PropertyType
}

export const propertyTypeOptions: PropertyTypeOptions[] = [
  {
    text: 'Single Family Residence',
    value: 'sfr'
  },
  {
    text: 'Condominium',
    value: 'condo'
  },
  {
    text: '2-4 Unit',
    value: 'multi_unit'
  },
  {
    text: 'Manufactured Home',
    value: 'manufactured'
  },
  {
    text: 'Commercial',
    value: 'commercial'
  },
  {
    text: 'Farm',
    value: 'farm'
  },
  {
    text: 'Land',
    value: 'land'
  },
  {
    text: 'Mobile Home',
    value: 'mobile_home'
  },
  {
    text: 'Mixed Use',
    value: 'mixed_use'
  },
  {
    text: 'Townhouse',
    value: 'townhouse'
  }
]

export const propertyUsageOptions = [
  {
    text: 'Primary Residence',
    value: 'primary'
  },
  {
    text: 'Secondary Residence',
    value: 'secondary'
  },
  {
    text: 'Investment',
    value: 'investment'
  },
]

export const propertyUsageLessPrimaryOptions = [
  {
    text: 'Secondary Residence',
    value: 'secondary'
  },
  {
    text: 'Investment',
    value: 'investment'
  }
]

export const financingOptions = [
  {
    text: 'There is no financing, the property is currently free and clear',
    value: 'freeAndClear'
  },
  {
    text: 'There is a first mortgage only',
    value: 'firstMortgageOnly'
  },
  {
    text: 'There is a first and second mortgage',
    value: 'firstAndSecondMortgage'
  },
  {
    text: 'There is a first, second, and third mortgage',
    value: 'firstSecondAndThirdMortgage'
  }
]

export const impoundOptions = [
  {
    text: 'Not impounded',
    value: 'no-impound'
  },
  {
    text: 'Taxes and insurance',
    value: 'taxes-and-insurance'
  },
  {
    text: 'Taxes only',
    value: 'taxes-only'
  },
  {
    text: 'Insurance only',
    value: 'insurance-only'
  }
]
