import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import { Button, Modal } from 'semantic-ui-react';
import {ApplicationRoutes, LoanType} from '..';
import AddRemoveTable, { HeaderLabels } from '../../../components/AddRemoveTable/AddRemoveTable';
import { DataContext } from '../../../context/dataContext';
import { getHeaderText, reduceDisplayedTableData } from '../../../helpers/table-helper';
import { useStore } from '@jmjfinancial/apis/lib';
import ResidenceForm from './ResidenceForm';
import {scrollToDefaultOptions} from '../../../helpers/scroll-to-options';

interface TableViewProps {
  loanData: any
  hasCoBorrower: boolean
  setCoBorrowerConcierge: Function;
  conciergeMode?: boolean;
  hasResidences: boolean
  setRefreshResidenceView: Function
  coBorrowerConcierge: boolean
}

const HEADER_LABELS: HeaderLabels[] = [
  {
    text: 'Owner',
    value: 'owner'
  },
  {
    text: 'Street',
    value: 'address_street_line'
  },
  {
    text: 'City',
    value: 'address_city'
  },
  {
    text: 'State',
    value: 'address_state'
  },
  {
    text: 'Unit',
    value: 'address_unit'
  },
  {
    text: 'Years At Address',
    value: 'years_at_address'
  },
  {
    text: 'Months At Address',
    value: 'months_at_address'
  }
];

const initialEditFormData = {
  owner: '',
  // Housing
  housingType: '',
  rentMonthlyPayment: '',

  // Current address
  street: '',
  unit: '',
  city: '',
  state: '',
  postal: '',
  durationTermYears: '',
  durationTermMonths: '',
  matchesMailingAddress: null,
  currentAddress: '',
  sharedResidence: '',

  // Mailing address
  mailingStreet: '',
  mailingUnit: '',
  mailingCity: '',
  mailingState: '',
  mailingPostal: '',

  // Submit
  submit: null,
}

const TableView: FC<TableViewProps> = observer(({
  loanData,
  hasCoBorrower,
  conciergeMode,
  hasResidences,
  setRefreshResidenceView,
  coBorrowerConcierge,
  setCoBorrowerConcierge
}) => {

  const store = useStore();
  const history = useHistory();
  const { loansService } = store;
  const {
    activeLoan,
    pathname,
    setDarkThemeOverride,
    setOverrideTrackerVisibility,
    reviewMode,
    refLoanType
  } = useContext(DataContext);

  const [tableData, setTableData] = useState<any>(loanData.residences);
  const [deDuplicatedTableData, setDeDuplicatedTableData] = useState<any>([]);
  const [showForm, setShowForm] = useState(false);
  const [editFormData, setEditFormData] = useState<any>();
  const [editRowIndex, setEditRowIndex] = useState<any>();
  const [showModal, setShowModal] = useState(false);
  const [ownerName, setOwnerName] = useState('');

  setDarkThemeOverride(false);
  setOverrideTrackerVisibility(false);

  const handlePreviousClick = () => {
    if (loanData.coborrower) {
      history.push('/application/co-borrower-info')
    }
    else {
      history.push('/application/add-co-applicant')
    }
  }

  const handleNextClick = () => {
    if (reviewMode) {
      history.push('/application/review-and-submit')
    }
    else if (refLoanType.current === LoanType.refinance) {
      history.push('/application/employment-and-income');
    }
    else {
      history.push('/application/loan-details');
    }
  }

  const handleAddRow = (row: any) => {
    // Set a value equal to the data within the tableData variable
    let newTableData = [ ...tableData ];
    // Push the data entered into the newTableData array based on if row is an array or not
    if (row.length) {
      for (const obj of row) {
        newTableData.push(obj)
      }
    }
    else {
      newTableData.push(row)
    }

    setTableData(newTableData);
  };

  const handleRemoveRow = (index: number) => {
    const rowIndex = getRowIndex(getDuplicateRowId(index))
    let newTableData = [...tableData];
    // Account for removing duplicated residences, checking to see if the id value of the residence being
    // removed matches the duplicated_from_id generated from HIVE.
    let sharedCoborrowerResidence = newTableData.find((residence: any) => residence.duplicated_from_id === newTableData[rowIndex].id)
    if (sharedCoborrowerResidence) {
      sharedCoborrowerResidence.to_be_deleted = true
    }
    newTableData[rowIndex].to_be_deleted = true;
    setTableData(newTableData);
  };

  const getDuplicateRowId = (index: number): number => {
    return deDuplicatedTableData[index].id;
  }

  const getRowIndex = (id: number): number => {
    return tableData.findIndex((row: any) => row.id === id);
  }

  const handleShowForm = (visible: boolean) => {
    const emptyForm = { ...initialEditFormData }
    setEditFormData(emptyForm);
    setShowForm(visible);
    setShowModal(false);
  };

  const handleEditRow = (index: number) => {
    const rowIndex = getRowIndex(getDuplicateRowId(index))
    setEditRowIndex(rowIndex);
    setEditFormData({ ...tableData[rowIndex] });
    setShowForm(true);
  };

  const handleUpdateRow = (row: any) => {
    const updatedTableData = [...tableData];
    updatedTableData[editRowIndex] = row;
    setTableData(updatedTableData);
  }

  // Cancel data entry into the form
  const handleCancelResidence = () => {
    setShowForm(false);
    setEditRowIndex('');
  }

  let borrowerMonths: number = 0;
  let coBorrowerMonths: number = 0;

  const yearsAtResidence = (residences: any) => {
    let borrowerArray: any = [];
    let coBorrowerArray: any = [];

    residences.forEach((residence: any) => {
      const years = residence.years_at_address * 12;
      const monthTime = residence.months_at_address;

      if (residence.owner === 'borrower') {
        // If the residence is marked as being deleted, do not count the time towards the totalTime
        const totalTime = residence.to_be_deleted === true ? 0 : years + monthTime;
        borrowerArray.push(totalTime);
        // If the residence is duplicated, shared_residence === true, via tableView, i.e. there is no id, add the amount to the co-borrower.
        (residence.shared_residence.toString() === "true" && !residence?.id) && coBorrowerArray.push(totalTime)
      }

      if (residence.owner === 'coborrower') {
        // If the residence is marked as being deleted, do not count the time towards the totalTime
        const totalTime = residence.to_be_deleted === true ? 0 : years + monthTime;
        coBorrowerArray.push(totalTime)
      }
    })
    borrowerMonths = borrowerArray.length > 0 && borrowerArray.reduce((current: number, prev: number): number => current + prev);
    coBorrowerMonths = coBorrowerArray.length > 0 && coBorrowerArray.reduce((current: number, prev: number): number => current + prev);
  }

  const handleSubmit = async () => {
    // Run yearsAtResidence function to calculate housing duration.
    yearsAtResidence(tableData);

    const updatedLoan = {
      application: {
        current_step: ApplicationRoutes[pathname as keyof typeof ApplicationRoutes] + (refLoanType.current === LoanType.refinance ? 2 : 1),
        completed_steps: {
          residences_done: true
        }
      },
      residences: tableData
    }

    const dataSubmit = _.merge(loanData, updatedLoan);

    // Determine whether user validates 24 months of residential history. If so, route to next page. If not, display modal

    if (loanData.coborrower && borrowerMonths >= 24 && coBorrowerMonths >= 24) {
      handleNextClick();
      await loansService.updateLoan(activeLoan, dataSubmit);
    } else if (loanData.coborrower && borrowerMonths < 24) {
      setOwnerName(loanData.borrower.first_name);
      setShowModal(true);
    } else if (loanData.coborrower && coBorrowerMonths < 24) {
      setOwnerName(loanData.coborrower.first_name);
      setShowModal(true);
    } else if (!loanData.coborrower && borrowerMonths >= 24) {
      handleNextClick();
      await loansService.updateLoan(activeLoan, dataSubmit);
    } else if (!loanData.coborrower && borrowerMonths < 24) {
      setOwnerName(loanData.borrower.first_name);
      setShowModal(true);
    }
  }

  const filterDuplicatedResidences = (tableData: any) => {
    // Filter every instance where a residence is shared and the owner is a coborrower
    const deDupedResidences = tableData.filter((row: any) => (row.shared_residence && row.owner === 'borrower') || row.shared_residence.toString() === 'false')

    setDeDuplicatedTableData((deDuplicatedTableData: any) => deDupedResidences)
  }

  useEffect(() => {
    filterDuplicatedResidences(tableData);

    // Run function on page load to set the borrower and coborrower months.
    yearsAtResidence(loanData.residences);

    if (!loanData.coborrower && HEADER_LABELS.find(label => label.text === 'Shared')) {
      HEADER_LABELS.pop()
    }
    else if (loanData.coborrower && !HEADER_LABELS.find(label => label.text === 'Shared')) {
      HEADER_LABELS.push({
        text: 'Shared',
        value: 'shared_residence'
      })
    }

    setCoBorrowerConcierge(false);
  }, [loanData, tableData])

  useEffect(() => {
    if (!showForm && tableData.filter((row: any) => row.to_be_deleted).length === 0) {
      window.scrollTo(scrollToDefaultOptions)
    }
  }, [handleCancelResidence])

  return (
    <>
      <h1 className="title text-light-blue">Housing History</h1>
      <AddRemoveTable
        headerLabels={getHeaderText(HEADER_LABELS)}
        table={reduceDisplayedTableData(deDuplicatedTableData, HEADER_LABELS)}
        removeTableRow={handleRemoveRow}
        addButtonLabel="Add additional residence"
        showForm={showForm}
        setShowForm={handleShowForm}
        editTableRow={handleEditRow}
        loanData={loanData}
        tableData={tableData}
        form={
          <div className="application-step-container">
            <ResidenceForm
              loanData={loanData}
              conciergeMode={conciergeMode}
              hasCoBorrower={hasCoBorrower}
              handleCancel={handleCancelResidence}
              editRowIndex={editRowIndex}
              editTable={false}
              displayOwnerOption
              hasResidences={hasResidences}
              setRefreshResidenceView={setRefreshResidenceView}
              coBorrowerConcierge={coBorrowerConcierge}
              setShowForm={handleShowForm}
              saveForm={editFormData?.id || editFormData?.address_city ? handleUpdateRow : handleAddRow}
              formData={editFormData}
              formOwnerName={ownerName}
            />
          </div>
        }
      />
      {/* Display modal if housing history < 24 months */}
      <Modal
        open={showModal}
        size="small"
        dimmer="blurring"
        className="error-modal residences-modal"
      >
        <Modal.Content>
          <span className="title text-light-blue">Error Submitting Residence(s)</span>
          <span className="error-message subtitle">{`Please enter two years of housing history for ${ownerName}`}</span>
          <Button
            color="blue"
            onClick={() => {
              setShowModal(false);
              setShowForm(true);
            }}
            type="button"
          >
            Add Residence
          </Button>
        </Modal.Content>
      </Modal>
      {!showForm && (
        <div className="application-step-footer">
          <Button
            onClick={() => handlePreviousClick()}
            className="form-previous-button"
          >
            Previous
          </Button>
          <Button
            className="save"
            color="blue"
            onClick={() => handleSubmit()}
          >
            {reviewMode ? "Back to Review" : "Save & Continue"}
          </Button>
        </div>
      )}
    </>
  )

})

export default TableView;
