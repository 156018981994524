import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react';
import * as Yup from 'yup';
import AddressInput from '../../components/AddressInput/AddressInput';
import ErrorContainer from '../../components/ErrorContainer';
import LoaderOverlay from '../../components/LoaderOverlay/LoaderOverlay';
import { DataContext } from '../../context/dataContext';
import { useStore } from '@jmjfinancial/apis/lib';

const MyLoansEditForm: FC = observer(() => {
  const [showErrorContainer, setShowErrorContainer] = useState<boolean>(false)
  const [disableOnErrors, setDisableOnErrors] = useState<boolean>(false)

  const {
    activeLoan,
    showEditForm,
    setShowEditForm,
    isSavingChanges,
    setIsSavingChanges,
    loanData
  } = useContext(DataContext)
  const { loansService } = useStore()

  const history = useHistory();

  return (
    <>
      {isSavingChanges && <LoaderOverlay active label="Saving Changes..." />}
      <Formik
        initialValues={{
          street: loanData?.property.address_street_line || '',
          unit: loanData?.property.address_unit || '',
          city: loanData?.property.address_city || '',
          state: loanData?.property.address_state || '',
          postal: loanData?.property.address_postal || '',
          county: loanData?.property.address_county || ''
        }}
        validationSchema={Yup.object().shape({
          street: Yup.string().required(),
          unit: Yup.string(),
          city: Yup.string().required(),
          state: Yup.string().required(),
          postal: Yup.string().required('')
            .matches(/^[0-9]{5}$/, 'Zipcode must be exactly 5 digits')
        })}
        onSubmit={async (values, {
          setStatus,
          setErrors,
          setSubmitting
        }) => {
          try {
            const changedData = {
              property: {
                address_street_line: values.street,
                address_unit: values.unit,
                address_city: values.city,
                address_state: values.state,
                address_postal: values.postal,
                address_county: values.county
              }
            }

            const dataSubmit = _.merge(loanData, changedData)

            await loansService.updateLoan(activeLoan, dataSubmit)
              .then(response => {
                history.go(0)
              })
          } catch (err: any) {
            console.error('My Loans error: ', err.message)
            setStatus({ success: false })
            setSubmitting(false)
          }
        }}
      >
        {({
          values,
          errors,
          handleSubmit,
          handleBlur,
          handleChange,
          setFieldValue,
          touched,
          isSubmitting
        }) => (
          <form
            onSubmit={handleSubmit}
            className="my-loans-form"
          >
            <div className="form-step">
              <AddressInput
                handleBlur={handleBlur}
                handleChange={handleChange}
                touched={touched}
                errors={errors}
                showLabel={false}
                showErrorContainer={showErrorContainer}
                disableFormikError={true}
                setFieldValue={setFieldValue}
                values={values}
              />
            </div>
            <div className="form-controls">
              <Button
                type="button"
                className="alternate-button"
                onClick={() => setShowEditForm && (setShowEditForm((showEditForm: boolean) => false))}
              >
                Cancel
              </Button>
              <ErrorContainer
                errors={errors}
                showErrorContainer={showErrorContainer}
                setDisableOnErrors={setDisableOnErrors}
                  errorMessage={
                    (errors.postal)
                  }
              />
              <Button
                  disabled={isSubmitting || disableOnErrors}
                type="submit"
                color="blue"
                  onClick={() => {
                    setIsSavingChanges((isSavingChanges: boolean) => true)
                    setShowErrorContainer(showErrorContainer => true)
                  }}
              >
                Save
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </>
  )
})

export default MyLoansEditForm
