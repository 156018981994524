import React, {FC, useContext, useEffect, useState} from 'react';
import { Button } from 'semantic-ui-react';
import {Link, useParams} from 'react-router-dom';
import {scrollToDefaultOptions} from '../../helpers/scroll-to-options';
import {DataContext} from '../../context/dataContext';

const LandingPage: FC = () => {
  const {
    setDarkThemeOverride,
    setReferrer
  } = useContext(DataContext);
  const { referrer } = useParams<any>()

  setDarkThemeOverride(true)

  useEffect(() => {
    window.scrollTo(scrollToDefaultOptions)

    setReferrer(referrer)
  }, [])

  return (
    <div className="landing-container">
      <div className="landing-content">
        <h1 className="title">Fast Preapprovals</h1>
        <h2 className="subtitle">Get prequalified, view your progress, securely upload documents,
          and message your Loan Officer as you go.
        </h2>
        <div className="landing-controls">
          <Button
            as={Link}
            to="/registration"
            className="landing-button"
          >
            Start Application
          </Button>
          <Button
            as={Link}
            to="/login"
            className="landing-button inverted"
          >
            Already started a loan?
          </Button>
        </div>
      </div>
      <div className="landing-graphic" />
    </div>
  );
}

export default LandingPage;
