import React, { FC, useEffect } from 'react';
import './disclosure-modal.scss';
import HelloSign from 'hellosign-embedded';

const HELLO_SIGN_CLIENT_ID = process.env.REACT_APP_HELLO_SIGN_CLIENT_ID || '';

const EmployeeDisclosureModal: FC = () => {
  const client = new HelloSign({
    clientId: HELLO_SIGN_CLIENT_ID
  });

  useEffect(() => {
    const query = new URLSearchParams(document.location.search)
    let signUrl = query.get("signUrl")
    let token = query.get("token")
    let fullUrl = `${signUrl}&token=${token}`
    let loanId = query.get("loanId") || ""
    let eSignEnvelopeId = query.get("eSignEnvelopeId") || ""
     
    if (signUrl) {
      client.open(fullUrl, {
        skipDomainVerification: true
      });
    }
  }, [client])

  return (
    <></>
  )
}

export default EmployeeDisclosureModal;
