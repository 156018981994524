import React, { FC, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import '../../../components/PageHeader/index.scss';
import './section0.scss';
import { observer } from 'mobx-react-lite';
import {DataContext} from '../../../context/dataContext';
import LoaderOverlay from '../../../components/LoaderOverlay/LoaderOverlay';
import { useStore } from '@jmjfinancial/apis/lib';
import {scrollToDefaultOptions} from '../../../helpers/scroll-to-options';

const Step1: FC = observer(() => {
  const history = useHistory()
  const store = useStore();
  const { loansService } = store;

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { setDarkThemeOverride, setOverrideTrackerVisibility, activeLoan } = useContext(DataContext);

  setDarkThemeOverride(true);
  setOverrideTrackerVisibility(true);

  const handleClick = (key: string, value: string, setFieldValue: Function) => {
    setFieldValue(key, value)
    history.push(`/application/${value}`)
  }

  useEffect(() => {
    setIsLoading(true)
    loansService.getLoan(activeLoan).then((l: any) => {
      const loan = l?.data?.form_data

      if (loan.borrower.id !== loan.loan.principal_borrower_id) {
        history.push('/application/borrower-info')
      }
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    window.scrollTo(scrollToDefaultOptions)
  }, [])

  return (
    <>
      <LoaderOverlay active={isLoading} />
      {!isLoading && (
        <Formik
          initialValues={{
            loanPurpose: '',
            submit: null
          }}
          validationSchema={Yup.object().shape({
            loanPurpose: Yup.string()
          })}
          onSubmit={async (values, {
            setErrors,
            setStatus,
            setSubmitting
          }) => {
            try {
              console.log('Submitting ', values.loanPurpose)
            } catch (err: any) {
              console.error('Application error ', err.message);
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }}
        >
          {({
            setFieldValue
          }) => (
            <>
              <form className="choice-form purchase-or-refinance-form">
                <button
                  className="choice purchase"
                  onClick={() => handleClick('loanPurpose', 'purchase', setFieldValue)}
                >
                  <i className="angle right icon tiny"></i>
                  <span>I want to purchase a home.</span>
                </button>
                <button
                  className="choice choice-refinance"
                  onClick={() => handleClick('loanPurpose', 'refinance', setFieldValue)}
                >
                  <i className="angle right icon tiny"></i>
                  <span>I'm looking to refinance.</span>
                </button>
              </form>
            </>
          )}
        </Formik>
      )}
    </>
  )
})

export default Step1;
