import React, { FC } from 'react';
import { Form, Radio } from 'semantic-ui-react';
import { currencyMask } from '../../../../helpers/currency-mask-input';
import FormikMaskedInput from '../../../../components/Formik/FormikMaskedInput';
import { clearValues } from '../../../../helpers/clear-values-helper';
import {REOSubPathProps} from './index';

const PurchasePrimaryPath: FC<REOSubPathProps> = ({
  values,
  errors,
  handleBlur,
  handleChange,
  touched,
  showErrorContainer,
}) => {
  return (
    <>
      <Form.Field>
        <label>What are your plans for the property?</label>
        <div className={`radio-group ${showErrorContainer && !values.propertyPlans && 'radio-error'}`}>
          <Radio
            id="propertyPlansSell"
            className={errors.propertyPlans && 'has-error'}
            label="Sell"
            name="propertyPlans"
            value="sell"
            checked={values.propertyPlans === 'sell'}
            onClick={() => clearValues(
              values, [
                'isRent',
                'rentalIncomeGrossAmount'
              ])}
            onChange={(e) => handleChange(e)}
          />
          <Radio
            id="propertyPlansKeep"
            className={errors.propertyPlans && 'has-error'}
            label="Keep"
            name="propertyPlans"
            value="keep"
            checked={values.propertyPlans === 'keep'}
            onFocus={() => clearValues(
              values, [
                'isRent',
                'sellTimeline',
                'sellNet'
              ])}
            onChange={(e) => handleChange(e)}
          />
        </div>
      </Form.Field>
      {values.propertyPlans === 'sell' && (
        <>
          <Form.Field className='nested'>
            <label>Got it. Are your plans to sell before or after closing on your new home?</label>
            <div className={`radio-group ${showErrorContainer && !values.sellTimeline && 'radio-error'}`}>
              <Radio
                id="sellTimelineBefore"
                label="Before"
                name="sellTimeline"
                value="before"
                checked={values.sellTimeline === 'before'}
                className={errors.sellTimeline && 'has-error'}
                onChange={(e) => handleChange(e)}
              />
              <Radio
                id="sellTimelineAfter"
                label="After"
                name="sellTimeline"
                value="after"
                checked={values.sellTimeline === 'after'}
                className={errors.sellTimeline && 'has-error'}
                onFocus={() => clearValues(
                  values, [
                    'sellNet',
                  ])}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </Form.Field>
          {values.sellTimeline === 'before' && (
            <Form.Field className='nested'>
              <FormikMaskedInput
                className={`small ${!!(touched.sellNet && errors.sellNet) ? 'error' : ''}`}
                label="Okay, how much do you anticipate netting from the sale?"
                name="sellNet"
                placeholder="Net Profit"
                type="text"
                value={values.sellNet}
                onBlur={handleBlur}
                onChange={handleChange}
                mask={currencyMask}
                error={
                  !!(touched.sellNet && errors.sellNet)
                  || (showErrorContainer && !values.sellNet)
                }
              />
            </Form.Field>
          )}
        </>
      )}
      {values.propertyPlans === 'keep' && (
        <>
          <Form.Field className="nested">
            <label>Got it. Do you plan on renting out the property?</label>
            <div className={`radio-group ${showErrorContainer && !values.isRent && 'radio-error'}`}>
              <Radio
                id="isRentTrue"
                className={(errors.isRent) ? 'has-error' : undefined}
                label="Yes"
                name="isRent"
                value="true"
                checked={values.isRent?.toString() === "true"}
                onChange={(e) => handleChange(e)}
              />
              <Radio
                id="isRentFalse"
                className={(errors.isRent) ? 'has-error' : undefined}
                label="No"
                name="isRent"
                value="false"
                checked={values.isRent?.toString() === "false"}
                onFocus={() => clearValues(
                  values, [
                    'rentalIncomeGrossAmount',
                  ])}
                onChange={(e) => handleChange(e)}
              />
            </div>
          </Form.Field>
          {values.isRent.toString() === "true" && (
            <>
              <Form.Field className="nested">
                <FormikMaskedInput
                  className={`small ${!!(touched.rentalIncomeGrossAmount && errors.rentalIncomeGrossAmount) ? 'error' : ''}`}
                  label="Great, what's the approximate rent you'll receive monthly?"
                  name="rentalIncomeGrossAmount"
                  placeholder="Gross Rental Income"
                  type="text"
                  value={values.rentalIncomeGrossAmount}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  mask={currencyMask}
                  error={
                    !!(touched.rentalIncomeGrossAmount && errors.rentalIncomeGrossAmount)
                    || (showErrorContainer && !values.rentalIncomeGrossAmount)
                  }
                />
              </Form.Field>
            </>
          )}
        </>
      )}
    </>
  )
}

export default PurchasePrimaryPath
