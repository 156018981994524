const CoappModeSubtitle = (
  props: any
) => (
  <h3 className="subtitle text-grey coapplicant-mode">
    Co-applicants are not able to edit this section.
    {` ${props.customText || 'Only the Primary Borrower can make changes to this section.'}`}
  </h3>
)

export default CoappModeSubtitle
