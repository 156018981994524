import React, {FC, useContext, useEffect, useRef, useState} from 'react';
import * as Yup from 'yup';
import { useStore } from '@jmjfinancial/apis/lib';
import { DataContext } from '../../../context/dataContext';
import { AddRemoveFormProps } from '../../../components/AddRemoveTable/AddRemoveTable';
import {Formik} from 'formik';
import { Form, Icon, Modal } from 'semantic-ui-react';
import FormikInput from '../../../components/Formik/FormikInput';
import MaskedInput from 'react-text-mask';
import { Button, Input } from 'semantic-ui-react';
import FormikErrorMessage from '../../../components/Formik/ErrorMessage';
import ErrorContainer from '../../../components/ErrorContainer';
import { scrollIntoViewDefaultOptions } from '../../../helpers/scroll-to-options';
import {valuesAreUnique} from '../../../helpers/uniqueness-helper';
import { PHONE_INPUT_MASK, PHONE_MATCH, PHONE_MASK } from '../../../helpers/regex-helper';
import FormikDropdown from '../../../components/Formik/FormikDropdown';

const relationshipToBorrowerOptions = [
  { text: 'Spouse', value: 'spouse' },
  { text: 'Father', value: 'father' },
  { text: 'Mother', value: 'mother'  },
  { text: 'Sibling', value: 'sibling'  },
  { text: 'Business Partner', value: 'business'  },
  { text: 'Other', value: 'other_relationship'  }
];

const relationshipOptionsLessSpouse = [
  { text: 'Father', value: 'father' },
  { text: 'Mother', value: 'mother' },
  { text: 'Sibling', value: 'sibling' },
  { text: 'Business Partner', value: 'business' },
  { text: 'Other', value: 'other_relationship' }
];

interface EditIncomeFormProps extends AddRemoveFormProps {
  loanData?: any
  isSpouse: boolean
  setIsSpouse: Function
  setShowCoApplicantForm: Function
  coapplicantCount: number
  setCoapplicantCount: Function
  conciergeMode: boolean
  tableData?: any
}

const EditAddCoApplicantForm: FC<EditIncomeFormProps> = ({
  loanData,
  isSpouse,
  setIsSpouse,
  setShowCoApplicantForm,
  setShowForm,
  coapplicantCount,
  setCoapplicantCount,
  saveForm,
  conciergeMode,
  tableData
}) => {
  const store = useStore();
  const { loansService } = store;
  const { activeLoan,
    setDarkThemeOverride,
    setOverrideTrackerVisibility
  } = useContext(DataContext);
  const [disableOnErrors, setDisableOnErrors] = useState<boolean>(false);
  const [showErrorContainer, setShowErrorContainer] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const bypassSubmitRef = useRef<boolean>(false);
  const allLoansRef = useRef<any>();
  const formRef = useRef<HTMLFormElement>(null);

  setDarkThemeOverride(false)
  setOverrideTrackerVisibility(false)

  const handleFormCleanup = () => {
    setShowForm(false)
    setShowCoApplicantForm(false)
  }

  const onCancelClick = (resetForm: Function) => {
    resetForm();
    handleFormCleanup()
  }

  const handleModalClose = (setSubmitting: Function) => {
    bypassSubmitRef.current = false
    setSubmitting(false)
    setShowModal(false)
  }

  useEffect(() => {
    if (formRef && formRef.current) {
      formRef.current.scrollIntoView(scrollIntoViewDefaultOptions)
    }

    loansService.getCustomer().then(customerData => {
      if (customerData.data) {
        const customerLoans = customerData.data.loans;
        loansService.getAllLoans(customerLoans).then(loans => allLoansRef.current = loans);
      }
    })
  }, [])

  return (
    <Formik
      enableReinitialize
      initialValues={{
        relationToPrimaryBorrower: isSpouse ? 'spouse' : '',
        firstName: '',
        lastName: '',
        email: '',
        mobilePhone: '',
        submit: null
      }}
      validationSchema={Yup.object().shape({
        relationToPrimaryBorrower: Yup.string().required(' '),
        firstName: Yup.string().required(' '),
        lastName: Yup.string().required(' '),
        email: Yup.string()
          .email('Invalid email address')
          .required(' '),
        mobilePhone: Yup.string()
          .matches(PHONE_MASK, 'Phone number must be 10 digits')
          .matches(PHONE_MATCH, 'Area code cannot start with 1')
          .required(' ')
      })}
      onSubmit={(values, {
        setErrors,
        setStatus,
        setSubmitting,
        resetForm
      }) => {
        try {
          const changedData = {
            relationship_to_primary_borrower: values.relationToPrimaryBorrower,
            first_name: values.firstName,
            last_name: values.lastName,
            email: values.email,
            mobile_phone: values.mobilePhone.replace(/\D/g, ''),
            spouse: isSpouse
          }

          console.log({changedData})

          // Filters all loans that match the co-applicant email entered in the form. If the length of the array is > 0 then a modal will appear informing the user that an email already exists for the co-applicant.
          // Returns an array of the entire loan
          const previouslyAddedCoApplicants = allLoansRef.current.filter((loan: any) =>
            loan.coapplicants.some((coapplicant: any) =>
              coapplicant.email === changedData.email
            )
          )

          // Check if bypassSubmit is set to false, if so check for the length of previouslyAddedCoApplicants array. This will automatically check upon the first submission since bypassSubmit is initialized as false. This will also check if a user hits cancel in the modal as that also sets the value to false. If a user hits proceed in the modal however, this sets bypassSubmit.current to true and therefore bypasses the check.
          if (!bypassSubmitRef.current) {
            if (previouslyAddedCoApplicants.length > 0) {
              bypassSubmitRef.current = false;
            } else {
              bypassSubmitRef.current = true;
            }
          }

          if (conciergeMode) {
            if (bypassSubmitRef.current) {
              loansService.addBorrower(activeLoan, [changedData]).then((res: any) => {
                if (res.success) {
                  setStatus({ success: true });
                  setSubmitting(false);
                  // Prevents spouse version of concierge from being shown twice
                  if (isSpouse) {
                    setIsSpouse(false)
                  }
                  // loanData.coapplicant.length does not update unless index file is rerendered
                  // using setCoapplicantCount allows us to keep track of the total number of entries without having to rerender index file
                  setCoapplicantCount(coapplicantCount + 1)
                  handleFormCleanup()
                  resetForm({});
                }
                else {
                  setStatus({ success: false });
                  setErrors({ submit: res.errors?.base[0].includes('Addcoborrowerinteraction')
                    ? `"${values.email}" has already been used on this loan.`
                    : res.errors?.base[0] || res.error
                  });
                  setSubmitting(false);
                }
              })
            }
            else if (previouslyAddedCoApplicants.length) {
              bypassSubmitRef.current = true;
              setShowModal(true)
            }
          }
          else {
            if (valuesAreUnique(
              [values.email],
              loanData,
              'coapplicants',
              ['email'],
              tableData
            )) {
              console.log('values are unique')

              console.log('changedData: ', changedData)
              saveForm(changedData)
              setStatus({ success: true });
              setSubmitting(false);
              handleFormCleanup()
              resetForm({});
            }
            else {
              setStatus({ success: false });
              setErrors({ submit: `"${values.email}" has already been used on this loan.` });
              setSubmitting(false);
            }
          }
        } catch (err: any) {
          console.error('EditAddCoApplicantForm: ', err.message);
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        setSubmitting,
        touched,
        values,
        resetForm
      }) => (
        <>
          {conciergeMode && (
            <>
              <h1 className='title has-subtitle text-light-blue'>Add Co-Applicant</h1>
              {isSpouse
                ? <h3 className="subtitle text-grey">Okay, let's collect some info about your spouse.</h3>
                : <h3 className="subtitle text-grey">Okay, let's collect some info about your co-applicant.</h3>
              }
            </>
          )}
          <form
            ref={formRef}
            onSubmit={handleSubmit}
            className={`application-form ${!conciergeMode ? 'form-view' : ''}`}
          >
            <div className={`form-step ${isSpouse && 'disabled'}`}>
              <Form.Field>
                <label>{`Relationship to ${loanData ? loanData?.borrower.first_name : 'primary borrower'}?`}</label>
                <FormikDropdown
                  className="small"
                  name="relationToPrimaryBorrower"
                  placeholder="Relationship"
                  options={isSpouse ? relationshipToBorrowerOptions : relationshipOptionsLessSpouse}
                  error={!!(touched.relationToPrimaryBorrower && errors.relationToPrimaryBorrower) || !!(showErrorContainer && values.relationToPrimaryBorrower === '')}
                  disabled={isSpouse}
                />
              </Form.Field>
            </div>
            <div className="form-step">
              <Form.Field>
                <label>Full Name</label>
                <div className="borrower-name co-applicant">
                  <Input
                    className="small first-name"
                    name="firstName"
                    placeholder="First"
                    type="text"
                    value={values.firstName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.firstName && errors.firstName) || Boolean(showErrorContainer && values.firstName === '')}
                  />
                  <Input
                    className="small"
                    name="lastName"
                    placeholder="Last"
                    type="text"
                    value={values.lastName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={Boolean(touched.lastName && errors.lastName) || Boolean(showErrorContainer && values.lastName === '')}
                  />
                </div>
              </Form.Field>
            </div>
            <div className="form-step">
              <Form.Field>
                <label>Email</label>
                <FormikInput
                  className="small"
                  name="email"
                  placeholder="Email"
                  type="text"
                  value={values.email}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={Boolean(touched.email && errors.email) || Boolean(showErrorContainer && values.email === '')}
                />
              </Form.Field>
            </div>
            <div className="form-step">
              <Form.Field>
                <label>Mobile Phone</label>
                <Input
                  className="small"
                  error={Boolean(touched.mobilePhone && errors.mobilePhone) || Boolean(showErrorContainer && values.mobilePhone === '')}
                >
                  <MaskedInput
                    mask={PHONE_INPUT_MASK}
                    guide={false}
                    name="mobilePhone"
                    placeholder="Phone Number"
                    type="text"
                    value={values.mobilePhone}
                    onBlur={handleBlur}
                    onChange={handleChange}
                  />
                  <FormikErrorMessage name="mobilePhone" />
                </Input>
              </Form.Field>
            </div>
            <Modal
              dimmer="blurring"
              className="default-modal income-modal co-applicant-modal"
              open={showModal}
            >
              <Icon
                name="close"
                className="close-icon"
                onClick={() => handleModalClose(setSubmitting)}
              />
              <span className="modal-header">Quick Tip</span>
              <div className="modal-subheader">
                <p>It looks like you've added the email '{values.email}' in another loan. </p>
                <p>This will populate various sections with the information associated with that email such as name, dob, ssn, etc. </p>
                <span>If this is correct, click 'Proceed' to move forward in the loan process. </span>
                <span>Otherwise, select 'Cancel' to update the form.</span>
              </div>
              <div className="modal-footer">
                <Button
                  type="button"
                  className="alternate-button orange"
                  onClick={() => handleModalClose(setSubmitting)}
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  className="alternate-button blue"
                  onClick={() => {
                    bypassSubmitRef.current = true;
                    handleSubmit()
                  }}
                >
                  Proceed
                </Button>
              </div>
            </Modal>
            <div className="application-step-footer">
              <Button
                className="cancel"
                type="button"
                onClick={() => onCancelClick(resetForm)}
              >
                Cancel
              </Button>
              <ErrorContainer
                errors={errors}
                showErrorContainer={showErrorContainer}
                setDisableOnErrors={setDisableOnErrors}
                errorMessage={errors.submit ? errors.submit : undefined}
              />
              <Button
                className="save"
                disabled={isSubmitting || disableOnErrors}
                color="blue"
                type="submit"
                onClick={() => setShowErrorContainer(true)}
              >
                Save
             </Button>
            </div>
          </form>
        </>
      )}
    </Formik>
  )
}

export default EditAddCoApplicantForm;
