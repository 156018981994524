import { Link } from 'react-router-dom';
import logoWhite from '../../assets/images/logo-horizontal-white.png';
import logoBlue from '../../assets/images/logo-horizontal.png';
import PageHeaderControls from './PageHeaderControls';
import React, { FC, useContext, useEffect, useState } from 'react';
import './index.scss'
import { DataContext } from '../../context/dataContext';
import {Button, Icon} from 'semantic-ui-react';
import MobileSidebar from './MobileSidebar';

export interface PageHeaderProps {
  darkTheme: boolean;
  splitTheme: boolean;
}

const PageHeader: FC<PageHeaderProps> = ({
  darkTheme, splitTheme
}) => {
  const {
    user,
    pathname,
    sidebarVisible,
    setSidebarVisible
  } = useContext(DataContext)
  const [hasSubLabel, setHasSubLabel] = useState<boolean>(false)

  useEffect(() => {
    if (pathname.includes('/dashboard')) {
      setHasSubLabel(true)
    } else {
      setHasSubLabel(false)
    }
  }, [pathname])

  return (
    <header className={`page-header ${hasSubLabel ? 'has-sub-label' : ''}`}>

      <Link to={user ? '/dashboard' : '/'}>
        <img src={darkTheme || splitTheme ? logoWhite : logoBlue} className="logo" alt="logo" />
      </Link>
      <Button
        basic
        icon
        inverted={darkTheme || splitTheme }
        className="header-menu-button borderless no-padding"
        onClick={() => setSidebarVisible(true)}
        aria-label="main menu"
      >
        <Icon
          size="large"
          name="bars"
        />
      </Button>
      <PageHeaderControls darkTheme={darkTheme} splitTheme={splitTheme} />
      <MobileSidebar
        visible={sidebarVisible}
        setVisible={setSidebarVisible}
        darkTheme={darkTheme}
        splitTheme={splitTheme}
      />
    </header>
  )
}

export default PageHeader;
