import React, {FC, useContext, useEffect, useState} from 'react';
import { AddRemoveFormProps } from '../../../components/AddRemoveTable/AddRemoveTable';
import REOPathForm from './PathForms';
import DuplicateREOForm from './DuplicateREOForm';
import { DataContext } from '../../../context/dataContext';

interface EditREOFormProps extends AddRemoveFormProps {
  loanData: any;
  setRefreshREOView: Function;
  incompleteREOs: Array<any>;
  setIncompleteREOs: Function;
  reoIndex: number;
  setReoIndex: Function;
  conciergeMode: boolean;
  formData?: any;
  setFormData: Function;
  tableData?: any;
  borrowerData?: any
}

const EditREOForm: FC<EditREOFormProps> = ({
  loanData,
  setRefreshREOView,
  incompleteREOs,
  setIncompleteREOs,
  reoIndex,
  setReoIndex,
  conciergeMode,
  setShowForm,
  saveForm,
  formData,
  tableData,
  setFormData,
  borrowerData
}) => {
  const { setDarkThemeOverride, setOverrideTrackerVisibility } = useContext(DataContext);

  const [showDuplicateForm, setShowDuplicateForm] = useState(false)

  setDarkThemeOverride(false)
  setOverrideTrackerVisibility(false)

  const addREO = () => {
    const updatedREOArray = [...incompleteREOs];
    updatedREOArray.push({});
    setFormData({})
    setIncompleteREOs(updatedREOArray);
  }

  const handleFormCancelClick = () => {
    if (tableData) {
      setShowForm(false)
    }
    else {
      const updatedREOArray = [...incompleteREOs]
      updatedREOArray.splice((reoIndex), 1)
      setIncompleteREOs(updatedREOArray)
      if (reoIndex >= incompleteREOs.length) {
        setShowDuplicateForm(true)
      }
      else {
        setShowDuplicateForm(false);
      }
    }
  }

  // Increments the current index value in the array
  const incrementREOIndex = () => {
    const nextIndex = reoIndex + 1;
    setReoIndex(nextIndex);
  }

  useEffect(() => {
    if (reoIndex >= incompleteREOs.length) {
      setShowDuplicateForm(true)
    } else {
      setShowDuplicateForm(false);
      setFormData(incompleteREOs[reoIndex])
    }
  }, [reoIndex, incompleteREOs]);

  // Display duplicate form if the index value is equal to the length of the array minus 1.
  return (showDuplicateForm && conciergeMode) ? (
    <DuplicateREOForm
      loanData={loanData}
      incrementREOIndex={incrementREOIndex}
      addREO={addREO}
      setRefreshREOView={setRefreshREOView}
      incompleteREOs={incompleteREOs}
    />
  ) : (
    <REOPathForm
      loanData={loanData}
      incrementREOIndex={incrementREOIndex}
      setShowForm={setShowForm}
      handleFormCancelClick={handleFormCancelClick}
      conciergeMode={conciergeMode}
      saveForm={saveForm}
      formData={formData}
      tableData={tableData}
      borrowerData={borrowerData}
    />
  )
}

export default EditREOForm;
