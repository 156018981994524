import React, {FC, useContext, useState, useEffect} from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '@jmjfinancial/apis/lib';
import { DataContext } from '../../../../../context/dataContext';
import IncomeFormHeader from '../IncomeFormHeader';
import * as Yup from 'yup';
import _ from 'lodash';
import {Dropdown, DropdownProps, Form, Input} from 'semantic-ui-react';
import AddressInput from '../../../../../components/AddressInput/AddressInput';
import MaskedInput from 'react-text-mask';
import FormikDropdown from '../../../../../components/Formik/FormikDropdown';
import {
  entityTypeOptions,
  ownershipYearOptions,
} from '../../dropdown-data';
import {Formik, useField} from 'formik';
import ApplicationFormFooter from '../../../shared/ApplicationFormFooter';
import { IncomeFormProps } from '../../EditIncomeForm';
import FormikDatePicker from '../../../../../components/Formik/FormikDatePicker';
import {valuesAreIdentical, valuesAreUnique} from '../../../../../helpers/uniqueness-helper';
import FormikErrorMessage from '../../../../../components/Formik/ErrorMessage';
import { PHONE_MASK, PHONE_INPUT_MASK, PHONE_MATCH } from '../../../../../helpers/regex-helper';
import moment from 'moment';
import IncomeModal from '../../../../../components/IncomesModal/IncomeModal'
import { clearValues } from '../../../../../helpers/clear-values-helper';
import FormikRadio from '../../../../../components/Formik/FormikRadio';
import EntityForm from './EntityForm';
import {getPluralizedName} from '../../../../../helpers/pluralize-name';
import {currentYear} from '../../../../../helpers/date-helpers';
import {taxReturnOptions} from '../../../../../data/tax-return-options';

interface SelfEmployedIncomeProps extends IncomeFormProps {
  incomeTypes: any
  borrowerData: any
  coborrowerData: any
}

export type EntityType = 'soleProprietor' | 'partnership' | 'llc' | 's-corp' | 'c-corp'

const SelfEmployedIncomeForm: FC<SelfEmployedIncomeProps> = observer(({
  loanData,
  onCancelClick,
  updateIncomeFormView,
  owner,
  ownerName,
  incomeType,
  duplicateIncomeType,
  conciergeMode,
  saveForm,
  setShowForm,
  formData,
  showEndDate,
  setShowEndDate,
  tableData,
  incomeTypes,
  borrowerData,
  coborrowerData
}) => {
  const store = useStore();
  const { loansService } = store;

  const { activeLoan } = useContext(DataContext);

  const [disableOnErrors, setDisableOnErrors] = useState<boolean>(false)
  const [showErrorContainer, setShowErrorContainer] = useState<boolean>(false)
  const [showModal, setShowModal] = useState<boolean>(false)

  const handleShowForm = (visible: boolean) => {
    setShowForm(visible)
  }

  const checkForCoborrower = (employments: any) => {
    const coborrowerCheck = employments.find((income: any) => income.owner === 'coborrower')
    if (owner === 'coborrower' && (coborrowerCheck === undefined || !coborrowerCheck)) {
      setShowEndDate && setShowEndDate(false);
    }
  }

  const valuesToClear = [
    "companyName",
    "street",
    "unit",
    "city",
    "state",
    "postal",
    "companyPhone",
    "lineOfWorkDescription",
    "yearsInLineOfWork",
    "personalTaxFiled",
    "entityType",
    "positionTitle",
    "monthlyIncome",
    "startDate",
  ]

  const twoYearHistory = (values: any) => {
    const today = Date.now()
    if (moment(moment(today)).diff(values.endDate, 'months') > 24 ) {
      setShowModal(true)
      values.endDate = null
      clearValues(values, valuesToClear)
    }
  }

  const convertToMonthlyIncome = (values: any) => {
    if (values.yearToDateAmount) {
      return values.yearToDateAmount.replace(/[$,]/g, '') / 12;
    } else if (values.previousYearIncome) {
      return values.previousYearIncome.replace(/[$,]/g, '') / 12;
    } else if (values.previousTwoYearsIncome) {
      return values.previousTwoYearsIncome.replace(/[$,]/g, '') / 12;
    } else {
      return 0;
    }
  }

  const EntityTypeDropdown = (props: DropdownProps) => {
    const [field, meta, helpers] = useField(props.name);

    return (
      <Dropdown
        {...props}
        clearable
        fluid
        selection
        selectOnBlur={false}
        upward={false}
        onChange={(e, v) => {
          clearValues(props.values, [
            'ownershipPercent',
            'taxFilingMethod',
            'k1ReceivedYear',
            'taxReturnFileYear',
            'noW2',
            'previousYearIncome',
            'previousTwoYearsIncome',
            'incomeYears'
          ])
          helpers.setValue(v.value)
        }}
      />
    )
  }

  useEffect(() => {
    checkForCoborrower(loanData.employments)
  }, [])

  const modalHeaderText = "Only Recent Employment Required!"
  const modalBodyText = "We don't need information about income received more than two years ago. If you have received any other Self-Employment income within the last two years, then select 'Yes' to include the income on the form. If you are done with income, select 'No'."

  return (
    <>
      <IncomeFormHeader
        ownerName={ownerName}
        incomeType={incomeType}
        duplicateIncomeType={duplicateIncomeType}
        conciergeMode={conciergeMode}
      />
      <IncomeModal
       showModal={showModal}
       setShowModal={setShowModal}
       setShowForm={handleShowForm}
       tableData={tableData}
       loanData={loanData}
       modalHeader={modalHeaderText}
       modalBody={modalBodyText}
       incomeTypes={incomeTypes}
       updateIncomeFormView={updateIncomeFormView}
       conciergeMode={conciergeMode}
      />
      <Formik
        initialValues={{
          conciergeMode: conciergeMode,

          companyName: formData?.employer_name || '',
          currentEmployer: formData?.currently_employed?.toString() || '',
          street: formData?.address_street_line || '',
          unit: formData?.address_unit || '',
          city: formData?.address_city || '',
          state: formData?.address_state || '',
          postal: formData?.address_postal || '',
          companyPhone: formData?.phone_number?.replace(/^\+[0-9]/, '') || '',
          companyYears: formData?.time_on_job_term_years || '',
          lineOfWorkDescription: formData?.line_of_work || '',
          yearsInLineOfWork: formData?.time_in_line_of_work_years || '',
          personalTaxFiled: owner === 'borrower'
            ? borrowerData?.tax_filed_year?.toString() || loanData?.borrower?.tax_filed_year?.toString() || ''
            : coborrowerData?.tax_filed_year?.toString() || loanData?.coborrower?.tax_filed_year?.toString() || '',
          entityType: formData?.entity_type || '',
          ownershipPercent: formData?.ownership_percent || '',
          positionTitle: formData?.position_description || '',
          amount: formData?.amount || '',
          monthlyIncome: formData?.amount || '',
          startDate: formData?.start_date ? new Date(formData?.start_date) : '',
          endDate: formData?.end_date ? new Date(formData?.end_date) : '',

          // Entity Types
          taxFilingMethod: formData?.tax_filing_method || '',
          taxFiledYearBusiness: formData?.tax_filed_year_business?.toString() || '',
          incomeYears: formData?.income_years || [],
          noW2: formData?.no_w2?.toString() || '',
          yearToDateAmount: formData?.year_to_date_amount?.toString() || '',
          previousYearIncome: formData?.previous_year_income?.toString() || '',
          previousTwoYearsIncome: formData?.previous_two_years_income?.toString() || '',
          submit: null
        }}
        validationSchema={Yup.object().shape({
          companyName: Yup.string().required(' '),
          currentEmployer: Yup.string().when('conciergeMode', {
            is: false,
            then: Yup.string().required(' ')
          }),
          street: Yup.string().min(2, 'Invalid address entered, minimum of 2 characters required').required(' '),
          unit: Yup.string(),
          city: Yup.string().required(' '),
          state: Yup.string().required(' '),
          postal: Yup.string()
            .nullable()
            .matches(/^[0-9]{5}$/, 'Must be exactly 5 digits')
            .required(' '),
          companyPhone: Yup.string()
            .matches(PHONE_MASK, 'Phone number must be 10 digits')
            .matches(PHONE_MATCH, 'Area code cannot start with 1')
            .required(' '),
          startDate: Yup.date().required(' '),
          lineOfWorkDescription: Yup.string().required(' '),
          yearsInLineOfWork: Yup.number().required(' '),
          endDate: Yup.date().when('currentEmployer', {
            is: 'false',
            then: Yup.date().required(' ')
          }),
          personalTaxFiled: Yup.string().required(' '),
          entityType: Yup.string().required(' '),
          ownershipPercent: Yup.string().when('entityType', {
            is: 'partnership' || 'llc' || 's-corp' || 'c-corp',
            then: Yup.string().required(' ')
          }),
          positionTitle: Yup.string().required(' '),
          taxFilingMethod: Yup.string().when(['ownershipPercent', 'entityType', 'startDate'], {
            is: (
              ownershipPercent: string,
              entityType: EntityType,
              startDate: Date
            ) => (
              ownershipPercent === '100'
              && (entityType === 'llc' || entityType === 'partnership')
              && (startDate && startDate.getFullYear() < currentYear)
            ),
            then: Yup.string().required(' ')
          }),
          taxFiledYearBusiness: Yup.string().when(['ownershipPercent', 'taxFilingMethod', 'startDate'], {
            is: (
              ownershipPercent: string,
              taxFilingMethod: string,
              startDate: Date
            ) => (
              ownershipPercent
              && taxFilingMethod !== 'personal'
              && (startDate && startDate.getFullYear() < currentYear)
            ),
            then: Yup.string().required(' ')
          }),
          incomeYears: Yup.array().when(['entityType', 'noW2', 'personalTaxFiled', 'startDate', 'taxFiledYearBusiness'], {
            is: (
              entityType: EntityType,
              noW2: boolean,
              personalTaxFiled: number,
              startDate: Date,
              taxFiledYearBusiness: number
            ) => (
              entityType !== 'soleProprietor'
              && !noW2
              && (startDate && (taxFiledYearBusiness >= startDate.getFullYear() || personalTaxFiled >= startDate.getFullYear()))
            ),
            then: Yup.array().min(1).required(' ')
          }),
          noW2: Yup.boolean().when(['entityType', 'incomeYears', 'personalTaxFiled', 'startDate', 'taxFiledYearBusiness'], {
            is: (
              entityType: EntityType,
              incomeYears: Array<string>,
              personalTaxFiled: number,
              startDate: Date,
              taxFiledYearBusiness: number
            ) => (
              entityType !== 'soleProprietor'
              && (!incomeYears || incomeYears?.length === 0)
              && (startDate && (taxFiledYearBusiness >= startDate.getFullYear() || personalTaxFiled >= startDate.getFullYear()))
            ),
            then: Yup.boolean().required(' ')
          }),
          yearToDateAmount: Yup.string().when(['taxFiledYearBusiness', 'startDate'], {
            is: (taxFiledYearBusiness: string, startDate: Date) => (
              taxFiledYearBusiness === "0"
              || (startDate && startDate.getFullYear() === currentYear)
            ),
            then: Yup.string().required(' ')
          }),
          previousYearIncome: Yup.string().when(['personalTaxFiled', 'taxFiledYearBusiness', 'startDate'], {
            is: (
              personalTaxFiled: string,
              taxFiledYearBusiness: string,
              startDate: Date
            ) => (
              (personalTaxFiled || taxFiledYearBusiness)
              && taxFiledYearBusiness !== "0"
              && (startDate && startDate.getFullYear() < currentYear - 1)
            ),
            then: Yup.string().required(' ')
          }),
          previousTwoYearsIncome: Yup.string().when(['personalTaxFiled', 'taxFiledYearBusiness', 'startDate'], {
            is: (
              personalTaxFiled: string,
              taxFiledYearBusiness: string,
              startDate: Date
            ) => (
              (personalTaxFiled || taxFiledYearBusiness)
              && taxFiledYearBusiness !== "0"
              && (startDate && startDate.getFullYear() < currentYear - 2)
            ),
            then: Yup.string().required(' ')
          }),
        }, [ ['incomeYears', 'noW2'] ])}
        onSubmit={(values, {
          setErrors,
          setStatus,
          setSubmitting,
          resetForm
        }) => {
          try {
            const changedBorrowerData = {
              tax_filed_year: values.personalTaxFiled
            }

            const changedEmploymentData = {
              owner: owner,
              id: formData?.id || null,
              income_type: 'Self Employed',
              self_employed: true,
              employer_name: values.companyName,
              position_description: values.positionTitle,
              amount: convertToMonthlyIncome(values),
              phone_number: values.companyPhone.replace(/\D/g, ''),
              address_street_line: values.street,
              address_unit: values.unit,
              address_city: values.city,
              address_state: values.state,
              address_postal: values.postal,
              line_of_work: values.lineOfWorkDescription,
              time_in_line_of_work_years: values.yearsInLineOfWork,
              entity_type: values.entityType,
              ownership_percent: values.ownershipPercent,
              start_date: values.startDate,
              end_date: values.endDate,
              currently_employed: values.currentEmployer === '' ? !showEndDate : values.currentEmployer,
              // TODO: Remove `base_pay_amount` once no long needed by hive
              base_pay_amount: convertToMonthlyIncome(values),
              tax_filing_method: values.taxFilingMethod,
              tax_filed_year_business: values.taxFiledYearBusiness,
              income_years: values.incomeYears,
              no_w2: values.noW2,
              year_to_date_amount: values.yearToDateAmount?.replace(/[$,]/g, '') || '',
              previous_year_income: values.previousYearIncome?.replace(/[$,]/g, '') || '',
              previous_two_years_income: values.previousTwoYearsIncome?.replace(/[$,]/g, '') || '',
            }

            if (conciergeMode) {
              // Clear the employments && incomes arrays so as to not create any duplicate entries caused by lodash
              loanData.employments = []
              loanData.incomes = []
              const dataSubmit = _.merge(loanData, owner === 'borrower' ? {
                borrower: changedBorrowerData,
                employments: [changedEmploymentData]
              } : {
                coborrower: changedBorrowerData,
                employments: [changedEmploymentData]
              })
              loansService.updateLoan(activeLoan, dataSubmit).then((res: any) => {
                if (res.success) {
                  // If concierge mode, do not increment index
                  updateIncomeFormView(false)
                  setStatus({ success: true });
                  setSubmitting(false);
                  resetForm({});
                }
                else {
                  setStatus({ success: false });
                  // TODO: Replace ternary comparative value with something other than undefined
                  setErrors({ submit: res.error === undefined
                      ? `You have already added "${values.positionTitle} at ${values.companyName}"`
                      : res.errors?.base[0] || res.error
                  });
                  setSubmitting(false);
                }
              })

            }
            else {
              let skipUniquenessCheck = false

              if (formData.income_type) {
                skipUniquenessCheck = valuesAreIdentical(
                  [
                    formData.employer_name,
                    formData.position_description,
                    new Date(formData.start_date).toISOString().split('T')[0]
                  ],
                  [
                    values.companyName,
                    values.positionTitle,
                    new Date(values.startDate).toISOString().split('T')[0]
                  ]
                )
              }

              if (skipUniquenessCheck || (!skipUniquenessCheck && valuesAreUnique(
                [
                  owner === 'borrower' ? loanData.borrower.id : loanData.coborrower.id,
                  values.companyName,
                  values.positionTitle,
                  new Date(values.startDate).toISOString().split('T')[0]
                ],
                loanData,
                'employments',
                [
                  'borrower_id',
                  'employer_name',
                  'position_description',
                  'start_date'
                ],
                tableData
              ))) {
                saveForm(changedEmploymentData, changedBorrowerData)
                setStatus({ success: true });
                setSubmitting(false);
                resetForm({});
                setShowForm(false)
              }
              else {
                setStatus({ success: false });
                setErrors({ submit: `You have already added "${values.positionTitle} at ${values.companyName}"` });
                setSubmitting(false);
              }
            }
          } catch (err: any) {
            console.error('Income source error: ', err.message);
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          setFieldTouched,
          isSubmitting,
          touched,
          values
        }) => (
          <form
            onSubmit={handleSubmit}
            className="application-form"
          >
            <div className="form-step">
              <Form.Field>
                <label>What's the last year you filed personal tax returns?</label>
                <FormikDropdown
                  className="small"
                  fluid
                  selection
                  name="personalTaxFiled"
                  placeholder="Tax Year Filed"
                  options={taxReturnOptions}
                  value={values.personalTaxFiled}
                  error={!!(touched.personalTaxFiled && errors.personalTaxFiled) || (showErrorContainer && values.personalTaxFiled === '')}
                />
              </Form.Field>
              <Form.Field>
                <label>What's the company name?</label>
                <Input
                  name="companyName"
                  placeholder="Company Name"
                  type="text"
                  value={values.companyName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!(touched.companyName && errors.companyName) || (showErrorContainer && values.companyName === '')}
                />
              </Form.Field>
            {!conciergeMode && (
              <div className="form-step">
                <Form.Field>
                  <label>Is <span>{values.companyName || 'your company'}</span> still in operation?</label>
                  <div className={`radio-group ${showErrorContainer && values.currentEmployer === '' && 'radio-error'}`}>
                    <FormikRadio
                      label="Yes"
                      name="currentEmployer"
                      value='true'
                      checked={values.currentEmployer === 'true'}
                      className={errors.currentEmployer && 'has-error'}
                      onClick={() => clearValues(values, ['endDate'])}
                    />
                    <FormikRadio
                      label="No"
                      name="currentEmployer"
                      value='false'
                      checked={values.currentEmployer === 'false'}
                      className={errors.currentEmployer && 'has-error'}
                    />
                  </div>
                </Form.Field>
              </div>
            )}
            <AddressInput
              handleBlur={handleBlur}
              handleChange={handleChange}
              touched={touched}
              errors={errors}
              label="What's the company address?"
              showErrorContainer={showErrorContainer}
              setFieldValue={setFieldValue}
              values={values}
                companyAddress={true}
              />
              <Form.Field>
                <label>Company Phone</label>
                <Input
                  error={!!(touched.companyPhone && errors.companyPhone) || (showErrorContainer && values.companyPhone === '')}
                  className="small"
                >
                  <MaskedInput
                    mask={PHONE_INPUT_MASK}
                    name="companyPhone"
                    placeholder="Company Phone"
                    type="text"
                    value={values.companyPhone}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    guide={false}
                  />
                  <FormikErrorMessage name="companyPhone" />
                </Input>
              </Form.Field>
              <Form.Field>
                <label>What's {owner === "coborrower" ? getPluralizedName(ownerName) : 'your'} title?</label>
                <Input
                  name="positionTitle"
                  placeholder="Title"
                  type="text"
                  value={values.positionTitle}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!(touched.positionTitle && errors.positionTitle) || (showErrorContainer && values.positionTitle === '')}
                />
              </Form.Field>
              <Form.Field>
                <label>When did <span>{values.companyName || 'your company'}</span> start?</label>
                <FormikDatePicker
                  className="small"
                  name="startDate"
                  icon="calendar alternate outline"
                  placeholder="MM/DD/YYYY"
                  format="MM-DD-YYYY"
                  maxDate={new Date()}
                  value={values.startDate}
                  error={!!(touched.startDate && errors.startDate) || (showErrorContainer && values.startDate === '')}
                />
              </Form.Field>
              {showEndDate && values.currentEmployer !== 'true' && (
                <Form.Field>
                  <label>When did {values.companyName || 'your company'} stop operating?</label>
                  <FormikDatePicker
                    className="small"
                    name="endDate"
                    icon="calendar alternate outline"
                    placeholder="MM/DD/YYYY"
                    format="MM-DD-YYYY"
                    maxDate={new Date()}
                    value={values.endDate}
                    error={!!(touched.endDate && errors.endDate) || (showErrorContainer && values.endDate === '')}
                  />
                </Form.Field>
              )}
              {values.endDate && twoYearHistory(values)}
              <Form.Field>
                <label>Line of Work Description</label>
                <Input
                  name="lineOfWorkDescription"
                  placeholder="Ex. Software Engineering"
                  type="text"
                  value={values.lineOfWorkDescription}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  error={!!(touched.lineOfWorkDescription && errors.lineOfWorkDescription) || (showErrorContainer && values.lineOfWorkDescription === '')}
                />
              </Form.Field>
              <Form.Field>
                <label>How long have you been in this line of work?</label>
                <FormikDropdown
                  className="small"
                  fluid
                  selection
                  name="yearsInLineOfWork"
                  placeholder="Years"
                  type="number"
                  value={values.yearsInLineOfWork}
                  options={ownershipYearOptions}
                  error={!!(touched.yearsInLineOfWork && errors.yearsInLineOfWork) || (showErrorContainer && values.yearsInLineOfWork === '')}
                />
              </Form.Field>
              <Form.Field>
                <label>Entity Type</label>
                <EntityTypeDropdown
                  className="small"
                  name="entityType"
                  placeholder="Entity Type"
                  options={entityTypeOptions}
                  value={values.entityType}
                  values={values}
                  error={!!(touched.entityType && errors.entityType) || (showErrorContainer && values.entityType === '')}
                />
              </Form.Field>
              {values.entityType && (
                <EntityForm
                  values={values}
                  touched={touched}
                  errors={errors}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  setFieldTouched={setFieldTouched}
                  showErrorContainer={showErrorContainer}
                />
              )}
            </div>
            <ApplicationFormFooter
              onCancelClick={onCancelClick}
              nextDisabled={isSubmitting || disableOnErrors}
              errors={errors}
              showErrorContainer={showErrorContainer}
              setShowErrorContainer={setShowErrorContainer}
              setDisableOnErrors={setDisableOnErrors}
              errorMessage={errors.submit ? errors.submit : undefined}
            />
          </form>
        )}
      </Formik>
    </>
  )
})

export default SelfEmployedIncomeForm
