import React, { FC, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import _ from 'lodash';
import { Button } from 'semantic-ui-react';
import { Formik } from 'formik';
import { DataContext } from '../../../context/dataContext';
import PurchaseContractOffer from './PurchaseContractOffer'
import PurchaseFoundHome from './PurchaseFoundHome';
import PurchasePrequalify from './PurchasePrequalify';
import RefiBase from './RefiBase'
import RefiInvestment from './RefiInvestment';
import { observer } from 'mobx-react-lite';
import LoaderOverlay from '../../../components/LoaderOverlay/LoaderOverlay';
import { useStore } from '@jmjfinancial/apis/lib';
import { ApplicationRoutes } from '../index';
import ErrorContainer from '../../../components/ErrorContainer';
import CoappModeSubtitle from '../../../components/CoappModeSubtitle/CoappModeSubtitle';
import './loan-details.scss';
import { LoanPurposeType } from '../../../types/loan';
import {scrollToDefaultOptions} from '../../../helpers/scroll-to-options';
import { PHONE_MASK, PHONE_MATCH } from '../../../helpers/regex-helper';
import { futureDate } from '../../../helpers/date-helpers'

export interface BorrowerInfoProps {
  errors: any,
  values: any,
  touched: any,
  handleBlur: any,
  handleChange: Function,
  setFieldValue: any,
  handleSubmit: Function,
  showErrorContainer: boolean
  coapplicantMode: boolean
}

const LoanDetailsV2: FC = observer(() => {
  const store = useStore();
  const { loansService } = store;

  const {
    activeLoan,
    pathname,
    reviewMode,
    coapplicantMode,
    setCoapplicantMode
  } = useContext(DataContext)
  const [loanData, setLoanData] = useState<any>()
  const [isLoading, setIsLoading] = useState(true)
  const [loanPurpose, setLoanPurpose] = useState<LoanPurposeType>()
  const [disableOnErrors, setDisableOnErrors] = useState<boolean>(false)
  const [showErrorContainer, setShowErrorContainer] = useState<boolean>(false)

  const history = useHistory();

  const handlePreviousClick = () => {
    history.push('/application/residences');
  }

  const handleNextClick = () => {
    if (reviewMode) {
      history.push('/application/review-and-submit')
    }
    else {
      history.push('/application/employment-and-income')
    }
  }

  const handleCoApplicantNextClick = () => {
    const changedData = {
      application: {
        current_step: ApplicationRoutes[pathname as keyof typeof ApplicationRoutes] + 1,
        completed_steps: {
          loan_details_done: true
        }
      }
    }

    const dataSubmit = _.merge(loanData, changedData)
    loansService.updateLoan(activeLoan, dataSubmit).then(() => handleNextClick())
  }

  useEffect(() => {
    loansService.getLoan(activeLoan)
      .then(loan => {
        const l = loan.data.form_data
        setLoanData({ ...loan.data.form_data })
        setLoanPurpose(loan.data.form_data.loan.loan_purpose_type)

        setIsLoading(false);
      })
  }, [loansService, activeLoan])

  useEffect(() => {
    window.scrollTo(scrollToDefaultOptions)
  }, [])


  return (
    <div className={`application-step-container loan-details-container ${coapplicantMode ? 'coapplicant-mode' : ''}`}>
      <LoaderOverlay active={isLoading} />
      {!isLoading && (
        <>
          <h1 className="title has-subtitle text-light-blue">Loan Details</h1>
          {coapplicantMode && <CoappModeSubtitle />}
          {loanPurpose === "purchase" && !coapplicantMode && <h3 className="subtitle text-grey">Next, let's gather more details about your purchase.</h3>}
          {loanPurpose === "refinance" && !coapplicantMode && <h3 className="subtitle text-grey">Next, let's capture more details about the home you’re refinancing</h3>}
          <Formik
            initialValues={{

              //PURPOSE
              loanPurpose: loanData.loan.loan_purpose_type || "purchase",
              loanAmount: loanData.loan.base_loan_amount || '',

              //REALTOR
              realtorExists: loanData.borrower.realtor_exists?.toString() || '',
              requestRealtorReferral: loanData.borrower.request_realtor_referral?.toString() || '',
              realtorFirstName: loanData.realtor?.first_name || '',
              realtorLastName: loanData.realtor?.last_name || '',
              realtorNumber: loanData.realtor?.phone_number?.replace(/^\+[0-9]/, '') || '',
              realtorEmail: loanData.realtor?.email || '',

              //ADDRESS
              street: loanData.property?.address_street_line || '',
              unit: loanData.property?.address_unit || '',
              city: loanData.property?.address_city || '',
              state: loanData.property?.address_state || '',
              postal: loanData.property?.address_postal || '',
              county: loanData.property?.address_county || '',

              //PURCHASE
              purchasePlan: loanData.loan.purchase_plan || 'contract',
              purchaseTimeframe: loanData.loan.purchase_timeframe || '',
              propertyType: loanData.property?.property_type || '',
              // structureYearBuilt: loanData.property?.structure_year_built || '',
              purchasePrice: loanData.property?.purchase_price_amount || '',
              downPaymentAmount: loanData.loan.down_payment_amount || '',
              downPaymentPercent: loanData.loan.down_payment_percent?.toString() || '',
              closingDate: loanData.loan.closing_date ? new Date(loanData.loan.closing_date) : '',

              //REFINANCE
              homeUse: loanData.property.usage || 'primary',

              // TODO: map the isRental value correctly to Hive
              isRental: loanData.property.is_rental?.toString() || '',
              rentalIncomeMonthly: loanData.property.rental_income_monthly || '',
              commonWalls: loanData.property.common_walls || '',
              propertyTaxes: loanData.property.property_taxes || '',
              insurancePremium: loanData.property.insurance_premium || '',
              hoaDues: loanData.property?.hoa_dues_amount || '',
              outstandingLoans: loanData.property.outstanding_loans || '',
              mortgages: loanData.property?.existing_mortgages || [],

              // propertyAppraisedValueAmount: loanData.property?.property_appraised_value_amount || '',

              firstMortgageLoanAmount: loanData.property.first_mortgage_loan_amount || '',
              firstMortgageTaxAndInsurance: loanData.property.first_mortgage_tax_and_insurance || '',
              firstMortgagePayment: loanData.property.first_mortgage_payment || '',
              firstMortgageImpounded: loanData.property.first_mortgage_impounded || '',

              secondMortgageType: loanData.property.second_mortgage_type || '',
              secondMortgageLoanAmount: loanData.property.second_mortgage_loan_amount || '',
              secondMortgageTaxAndInsurance: loanData.property.second_mortgage_tax_and_insurance || '',
              secondMortgagePayment: loanData.property.second_mortgage_payment || '',
              secondMortgageBalance: loanData.property.second_mortgage_balance || '',
              secondMortgageCreditLimit: loanData.property.second_mortgage_credit_limit || '',
              secondMortgageInterestRate: loanData.property.second_mortgage_interest_rate || '',
              secondMortgageEquityPayment: loanData.property.second_mortgage_equity_payment || '',

              thirdMortgageType: loanData.property.third_mortgage_type || '',
              thirdMortgageLoanAmount: loanData.property.third_mortgage_loan_amount || '',
              thirdMortgageTaxAndInsurance: loanData.property.third_mortgage_tax_and_insurance || '',
              thirdMortgagePayment: loanData.property.third_mortgage_payment || '',
              thirdMortgageBalance: loanData.property.third_mortgage_balance || '',
              thirdMortgageCreditLimit: loanData.property.third_mortgage_credit_limit || '',
              thirdMortgageInterestRate: loanData.property.third_mortgage_interest_rate || '',
              thirdMortgageEquityPayment: loanData.property.third_mortgage_equity_payment || '',
              submit: null
            }}
            validationSchema={Yup.object().shape({
              // REALTOR
              realtorExists: Yup.string().when('loanPurpose', {
                is: "purchase",
                then: Yup.string().required(' '),
              }),
              realtorFirstName: Yup.string().when('realtorExists', {
                is: 'true',
                then: Yup.string().required(' ')
              }),
              realtorLastName: Yup.string().when('realtorExists', {
                is: 'true',
                then: Yup.string().required(' ')
              }),
              realtorEmail: Yup.string().when('realtorExists', {
                is: 'true',
                then: Yup.string()
                  .email('Invalid email')
                  .required(' '),
              }),
              realtorNumber: Yup.string().when('realtorExists', {
                is: 'true',
                then: Yup.string()
                  .matches(PHONE_MASK, 'Phone number must be 10 digits')
                  .matches(PHONE_MATCH, 'Area code cannot start with 1')
                  .required(' ')
              }),

              requestRealtorReferral: Yup.string().when('realtorExists', {
                is: 'false',
                then: Yup.string().required('Realtor confirmation is required')
              }),

              // //ADDRESS
              street: Yup.string().when('purchasePlan', {
                is: 'contract' || 'offerMade' || 'foundHome' || 'primary' || 'secondary' || 'investment',
                then: Yup.string().min(2, 'Invalid address entered, minimum of 2 characters required').required(' '),
              }),
              city: Yup.string().required(' '),
              state: Yup.string().required(' '),
              postal: Yup.string().when('purchasePlan', {
                is: 'contract' || 'offerMade' || 'foundHome' || 'primary' || 'secondary' || 'investment',
                then: Yup.string().required(' ').matches(/^[0-9]{5}$/, 'Zipcode must be exactly 5 digits'),
              }),

              // //PURCHASE
              propertyType: Yup.string().required(' '),
              // structureYearBuilt: Yup.string().when(['loanPurpose', 'purchasePlan'], {
              //   is: (loanPurpose: number, purchasePlan: string) => (
              //     (loanPurpose === "refinance") ||
              //     (loanPurpose === "purchase" &&
              //       (
              //         purchasePlan === 'foundHome' ||
              //         purchasePlan === 'contract' ||
              //         purchasePlan === 'offerMade'
              //       )
              //     )
              //   ),
              //   then: Yup.string().required(' ')
              // }),
              purchasePrice: Yup.string().when('loanPurpose', {
                is: "purchase",
                then: Yup.string().required(' ')
              }),
              downPaymentAmount: Yup.string().when('loanPurpose', {
                is: "purchase",
                then: Yup.string().required(' ')
              }),
              downPaymentPercent: Yup.string().when('loanPurpose', {
                is: "purchase",
                then: Yup.string().required(' ')
              }),
              closingDate: Yup.date().when('loanPurpose', {
                is: "purchase",
                then: Yup.date().when('purchasePlan', {
                  is: 'contract',
                  then: Yup.date()
                    .min(futureDate(), 'Date must occur in the future')
                    .required(' '),
                })
              }),

              // //REFINANCE
              // homeUse: Yup.string().when('loanPurpose', {
              //   is: "refinance",
              //   then: Yup.string().required('This field is required')
              // }),

              isRental: Yup.string().when(['loanPurpose', 'homeUse'], {
                is: (loanPurpose: LoanPurposeType, homeUse: string) => (loanPurpose === "refinance" && homeUse === 'investment'),
                then: Yup.string().required(' ')
              }),
              rentalIncomeMonthly: Yup.string().when('isRental', {
                is: 'true',
                then: Yup.string().required('This field is required')
              }),

              commonWalls: Yup.string().when(['loanPurpose', 'propertyType'], {
                is: (loanPurpose: LoanPurposeType, propertyType: string) => (loanPurpose === "purchase" || loanPurpose === "refinance") && (propertyType === 'sfr-planned-development' || propertyType === 'condo'),
                then: Yup.string().required(' ')
              }),
              propertyTaxes: Yup.string().when('loanPurpose', {
                is: "refinance",
                then: Yup.string().required(' ')
              }),
              insurancePremium: Yup.string().when('loanPurpose', {
                is: "refinance",
                then: Yup.string().required(' ')
              }),
              hoaDues: Yup.string().when('loanPurpose', {
                is: "refinance",
                then: Yup.string().when('propertyType', {
                  is: (propertyType: string) => propertyType === 'sfr-planned-development' || propertyType === 'condo',
                  then: Yup.string().required(' ')
                })
              }),
              loanAmount: Yup.string().when('loanPurpose', {
                is: "refinance",
                then: Yup.string().required(' '),
              }),

              outstandingLoans: Yup.string().when('loanPurpose', {
                is: "refinance",
                then: Yup.string().required(' ')
              }),

              // propertyAppraisedValueAmount: Yup.string().when(['loanPurpose', 'purchasePlan'], {
              //   is: (loanPurpose: LoanPurposeType, purchasePlan: string) => (loanPurpose === "purchase" && purchasePlan === 'contract'),
              //   then: Yup.string().required(' ')
              // }),

              // OUTSTANDING LOANS

              // first loan only
              // firstMortgageLoanAmount: Yup.string().when('outstandingLoans', {
              //   is: 'firstMortgageOnly' || 'firstAndSecondMortgage' || 'firstSecondAndThirdMortgage',
              //   then: Yup.string().required(' ')
              // }),
              firstMortgageLoanAmount: Yup.string().when('outstandingLoans', {
                is: (outstandingLoans: string) =>
                  outstandingLoans === 'firstMortgageOnly' ||
                  outstandingLoans === 'firstAndSecondMortgage' ||
                  outstandingLoans === 'firstSecondAndThirdMortgage',
                then: Yup.string().required(' ')
              }),
              firstMortgageTaxAndInsurance: Yup.string().when('outstandingLoans', {
                is: (outstandingLoans: string) =>
                  outstandingLoans === 'firstMortgageOnly' ||
                  outstandingLoans === 'firstAndSecondMortgage' ||
                  outstandingLoans === 'firstSecondAndThirdMortgage',
                then: Yup.string().required(' ')
              }),
              firstMortgagePayment: Yup.string().when('outstandingLoans', {
                is: (outstandingLoans: string) =>
                  outstandingLoans === 'firstMortgageOnly' ||
                  outstandingLoans === 'firstAndSecondMortgage' ||
                  outstandingLoans === 'firstSecondAndThirdMortgage',
                then: Yup.string().required(' ')
              }),
              firstMortgageImpounded: Yup.string().when('outstandingLoans', {
                is: (outstandingLoans: string) =>
                  outstandingLoans === 'firstMortgageOnly' ||
                  outstandingLoans === 'firstAndSecondMortgage' ||
                  outstandingLoans === 'firstSecondAndThirdMortgage',
                then: Yup.string().required(' ')
              }),

              // first and second loan
              secondMortgageType: Yup.string().when('outstandingLoans', {
                is: (outstandingLoans: string) => outstandingLoans === 'firstAndSecondMortgage' || outstandingLoans === 'firstSecondAndThirdMortgage',
                then: Yup.string().required(' ')
              }),
              secondMortgageLoanAmount: Yup.string().when('outstandingLoans', {
                is: (outstandingLoans: string) => outstandingLoans === 'firstAndSecondMortgage' || outstandingLoans === 'firstSecondAndThirdMortgage',
                then: Yup.string().when('secondMortgageType', {
                  is: 'fixed',
                  then: Yup.string().required(' ')
                })
              }),
              secondMortgageTaxAndInsurance: Yup.string().when('outstandingLoans', {
                is: (outstandingLoans: string) => outstandingLoans === 'firstAndSecondMortgage' || outstandingLoans === 'firstSecondAndThirdMortgage',
                then: Yup.string().when('secondMortgageType', {
                  is: 'fixed',
                  then: Yup.string().required(' ')
                })
              }),
              secondMortgagePayment: Yup.string().when('outstandingLoans', {
                is: (outstandingLoans: string) => outstandingLoans === 'firstAndSecondMortgage' || outstandingLoans === 'firstSecondAndThirdMortgage',
                then: Yup.string().when('secondMortgageType', {
                  is: 'fixed',
                  then: Yup.string().required(' ')
                })
              }),
              secondMortgageBalance: Yup.string().when('outstandingLoans', {
                is: (outstandingLoans: string) => outstandingLoans === 'firstAndSecondMortgage' || outstandingLoans === 'firstSecondAndThirdMortgage',
                then: Yup.string().when('secondMortgageType', {
                  is: 'heloc',
                  then: Yup.string().required(' ')
                })
              }),
              secondMortgageCreditLimit: Yup.string().when('outstandingLoans', {
                is: (outstandingLoans: string) => outstandingLoans === 'firstAndSecondMortgage' || outstandingLoans === 'firstSecondAndThirdMortgage',
                then: Yup.string().when('secondMortgageType', {
                  is: 'heloc',
                  then: Yup.string().required(' ')
                })
              }),
              secondMortgageInterestRate: Yup.string().when('outstandingLoans', {
                is: (outstandingLoans: string) => outstandingLoans === 'firstAndSecondMortgage' || outstandingLoans === 'firstSecondAndThirdMortgage',
                then: Yup.string().when('secondMortgageType', {
                  is: 'heloc',
                  then: Yup.string().required(' ')
                })
              }),
              secondMortgageEquityPayment: Yup.string().when('outstandingLoans', {
                is: (outstandingLoans: string) => outstandingLoans === 'firstAndSecondMortgage' || outstandingLoans === 'firstSecondAndThirdMortgage',
                then: Yup.string().when('secondMortgageType', {
                  is: 'heloc',
                  then: Yup.string().required(' ')
                })
              }),

              // first, second and third mortgage
              thirdMortgageType: Yup.string().when('outstandingLoans', {
                is: (outstandingLoans: string) => outstandingLoans === 'firstSecondAndThirdMortgage',
                then: Yup.string().required(' ')
              }),
              thirdMortgageLoanAmount: Yup.string().when('outstandingLoans', {
                is: (outstandingLoans: string) => outstandingLoans === 'firstSecondAndThirdMortgage',
                then: Yup.string().when('thirdMortgageType', {
                  is: 'fixed',
                  then: Yup.string().required(' ')
                })
              }),
              thirdMortgageTaxAndInsurance: Yup.string().when('outstandingLoans', {
                is: (outstandingLoans: string) => outstandingLoans === 'firstSecondAndThirdMortgage',
                then: Yup.string().when('thirdMortgageType', {
                  is: 'fixed',
                  then: Yup.string().required(' ')
                })
              }),
              thirdMortgagePayment: Yup.string().when('outstandingLoans', {
                is: (outstandingLoans: string) => outstandingLoans === 'firstSecondAndThirdMortgage',
                then: Yup.string().when('thirdMortgageType', {
                  is: 'fixed',
                  then: Yup.string().required(' ')
                })
              }),
              thirdMortgageBalance: Yup.string().when('outstandingLoans', {
                is: (outstandingLoans: string) => outstandingLoans === 'firstSecondAndThirdMortgage',
                then: Yup.string().when('thirdMortgageType', {
                  is: 'heloc',
                  then: Yup.string().required(' ')
                })
              }),
              thirdMortgageCreditLimit: Yup.string().when('outstandingLoans', {
                is: (outstandingLoans: string) => outstandingLoans === 'firstSecondAndThirdMortgage',
                then: Yup.string().when('thirdMortgageType', {
                  is: 'heloc',
                  then: Yup.string().required(' ')
                })
              }),
              thirdMortgageInterestRate: Yup.string().when('outstandingLoans', {
                is: (outstandingLoans: string) => outstandingLoans === 'firstSecondAndThirdMortgage',
                then: Yup.string().when('thirdMortgageType', {
                  is: 'heloc',
                  then: Yup.string().required(' ')
                })
              }),
              thirdMortgageEquityPayment: Yup.string().when('outstandingLoans', {
                is: (outstandingLoans: string) => outstandingLoans === 'firstSecondAndThirdMortgage',
                then: Yup.string().when('thirdMortgageType', {
                  is: 'heloc',
                  then: Yup.string().required(' ')
                })
              }),

            }, [['downPaymentAmount', 'downPaymentPercent']])}
            onSubmit={async (values, {
              setErrors,
              setStatus,
              setSubmitting
            }) => {
              try {

                const reoData = {
                  owner: 'borrower',
                  address_street_line: values.street,
                  address_unit: values.unit,
                  address_city: values.city,
                  address_state: values.state,
                  address_postal: values.postal,

                  gse_property_type: values.propertyType,
                  annual_property_tax: values.propertyTaxes.replace(/[$,]/g, ''),
                  insurance_amount: values.insurancePremium.replace(/[$,]/g, ''),
                  property_financing: values.outstandingLoans,
                  is_rent: values.isRental,

                  first_mortgage_loan_amount: values.firstMortgageLoanAmount.replace(/[$,]/g, ''),
                  first_mortgage_payment: values.firstMortgagePayment.replace(/[$,]/g, ''),
                  first_mortgage_impounded: values.firstMortgageImpounded,

                  second_mortgage_type: values.secondMortgageType,
                  second_mortgage_loan_amount: values.secondMortgageLoanAmount.replace(/[$,]/g, ''),
                  second_mortgage_payment: values.secondMortgagePayment.replace(/[$,]/g, '') || values.secondMortgageEquityPayment.replace(/[$,]/g, ''),
                  second_mortgage_balance: values.secondMortgageBalance.replace(/[$,]/g, ''),
                  second_mortgage_credit_limit: values.secondMortgageCreditLimit.replace(/[$,]/g, ''),
                  second_mortgage_interest_rate: values.secondMortgageInterestRate.replace(/[%]/g, ''),
                  second_mortgage_equity_payment: values.secondMortgageEquityPayment.replace(/[$,]/g, ''),

                  third_mortgage_type: values.thirdMortgageType,
                  third_mortgage_loan_amount: values.thirdMortgageLoanAmount.replace(/[$,]/g, ''),
                  third_mortgage_payment: values.thirdMortgagePayment.replace(/[$,]/g, ''),
                  third_mortgage_balance: values.thirdMortgageBalance.replace(/[$,]/g, ''),
                  third_mortgage_credit_limit: values.thirdMortgageCreditLimit.replace(/[$,]/g, ''),
                  third_mortgage_interest_rate: values.thirdMortgageInterestRate.replace(/[%]/g, ''),
                  third_mortgage_equity_payment: values.thirdMortgageEquityPayment.replace(/[$,]/g, '')
                }

                const changedData = {
                  application: {
                    current_step: ApplicationRoutes[pathname as keyof typeof ApplicationRoutes] + 1,
                    completed_steps: {
                      loan_details_done: true
                    }
                  },
                  borrower: {
                    request_realtor_referral: values.requestRealtorReferral,
                    realtor_exists: values.realtorExists,
                  },
                  loan: {
                    loan_purpose_type: values.loanPurpose,
                    purchase_plan: values.purchasePlan,
                    purchase_timeframe: values.purchaseTimeframe,
                    down_payment_amount: values.downPaymentAmount,
                    down_payment_percent: values.downPaymentPercent.replace(/[%]/g, ''),
                    base_loan_amount: values.loanAmount.replace(/[$,]/g, ''),
                    // loan_amount: values.loanAmount.replace(/[$,]/g, ''),
                    closing_date: values.closingDate,
                  },
                  property: {
                    address_street_line: values.street,
                    address_unit: values.unit,
                    address_city: values.city,
                    address_state: values.state,
                    address_postal: values.postal,
                    address_county: values.county,
                    usage: values.homeUse,
                    purchase_price_amount: values.purchasePrice.replace(/[$,]/g, ''),
                    property_taxes: values.propertyTaxes.replace(/[$,]/g, ''),
                    insurance_premium: values.insurancePremium.replace(/[$,]/g, ''),
                    property_type: values.propertyType,
                    // structure_year_built: values.structureYearBuilt,
                    common_walls: values.commonWalls,
                    hoa_dues_amount: values.hoaDues.replace(/[$,]/g, ''),
                    outstanding_loans: values.outstandingLoans,
                    is_rental: values.isRental,
                    rental_income_monthly: values.rentalIncomeMonthly.replace(/[$,]/g, ''),

                    first_mortgage_loan_amount: values.firstMortgageLoanAmount.replace(/[$,]/g, ''),
                    first_mortgage_tax_and_insurance: values.firstMortgageTaxAndInsurance.replace(/[$,]/g, ''),
                    first_mortgage_payment: values.firstMortgagePayment.replace(/[$,]/g, ''),
                    first_mortgage_impounded: values.firstMortgageImpounded,

                    second_mortgage_type: values.secondMortgageType,
                    second_mortgage_loan_amount: values.secondMortgageLoanAmount.replace(/[$,]/g, ''),
                    second_mortgage_tax_and_insurance: values.secondMortgageTaxAndInsurance.replace(/[$,]/g, ''),
                    second_mortgage_payment: values.secondMortgagePayment.replace(/[$,]/g, ''),
                    second_mortgage_balance: values.secondMortgageBalance.replace(/[$,]/g, ''),
                    second_mortgage_credit_limit: values.secondMortgageCreditLimit.replace(/[$,]/g, ''),
                    second_mortgage_interest_rate: values.secondMortgageInterestRate.replace(/[%]/g, ''),
                    second_mortgage_equity_payment: values.secondMortgageEquityPayment.replace(/[$,]/g, ''),

                    third_mortgage_type: values.thirdMortgageType,
                    third_mortgage_loan_amount: values.thirdMortgageLoanAmount.replace(/[$,]/g, ''),
                    third_mortgage_tax_and_insurance: values.thirdMortgageTaxAndInsurance.replace(/[$,]/g, ''),
                    third_mortgage_payment: values.thirdMortgagePayment.replace(/[$,]/g, ''),
                    third_mortgage_balance: values.thirdMortgageBalance.replace(/[$,]/g, ''),
                    third_mortgage_credit_limit: values.thirdMortgageCreditLimit.replace(/[$,]/g, ''),
                    third_mortgage_interest_rate: values.thirdMortgageInterestRate.replace(/[%]/g, ''),
                    third_mortgage_equity_payment: values.thirdMortgageEquityPayment.replace(/[$,]/g, ''),
                  },
                  realtor: {

                    first_name: values.realtorFirstName,
                    last_name: values.realtorLastName,
                    phone_number: values.realtorNumber.replace(/\D/g, ''),
                    email: values.realtorEmail,
                  },
                  // Determine if on refinance path and merge data from laon details with REO if so.
                  reo: _.merge(loanData.reo[0], (loanData.loan.loan_purpose_type === "refinance" ? reoData : {}))
                }

                const dataSubmit = _.merge(loanData, changedData)

                await loansService.updateLoan(activeLoan, dataSubmit)

                setStatus({ success: true })
                setSubmitting(false)

                handleNextClick()
              } catch (err: any) {
                console.error('Application error: ', err.message);
                setStatus({ success: false })
                setErrors({ submit: err.message })
                setSubmitting(false)
              }
            }}
          >
            {({
              handleBlur,
              handleChange,
              setFieldValue,
              handleSubmit,
              errors,
              isSubmitting,
              touched,
              values,
            }) => (
              <>
                <form
                  onSubmit={handleSubmit}
                  className="application-form"
                >
                  {values.loanPurpose === "purchase" ?
                    <>
                      <PurchaseContractOffer
                        errors={errors}
                        values={values}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        setFieldValue={setFieldValue}
                        showErrorContainer={showErrorContainer}
                        coapplicantMode={coapplicantMode}
                      />
                      <PurchaseFoundHome
                        errors={errors}
                        values={values}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        setFieldValue={setFieldValue}
                        showErrorContainer={showErrorContainer}
                        coapplicantMode={coapplicantMode}
                      />
                      <PurchasePrequalify
                        errors={errors}
                        values={values}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        handleSubmit={handleSubmit}
                        showErrorContainer={showErrorContainer}
                        coapplicantMode={coapplicantMode}
                      />
                    </>
                    :
                    <>
                      <RefiBase
                        errors={errors}
                        values={values}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        handleSubmit={handleSubmit}
                        showErrorContainer={showErrorContainer}
                        coapplicantMode={coapplicantMode}
                      />
                      <RefiInvestment
                        errors={errors}
                        values={values}
                        touched={touched}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        handleSubmit={handleSubmit}
                        showErrorContainer={showErrorContainer}
                        coapplicantMode={coapplicantMode}
                      />
                    </>
                  }

                  <div className="application-step-footer">
                    <Button
                      type="button"
                      className="form-previous-button"
                      onClick={handlePreviousClick}
                    >
                      Previous
                    </Button>
                    <ErrorContainer
                      errors={errors}
                      showErrorContainer={showErrorContainer}
                      setDisableOnErrors={setDisableOnErrors}
                    />
                    <Button
                      className="save"
                      disabled={isSubmitting || disableOnErrors}
                      color="blue"
                      type={coapplicantMode ? 'button' : 'submit'}
                      onClick={coapplicantMode ? () => handleCoApplicantNextClick() : () => setShowErrorContainer(true)}
                    >
                      {coapplicantMode ? 'Skip' : reviewMode ? "Back to Review" : "Save & Continue"}
                    </Button>
                  </div>
                </form>
              </>
            )}
          </Formik>
        </>
      )}
    </div>
  )
})

export default LoanDetailsV2
