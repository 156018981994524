import React, { FC, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import _ from 'lodash';
import {Button, Form, Icon, Input, Radio, TextArea} from 'semantic-ui-react';
import {Formik, Field, getIn} from 'formik';
import FormikRadio from '../../../components/Formik/FormikRadio';
import { DataContext } from '../../../context/dataContext';
import { observer } from 'mobx-react-lite';
import LoaderOverlay from '../../../components/LoaderOverlay/LoaderOverlay';
import { useStore } from '@jmjfinancial/apis/lib';
import FormikDropdown from '../../../components/Formik/FormikDropdown';
import { ApplicationRoutes } from '../index';
import ErrorContainer from '../../../components/ErrorContainer';
import { clearValues } from '../../../helpers/clear-values-helper';
import { propertyTypeOptions, bankruptcyTypesOptions } from './data';
import { FieldArray } from 'formik';
import FormikDatePicker from '../../../components/Formik/FormikDatePicker';
import './declarations.scss'
import {scrollToDefaultOptions} from '../../../helpers/scroll-to-options';


const DeclarationsIndex: FC = observer(() => {
  const store = useStore();
  const { loansService } = store;

  const { activeLoan, pathname, reviewMode } = useContext(DataContext)
  const [loanData, setLoanData] = useState<any>()
  const [isLoading, setIsLoading] = useState(true)
  const [borrowerDisplayName, setBorrowerDisplayName] = useState('Borrower')
  const [coborrowerDisplayName, setCoborrowerDisplayName] = useState('Coborrower')
  const [disableOnErrors, setDisableOnErrors] = useState<boolean>(false)
  const [showErrorContainer, setShowErrorContainer] = useState<boolean>(false)

  const history = useHistory();

  let currentOwnerType: string;

  if (pathname.includes('coborrower')) {
    currentOwnerType = 'coborrower'
  }
  else {
    currentOwnerType = 'borrower'
  }

  const handlePreviousClick = () => {
    if (reviewMode) {
      history.push('/application/reo')
    }
    else {
      (currentOwnerType === 'coborrower')
        ? history.push('/application/declarations')
        : history.push('/application/reo')
    }

  }
  const handleNextClick = () => {
    if (reviewMode) {
      history.push('/application/review-and-submit')
    }
    else {
      (currentOwnerType === 'borrower' && loanData.coborrower)
        ? history.push('/application/declarations-coborrower')
        : history.push('/application/demographics')
    }
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  })

  const clearDischargeDate = (values: any, dischargeName: string, dischargeDate: string) => {
    if (values[dischargeName] === false) {
      values[dischargeDate] = "";
    }
  };

  // Initialize function to run google maps autocomplete api call
  const inputAutoComplete = (setFieldValue: Function, index: number, foreclosureDetails: any) => {
    // Set a const value to the html element with the id autocomplete-address
    const input = document.getElementById(`autocomplete-address-${index}`) as HTMLInputElement;
    const options = {
      fields: ["formatted_address", "name"],
      strictBounds: false,
      types: ['address'],
    };
    const autocomplete = new google.maps.places.Autocomplete(input, options);
    console.log({'autocomplete': autocomplete, 'input': input})

    autocomplete.addListener("place_changed", () => {
      const place = autocomplete.getPlace() || "";

      // set the field value generated by the autocomplete formatted_address into the foreclosureDetails.address
      // value at the passed index.
      setFieldValue(`foreclosureDetails[${index}].address`, place.formatted_address)
    })

    // unbind all event listeners to the Autocomplete method
    autocomplete.unbindAll();
  }

  useEffect(() => {
    loansService.getLoan(activeLoan)
      .then(loan => {
        setLoanData({ ...loan.data.form_data })
        setIsLoading(false);
        setBorrowerDisplayName(loan.data.form_data.borrower.first_name)
        setCoborrowerDisplayName(loan.data.form_data.coborrower?.first_name)
      })
  }, [activeLoan, loansService])

  useEffect(() => {
    window.scrollTo(scrollToDefaultOptions)
  }, [])

  const cosignerLiabilityOptions = [
    {
      text:  currentOwnerType === 'coborrower' ? `${coborrowerDisplayName} makes the payment` : 'I make the payment',
      value: 'cosignerLiable'
    },
    {
      text: 'The other party makes the payment',
      value: 'otherPartyLiable'
    }
  ]

const titleOptions = [
    {
      text: currentOwnerType === 'coborrower' ? 'Themself (S)' : 'Yourself (S)',
      value: 'sole'
    },
    {
      text: currentOwnerType === 'coborrower' ? 'Jointly with their spouse (SP)' : 'Jointly with your spouse (SP)',
      value: 'jointWithSpouse'
    },
    {
      text: 'Jointly with another person (O)',
      value: 'jointWithOtherThanSpouse'
    }
  ]

  return (
    <div className="application-step-container declarations">
      <LoaderOverlay active={isLoading} />
      {!isLoading && (
        <>
          <h1 className="title has-subtitle text-light-blue">Declarations</h1>
          {(!isLoading && currentOwnerType === 'coborrower') && <h3 className=" subtitle text-grey">{`Let's learn a little more about ${coborrowerDisplayName}'s declarations`}</h3>}
          {(!isLoading && currentOwnerType === 'borrower') && <h3 className="subtitle text-grey">{`Let's learn a little more about ${borrowerDisplayName}'s declarations`}</h3>}
          <Formik
            initialValues={{
              loanPurpose: loanData?.loan.loan_purpose_type,
              occupyProperty: (currentOwnerType === 'coborrower')
                ? loanData.coborrower.intent_to_occupy_indicator?.toString() || ''
                : loanData.borrower.intent_to_occupy_indicator?.toString() || '',
              ownershipInterest: (currentOwnerType === 'coborrower')
                ? loanData.coborrower.homeowner_past_three_years_indicator?.toString() || ''
                : loanData.borrower.homeowner_past_three_years_indicator?.toString() || '',
              propertyType: (currentOwnerType === 'coborrower')
                ? loanData.coborrower.prior_property_usage_type?.toString() || ''
                : loanData.borrower.prior_property_usage_type?.toString() || '',
              propertyTitle: (currentOwnerType === 'coborrower')
                ? loanData.coborrower.prior_property_title_type?.toString() || ''
                : loanData.borrower.prior_property_title_type?.toString() || '',
              relationshipWithPropertySeller: (currentOwnerType === 'coborrower')
                ? loanData.coborrower.relationship_with_prior_seller_indicator?.toString() || ''
                : loanData.borrower.relationship_with_prior_seller_indicator?.toString() || '',
              applyingMultipleLoans: (currentOwnerType === 'coborrower')
                ? loanData.coborrower.applying_for_multiple_loans_indicator?.toString() || ''
                : loanData.borrower.applying_for_multiple_loans_indicator?.toString() || '',
              applyingCredit: (currentOwnerType === 'coborrower')
                ? loanData.coborrower.applying_for_credit_indicator?.toString() || ''
                : loanData.borrower.applying_for_credit_indicator?.toString() || '',
              cosigner: (currentOwnerType === 'coborrower')
                ? loanData.coborrower.cosigner_on_undisclosed?.toString() || ''
                : loanData.borrower.cosigner_on_undisclosed?.toString() || '',
              cosignerLiability: (currentOwnerType === 'coborrower')
                ? loanData.coborrower.cosigner_liability?.toString() || ''
                : loanData.borrower.cosigner_liability?.toString() || '',
              outstandingJudgments: (currentOwnerType === 'coborrower')
                ? loanData.coborrower.outstanding_judgements_indicator?.toString() || ''
                : loanData.borrower.outstanding_judgements_indicator?.toString() || '',
              aboutDebt: (currentOwnerType === 'coborrower')
                ? loanData.coborrower.about_debt?.toString() || ''
                : loanData.borrower.about_debt?.toString() || '',
              delinquent: (currentOwnerType === 'coborrower')
                ? loanData.coborrower.presently_delinquent_indicator?.toString() || ''
                : loanData.borrower.presently_delinquent_indicator?.toString() || '',
              lawsuit: (currentOwnerType === 'coborrower')
                ? loanData.coborrower.party_to_lawsuit_indicator?.toString() || ''
                : loanData.borrower.party_to_lawsuit_indicator?.toString() || '',
              aboutLawsuit: (currentOwnerType === 'coborrower')
                ? loanData.coborrower.about_lawsuit?.toString() || ''
                : loanData.borrower.about_lawsuit?.toString() || '',
              conveyedTitle: (currentOwnerType === 'coborrower')
                ? loanData.coborrower.conveyed_title_indicator?.toString() || ''
                : loanData.borrower.conveyed_title_indicator?.toString() || '',
              preforeclosure: (currentOwnerType === 'coborrower')
                ? loanData.coborrower.completed_preforeclosure_or_short_sale?.toString() || ''
                : loanData.borrower.completed_preforeclosure_or_short_sale?.toString() || '',
              foreclosure: (currentOwnerType === 'coborrower')
                ? loanData.coborrower.recent_foreclosure?.toString() || ''
                : loanData.borrower.recent_foreclosure?.toString() || '',
              foreclosureDetails: (currentOwnerType === 'coborrower')
                ? loanData.coborrower.foreclosure_details?.map((fd: any )=> {if(fd.date) fd.date = new Date(fd.date); return fd}) || [{}]
                : loanData.borrower.foreclosure_details?.map((fd: any )=>  {if(fd.date) fd.date = new Date(fd.date); return fd}) || [{}],
              declaredBankruptcy: (currentOwnerType === 'coborrower')
                ? loanData.coborrower.recent_bankruptcy?.toString() || ''
                : loanData.borrower.recent_bankruptcy?.toString() || '',
              chapter7: (currentOwnerType === 'coborrower')
                ? loanData.coborrower.bankruptcy_indicator_chapter_seven || false
                : loanData.borrower.bankruptcy_indicator_chapter_seven || false,
              chapter11: (currentOwnerType === 'coborrower')
                ? loanData.coborrower.bankruptcy_indicator_chapter_eleven || false
                : loanData.borrower.bankruptcy_indicator_chapter_eleven || false,
              chapter12: (currentOwnerType === 'coborrower')
                ? loanData.coborrower.bankruptcy_indicator_chapter_twelve || false
                : loanData.borrower.bankruptcy_indicator_chapter_twelve || false,
              chapter13: (currentOwnerType === 'coborrower')
                ? loanData.coborrower.bankruptcy_indicator_chapter_thirteen || false
                : loanData.borrower.bankruptcy_indicator_chapter_thirteen || false,
              chapter7DischargeDate: (currentOwnerType === 'borrower')
                ? loanData.borrower.chapter_seven_discharge_date ? new Date(loanData.borrower.chapter_seven_discharge_date) : ''
                : loanData.coborrower.chapter_seven_discharge_date ? new Date(loanData.coborrower.chapter_seven_discharge_date) : '',
              chapter11DischargeDate: (currentOwnerType === 'borrower')
                ? loanData.borrower.chapter_eleven_discharge_date ? new Date(loanData.borrower.chapter_eleven_discharge_date) : ''
                : loanData.coborrower.chapter_eleven_discharge_date ? new Date(loanData.coborrower.chapter_eleven_discharge_date) : '',
              chapter12DischargeDate: (currentOwnerType === 'borrower')
                ? loanData.borrower.chapter_twelve_discharge_date ? new Date(loanData.borrower.chapter_twelve_discharge_date) : ''
                : loanData.coborrower.chapter_twelve_discharge_date ? new Date(loanData.coborrower.chapter_twelve_discharge_date) : '',
              chapter13DischargeDate: (currentOwnerType === 'borrower')
                ? loanData.borrower.chapter_thirteen_discharge_date ? new Date(loanData.borrower.chapter_thirteen_discharge_date) : ''
                : loanData.coborrower.chapter_thirteen_discharge_date ? new Date(loanData.coborrower.chapter_thirteen_discharge_date) : '',

              submit: null
            }}

            ///////// TODO: Fix validation for bankruptcyType array /////////
            validationSchema={Yup.object().shape({
              occupyProperty: Yup.string().required('This field is required'),
              ownershipInterest: Yup.string().when('occupyProperty', {
                is: "true",
                then: Yup.string().nullable().required('This field is required')
              }),
              propertyType: Yup.string().when('ownershipInterest', {
                is: "true",
                then: Yup.string().required('This field is required')
              }),
              propertyTitle: Yup.string().when('ownershipInterest', {
                is: "true",
                then: Yup.string().required('This field is required')
              }),
              relationshipWithPropertySeller: Yup.string().when('loanPurpose', {
                is: "purchase",
                then: Yup.string().nullable().required('This field is required')
              }),
              applyingMultipleLoans: Yup.string().required('This field is required'),
              applyingCredit: Yup.string().required('This field is required'),
              cosigner: Yup.string().required('This field is required'),
              cosignerLiability: Yup.string().when('cosigner', {
                is: 'true',
                then: Yup.string().required('This field is required')
              }),
              outstandingJudgments: Yup.string().required('This field is required'),
              delinquent: Yup.string().required('This field is required'),
              lawsuit: Yup.string().required('This field is required'),
              conveyedTitle: Yup.string().required('This field is required'),
              foreclosureDetails: Yup.array().when('conveyedTitle', {
                is: 'true',
                then: Yup.array().of(
                  Yup.object().shape({
                    date: Yup.string().required('This field is required'),
                    address: Yup.string().required('This field is required')
                  })
                )
              }),
              preforeclosure: Yup.string().required('This field is required'),
              foreclosure: Yup.string().required('This field is required'),
              declaredBankruptcy: Yup.string().required('This field is required'),

              chapter7: Yup.boolean().when(['declaredBankruptcy', 'chapter11', 'chapter12', 'chapter13'], {
                is: (declaredBankruptcy: string, chapter11: boolean, chapter12: boolean, chapter13: boolean) =>
                  (declaredBankruptcy === "true" && !chapter11 && !chapter12 && !chapter13),
                then: Yup.boolean().oneOf([true], 'Atleast one bankruptcy type must be selected')
              }),

              chapter7DischargeDate: Yup.string().nullable().when('chapter7', {
                is: true,
                then: Yup.string().required('')
              }),
              chapter11DischargeDate: Yup.string().nullable().when('chapter11', {
                is: true,
                then: Yup.string().required('')
              }),
              chapter12DischargeDate: Yup.string().nullable().when('chapter12', {
                is: true,
                then: Yup.string().required('')
              }),
              chapter13DischargeDate: Yup.string().nullable().when('chapter13', {
                is: true,
                then: Yup.string().required('')
              }),
            })}
            onSubmit={async (values: any, {
              setErrors,
              setStatus,
              setSubmitting
            }) => {
              try {
                let changedData;

                currentOwnerType === 'borrower' && (changedData = {
                  application: {
                    current_step: ApplicationRoutes[pathname as keyof typeof ApplicationRoutes] + (loanData.coborrower ? 1 : 2),
                    completed_steps: {
                      declarations_done: true
                    }
                  },
                  borrower: {
                    // Will you Occupy the Property as Primary?
                    intent_to_occupy_indicator: values.occupyProperty,
                    homeowner_past_three_years_indicator: values.ownershipInterest,
                    prior_property_usage_type: values.propertyType,
                    prior_property_title_type: values.propertyTitle,

                    // Information about borrower and relationships
                    relationship_with_prior_seller_indicator: values.relationshipWithPropertySeller,
                    applying_for_multiple_loans_indicator: values.applyingMultipleLoans,
                    applying_for_credit_indicator: values.applyingCredit,
                    cosigner_on_undisclosed: values.cosigner,
                    cosigner_liability: values.cosignerLiability,
                    outstanding_judgements_indicator: values.outstandingJudgments,
                    about_debt: values.aboutDebt,
                    presently_delinquent_indicator: values.delinquent,
                    party_to_lawsuit_indicator: values.lawsuit,
                    about_lawsuit: values.aboutLawsuit,
                    conveyed_title_indicator: values.conveyedTitle,
                    recent_foreclosure: values.foreclosure,
                    completed_preforeclosure_or_short_sale: values.preforeclosure,
                    foreclosure_details: values.foreclosureDetails,

                    // Bankruptcy
                    recent_bankruptcy: values.declaredBankruptcy,
                    bankruptcy_indicator_chapter_seven: values.chapter7,
                    bankruptcy_indicator_chapter_eleven: values.chapter11,
                    bankruptcy_indicator_chapter_twelve: values.chapter12,
                    bankruptcy_indicator_chapter_thirteen: values.chapter13,
                    chapter_seven_discharge_date: values.chapter7DischargeDate,
                    chapter_eleven_discharge_date: values.chapter11DischargeDate,
                    chapter_twelve_discharge_date: values.chapter12DischargeDate,
                    chapter_thirteen_discharge_date: values.chapter13DischargeDate
                  }
                })

                currentOwnerType === 'coborrower' && (changedData = {
                  application: {
                    current_step: ApplicationRoutes[pathname as keyof typeof ApplicationRoutes] + 1,
                    completed_steps: {
                      declarations_coborrower_done: true
                    }
                  },
                  coborrower: {
                    // Will you Occupy the Property as Primary?
                    intent_to_occupy_indicator: values.occupyProperty,
                    homeowner_past_three_years_indicator: values.ownershipInterest,
                    prior_property_usage_type: values.propertyType,
                    prior_property_title_type: values.propertyTitle,

                    // Information about borrower and relationships
                    relationship_with_prior_seller_indicator: values.relationshipWithPropertySeller,
                    applying_for_multiple_loans_indicator: values.applyingMultipleLoans,
                    applying_for_credit_indicator: values.applyingCredit,
                    cosigner_on_undisclosed: values.cosigner,
                    cosigner_liability: values.cosignerLiability,
                    outstanding_judgements_indicator: values.outstandingJudgments,
                    about_debt: values.aboutDebt,
                    presently_delinquent_indicator: values.delinquent,
                    party_to_lawsuit_indicator: values.lawsuit,
                    about_lawsuit: values.aboutLawsuit,
                    conveyed_title_indicator: values.conveyedTitle,
                    completed_preforeclosure_or_short_sale: values.preforeclosure,
                    recent_foreclosure: values.foreclosure,
                    foreclosure_details: values.foreclosureDetails,

                    // Bankruptcy
                    recent_bankruptcy: values.declaredBankruptcy,
                    bankruptcy_indicator_chapter_seven: values.chapter7,
                    bankruptcy_indicator_chapter_eleven: values.chapter11,
                    bankruptcy_indicator_chapter_twelve: values.chapter12,
                    bankruptcy_indicator_chapter_thirteen: values.chapter13,
                    chapter_seven_discharge_date: values.chapter7DischargeDate,
                    chapter_eleven_discharge_date: values.chapter11DischargeDate,
                    chapter_twelve_discharge_date: values.chapter12DischargeDate,
                    chapter_thirteen_discharge_date: values.chapter13DischargeDate
                  }
                })

                // Clear foreclosureDetails to ensure that previously deleted data is not merged into foreclosure_details
                loanData.borrower.foreclosure_details = [];

                const dataSubmit = _.merge(loanData, changedData)

                await loansService.updateLoan(activeLoan, dataSubmit);

                setStatus({ success: true });
                setSubmitting(false)

                handleNextClick();
              } catch (err: any) {
                console.error('Application error: ', err.message);
                setStatus({ success: false })
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldValue,
              isSubmitting,
              touched,
              values,
            }) => (
              <form
                onSubmit={handleSubmit}
                className="application-form"
              >
                <div className="form-step">
                  <Form.Field>
                    <label> Will {`${currentOwnerType === 'coborrower' ? coborrowerDisplayName : 'you' }`} occupy the property as {`${currentOwnerType === 'coborrower' ? 'their' : 'your'}`} primary residence?</label>
                    <div className={`radio-group ${showErrorContainer && values.occupyProperty !== null ? 'radio-error' : ''}`}>
                      <Radio
                        id="occupyPropertyTrue"
                        className={errors.occupyProperty && 'has-error'}
                        label="Yes"
                        name="occupyProperty"
                        value='true'
                        checked={values.occupyProperty === 'true'}
                        onChange={handleChange}
                      />
                      <Radio
                        id="occupyPropertyFalse"
                        className={errors.occupyProperty && 'has-error'}
                        label="No"
                        name="occupyProperty"
                        value='false'
                        checked={values.occupyProperty === 'false'}
                        onFocus={() => clearValues(values, ['ownershipInterest'])}
                        onChange={handleChange}
                      />
                    </div>
                  </Form.Field>
                </div>
                {values.occupyProperty === "true" && (
                  <>
                    <div className="form-step">
                      <Form.Field className="nested">
                        <label>{`${currentOwnerType === 'coborrower' ? `Has ${coborrowerDisplayName}` : 'Have you'}`} had an ownership interest in another property in the last three years?</label>
                        <div className={`radio-group ${showErrorContainer && values.ownershipInterest === '' && 'radio-error'}`}>
                          <Radio
                            id="ownershipInterestTrue"
                            label="Yes"
                            name="ownershipInterest"
                            value="true"
                            checked={values.ownershipInterest === "true"}
                            className={errors.ownershipInterest && 'has-error'}
                            onChange={handleChange}
                          />
                          <Radio
                            id="ownershipInterestFalse"
                            label="No"
                            name="ownershipInterest"
                            value="false"
                            checked={values.ownershipInterest === "false"}
                            className={errors.ownershipInterest && 'has-error'}
                            onChange={handleChange}
                          />
                        </div>
                      </Form.Field>
                    </div>

                    {values.ownershipInterest === "true" ? (
                      <>
                        <div className="form-step">
                          <Form.Field className="nested">
                            <label>What type of property did {`${currentOwnerType === 'coborrower' ? coborrowerDisplayName : 'you'}`} own?</label>
                            <FormikDropdown
                              className="small"
                              name="propertyType"
                              fluid
                              selection
                              placeholder="Property Type"
                              options={propertyTypeOptions}
                              value={values.propertyType}
                              error={Boolean(touched.propertyType && errors.propertyType) || Boolean(showErrorContainer && values.propertyType === '')}
                            />
                          </Form.Field>
                          <Form.Field className="nested">
                            <label>How did {`${currentOwnerType === 'coborrower' ? coborrowerDisplayName : 'you'}`} hold title to the property?</label>
                            <FormikDropdown
                              className="small"
                              name="propertyTitle"
                              fluid
                              selection
                              placeholder="Title"
                              options={titleOptions}
                              value={values.propertyTitle}
                              error={Boolean(touched.propertyTitle && errors.propertyTitle) || Boolean(showErrorContainer && values.propertyTitle === '')}
                            />
                          </Form.Field>
                        </div>
                      </>
                    ) : (
                      values.propertyType = '',
                      values.propertyTitle = ''
                    )}
                  </>
                )}
                {loanData.loan.loan_purpose_type === "purchase" && (
                  <div className="form-step">
                    <Form.Field>
                      <label>{`${currentOwnerType === 'coborrower' ? `Does ${coborrowerDisplayName}` : 'Do you'}`} have a family relationship or business affiliation with the seller of the property?</label>
                      <div className={`radio-group ${showErrorContainer && values.relationshipWithPropertySeller !== null && 'radio-error'}`}>
                        <Radio
                          id="relationshipWithPropertySellerTrue"
                          className={errors.relationshipWithPropertySeller && 'has-error'}
                          label="Yes"
                          name="relationshipWithPropertySeller"
                          value='true'
                          checked={values.relationshipWithPropertySeller === 'true'}
                          onChange={handleChange}
                        />
                        <Radio
                          id="relationshipWithPropertySellerFalse"
                          className={errors.relationshipWithPropertySeller && 'has-error'}
                          label="No"
                          name="relationshipWithPropertySeller"
                          value='false'
                          checked={values.relationshipWithPropertySeller === 'false'}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Field>
                  </div>
                )}
                <div className="form-step">
                  <Form.Field>
                    <label>{`${currentOwnerType === 'coborrower' ? `Has ${coborrowerDisplayName} or will ${coborrowerDisplayName}` : 'Have you or will you'}`} be applying for a mortgage loan on another property (not the property securing this loan)?</label>
                    <div className={`radio-group ${showErrorContainer && values.applyingMultipleLoans === '' && 'radio-error'}`}>
                      <Radio
                        id="applyingMultipleLoansTrue"
                        className={errors.applyingMultipleLoans && 'has-error'}
                        label="Yes"
                        name="applyingMultipleLoans"
                        value='true'
                        checked={values.applyingMultipleLoans === 'true'}
                        onChange={handleChange}
                      />
                      <Radio
                        id="applyingMultipleLoansFalse"
                        className={errors.applyingMultipleLoans && 'has-error'}
                        label="No"
                        name="applyingMultipleLoans"
                        value='false'
                        checked={values.applyingMultipleLoans === 'false'}
                        onChange={handleChange}
                      />
                    </div>
                  </Form.Field>
                </div>
                <div className="form-step">
                  <Form.Field>
                    <label>{`${currentOwnerType === 'coborrower' ? `Has ${coborrowerDisplayName} or will ${coborrowerDisplayName}` : 'Have you or will you'}`} be applying for any new credit (e.g., installment loan, credit card, etc.) on or before closing this transaction?</label>
                    <div className={`radio-group ${showErrorContainer && values.applyingCredit === '' && 'radio-error'}`}>
                      <Radio
                        id="applyingCreditTrue"
                        className={errors.applyingCredit && 'has-error'}
                        label="Yes"
                        name="applyingCredit"
                        value='true'
                        checked={values.applyingCredit === 'true'}
                        onChange={handleChange}
                      />
                      <Radio
                        id="applyingCreditFalse"
                        className={errors.applyingCredit && 'has-error'}
                        label="No"
                        name="applyingCredit"
                        value='false'
                        checked={values.applyingCredit === 'false'}
                        onChange={handleChange}
                      />
                    </div>
                  </Form.Field>
                </div>
                <div className="form-step">
                  <Form.Field>
                    <label>{`${currentOwnerType === 'coborrower' ? `Is ${coborrowerDisplayName}` : 'Are you'}`} a co-signer or guarantor on any debt or loan?</label>
                    <div className={`radio-group ${showErrorContainer && values.cosigner === '' && 'radio-error'}`}>
                      <Radio
                        id="cosignerTrue"
                        className={errors.cosigner && 'has-error'}
                        label="Yes"
                        name="cosigner"
                        value='true'
                        checked={values.cosigner === 'true'}
                        onChange={handleChange}
                      />
                      <Radio
                        id="cosignerFalse"
                        className={errors.cosigner && 'has-error'}
                        label="No"
                        name="cosigner"
                        value='false'
                        checked={values.cosigner === 'false'}
                        onClick={() => clearValues(values, ['cosignerLiability'])}
                        onChange={handleChange}
                      />
                    </div>
                  </Form.Field>
                </div>
                {values.cosigner === "true" && (
                  <div className="form-step">
                    <Form.Field className="nested">
                      <label>Who makes the payment?</label>
                      <FormikDropdown
                        className="small"
                        name="cosignerLiability"
                        placeholder="Who is liable"
                        options={cosignerLiabilityOptions}
                        value={values.cosignerLiability}
                        error={Boolean(touched.cosignerLiability && errors.cosignerLiability) || Boolean(showErrorContainer && values.cosignerLiability === '')}
                      />
                    </Form.Field>
                  </div>
                )}
                <div className="form-step">
                  <Form.Field>
                    <label>Are there any outstanding judgments against {`${currentOwnerType === 'coborrower' ? coborrowerDisplayName : 'you'}`}?</label>
                    <div className={`radio-group ${showErrorContainer && values.outstandingJudgments === '' && 'radio-error'}`}>
                      <Radio
                        id="outstandingJudgmentsTrue"
                        className={errors.outstandingJudgments && 'has-error'}
                        label="Yes"
                        name="outstandingJudgments"
                        value='true'
                        checked={values.outstandingJudgments === 'true'}
                        onChange={handleChange}
                      />
                      <Radio
                        id="outstandingJudgmentsFalse"
                        className={errors.outstandingJudgments && 'has-error'}
                        label="No"
                        name="outstandingJudgments"
                        value='false'
                        onClick={() => clearValues(values, ['aboutDebt'])}
                        checked={values.outstandingJudgments === 'false'}
                        onChange={handleChange}
                      />
                    </div>
                  </Form.Field>
                </div>
                {values.outstandingJudgments === 'true' && (
                  <div className="form-step">
                    <Form.Field className="nested">
                      <label>Give us a brief description about {`${currentOwnerType === 'coborrower' ? 'the' : 'your'}`} debt</label>
                      <TextArea
                        name="aboutDebt"
                        placeholder="Debt Summary"
                        type="text"
                        value={values.aboutDebt}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.aboutLawsuit && errors.aboutLawsuit)}
                      />
                    </Form.Field>
                  </div>
                )}
                <div className="form-step">
                  <Form.Field>
                    <label>{`${currentOwnerType === 'coborrower' ? `Is ${coborrowerDisplayName}` : 'Are you'}`} currently delinquent or in default on a Federal debt?</label>
                    <div className={`radio-group ${showErrorContainer && values.delinquent === '' && 'radio-error'}`}>
                      <Radio
                        id="delinquentTrue"
                        className={errors.delinquent && 'has-error'}
                        label="Yes"
                        name="delinquent"
                        value='true'
                        checked={values.delinquent === 'true'}
                        onChange={handleChange}
                      />
                      <Radio
                        id="delinquentFalse"
                        className={errors.delinquent && 'has-error'}
                        label="No"
                        name="delinquent"
                        value='false'
                        checked={values.delinquent === 'false'}
                        onChange={handleChange}
                      />
                    </div>
                  </Form.Field>
                </div>
                <div className="form-step">
                  <Form.Field>
                    <label>{`${currentOwnerType === 'coborrower' ? `Is ${coborrowerDisplayName}` : 'Are you'}`} a party to a lawsuit in which {`${currentOwnerType === 'coborrower' ? 'they' : 'you'}`} potentially have any personal financial liability?</label>
                    <div className={`radio-group ${showErrorContainer && values.lawsuit === '' && 'radio-error'}`}>
                      <Radio
                        id="lawsuitTrue"
                        className={errors.lawsuit && 'has-error'}
                        label="Yes"
                        name="lawsuit"
                        value='true'
                        checked={values.lawsuit === 'true'}
                        onChange={handleChange}
                      />
                      <Radio
                        id="lawsuitFalse"
                        className={errors.lawsuit && 'has-error'}
                        label="No"
                        name="lawsuit"
                        value='false'
                        checked={values.lawsuit === 'false'}
                        onClick={() => clearValues(values, ['aboutLawsuit'])}
                        onChange={handleChange}
                      />
                    </div>
                  </Form.Field>
                </div>
                {values.lawsuit === 'true' && (
                  <div className="form-step">
                    <Form.Field className="nested">
                      <label>Give us a brief description of the lawsuit</label>
                      <TextArea
                        name="aboutLawsuit"
                        placeholder="Lawsuit summary"
                        type="text"
                        value={values.aboutLawsuit}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        error={Boolean(touched.aboutLawsuit && errors.aboutLawsuit)}
                      />
                    </Form.Field>
                  </div>
                )}
                <div className="form-step">
                  <Form.Field>
                    <label>{`${currentOwnerType === 'coborrower' ? `Has ${coborrowerDisplayName}` : 'Have you'}`} conveyed title to any property in lieu of foreclosure in the past 7 years?</label>
                    <div className={`radio-group ${showErrorContainer && values.conveyedTitle === '' && 'radio-error'}`}>
                      <Radio
                        id="conveyedTitleTrue"
                        className={errors.conveyedTitle && 'has-error'}
                        label="Yes"
                        name="conveyedTitle"
                        value='true'
                        checked={values.conveyedTitle === 'true'}
                        onChange={handleChange}
                      />
                      <Radio
                        id="conveyedTitleFalse"
                        className={errors.conveyedTitle && 'has-error'}
                        label="No"
                        name="conveyedTitle"
                        value='false'
                        checked={values.conveyedTitle === 'false'}
                        onChange={handleChange}
                        onClick={() => values.foreclosureDetails = [""]}
                      />
                    </div>
                  </Form.Field>
                </div>
                {values.conveyedTitle === "true" && (
                  <FieldArray
                    name="foreclosureDetails"
                    render={arrayHelpers => (
                      <>
                        {values.foreclosureDetails.map((foreclosureDetail: any, index: number) => (
                          <React.Fragment key={`${foreclosureDetail}-${index}`}>
                            <div className="form-step">
                              <Form.Field className="nested">
                                <label className="foreclosure-date">Foreclosure date</label>
                                <FormikDatePicker
                                  name={`foreclosureDetails[${index}].date`}
                                  placeholder="MM/DD/YYYY"
                                  format="MM/DD/YYYY"
                                  icon="calendar alternate outline"
                                  value={foreclosureDetail.date}
                                  onBlur={handleBlur}
                                  error={
                                    !!(getIn(touched, `foreclosureDetails[${index}].date`)
                                      && getIn(errors, `foreclosureDetails[${index}].date`))
                                    || (showErrorContainer
                                      && foreclosureDetail.date === undefined)}
                                />
                                <label className="foreclosure-date">Property address</label>
                                <Input
                                  name={`foreclosureDetails[${index}].address`}
                                  id={`autocomplete-address-${index}`}
                                  placeholder="123 Street City, State, Zip"
                                  type="text"
                                  value={foreclosureDetail.address}
                                  onChange={(e: any) => {
                                    handleChange(e)
                                    inputAutoComplete(setFieldValue, index, values.foreclosureDetails)
                                  }}
                                  onBlur={handleBlur}
                                  error={
                                    !!(getIn(touched, `foreclosureDetails[${index}].address`)
                                      && getIn(errors, `foreclosureDetails[${index}].address`))
                                    || (showErrorContainer
                                      && foreclosureDetail.address === undefined)}
                                />
                              </Form.Field>
                            </div>
                            {values.foreclosureDetails.length > 1 ? (
                              <Button
                                className="nested delete-address-button"
                                type="button"
                                onClick={() => {
                                  arrayHelpers.remove(index)
                                }}
                              >
                                Remove
                              </Button>
                            ) : null}
                          </React.Fragment>
                        ))}
                        <Button
                          className="nested add-address-button blue"
                          type="button"
                          onClick={() => arrayHelpers.push(
                            {}
                          )}
                        >
                          <Icon className="plus" /> Add Foreclosure
                        </Button>
                      </>
                    )}
                  />
                )}
                <div className="form-step">
                  <Form.Field>
                    <label>
                      Within the past 7 years, {`${currentOwnerType === 'coborrower' ? `has ${coborrowerDisplayName}` : 'have you'}`} completed a pre-foreclosure sale or short sale, whereby the property was sold to
                      a third party and the Lender agreed to accept less than the outstanding mortgage balance due?
                    </label>
                    <div className={`radio-group ${showErrorContainer && values.preforeclosure === '' && 'radio-error'}`}>
                      <Radio
                        id="preforeclosureTrue"
                        className={errors.preforeclosure && 'has-error'}
                        label="Yes"
                        name="preforeclosure"
                        value='true'
                        checked={values.preforeclosure === 'true'}
                        onChange={handleChange}
                      />
                      <Radio
                        id="preforeclosureFalse"
                        className={errors.preforeclosure && 'has-error'}
                        label="No"
                        name="preforeclosure"
                        value='false'
                        checked={values.preforeclosure === 'false'}
                        onChange={handleChange}
                      />
                    </div>
                  </Form.Field>
                </div>
                <div className="form-step">
                  <Form.Field>
                    <label>{`${currentOwnerType === 'coborrower' ? `Has ${coborrowerDisplayName}` : 'Have you'}`} had property foreclosed upon in the last 7 years?</label>
                    <div className={`radio-group ${showErrorContainer && values.foreclosure === '' && 'radio-error'}`}>
                      <Radio
                        id="foreclosureTrue"
                        className={errors.foreclosure && 'has-error'}
                        label="Yes"
                        name="foreclosure"
                        value='true'
                        checked={values.foreclosure === 'true'}
                        onChange={handleChange}
                      />
                      <Radio
                        id="foreclosureFalse"
                        className={errors.foreclosure && 'has-error'}
                        label="No"
                        name="foreclosure"
                        value='false'
                        checked={values.foreclosure === 'false'}
                        onChange={handleChange}
                      />
                    </div>
                  </Form.Field>
                </div>
                <div className="form-step">
                  <Form.Field>
                    <label>{`${currentOwnerType === 'coborrower' ? `Has ${coborrowerDisplayName}` : 'Have you'}`} declared bankruptcy within the past 7 years?</label>
                    <div className={`radio-group ${showErrorContainer && values.declaredBankruptcy === '' && 'radio-error'}`}>
                      <Radio
                        id="declaredBankruptcyTrue"
                        className={errors.declaredBankruptcy && 'has-error'}
                        label="Yes"
                        name="declaredBankruptcy"
                        value='true'
                        checked={values.declaredBankruptcy === 'true'}
                        onChange={handleChange}
                      />
                      <Radio
                        id="declaredBankruptcyFalse"
                        className={errors.declaredBankruptcy && 'has-error'}
                        label="No"
                        name="declaredBankruptcy"
                        value='false'
                        checked={values.declaredBankruptcy === 'false'}
                        onClick={() => (
                          values.chapter7 = false,
                          values.chapter11 = false,
                          values.chapter12 = false,
                          values.chapter13 = false,
                          values.chapter7DischargeDate = "",
                          values.chapter11DischargeDate = "",
                          values.chapter12DischargeDate = "",
                          values.chapter13DischargeDate = ""
                        )}
                        onChange={handleChange}
                      />
                    </div>
                  </Form.Field>
                  {values.declaredBankruptcy === "true" && (
                    <>
                      <Form.Field className={`nested bankruptcy ${showErrorContainer &&
                        values.chapter7 === false &&
                        values.chapter11 === false &&
                        values.chapter12 === false &&
                        values.chapter13 === false && 'has-error'}`}>
                        <h2 className="subtitle bold">Select all bankruptcy type(s) that apply: </h2>
                        <div className="checkbox-group">
                          {bankruptcyTypesOptions.map((bankruptcyType: string, index: number) => {
                            let bankruptcyShortName: string = bankruptcyType.split(' ').join('').toLowerCase()
                            let bankruptcyDischargeDate = `${bankruptcyType.split(' ').join('').toLowerCase()}DischargeDate`
                            return (
                              <div className="checkbox bankruptcy">
                                <label key={bankruptcyShortName}>
                                  <Field
                                    type="checkbox"
                                    id={bankruptcyShortName}
                                    name={bankruptcyShortName}
                                    onClick={() => clearDischargeDate(values, bankruptcyShortName, bankruptcyDischargeDate)}
                                  />
                                  <span className="bankruptcy-text">{bankruptcyType}</span>
                                </label>
                                <div className="discharge-date-container">
                                  <span className={`${values[bankruptcyShortName] ? 'bankruptcy-checked' : 'bankruptcy-unchecked'}`}>
                                    Discharge Date:
                                  </span>
                                  <FormikDatePicker
                                    className={`small ${values[bankruptcyShortName] ? 'bankruptcy-checked' : 'bankruptcy-unchecked'}`}
                                    name={`${bankruptcyDischargeDate}`}
                                    placeholder="MM/DD/YYYY"
                                    format="MM/DD/YYYY"
                                    icon="calendar alternate outline"
                                    error={
                                      !!(getIn(touched, `${values[bankruptcyShortName]}`) && getIn(errors, `${values[bankruptcyShortName]}`))
                                      || (showErrorContainer && values[bankruptcyShortName] === true && (
                                        values[bankruptcyDischargeDate] === null || values[bankruptcyDischargeDate] === ''))
                                    }
                                  />
                                </div>
                              </div>
                            )
                          })}
                        </div>
                      </Form.Field>
                    </>
                  )}
                </div>
                <div className="application-step-footer">
                  <Button
                    type="button"
                    className="form-previous-button"
                    onClick={handlePreviousClick}
                  >
                    Previous
                  </Button>
                  <ErrorContainer
                    errors={errors}
                    showErrorContainer={showErrorContainer}
                    setDisableOnErrors={setDisableOnErrors}
                  />
                  <Button
                    disabled={isSubmitting || disableOnErrors}
                    className="save"
                    color="blue"
                    type="submit"
                    onClick={() => setShowErrorContainer(true)}
                  >
                    {reviewMode ? "Back to Review" : "Save & Continue" }
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </>
      )}
    </div>
  )
})

export default DeclarationsIndex;
