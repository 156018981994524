export const suffixOptions = [
  {
    text: 'Jr.',
    value: 'Jr.',
    key: 'Jr.'
  },
  {
    text: 'Sr.',
    value: 'Sr.',
    key: 'Sr.'
  },
  {
    text: 'II',
    value: 'II',
    key: 'II'
  },
  {
    text: 'III',
    value: 'III',
    key: 'III'
  },
  {
    text: 'IV',
    value: 'IV',
    key: 'IV'
  }
]

export const yesNoOptions = [
  {
    text: 'Yes',
    value: 'yes',
    key: 'yes'
  },
  {
    text: 'No',
    value: 'no',
    key: 'no'
  }
]

export const citizenshipOptions = [
  {
    text: 'U.S. Citizen',
    value: 'usCitizen'
  },
  {
    text: 'Permanent Resident Alien',
    value: 'permanentAlien'
  },
  {
    text: 'Foreign National',
    value: 'nonPermanentAlien'
  },
  {
    text: 'Visa - Non Permanent Alien',
    value: 'nonPermanentAlienVisa'
  },
  {
    text: 'EAD - Non Permanent Alien',
    value: 'nonPermanentAlienEAD'
  }
]

export const maritalStatusOptions = [
  {
    text: 'Single',
    value: 'single',
    key: 'single'
  },
  {
    text: 'Married',
    value: 'married',
    key: 'married'
  },
  {
    text: 'Legal Domestic Partnership',
    value: 'domesticPartnership',
    key: 'domesticPartnership'
  },
  {
    text: 'Divorced',
    value: 'divorced',
    key: 'divorced'
  },
  {
    text: 'Legally Separated',
    value: 'separated',
    key: 'separated'
  },
  {
    text: 'Widowed',
    value: 'widowed',
    key: 'widowed'
  },
]

export const housingTypeOptions = [
  {
    text: 'Own',
    value: 'own',
    key: 'own'
  },
  {
    text: 'Rent',
    value: 'rent',
    key: 'rent'
  },
  {
    text: 'Rent Free',
    value: 'free',
    key: 'free'
  }
]

export const yearsAtCurrentAddressOptions = [
  {
    text: '0',
    value: 0,
    key: '0'
  },
  {
    text: '1',
    value: 1,
    key: '1'
  },
  {
    text: '2',
    value: 2,
    key: '2'
  },
  {
    text: '3',
    value: 3,
    key: '3'
  },
  {
    text: '4',
    value: 4,
    key: '4'
  },
  {
    text: '5',
    value: 5,
    key: '5'
  },
  {
    text: '6',
    value: 6,
    key: '6'
  },
  {
    text: '7',
    value: 7,
    key: '7'
  },
  {
    text: '8',
    value: 8,
    key: '8'
  },
  {
    text: '9',
    value: 9,
    key: '9'
  },
  {
    text: '10+',
    value: 10,
    key: '10'
  },
]

export const monthsAtCurrentAddressOptions = [
  {
    text: '0',
    value: 0,
    key: '0'
  },
  {
    text: '1',
    value: 1,
    key: '1'
  },
  {
    text: '2',
    value: 2,
    key: '2'
  },
  {
    text: '3',
    value: 3,
    key: '3'
  },
  {
    text: '4',
    value: 4,
    key: '4'
  },
  {
    text: '5',
    value: 5,
    key: '5'
  },
  {
    text: '6',
    value: 6,
    key: '6'
  },
  {
    text: '7',
    value: 7,
    key: '7'
  },
  {
    text: '8',
    value: 8,
    key: '8'
  },
  {
    text: '9',
    value: 9,
    key: '9'
  },
  {
    text: '10',
    value: 10,
    key: '10'
  },
  {
    text: '11',
    value: 11,
    key: '11'
  }
]

export const dependentOptions = [
  {
    text: '0',
    value: 0,
    key: '0'
  },
  {
    text: '1',
    value: 1,
    key: '1'
  },
  {
    text: '2',
    value: 2,
    key: '2'
  },
  {
    text: '3',
    value: 3,
    key: '3'
  },
  {
    text: '4',
    value: 4,
    key: '4'
  },
  {
    text: '5',
    value: 5,
    key: '5'
  },
  {
    text: '6',
    value: 6,
    key: '6'
  },
  {
    text: '7',
    value: 7,
    key: '7'
  },
  {
    text: '8',
    value: 8,
    key: '8'
  },
  {
    text: '9',
    value: 9,
    key: '9'
  },
  {
    text: '10',
    value: 10,
    key: '10'
  },
  {
    text: '10+',
    value: 10,
    key: '10+'
  }
]
