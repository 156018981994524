import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router';
import { Button, Icon, Modal } from 'semantic-ui-react';

interface ForgotPasswordModalProps {
  showModal: boolean;
  setShowModal: Function;
  errorModal?: boolean;
  email?: boolean;
  password?: boolean;
}

const ForgotPasswordModal: FC<ForgotPasswordModalProps> = ({
  showModal,
  setShowModal,
  errorModal,
  email,
  password
}) => {

  const history = useHistory()
  
  const submitModal = () => {
    setShowModal(false);
    if (password && errorModal) {
      history.push('/forgot-password')
    } else if (password) {
      history.push('/login')
    }
  }

  const header = (): string | undefined => {
    if (password) {
      return errorModal ? 'Password Reset Unsuccessful' : 'Password Reset Successful'
    } else if (email) {
      return `Email sent`
    }
  }

  const subheader = (): string | undefined => {
    if (password) {
       return errorModal ? 'Follow the instructions in the password reset email' : 'Click "Ok" to proceed to login page'
    } else if (email) {
      return 'If the email exists, you will receive an email with instructions on resetting your password'
    }
  }

  return (
    <Modal
      dimmer="blurring"
      className="default-modal forgot-password-modal"
      open={showModal}
    >
      <Icon
        name="close"
        className="close-icon"
        onClick={() => setShowModal(false)}
      />
      <span className="modal-header">{`${header()}`}</span>
      <span className="modal-subheader">{`${subheader()}`}</span>
      <div className="modal-footer">
        <Button
          type="button"
          onClick={() => submitModal()}
        >
          Ok
        </Button>
      </div>
    </Modal>
  )
};

export default ForgotPasswordModal;