import React, {FC, useContext, useEffect, useState} from 'react';
import {Button, Icon, Modal} from 'semantic-ui-react';
import './disclosure-modal.scss';
import {DataContext} from '../../../context/dataContext';
import HelloSign from 'hellosign-embedded';
import LoaderOverlay from '../../LoaderOverlay/LoaderOverlay';
import {useStore} from '@jmjfinancial/apis/lib';

const HELLO_SIGN_CLIENT_ID = process.env.REACT_APP_HELLO_SIGN_CLIENT_ID || '';

const DisclosureModal: FC = () => {
  const store = useStore()

  const { tasksService } = store

  const {
    activeLoan,
    showDisclosureModal,
    setShowDisclosureModal,
    activeTask,
    setRefreshTasks,
    showCoborrowerTasks
  } = useContext(DataContext)

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [buttonLabel, setButtonLabel] = useState<string>('Cancel')
  const [helloSignClient, setHelloSignClient] = useState<any>()

  // @ts-ignore
  const client = new HelloSign({
    clientId: HELLO_SIGN_CLIENT_ID
  });

  const handleCloseClick = () => {
    helloSignClient.close()
  }

  useEffect(() => {
    setHelloSignClient(client)
    console.log('DisclosureModal useEffect: ', activeTask)
    if (showDisclosureModal) {
      tasksService.getSignUrl(
        activeTask.loan_id,
        activeTask.deliverable_id,
        activeTask.signature_id
      ).then((res) => {
        if (res?.data?.sign_url) {
          // TODO: Update options for production
          client.open(res.data.sign_url, {
            testMode: true,
            skipDomainVerification: true,
            allowCancel: true,
            debug: true,
            hideHeader: true,
            // @ts-ignore
            container: document.getElementById('helloSignContainer')
          });
        }
      })
    }
  }, [showDisclosureModal])

  useEffect(() => {
    client.on('open', ((data: any) => {
      setIsLoading(isLoading => true)
    }))

    client.on('ready', ((data: any) => {
      setIsLoading(isLoading => false)
    }))

    client.on('sign', ((data: any) => {
      tasksService.setEsignTaskCompleted(activeTask.loan_id, activeTask.deliverable_id, showCoborrowerTasks ? 'coborrower' : 'borrower').then(() => setRefreshTasks(true))
      setButtonLabel(buttonLabel => 'Close')
    }))

    client.on('error', ((data: any) => {
      setIsLoading(isLoading => false)
    }))

    client.on('message', ((data: any) => {
      setIsLoading(isLoading => false)
    }))

    // @ts-ignore
    client.on('close', (() => {
      setShowDisclosureModal(false)
    }))

    // @ts-ignore
    client.on('finish', ((data: any) => {
      setShowDisclosureModal(false)
    }))
  }, [client])

  return (
    // <></>
    <Modal
      dimmer="blurring"
      className="default-modal disclosure-modal"
      open={showDisclosureModal}
      onClose={handleCloseClick}
      onOpen={() => setShowDisclosureModal(true)}
    >
      <Icon
        name="close"
        className="close-icon"
        onClick={handleCloseClick}
      />
      <span className="modal-header">Disclosures</span>
      <span className="modal-subheader">{activeTask.label}</span>
      <LoaderOverlay active={isLoading} label="Loading document..." />
      <div id="helloSignContainer" />
      <div className="modal-footer">
        <Button onClick={handleCloseClick}>{buttonLabel}</Button>
      </div>
    </Modal>
  )
}

export default DisclosureModal;
