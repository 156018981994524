import { FC } from 'react';
import {getPluralizedName} from '../../../../helpers/pluralize-name';
import {AssetTypeLabelEnum, BasicAssetType} from '../asset-types';

interface AssetFormHeaderProps {
  ownerName: string
  basicAssetType: BasicAssetType
  duplicateAssetType?: boolean
  conciergeMode?: boolean
  hasCoborrower?: boolean
}

const AssetFormHeader: FC<AssetFormHeaderProps> = ({
  ownerName,
  basicAssetType,
  duplicateAssetType,
  conciergeMode,
  hasCoborrower
}) => {
  let pluralizedOwnerName: string = ''

  if (!hasCoborrower) {
    pluralizedOwnerName = getPluralizedName(ownerName)
  }

  const duplicateAssetCopy = duplicateAssetType ? 'Additional ' : ''

  const headerCopy = (): string => {
    return `${pluralizedOwnerName}${duplicateAssetCopy}${AssetTypeLabelEnum[basicAssetType as keyof typeof AssetTypeLabelEnum]}`
  }

  const subHeaderCopy = (): string => {
    return `${duplicateAssetCopy}${AssetTypeLabelEnum[basicAssetType as keyof typeof AssetTypeLabelEnum]}`
  }

  return conciergeMode ?
    <>
      <h1 className="title has-subtitle text-light-blue">{headerCopy()}</h1>
      <h3 className="subtitle text-grey">Let's find out more about your {subHeaderCopy()}</h3>
    </>
    : <h2 className="section-title text-light-blue">{headerCopy()}</h2>
}

export default AssetFormHeader
