import React, { FC, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup';
import MaskedInput from 'react-text-mask';
import { Button, Form, Input, Radio } from 'semantic-ui-react';
import { Formik } from 'formik';
import FormikDatePicker from '../../../components/Formik/FormikDatePicker';
import { DataContext } from '../../../context/dataContext';
import { observer } from 'mobx-react-lite';
import LoaderOverlay from '../../../components/LoaderOverlay/LoaderOverlay';
import moment from 'moment';
import { useStore } from '@jmjfinancial/apis/lib';
import _ from 'lodash';
import {
  suffixOptions,
  citizenshipOptions,
  maritalStatusOptions
} from './data'
import FormikErrorMessage from '../../../components/Formik/ErrorMessage';
import { ApplicationRoutes } from '../index';
import FormikDropdown from '../../../components/Formik/FormikDropdown';
import ErrorContainer from '../../../components/ErrorContainer/index';
import DependentFields from './DependentFields';
import './borrower-info.scss';
import {scrollToDefaultOptions} from '../../../helpers/scroll-to-options';
import { getMaxBirthdate, getMinBirthdate } from '../../../helpers/date-helpers';
import HelperPopup from '../../../components/Popup/Popup';
import {SOCIAL_SECURITY_MASK} from '../../../helpers/regex-helper';

const BorrowerInformation: FC = observer(() => {
  const store = useStore();
  const { loansService } = store;

  const {
    user,
    activeLoan,
    pathname,
    reviewMode
  } = useContext(DataContext)
  const [loanData, setLoanData] = useState<any>()
  const [isLoading, setIsLoading] = useState(true)
  const [disableOnErrors, setDisableOnErrors] = useState<boolean>(false)
  const [showErrorContainer, setShowErrorContainer] = useState<boolean>(false)

  const history = useHistory()

  const handleNextClick = () => {
    if (reviewMode) {
      history.push('/application/review-and-submit')
    }
    else {
      history.push('/application/auth-and-consent')
    }
  }

  useEffect(() => {
    loansService.getLoan(activeLoan)
      .then(loan => {
        setLoanData({ ...loan.data.form_data })
        setIsLoading(false);
      })
  }, [activeLoan, loansService])

  useEffect(() => {
    window.scrollTo(scrollToDefaultOptions)
  }, [])

  return (
    <div className="application-step-container borrower-info-container">
      <LoaderOverlay active={isLoading} />
      {!isLoading && (
        <>
          <h1 className="title has-subtitle text-light-blue">Borrower Information</h1>
          <h3 className="subtitle text-grey borrower-info-subtitle">Next, let's collect some details so we can get to know you better.</h3>
          <Formik
            enableReinitialize={true}
            initialValues={{
              // Borrower info
              firstName: loanData.borrower.first_name || '',
              email: loanData.borrower.email || user.customer.email || '',
              mobile: loanData.borrower.mobile_phone || user.customer.mobile_phone || '',
              lastName: loanData.borrower.last_name || user.customer.last_name || '',
              suffix: loanData.borrower.suffix_to_name || '',
              gender: loanData.borrower.gender || '',
              legalNameMatch: loanData.borrower.legal_name_match?.toString() || '',

              ssn: loanData.borrower.ssn || '',
              dob: loanData.borrower.date_of_birth ? moment(loanData.borrower.date_of_birth).toDate() : '',
              citizenship: loanData.borrower.citizenship || '',
              maritalStatus: loanData.borrower.marital_status_type || '',
              dependentCount: loanData.borrower.dependent_count,
              hasDependents: loanData.borrower.dependent_count !== undefined
                ? loanData.borrower.dependent_count !== 0
                  ? 'true'
                  : 'false'
                : '',
              dependents: loanData.borrower.dependent_ages_description || [],

              submit: null
            }}
            validationSchema={Yup.object().shape({
              // Borrower info
              legalNameMatch: Yup.string().required(' '),
              firstName: Yup.string().when('legalNameMatch', {
                is: 'false',
                then: Yup.string().required(' ')
              }),
              middleName: Yup.string(),
              lastName: Yup.string().when('legalNameMatch', {
                is: 'false',
                then: Yup.string().required(' ')
              }),
              email: Yup.string().email().required(' '),
              suffix: Yup.string(),

              ssn: Yup.string()
                .matches(/\d{3}-?\d{2}-?\d{4}$/, 'Social Security Number must be 9 digits')
                .required(' '),
              dob: Yup.date()
                .min(getMinBirthdate(), 'Invalid date')
                .max(getMaxBirthdate(), 'Must be 18 years old or older')
                .required(' '),
              citizenship: Yup.string().required(' '),
              maritalStatus: Yup.string().required('Required'),
              dependentCount: Yup.number().max(20, 'You cannot have more than 20 dependents').when('hasDependents', {
                is: 'true',
                then: Yup.number().required('This field is required')
              }),
              hasDependents: Yup.string().required(' '),
              dependents: Yup.array().when(['hasDependents', 'dependentCount'], {
                is: (hasDependents: string, dependentCount: number) => hasDependents === 'true' && dependentCount > 0,
                then: Yup.array().of(
                  Yup.number()
                    .required(' ')
                    .min(1, 'Invalid number')
                    .max(115, 'Invalid number')
                ),
              })
            })}
            onSubmit={async (values, {
              setErrors,
              setStatus,
              setSubmitting
            }) => {
              try {

                const changedData = {
                  application: {
                    current_step: ApplicationRoutes[pathname as keyof typeof ApplicationRoutes] + 1,
                    completed_steps: {
                      borrower_info_done: true
                    }
                  },
                  borrower: {
                    id: loanData.borrower.id,
                    first_name: values.legalNameMatch === 'true' ? loanData.borrower.first_name : values.firstName,
                    last_name: values.legalNameMatch === 'true' ? loanData.borrower.last_name : values.lastName,
                    suffix_to_name: values.legalNameMatch === 'true' ? loanData.borrower.suffix : values.suffix,
                    legal_name_match: values.legalNameMatch,
                    email: values.email,
                    ssn: values.ssn.replace(/-/g, ''),
                    mobile_phone: values.mobile.replace(/\D/g, ''),
                    date_of_birth: values.dob,
                    citizenship: values.citizenship,
                    marital_status_type: values.maritalStatus,
                    dependent_count: values.hasDependents === 'false' ? 0 : values.dependents.length
                  },
                }

                let dataSubmit = _.merge(loanData, changedData)

                dataSubmit.borrower.dependent_ages_description =
                  values.dependents !== ['']
                    && values.hasDependents !== 'false'
                    ? [...values.dependents]
                    : []

                await loansService.updateLoan(activeLoan, dataSubmit)

                setStatus({ success: true });
                setSubmitting(false);

                handleNextClick();
              } catch (err: any) {
                console.error('BorrowerInformation error: ', err.message);
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values,
              setFieldValue
            }) => (
              <>
                <form
                  onSubmit={handleSubmit}
                  className="application-form borrower-info"
                >
                  <div className="form-step">
                    <Form.Field>
                      <div className="label-popup-container">
                        <label>
                          Is your legal name&nbsp;
                          <span className="text-blue">
                            {loanData.borrower.first_name} {loanData.borrower.last_name}{loanData.borrower.suffix ? ` ${loanData.borrower.suffix}` : ''}
                          </span>
                          &nbsp;from registration?
                        </label>
                        <HelperPopup
                            headerLabel="Legal Name Required"
                            content={
                              <span>Your legal name is what is filed with the United States Social Security Administration. It can be found on your social security or ITIN card, as well as on your drivers license.</span>
                            }
                          />
                      </div>
                      <div className={`legalNameMatch radio-group ${showErrorContainer && values.legalNameMatch === '' && 'radio-error'}`}>
                        <Radio
                          id="legalNameMatchTrue"
                          label="Yes"
                          name="legalNameMatch"
                          value="true"
                          checked={values.legalNameMatch === "true"}
                          className={errors.legalNameMatch && 'has-error'}
                          onChange={handleChange}
                        />
                        <Radio
                          id="legalNameMatchFalse"
                          label="No"
                          name="legalNameMatch"
                          value="false"
                          checked={values.legalNameMatch === "false"}
                          className={errors.legalNameMatch && 'has-error'}
                          onChange={handleChange}
                        />
                      </div>
                    </Form.Field>
                    {values.legalNameMatch === "false" && (
                      <>
                        <Form.Field className="legal-name-label">
                          <label>Okay, what is the name on your driver's license?</label>
                        </Form.Field>
                        <Form.Field>
                          <div className="borrower-name">
                            <Input
                              className="small"
                              name="firstName"
                              placeholder="First"
                              type="text"
                              value={values.firstName}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={!!(touched.firstName && errors.firstName)}
                            />
                            <Input
                              className="small"
                              name="lastName"
                              placeholder="Last"
                              type="text"
                              value={values.lastName}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={!!(touched.lastName && errors.lastName)}
                            />
                            <FormikDropdown
                              className="small"
                              options={suffixOptions}
                              name="suffix"
                              placeholder="Suffix (Optional)"
                              type="text"
                              value={values.suffix}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              error={!!(touched.suffix && errors.suffix)}
                            />
                          </div>
                        </Form.Field>
                      </>
                    )}
                  </div>
                  <div className="form-step">
                    <Form.Field>
                      <label>What's your social security number?</label>
                      <Input
                        className="small"
                        error={!!(touched.ssn && errors.ssn) || !!(showErrorContainer && values.ssn === '')}
                      >
                        <MaskedInput
                          mask={SOCIAL_SECURITY_MASK}
                          name="ssn"
                          type="text"
                          placeholderChar={`\u2000`}
                          placeholder="000-00-0000"
                          value={values.ssn}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          guide={false}
                        />
                        <FormikErrorMessage name="ssn" />
                      </Input>
                    </Form.Field>
                  </div>
                  <div className="form-step">
                    <Form.Field>
                      <div className="label-popup-container">
                        <label>When were you born?</label>
                        <HelperPopup
                          headerLabel="Minimum Age Required"
                          content={
                            <span>You must be 18 years or older to apply for a JMJ loan.</span>
                          }
                        />
                      </div>
                      <div className="birth-date">
                        <FormikDatePicker
                          className="small"
                          name="dob"
                          placeholder="MM/DD/YYYY"
                          format="MM/DD/YYYY"
                          maxDate={getMaxBirthdate()}
                          icon="calendar alternate outline"
                          value={values.dob}
                          error={!!(touched.dob && errors.dob) || !!(showErrorContainer && (errors.dob || values.dob === '' || values.dob === null))}
                        />
                        {showErrorContainer && values.dob !== null ? <span className="error-message">{errors.dob}</span> : null}
                      </div>
                    </Form.Field>
                  </div>
                  <div className="form-step">
                    <Form.Field>
                      <label>What's your citizenship status?</label>
                      <FormikDropdown
                        className="small"
                        fluid
                        selection
                        name="citizenship"
                        placeholder="Citizenship"
                        options={citizenshipOptions}
                        value={values.citizenship}
                        error={!!(touched.citizenship && errors.citizenship) || !!(showErrorContainer && values.citizenship === '')}
                      />
                    </Form.Field>
                  </div>
                  <div className="form-step">
                    <Form.Field>
                      <label>What's your marital status?</label>
                      <FormikDropdown
                        className="small"
                        fluid
                        selection
                        name="maritalStatus"
                        placeholder="Marital Status"
                        options={maritalStatusOptions}
                        value={values.maritalStatus}
                        error={!!(touched.maritalStatus && errors.maritalStatus) || !!(showErrorContainer && values.maritalStatus === '')}
                      />
                    </Form.Field>
                  </div>
                  <DependentFields
                    values={values}
                    touched={touched}
                    errors={errors}
                    handleBlur={handleBlur}
                    handleChange={handleChange}
                    setFieldValue={setFieldValue}
                    showErrorContainer={showErrorContainer}
                  />
                  <div className="application-step-footer button-right-side">
                    <div className="error-container">
                      <ErrorContainer
                        errors={errors}
                        showErrorContainer={showErrorContainer}
                        setDisableOnErrors={setDisableOnErrors}
                      />
                    </div>
                    <Button
                      disabled={isSubmitting || disableOnErrors}
                      color="blue"
                      type="submit"
                      onClick={() => setShowErrorContainer(true)}
                    >
                      {reviewMode ? "Back to Review" : "Save & Continue"}
                    </Button>
                  </div>
                </form>
              </>
            )}
          </Formik>
        </>
      )}
    </div>
  )
})

export default BorrowerInformation;
