import { useField } from 'formik';
import { Input } from 'semantic-ui-react';
import FormikErrorMessage from './ErrorMessage';
import React from 'react';

const FormikInput = (props: any) => {
  const [field, meta, helpers] = useField(props.name);

  return (
    <div className={`field-with-error-container input`}>
      <Input
        {...props}
        options={props.options}
        value={field.value}
        onChange={(e, v) => helpers.setValue(v.value)}
      />
      <FormikErrorMessage name={props.name} />
    </div>

  )
}

export default FormikInput;
