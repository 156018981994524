import { observer } from 'mobx-react-lite';
import React, { FC, useContext, useEffect, useState } from 'react';
import LoaderOverlay from '../../../components/LoaderOverlay/LoaderOverlay';
import { DataContext } from '../../../context/dataContext';
import { useStore } from '@jmjfinancial/apis/lib';
import ResidenceConcierge from './ResidenceConcierge';
import ResidenceForm from './ResidenceForm';
import TableView from './TableView';
import './residences.scss';

const ResidenceV2: FC = observer(() => {

  const store = useStore();

  const { loansService } = store;
  const { activeLoan, setDarkThemeOverride, setOverrideTrackerVisibility } = useContext(DataContext);

  const [isLoading, setIsLoading] = useState(true);
  const [loanData, setLoanData] = useState<any>();
  const [hasResidences, setHasResidences] = useState(false);
  const [conciergeMode, setConciergeMode] = useState(!hasResidences);
  const [hasCoBorrower, setHasCoBorrower] = useState(false);
  const [formData, setFormData] = useState('');
  const [coBorrowerConcierge, setCoBorrowerConcierge] = useState(false);
  const [showResidenceForm, setShowResidenceForm] = useState(false);
  const [refreshResidenceView, setRefreshResidenceView] = useState(false)
  const [displayOwnerOption, setDisplayOwnerOption] = useState(false);

  setDarkThemeOverride(true)
  setOverrideTrackerVisibility(true);

  // TODO: Move to `useState()` hooks and solve race condition issues
  let borrowerMonths: number = 0;
  let coBorrowerMonths: number = 0;

  // Calculate data being returned from HIVE to determine if number of months is > 24 for both borrower and coborrower
  const calculateTime = (residences: any) => {
    let borrowerArray: Array<number> = [];
    let coBorrowerArray: Array<number> = [];

    residences.forEach((residence:any) => {
      const years = (residence.years_at_address || 0) * 12;
      const monthTime = residence.months_at_address || 0;
      const totalMonths = years + monthTime;

      // Determine whether the owner is a borrower or coborrower and
      if (residence.owner === 'borrower') {
        borrowerArray.push(totalMonths)
        borrowerMonths = borrowerArray.reduce((years:any, months:any) => years + months)
      }
      else if (residence.owner === 'coborrower') {
        coBorrowerArray.push(totalMonths)
        coBorrowerMonths = coBorrowerArray.reduce((years:any, months:any) => years + months)
      }
    })
  }

  const ResidenceView: FC = () => {
    // Borrower has at least 24 months history, and if coborrower exists
    // they have at least 24 months of history as well
    if (!showResidenceForm && !conciergeMode) {
      return (
        <div className="application-step-container">
          <TableView
            loanData={loanData}
            hasCoBorrower={hasCoBorrower}
            conciergeMode={conciergeMode}
            setCoBorrowerConcierge={setCoBorrowerConcierge}
            hasResidences={hasResidences}
            setRefreshResidenceView={setRefreshResidenceView}
            coBorrowerConcierge={coBorrowerConcierge}
          />
        </div>
      )
    }
    // If residences exist and borrower has less than 24 months history,
    // or coborrower exists and has less than 24 months history
    else if (showResidenceForm) {
      return (
        <div className="application-step-container residences-container">
          <ResidenceForm
            loanData={loanData}
            editTable
            conciergeMode={conciergeMode}
            hasCoBorrower={hasCoBorrower}
            formData={formData}
            handleCancel={() => setShowResidenceForm(false)}
            displayOwnerOption={displayOwnerOption}
            hasResidences={hasResidences}
            setRefreshResidenceView={setRefreshResidenceView}
            coBorrowerConcierge={coBorrowerConcierge}
            setShowForm={() => { }}
            saveForm={() => { }}
          />
        </div>
      )
    }

    // If residences don't exist, start with concierge view
    else {
      return (
        <ResidenceConcierge
          loanData={loanData}
          coBorrowerConcierge={coBorrowerConcierge}
          hasResidences={hasResidences}
          setShowResidenceForm={setShowResidenceForm}
        />
      )
    }
  }

  useEffect(() => {
    setIsLoading(true);
    loansService.getLoan(activeLoan).then(l => {
      const loan = l.data.form_data;
      setLoanData(loan);

      setHasResidences(loan.residences?.length > 0);
      setHasCoBorrower(!!loan.coborrower);

      calculateTime(loan.residences);
      // Shows concierge landing if no residences, or a coborrower exists, and borrower has at least 24 months of history
      setConciergeMode(!(loan.residences?.length > 0)
        || borrowerMonths < 24
        || (borrowerMonths >= 24 && !!loan.coborrower && coBorrowerMonths < 24)
        || !borrowerMonths
      )

      // Check to see if the user is on Refi path, meaning there is only one value in the array and borrower and coborrower months are less than 24
      if (loan.residences?.length === 1
        && (borrowerMonths === 0 || !borrowerMonths)
        && loan.loan.loan_purpose_type === 'refinance'
      ) {
        setFormData(loan.residences[0]);
      } else {
        setFormData('');
      }

      // set displayOwnerOption to true if the borrower && coborrower months are >= 24
      setDisplayOwnerOption(borrowerMonths >= 24 && coBorrowerMonths >= 24);

      // Controls if coborrower variation of concierge flow is enabled
      setCoBorrowerConcierge(!!loan.coborrower && borrowerMonths >= 24)
      // Show residence form if residences exist and borrower has less than 24 months history,
      // or coborrower exists and has less than 24 months history
      setShowResidenceForm(loan.residences?.length > 0
        && (borrowerMonths < 24
          || (!!loan.coborrower && coBorrowerMonths < 24))
      )
      // Reset forced refresh flag
      setRefreshResidenceView(false)
      setIsLoading(false);
    })
  }, [loansService, activeLoan, refreshResidenceView])

  return (
    <>
      <LoaderOverlay active={isLoading} />
      {!isLoading && <ResidenceView />}
    </>
  )
})

export default ResidenceV2;
