import React, { FC } from 'react';
import { List } from 'semantic-ui-react';

interface PasswordValidationProps {
  characterVal: boolean
  numberVal: boolean
  uppercaseVal: boolean
  lowercaseVal: boolean
  symbolVal: boolean
  headerText: string
}

const PasswordValidation: FC<PasswordValidationProps> = ({
  characterVal,
  numberVal,
  uppercaseVal,
  lowercaseVal,
  symbolVal,
  headerText
}) => {
  return (
    <>
      <h3>{headerText}</h3>
      <List className="instructions-container">
        <List.Content className={`val ${characterVal}`}>
          {!characterVal ? <List.Icon name="x" /> : <List.Icon name="checkmark" />}
          8 Characters
        </List.Content>
        <List.Content className={`val ${numberVal}`}>
          {!numberVal ? <List.Icon name="x" /> : <List.Icon name="checkmark" />}
          1 Number
        </List.Content>
        <List.Content className={`val ${uppercaseVal}`}>
          {!uppercaseVal ? <List.Icon name="x" /> : <List.Icon name="checkmark" />}
          1 Uppercase Letter
        </List.Content>
        <List.Content className={`val ${lowercaseVal}`}>
          {!lowercaseVal ? <List.Icon name="x" /> : <List.Icon name="checkmark" />}
          1 Lowercase Letter
        </List.Content>
        <List.Content className={`val ${symbolVal}`}>
          {!symbolVal ? <List.Icon name="x" /> : <List.Icon name="checkmark" />}
          1 Special Character
        </List.Content>
      </List>
    </>
  )
}

export default PasswordValidation;
