import React, { FC, useContext, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from '@jmjfinancial/apis/lib';
import { DataContext } from '../../../../context/dataContext';
import IncomeFormHeader from './IncomeFormHeader';
import * as Yup from 'yup';
import _ from 'lodash';
import { Form, Input, Radio } from 'semantic-ui-react';
import FormikRadio from '../../../../components/Formik/FormikRadio';
import MaskedInput from 'react-text-mask';
import { currencyMask } from '../../../../helpers/currency-mask-input';
import { Formik, FormikValues } from 'formik';
import ApplicationFormFooter from '../../shared/ApplicationFormFooter';
import { IncomeFormProps } from '../EditIncomeForm';

const RetirementIncomeForm: FC<IncomeFormProps> = observer(({
  loanData,
  onCancelClick,
  updateIncomeFormView,
  owner,
  ownerName,
  incomeType,
  duplicateIncomeType,
  conciergeMode,
  saveForm,
  setShowForm,
  formData
}) => {
  const store = useStore();
  const { loansService } = store;

  const { activeLoan } = useContext(DataContext);

  const [disableOnErrors, setDisableOnErrors] = useState<boolean>(false);
  const [showErrorContainer, setShowErrorContainer] = useState<boolean>(false)

  return (
    <>
      <IncomeFormHeader
        ownerName={ownerName}
        incomeType={incomeType}
        duplicateIncomeType={duplicateIncomeType}
        conciergeMode={conciergeMode}
      />
      <Formik
        initialValues={{
          ssn: formData?.income_type ? (formData?.income_type === 'social_security').toString() : null,
          pension: formData?.income_type ? (formData?.income_type === 'pension').toString() : null,
          annuity: formData?.income_type ? (formData?.income_type === 'annuity').toString() : null,
          fund401k: formData?.income_type ? (formData?.income_type === 'fund_401k').toString() : null,
          ira: formData?.income_type ? (formData?.income_type === 'ira').toString() : null,
          rothIra: formData?.income_type ? (formData?.income_type === 'ira_roth').toString() : null,

          ssnAmount: formData?.income_type === 'social_security' ? formData?.amount : '',
          pensionAmount: formData?.income_type === 'pension' ? formData?.amount : '',
          annuityAmount: formData?.income_type === 'annuity' ? formData?.amount : '',
          fund401kAmount: formData?.income_type === 'fund_401k' ? formData?.amount : '',
          iraAmount: formData?.income_type === 'ira' ? formData?.amount : '',
          rothIraAmount: formData?.income_type === 'ira_roth' ? formData?.amount : '',

          submit: null
        }}
        validationSchema={Yup.object().shape({
          ssn: Yup.string().required(' '),
          pension: Yup.string().required(' '),
          annuity: Yup.string().required(' '),
          fund401k: Yup.string().required(' '),
          ira: Yup.string().required(' '),
          rothIra: Yup.string().required(' '),

          ssnAmount: Yup.string().when('ssn', {
            is: 'true',
            then: Yup.string().nullable().required(' ')
          }),
          pensionAmount: Yup.string().when('pension', {
            is: 'true',
            then: Yup.string().nullable().required(' ')
          }),
          annuityAmount: Yup.string().when('annuity', {
            is: 'true',
            then: Yup.string().nullable().required(' ')
          }),
          fund401kAmount: Yup.string().when('fund401k', {
            is: 'true',
            then: Yup.string().nullable().required(' ')
          }),
          iraAmount: Yup.string().when('ira', {
            is: 'true',
            then: Yup.string().nullable().required(' ')
          }),
          rothIraAmount: Yup.string().when('rothIra', {
            is: 'true',
            then: Yup.string().nullable().required(' ')
          })
        })}
        onSubmit={async (values, {
          setErrors,
          setStatus,
          setSubmitting,
          resetForm
        }) => {
          try {
            let retirementArray = []

            if (values.ssn === 'true') {
              retirementArray.push(
                {
                  id: formData?.id || null,
                  owner: owner,
                  employer_name: '',
                  income_type: 'social_security',
                  amount: values.ssnAmount?.replace(/[$,]/g, '')
                },
              )
            }

            if (values.pension === 'true') {
              retirementArray.push(
                {
                  id: formData?.id || null,
                  owner: owner,
                  employer_name: '',
                  income_type: 'pension',
                  amount: values.pensionAmount?.replace(/[$,]/g, '')
                }
              )
            }

            if (values.annuity === 'true') {
              retirementArray.push(
                {
                  id: formData?.id || null,
                  owner: owner,
                  employer_name: '',
                  income_type: 'annuity',
                  amount: values.annuityAmount?.replace(/[$,]/g, '')
                }
              )
            }

            if (values.fund401k === 'true') {
              retirementArray.push(
                {
                  id: formData?.id || null,
                  owner: owner,
                  employer_name: '',
                  income_type: 'fund_401k',
                  amount: values.fund401kAmount?.replace(/[$,]/g, '')
                }
              )
            }

            if (values.ira === 'true') {
              retirementArray.push(
                {
                  id: formData?.id || null,
                  owner: owner,
                  employer_name: '',
                  income_type: 'ira',
                  amount: values.iraAmount?.replace(/[$,]/g, '')
                }
              )
            }

            if (values.rothIra === 'true') {
              retirementArray.push(
                {
                  id: formData?.id || null,
                  owner: owner,
                  employer_name: '',
                  income_type: 'ira_roth',
                  amount: values.rothIraAmount?.replace(/[$,]/g, '')
                }
              )
            }

            if (values.ssn === 'false'
              && values.pension === 'false'
              && values.annuity === 'false'
              && values.fund401k === 'false'
              && values.ira === 'false'
              && values.rothIra === 'false'
            ) {
              throw 'You must add at least one type of retirement income to continue.';
            }

            if (conciergeMode) {
              loanData.incomes = []
              loanData.employments = []
              let newIncomes = _.concat(loanData.incomes, retirementArray)
              const dataSubmit = _.merge(loanData, { incomes: newIncomes })
              await loansService.updateLoan(activeLoan, dataSubmit)
              // Clear incomes to prevent duplicates

              updateIncomeFormView()
            }
            else {
              if (retirementArray.length <= 1) {
                saveForm(retirementArray[0])
              }
              else {
                saveForm(retirementArray)
              }
              setShowForm(false);
            }

            setStatus({ success: true });
            setSubmitting(false);
            resetForm()
          } catch (err: any) {
            console.error('Income source error: ', err.message || err);
            setStatus({ success: false });
            setErrors({ submit: err.message || err });
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form
            onSubmit={handleSubmit}
            className="application-form"
          >
            {(formData?.income_type === 'social_security' || !formData?.income_type) && (
              <div className="form-step">
                {formData?.income_type === 'social_security' ? null : (
                  <Form.Field>
                    <label>Do you receive Social Security income?</label>
                    <div className={`radio-group ${showErrorContainer && values.ssn === null && 'radio-error'}`}>
                      <Radio
                        label="Yes"
                        id="socialTrue"
                        name="ssn"
                        value='true'
                        checked={values.ssn === 'true'}
                        className={errors.ssn && 'has-error'}
                        onChange={handleChange}
                      />
                      <Radio
                        label="No"
                        id="socialFalse"
                        name="ssn"
                        value='false'
                        checked={values.ssn === 'false'}
                        className={errors.ssn && 'has-error'}
                        onChange={handleChange}
                      />
                    </div>
                  </Form.Field>
                )}
                {values.ssn === 'true' ?
                  <Form.Field className="nested">
                    <Input
                      error={!!(touched.ssnAmount && errors.ssnAmount) || !!(showErrorContainer && values.ssnAmount === '')}
                    >
                      <MaskedInput
                        name="ssnAmount"
                        placeholder="Monthly SSN Income Amount"
                        type="text"
                        value={values.ssnAmount}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        mask={currencyMask}
                        guide={false}
                      />
                    </Input>
                  </Form.Field>
                  : values.ssnAmount = ''}
              </div>
            )}
            {(formData?.income_type === 'pension' || !formData?.income_type) && (
              <div className="form-step">
                {formData?.income_type === 'pension' ? null : (
                  <Form.Field>
                    <label>Do you receive Pension income?</label>
                    <div className={`radio-group ${showErrorContainer && values.pension === null && 'radio-error'}`}>
                      <Radio
                        label="Yes"
                        id="pensionTrue"
                        name="pension"
                        value='true'
                        checked={values.pension === 'true'}
                        className={errors.pension && 'has-error'}
                        onChange={handleChange}
                      />
                      <Radio
                        label="No"
                        id="pensionFalse"
                        name="pension"
                        value='false'
                        checked={values.pension === 'false'}
                        className={errors.pension && 'has-error'}
                        onChange={handleChange}
                      />
                    </div>
                  </Form.Field>
                )}
                {values.pension === 'true' ?
                  <Form.Field className="nested">
                    <Input
                      error={!!(touched.pensionAmount && errors.pensionAmount) || !!(showErrorContainer && values.pensionAmount === '')}
                    >
                      <MaskedInput
                        name="pensionAmount"
                        placeholder="Monthly Pension Income Amount"
                        type="text"
                        value={values.pensionAmount}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        mask={currencyMask}
                        guide={false}
                      />
                    </Input>
                  </Form.Field>
                  : values.pensionAmount = ''}
              </div>
            )}
            {(formData?.income_type === 'annuity' || !formData?.income_type) && (
              <div className="form-step">
                {formData?.income_type === 'annuity' ? null : (
                  <Form.Field>
                    <label>Do you receive Annuity income?</label>
                    <div className={`radio-group ${showErrorContainer && values.annuity === null && 'radio-error'}`}>
                      <Radio
                        label="Yes"
                        id="annuityTrue"
                        name="annuity"
                        value='true'
                        checked={values.annuity === 'true'}
                        className={errors.annuity && 'has-error'}
                        onChange={handleChange}
                      />
                      <Radio
                        label="No"
                        id="annuityFalse"
                        name="annuity"
                        value='false'
                        checked={values.annuity === 'false'}
                        className={errors.annuity && 'has-error'}
                        onChange={handleChange}
                      />
                    </div>
                  </Form.Field>
                )}
                {values.annuity === 'true' ?
                  <Form.Field className="nested">
                    <Input
                      error={!!(touched.annuityAmount && errors.annuityAmount) || !!(showErrorContainer && values.annuityAmount === '')}
                    >
                      <MaskedInput
                        name="annuityAmount"
                        placeholder="Monthly Annuity Income Amount"
                        type="text"
                        value={values.annuityAmount}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        mask={currencyMask}
                        guide={false}
                      />
                    </Input>
                  </Form.Field>
                  : values.annuityAmount = ''}
              </div>
            )}
            {(formData?.income_type === 'fund_401k' || !formData?.income_type) && (
              <div className="form-step">
                {formData?.income_type === 'fund_401k' ? null : (
                  <Form.Field>
                    <label>Do you receive 401-K Distributions income?</label>
                    <div className={`radio-group ${showErrorContainer && values.fund401k === null && 'radio-error'}`}>
                      <Radio
                        label="Yes"
                        id="401kTrue"
                        name="fund401k"
                        value='true'
                        checked={values.fund401k === 'true'}
                        className={errors.fund401k && 'has-error'}
                        onChange={handleChange}
                      />
                      <Radio
                        label="No"
                        id="401kFalse"
                        name="fund401k"
                        value='false'
                        checked={values.fund401k === 'false'}
                        className={errors.fund401k && 'has-error'}
                        onChange={handleChange}
                      />
                    </div>
                  </Form.Field>
                )}
                {values.fund401k === 'true' ?
                  <Form.Field className="nested">
                    <Input
                      error={!!(touched.fund401kAmount && errors.fund401kAmount) || !!(showErrorContainer && values.fund401kAmount === '')}
                    >
                      <MaskedInput
                        name="fund401kAmount"
                        placeholder="Monthly 401k Income Amount"
                        type="text"
                        value={values.fund401kAmount}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        mask={currencyMask}
                        guide={false}
                      />
                    </Input>
                  </Form.Field>
                  : values.fund401kAmount = ''}
              </div>
            )}
            {(formData?.income_type === 'ira' || !formData?.income_type) && (
              <div className="form-step">
                {formData?.income_type === 'ira' ? null : (
                  <Form.Field>
                    <label>Do you receive IRA Distributions income?</label>
                    <div className={`radio-group ${showErrorContainer && values.ira === null && 'radio-error'}`}>
                      <Radio
                        label="Yes"
                        id="iraTrue"
                        name="ira"
                        value='true'
                        checked={values.ira === 'true'}
                        className={errors.ira && 'has-error'}
                        onChange={handleChange}
                      />
                      <Radio
                        label="No"
                        id="iraFalse"
                        name="ira"
                        value='false'
                        checked={values.ira === 'false'}
                        className={errors.ira && 'has-error'}
                        onChange={handleChange}
                      />
                    </div>
                  </Form.Field>
                )}
                {values.ira === 'true' ?
                  <Form.Field className="nested">
                    <Input
                      error={!!(touched.iraAmount && errors.iraAmount) || !!(showErrorContainer && values.iraAmount === '')}
                    >
                      <MaskedInput
                        name="iraAmount"
                        placeholder="Monthly IRA Income Amount"
                        type="text"
                        value={values.iraAmount}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        mask={currencyMask}
                        guide={false}
                      />
                    </Input>
                  </Form.Field>
                  : values.iraAmount = ''}
              </div>
            )}
            {(formData?.income_type === 'ira_roth' || !formData?.income_type) && (
              <div className="form-step">
                {formData?.income_type === 'ira_roth' ? null : (
                  <Form.Field>
                    <label>Do you receive Roth IRA Distributions income?</label>
                    <div className={`radio-group ${showErrorContainer && values.rothIra === null && 'radio-error'}`}>
                      <Radio
                        label="Yes"
                        id="rothTrue"
                        name="rothIra"
                        value='true'
                        checked={values.rothIra === 'true'}
                        className={errors.rothIra && 'has-error'}
                        onChange={handleChange}
                      />
                      <Radio
                        label="No"
                        id="rothFalse"
                        name="rothIra"
                        value='false'
                        checked={values.rothIra === 'false'}
                        className={errors.rothIra && 'has-error'}
                        onChange={handleChange}
                      />
                    </div>
                  </Form.Field>
                )}
                {values.rothIra === 'true' ?
                  <Form.Field className="nested">
                    <Input
                      error={!!(touched.rothIraAmount && errors.rothIraAmount) || !!(showErrorContainer && values.rothIraAmount === '')}
                    >
                      <MaskedInput
                        name="rothIraAmount"
                        placeholder="Monthly Roth IRA Income Amount"
                        type="text"
                        value={values.rothIraAmount}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        mask={currencyMask}
                        guide={false}
                      />
                    </Input>
                  </Form.Field>
                  : values.rothIraAmount = ''}
              </div>
            )}
            <ApplicationFormFooter
              onCancelClick={onCancelClick}
              nextDisabled={isSubmitting || disableOnErrors}
              errors={errors}
              showErrorContainer={showErrorContainer}
              setShowErrorContainer={setShowErrorContainer}
              setDisableOnErrors={setDisableOnErrors}
              errorMessage={errors.submit}
            />
          </form>
        )}
      </Formik>
    </>
  )
})

export default RetirementIncomeForm
