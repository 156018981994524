import React, {FC, useContext} from 'react';
import {useHistory} from 'react-router-dom';
import PageHeaderLink from './PageHeaderLink';
import {PageHeaderProps} from './index';
import {DataContext} from '../../context/dataContext';
import {observer} from 'mobx-react-lite';
import {useStore} from '@jmjfinancial/apis/lib';

const PageHeaderControls: FC<PageHeaderProps> = observer(({
  darkTheme
}) => {
  const store = useStore();
  const {authService, loansService} = store;
  const history = useHistory();
  const { user, setUser, setActiveLoan } = useContext(DataContext)

  const logout = () => {
    authService.logout();
    setUser(authService.getCurrentUser());
    setActiveLoan(loansService.getActiveLoan());
    history.push('/');
  };

  return (
    <div className="page-controls">
      {user ? (
        <>
          <PageHeaderLink
            label="Dashboard"
            to="/dashboard"
            inverted={darkTheme}
          />
          <PageHeaderLink
            label="My Loans"
            to="/my-loans"
            inverted={darkTheme}
          />
          <PageHeaderLink
            label="My Account"
            to="/my-account"
            inverted={darkTheme}
          />
          <PageHeaderLink
            label="Report A Bug"
            href="https://forms.monday.com/forms/896018720e12873b70da2fb4c93c25d4?r=use1"
            inverted={darkTheme}
          />
          <PageHeaderLink
            id="logOut"
            label="Log Out"
            href="#"
            onClick={logout}
            inverted={darkTheme}
          />
        </>
      ) : (
        <>
          <PageHeaderLink
            label="Sign Up"
            to="/registration"
            inverted={darkTheme}
          />
          <PageHeaderLink
            id="logIn"
            label="Log In"
            to="/login"
            inverted={darkTheme}
          />
          <PageHeaderLink
            label="Report A Bug"
            href="https://forms.monday.com/forms/896018720e12873b70da2fb4c93c25d4?r=use1"
            inverted={darkTheme}
          />
        </>
      )}
    </div>
  );
})

export default PageHeaderControls;
