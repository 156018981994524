import React, { FC } from 'react';
import CompletedIcon from './CompletedIcon';

export interface DashProgressCounter {
  completed: number
  total: number
}

const DashProgressStatus: FC<DashProgressCounter> = ({
  completed,
  total
}) => {
  return completed !== total ? (
    <label className="dash-progress-counter">{completed}/{total}</label>
  ) : <CompletedIcon />
}

export default DashProgressStatus
