/**
 * Saves the data downloaded from a response blob get call
 * @param data response blob from get service call
 * @param fileName
 * @param isZip optional boolean to download blob as a zip
 */
export const saveAttachment = (data: any, fileName: string, isZip = false) => {
  const blobUrl = window.URL.createObjectURL(new Blob([data]));
  const link = document.createElement('a');
  link.href = blobUrl;
  link.download = isZip ? `${fileName}.zip` : fileName;
  document.body.appendChild(link);
  link.click();
  link.remove();
}
