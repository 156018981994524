import {HeaderLabels, TableRow} from '../components/AddRemoveTable/AddRemoveTable';

/**
 * Returns a new array containing only the properties that match
 * each property in `headerValues`.
 *
 * @param table - Array that needs reducing
 * @param headerLabels - Array of strings which dictates filtered properties
 * @return {[]}
 */
export const reduceDisplayedTableData = (table: TableRow[], headerLabels: HeaderLabels[]) => {
  const headerLabelsAndDelete: HeaderLabels[] = [...headerLabels, {text: 'to_be_deleted', value: 'to_be_deleted'}]
  return table.map(row => {
    // Merges all returned objects into a single object
    // Ref: https://stackoverflow.com/questions/27538349/merge-multiple-objects-inside-the-same-array-into-one-object
    return Object.assign({},
      // Returns an object containing a key:value pair matching each property in `headerValues`
      ...Object.entries(getHeaderValues(headerLabelsAndDelete)).map(([text, value]) => {
        return { [value]: row[value] }
      })
    )
  })
}

export const getHeaderText = (headerLabels: HeaderLabels[]) => {
  return headerLabels.map(key => key.text);
}

export const getHeaderValues = (headerLabels: HeaderLabels[]) => {
  return headerLabels.map(key => key.value);
}
