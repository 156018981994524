import React, {FC, useContext, useEffect, useState} from 'react';
import './my-loans.scss';
import SectionComponent, {Tab} from '../../components/Section';
import {PageHeaderLinkProps} from '../../components/PageHeader/PageHeaderLink';
import { useStore } from '@jmjfinancial/apis/lib';
import { DataContext } from '../../context/dataContext';
import LoaderOverlay from '../../components/LoaderOverlay/LoaderOverlay';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import LoansTab from './LoansTab';
import {scrollToDefaultOptions} from '../../helpers/scroll-to-options';

const MyLoans: FC = observer(() => {
  const store = useStore();
  const history = useHistory();
  const { authService, loansService } = store;
  const {
    setActiveLoan,
    setAllLoans,
    clearStaleUser
  } = useContext(DataContext)

  const [isLoading, setIsLoading] = useState(true);

  const startNewLoan = () => {
    loansService.createLoan({}).then(newLoan => {
      loansService.setActiveLoan(newLoan.data.loan_id);
      setActiveLoan(newLoan.data.loan_id);

      history.push('/application/start')
    })
  };

  useEffect(() => {
    loansService.getCustomer()
      .then(async customerData => {
        if (customerData.data) {
          const customerLoans = customerData.data.loans

          loansService.getAllLoans(customerLoans).then(loans => {
            setAllLoans(loans);
            setIsLoading(false);
          })
        }
        else {
          clearStaleUser()
        }
      })
  }, [loansService, authService])

  useEffect(() => {
    window.scrollTo(scrollToDefaultOptions)
  }, [])

  const myLoansTabs: Tab[] = [
    {
      menuItem: 'Active',
      render: () => <LoansTab />
    },
    {
      menuItem: 'Past Loans',
      render: () => <LoansTab pastLoans />
    }
  ]

  const startNewLoanLink: PageHeaderLinkProps[] = [
    {
      label: '+ Start New Loan',
      onClick: () => startNewLoan()
    }
  ]

  return (
    <>
      <LoaderOverlay active={isLoading} />
      {!isLoading && (
        <div className="my-loans-container">
          <div className="section-column">
            <SectionComponent
              headerLabel="My Loans"
              tabs={myLoansTabs}
              links={startNewLoanLink}
            />
          </div>
        </div>
      )}
    </>
  )
});

export default MyLoans
