import {Formik, FormikValues} from 'formik';
import { Button, Form, Input, Radio } from 'semantic-ui-react';
import _ from 'lodash';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { DataContext } from '../../../context/dataContext';
import { useStore } from '@jmjfinancial/apis/lib';
import FormikSelect from '../../../components/Formik/FormikSelect';
import FormikRadio from '../../../components/Formik/FormikRadio';
import { housingTypeOptions, monthsOptions, yearsOptions } from './data';
import MaskedInput from 'react-text-mask';
import { currencyMask } from '../../../helpers/currency-mask-input';
import AddressInput from '../../../components/AddressInput/AddressInput';
import FormikDropdown from '../../../components/Formik/FormikDropdown';
import FormikErrorMessage from '../../../components/Formik/ErrorMessage';
import { AddRemoveFormProps } from '../../../components/AddRemoveTable/AddRemoveTable';
import { OwnerType } from '../../../types/loan';
import ErrorContainer from '../../../components/ErrorContainer';
import { scrollIntoViewDefaultOptions } from '../../../helpers/scroll-to-options';
import { valuesAreIdentical, valuesAreUnique } from '../../../helpers/uniqueness-helper';
import { clearValues } from '../../../helpers/clear-values-helper'

export interface ResidenceFormProps extends AddRemoveFormProps {
  loanData: any;
  tableData?: any;
  conciergeMode?: boolean;
  hasCoBorrower: boolean;
  formData?: any;
  handleCancel: Function;
  displayOwnerOption: boolean;
  editRowIndex?: number;
  hasResidences: boolean
  setRefreshResidenceView: Function
  coBorrowerConcierge: boolean
  editTable: boolean;
  formOwnerName?: string;
}


const ResidenceForm: FC<ResidenceFormProps> = ({
  loanData,
  tableData,
  conciergeMode,
  handleCancel,
  editRowIndex,
  displayOwnerOption,
  saveForm,
  setShowForm,
  formData,
  hasResidences,
  setRefreshResidenceView,
  coBorrowerConcierge,
  editTable,
  formOwnerName
}) => {
  const store = useStore();
  const { loansService } = store;

  const { activeLoan, setDarkThemeOverride, setOverrideTrackerVisibility } = useContext(DataContext);

  const [ownerName, setOwnerName] = useState<string>('');
  const [showErrorContainer, setShowErrorContainer] = useState<boolean>(false);
  const [disableOnErrors, setDisableOnErrors] = useState<boolean>(false);
  const [isCurrentProperty, setIsCurrentProperty] = useState(true);
  const formRef = useRef<HTMLFormElement>(null);

  setDarkThemeOverride(false);
  setOverrideTrackerVisibility(false);

  const handleUpdateOwnerType = (name: string): OwnerType => {
    if (name === loanData.coborrower?.first_name) {
      return 'coborrower'
    } else {
      return 'borrower'
    }
  }

  const updatePreviousResidence = (name: string) => {
    if (name === 'borrower') {
      const borrowerArray = loanData.residences.filter((residence: any) => residence.owner === 'borrower');
      return borrowerArray[borrowerArray.length - 1]?.address_street_line;
    }
    else if (name === 'coborrower') {
      const coborrowerArray = loanData.residences.filter((residence: any) => residence.owner === 'coborrower');
      return coborrowerArray[coborrowerArray.length - 1]?.address_street_line;
    }
  }

  const handleReturnSubheader = (name: string, values: any) => {
    if (values.owner === 'borrower') {
      if (!formData?.years_at_address && formData?.id) {
        return <h3 className="subtitle text-grey">Great. Let's get some more information about {updatePreviousResidence(values.owner)}.</h3>
      }
      else if (loanData.residences.filter((residence: any) => residence.owner === 'borrower').length < 2) {
        return (
          <h3 className="subtitle text-grey">Great. Looks like you've lived at {updatePreviousResidence(values.owner)} less than 2 years.
            <br /> Where did you live before that?</h3>
        )
      } else {
        return <h3 className="subtitle text-grey">Great. Where did you live prior to {updatePreviousResidence(values.owner)}?</h3>
      }
    }

    else if (values.owner === 'coborrower') {
      if (loanData.residences.filter((residence: any) => residence.owner === 'coborrower').length < 1) {
        return <h3 className="subtitle text-grey">Great. Now let's learn more about {name}'s history.</h3>
      } else {
        return <h3 className="subtitle text-grey">Great. Where did {name} live prior to {updatePreviousResidence(values.owner)}?</h3>
      }
    }
  }

  const getDurationTermLabel = (owner: OwnerType): string => {
    let name = 'you'
    let tensedPhrase

    if (owner === 'coborrower') {
       name = loanData.coborrower?.first_name
    }

    if (isCurrentProperty) {
      const verbage = name === 'you' ? `have you` : `has ${name}`
      tensedPhrase = `${verbage} lived`
    }
    else {
      tensedPhrase = `did ${name} live`
    }

    return `How long ${tensedPhrase} at this address?`
  }

  const handleSetOwnerType = (): OwnerType => {
    if (formOwnerName) {
      return handleUpdateOwnerType(formOwnerName)
    }
    else if (coBorrowerConcierge) {
      return 'coborrower'
    }
    else {
      return formData?.owner || 'borrower'
    }
  }

  useEffect(() => {
    // Check the array to determine if an entry for borrowe already exists, set isCurrentProperty value to conditionally render the isRent question if true.
    if (loanData.residences.length === 0 || formData?.current === true) {
      setIsCurrentProperty(true)
    } else if (loanData.residences.filter((name: any) => name.owner === 'borrower' && loanData.residences.length > 0 )) {
      setIsCurrentProperty(false);
    }

    // TODO: Revisit ternary logic
    setOwnerName(coBorrowerConcierge ? loanData.coborrower.first_name : loanData.borrower.first_name);
  }, [coBorrowerConcierge, loanData])

  useEffect(() => {
    if (formRef && formRef.current) {
      formRef.current.scrollIntoView(scrollIntoViewDefaultOptions)
    }
  }, [])

  return (
    <>
      <Formik
        // TODO: "enableReinitialize" prop has been commented out because it was causing a race condition while editing residences inside the TableView
        //   as well as when a previous residence was being entered through concierge. This race condition caused residences to save with previous values
        //   instead of the current values within the form.
        // enableReinitialize={true}
        initialValues={
          {
            owner: handleSetOwnerType(),
            // Housing
            housingType: formData?.current_housing_type || '',
            rentMonthlyPayment: formData?.rent_monthly_payment || '',

            // Current address
            street: formData?.address_street_line || '',
            unit: formData?.address_unit || '',
            city: formData?.address_city || '',
            state: formData?.address_state || '',
            postal: formData?.address_postal || '',
            county: formData?.address_county || '',
            durationTermYears: formData?.years_at_address === 0 ? 0 : formData?.years_at_address || '',
            durationTermMonths: formData?.months_at_address === 0 ? 0 : formData?.months_at_address || '',
            matchesMailingAddress: formData?.mailing_address_indicator?.toString() || null,
            sharedResidence: conciergeMode ? '' : formData?.shared_residence?.toString() ,
            stillOwnResidence: formData?.still_own_residence?.toString() || '',
            ownedResidence: formData?.owned_residence?.toString() || '',
            hasCoborrower: !!loanData.coborrower,

            // Submit
            submit: null,

            // Add current key to pass isCurrentProperty for validation check
            current: formData?.current
          }
        }
        validationSchema={Yup.object().shape({
          owner: Yup.string().required(' '),
          stillOwnResidence: Yup.string().when(['owner', 'current', 'ownedResidence'], {
            is: (owner: string, current: boolean, ownedResidence: string) => owner === 'borrower' && (current === true || (!current && ownedResidence === 'true')),
            then: Yup.string().required(' ')
          }),
          ownedResidence: Yup.string()
            .when(['owner', 'current'], {
              is: (owner: string, current: boolean) => (owner === 'borrower' && !current),
              then: Yup.string().required(' ')
            }),
          housingType: Yup.string().when(['owner', 'stillOwnResidence', 'current'], {
            is: (owner: string, stillOwnResidence: string, current: boolean) => (owner === "borrower" && (stillOwnResidence === 'false' && current === true)),
            then: Yup.string().required(' ')
          }),
          rentMonthlyPayment: Yup.string().when('housingType', {
            is: 'rent',
            then: Yup.string().required(' ')
          }),
          street: Yup.string().min(2, 'Invalid address entered, minimum of 2 characters required').required(' '),
          city: Yup.string().required(' '),
          state: Yup.string().required(' '),
          postal: Yup.string().required(' ').matches(/^[0-9]{5}$/, 'Zipcode must be exactly 5 digits'),
          durationTermYears: Yup.string().required(' '),
          durationTermMonths: Yup.string().required(' '),
          sharedResidence: Yup.string().when(['hasCoborrower', 'owner'], {
            is: (hasCoborrower: boolean, owner: string) => (hasCoborrower === true && owner === 'borrower'),
            then: Yup.string().required(' ')
          })
        })}
        onSubmit={(values, {
          setErrors,
          setStatus,
          setSubmitting,
          resetForm,
        }) => {
          try {
            const changedData = {
              id: formData?.id || null,
              owner: values.owner,
              address_state: values.state,
              address_city: values.city,
              address_postal: values.postal,
              address_street_line: values.street,
              address_unit: values.unit,
              address_county: values.county,
              years_at_address: values.durationTermYears,
              months_at_address: values.durationTermMonths,
              mailing_address_indicator: values.matchesMailingAddress || 'false',
              current_housing_type: values.housingType,
              rent_monthly_payment: values.rentMonthlyPayment.replace(/[$,]/g, ''),
              shared_residence: values.sharedResidence || 'false',
              owned_residence: values.ownedResidence, // <--- was ever owned
              still_own_residence: values.stillOwnResidence, // <--- still owned
              current: formData?.current || isCurrentProperty
            }

            if (conciergeMode) {
              let dataSubmit;
              let mergedData;

              const newResidences = _.concat(loanData.residences, changedData)

              mergedData = { residences: newResidences}

              dataSubmit = _.merge(loanData, mergedData)

              console.log({dataSubmit})

              loansService.updateLoan(activeLoan, dataSubmit).then((res: any) => {
                if (res.success) {
                  setStatus({ success: true });
                  setSubmitting(false);
                  setRefreshResidenceView(true)
                }
                else {
                  setStatus({ success: false });
                  setErrors({ submit: res.errors?.base[0] || res.error });
                  setSubmitting(false);
                }
              })
            }
            else {
              let skipUniquenessCheck = false

              if (formData?.address_street_line) {
                skipUniquenessCheck = valuesAreIdentical(
                  // TODO: Add values below once implemented in hive
                  [formData?.address_street_line],
                  [values.street]
                )
              }

              if (skipUniquenessCheck || (!skipUniquenessCheck && valuesAreUnique(
                [
                  values.street,
                  // TODO: Uncomment once hive uniqueness constraints is checking for entire address field
                  // values.unit,
                  // values.city,
                  // values.state,
                  // values.postal
                ],
                loanData,
                'residences',
                [
                  'address_street_line',
                  // TODO: Uncomment once hive uniqueness constraints is checking for entire address field
                  // 'address_unit',
                  // 'address_city',
                  // 'address_state',
                  // 'address_postal'
                ],
                tableData
              ))) {
                saveForm(changedData)
                setStatus({ success: true });
                setSubmitting(false);
                resetForm({});
                setShowForm(false);
              }
              else {
                setStatus({ success: false });
                setErrors({ submit: `${values.street}${values.unit ? `${values.unit} ` : ''} has already been added.` });
                setSubmitting(false);
              }
            }
          } catch (err: any) {
            console.error('AddResidence Error: ', err.message);
            setStatus({ success: false });
            setErrors({ submit: err.message })
            setSubmitting(false);
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values,
          resetForm,
        }) => (
          <form
            ref={formRef}
            className="application-form form-view"
            onSubmit={handleSubmit}
          >
            {(hasResidences && editTable) ? (
              <>
                <h1 className="title has-subtitle text-light-blue">Housing History</h1>
                {handleReturnSubheader(ownerName, values)}
              </>
            ) : (
              conciergeMode && (
                <>
                  <h1 className="title has-subtitle text-light-blue">Housing History</h1>
                  <h3 className="subtitle text-grey">Let's talk more about your housing history, {ownerName}</h3>
                </>
              )
            )}
            {(loanData.coborrower && displayOwnerOption) && (
              <div className="form-step">
                <Form.Field>
                  <label>Does this entry belong to {loanData.borrower.first_name} or {loanData.coborrower.first_name}?</label>
                  <FormikSelect
                    name="owner"
                    placeholder="Select from the list"
                    options={[
                      {
                        text: loanData.borrower.first_name,
                        value: 'borrower'
                      },
                      {
                        text: loanData.coborrower.first_name,
                        value: 'coborrower'
                      }
                    ]}
                    value={values.owner}
                    error={Boolean(touched.owner && errors.owner)}
                    disabled={!!formData?.owner}
                  />
                </Form.Field>
              </div>
            )}

            {/* Grab the Address of this Residence */}
            <AddressInput
              handleBlur={handleBlur}
              handleChange={handleChange}
              touched={touched}
              errors={errors}
              label={isCurrentProperty ? "What's your current address?" : 'Address details'}
              showErrorContainer={showErrorContainer}
              setFieldValue={setFieldValue}
              values={values}
            />

            {/* Previous Residence Questions */}
            {(values.owner === 'borrower' && isCurrentProperty === false ) &&
              <div className="form-step">
                <Form.Field>
                  <label>Did you own this property when you lived there?</label>
                  <div className={`radio-group ${showErrorContainer && values.ownedResidence === '' && 'radio-error'}`}>
                  <Radio
                    id="ownedResidenceTrue"
                    className={errors.ownedResidence && 'has-error'}
                    label="Yes"
                    name="ownedResidence"
                    value='true'
                    checked={values.ownedResidence === 'true'}
                    onChange={handleChange}
                  />
                  <Radio
                    id="ownedResidenceFalse"
                    className={errors.ownedResidence && 'has-error'}
                    label="No"
                    name="ownedResidence"
                    value='false'
                    checked={values.ownedResidence === 'false'}
                    onFocus={() => clearValues(values, ['stillOwnResidence'])}
                    onChange={handleChange}
                  />
                  </div>
                  <FormikErrorMessage name="ownedResidence" />
                </Form.Field>
              </div>
            }

            {/* Current Residence Questions */}
            {((values.owner === 'borrower' && isCurrentProperty) || (isCurrentProperty === false && values.ownedResidence === 'true' )) && (
              <div className="form-step">
                <Form.Field>
                  <label>Do you currently own this property?</label>
                  <div className={`radio-group ${showErrorContainer && values.stillOwnResidence === '' && 'radio-error'}`}>
                    <Radio
                      id="stillOwnResidenceTrue"
                      className={errors.stillOwnResidence && 'has-error'}
                      label="Yes"
                      name="stillOwnResidence"
                      value='true'
                      checked={values.stillOwnResidence === 'true'}
                      onChange={(e) => {
                        if (isCurrentProperty === true) values.ownedResidence = 'true';
                        handleChange(e);
                      }}
                    />
                    <Radio
                      id="stillOwnResidenceFalse"
                      className={errors.stillOwnResidence && 'has-error'}
                      label="No"
                      name="stillOwnResidence"
                      value='false'
                      checked={values.stillOwnResidence === 'false'}
                      onChange={(e) => {
                        if (isCurrentProperty === true) values.ownedResidence = 'false';
                        handleChange(e);
                      }}
                    />
                  </div>
                  <FormikErrorMessage name="stillOwnResidence" />
                </Form.Field>
              </div>
            )}
            {values.stillOwnResidence === 'true' && (values.housingType = '', values.rentMonthlyPayment = '')}
            {(values.ownedResidence === 'false' && isCurrentProperty) && (
              <div className="form-step">
                <Form.Field>
                  <label>Okay, do you rent or live rent-free at this address?</label>
                  <FormikDropdown
                    className="small"
                    name="housingType"
                    placeholder="Rental Info"
                    options={housingTypeOptions}
                    value={values.housingType}
                    onFocus={() => clearValues(values, ['rentMonthlyPayment'])}
                    error={Boolean(touched.housingType && errors.housingType) || Boolean(showErrorContainer && values.housingType === '')}
                  />
                </Form.Field>
              </div>
            )}
            {values.housingType === 'rent' && (
              <div className="form-step">
                <Form.Field>
                  <label>Got it. What's your monthly rent payment?</label>
                  <Input
                    error={Boolean(touched.rentMonthlyPayment && errors.rentMonthlyPayment) || Boolean(showErrorContainer && values.rentMonthlyPayment == '')}
                  >
                    <MaskedInput
                      name="rentMonthlyPayment"
                      placeholder="Enter Monthly Dollar Amount $"
                      type="text"
                      value={values.rentMonthlyPayment}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      mask={currencyMask}
                      guide={false}
                    />
                    <FormikErrorMessage name="rentMonthlyPayment" />
                  </Input>
                </Form.Field>
              </div>
            )}
            <div className="form-step">
              <Form.Field>
                <label>{getDurationTermLabel(values.owner)}</label>
                <div className="time-at-residence">
                  <FormikDropdown
                    className="years small"
                    name="durationTermYears"
                    placeholder="Years"
                    options={yearsOptions}
                    value={values.durationTermYears}
                    error={Boolean(touched.durationTermYears && errors.durationTermYears) || Boolean(showErrorContainer && values.durationTermYears === '')}
                  />
                  <FormikDropdown
                    className="months small"
                    name="durationTermMonths"
                    placeholder="Months"
                    options={monthsOptions}
                    value={values.durationTermMonths}
                    error={Boolean(touched.durationTermMonths && errors.durationTermMonths) || Boolean(showErrorContainer && values.durationTermMonths === '')}
                  />
                </div>
              </Form.Field>
            </div>

            {/* Ask if the residence is shared with the coborrower if a coborrower is present for this borrower */}
            {(loanData.coborrower && values.owner === 'borrower') && (
              <div className="form-step">
                <Form.Field>
                  <label>Do you share this address with {loanData.coborrower.first_name}?</label>
                  <div className={`radio-group ${showErrorContainer && values.sharedResidence === '' && 'radio-error'}`}>
                    <Radio
                      id="sharedResidenceTrue"
                      className={errors.sharedResidence && 'has-error'}
                      label="Yes"
                      name="sharedResidence"
                      value='true'
                      checked={values.sharedResidence === 'true'}
                      disabled={!conciergeMode && formData?.id}
                      onChange={handleChange}
                    />
                    <Radio
                      id="sharedResidenceFalse"
                      className={errors.sharedResidence && 'has-error'}
                      label="No"
                      name="sharedResidence"
                      value='false'
                      checked={values.sharedResidence === 'false'}
                      disabled={!conciergeMode && formData?.id}
                      onChange={handleChange}
                    />
                    <FormikErrorMessage name="sharedResidence" />
                  </div>
                </Form.Field>
              </div>
            )}
            <div className="application-step-footer">
              <Button
                type="button"
                className="form-previous-button"
                onClick={() => {
                  resetForm();
                  handleCancel();
                }}
              >
                Cancel
              </Button>
              <ErrorContainer
                errors={errors}
                showErrorContainer={showErrorContainer}
                setDisableOnErrors={setDisableOnErrors}
                errorMessage={errors.submit ? errors.submit : undefined}
              />
              <Button
                disabled={isSubmitting || disableOnErrors}
                color="blue"
                type="submit"
                className="save form-save-button"
                onClick={() => setShowErrorContainer(true)}
              >
                Save
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </>
  )
}

export default ResidenceForm;
