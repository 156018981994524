import React, { FC, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import * as Yup from 'yup';
import _ from 'lodash';
import { Button, Form, Header, Icon, Input, Popup, Radio } from 'semantic-ui-react';
import { Formik, Field } from 'formik';
import FormikDropdown from '../../../components/Formik/FormikDropdown';
import FormikDatePicker from '../../../components/Formik/FormikDatePicker';
import FormikRadio from '../../../components/Formik/FormikRadio';
import { DataContext } from '../../../context/dataContext';
import { observer } from 'mobx-react-lite';
import LoaderOverlay from '../../../components/LoaderOverlay/LoaderOverlay';
import { useStore } from '@jmjfinancial/apis/lib';
import { ApplicationRoutes } from '../index';
import FormikErrorMessage from '../../../components/Formik/ErrorMessage';
import { ethnicityHispanicOptions, ethnicityOptions, genderOptions, militaryOptions, raceAsianOptions, raceOptions, racePacificIslanderOptions } from './data';
import { clearValues } from '../../../helpers/clear-values-helper'
import ErrorContainer from '../../../components/ErrorContainer';
import './demographics.scss';
import {scrollToDefaultOptions} from '../../../helpers/scroll-to-options';

const raceSwitchCase = (
  values: any,
  handleBlur: Function,
  handleChange: any,
  errors: any,
  touched: any,
  showErrorContainer: boolean
) => {
  switch (values.race) {
    case 'asian':
      return (
        <>
          <h2>Select all that apply: </h2>
          {raceAsianOptions.map((race: any, index: number) => (
            <label className="checkbox" key={`${race.text}-${index}`}>
              <Field
                type="checkbox"
                name="raceTypes"
                value={race.key}
              />
              <span className="race-text">{race.text}</span>
            </label>
          ))}
        </>
      )
    case 'native':
      return (
        <>
          <label>Enter name of enrolled or principal tribe:</label>
          <Input
            className="small"
            name="nativeTribe"
            placeholder="Tribe"
            value={values.nativeTribe}
            onBlur={handleBlur}
            onChange={handleChange}
            error={!!(touched.nativeTribe && errors.nativeTribe) || !!(showErrorContainer && values.nativeTribe === '')}
          />
        </>
      )
    case 'pacificIslander':
      return (
        <>
          <h2>Select all that apply: </h2>
          {racePacificIslanderOptions.map((race: any, index: number) => (
            <label className="checkbox" key={`${race.text}-${index}`}>
              <Field
                type="checkbox"
                name="raceTypes"
                value={race.key}
              />
              <span className="race-text">{race.text}</span>
            </label>
          ))}
        </>
      )
    case 'black':
      return <></>
    case 'white':
      return <></>
    case 'defer':
      return <></>
    case 'default':
      <></>
  }
}

const Demographics: FC = observer(() => {
  const store = useStore();
  const { loansService } = store;

  const {
    activeLoan,
    pathname,
    reviewMode,
    isMobileOnly,
    isTablet
  } = useContext(DataContext)
  const [loanData, setLoanData] = useState<any>()
  const [isLoading, setIsLoading] = useState(true)
  const [borrowerName, setBorrowerName] = useState('');
  const [coBorrowerName, setCoBorrowerName] = useState('');
  const [hmdaObject, setHmdaObject] = useState<any>();
  const [militaryStatusSelected, setMilitaryStatusSelected] = useState<number | null>()
  const [showErrorContainer, setShowErrorContainer] = useState<boolean>(false)
  const [disableOnErrors, setDisableOnErrors] = useState<boolean>(false)

  const history = useHistory();

  let currentOwnerType: string;

  if (pathname.includes('coborrower')) {
    currentOwnerType = 'coborrower'
  } else {
    currentOwnerType = 'borrower'
  }

  const handlePreviousClick = () => {
    if (reviewMode) {
      history.push('/application/declarations')
    }
    else if (currentOwnerType === 'coborrower') {
      history.push('/application/demographics')
    }
    else {
      (
        loanData.coborrower
          ? history.push('/application/declarations-coborrower')
          : history.push('/application/declarations')
      )
    }
  }

  const handleNextClick = () => {
    (currentOwnerType === 'borrower' && loanData.coborrower
      ? history.push('/application/demographics-coborrower')
      : history.push('/application/review-and-submit'));
  }

  const resetMilitaryStatus = (values: any) => {
    values.militaryStatus = ''
    setMilitaryStatusSelected(null)
  }

  useEffect(() => {
    loansService.getLoan(activeLoan)
      .then(loan => {
        setLoanData({ ...loan.data.form_data })
        setBorrowerName(loan.data.form_data.borrower.first_name);
        setCoBorrowerName(loan.data.form_data.coborrower?.first_name);
        setHmdaObject(loan.data.form_data.borrower?.hmda);
        setIsLoading(false);
      })
  }, [activeLoan, loansService])

  useEffect(() => {
    window.scrollTo(scrollToDefaultOptions)
  }, [])

  return (
    <div className="application-step-container demographics-step-container">
      <LoaderOverlay active={isLoading} />
      {!isLoading &&
        <>
          <h1 className="title has-subtitle text-light-blue">Demographics</h1>
          <h3 className="subtitle text-grey">Let's learn a little more about {`${(currentOwnerType === 'coborrower' ? `${coBorrowerName}'s demographics` : `${borrowerName}'s demographics`)}`}</h3>
        </>
      }
      {!isLoading && (
        <Formik
          // TODO: Needs data mapping
          initialValues={{
            military: currentOwnerType === 'borrower'
              ? loanData.borrower.military_indicator?.toString() || ''
              : loanData.coborrower.military_indicator?.toString() || '',
            militaryStatus: currentOwnerType === 'borrower'
              ? loanData.borrower.military_status || ''
              : loanData.coborrower.military_status || '',
            serviceExpiration: loanData.borrower?.service_expiration ? new Date(loanData.borrower.service_expiration) : '',
            ethnicity: currentOwnerType === 'borrower'
              ? loanData.borrower.ethnicity || ''
              : loanData.coborrower.ethnicity || '',
            ethnicityTypes: currentOwnerType === 'borrower'
              ? Object.keys(loanData.borrower.hmda_ethnicity).filter(value => loanData.borrower.hmda_ethnicity[value] === true) || {}
              : Object.keys(loanData.coborrower?.hmda_ethnicity).filter(value => loanData.coborrower?.hmda_ethnicity[value] === true) || {},
            gender: currentOwnerType === 'borrower'
              ? loanData.borrower.gender?.toLowerCase() || ''
              : loanData.coborrower.gender?.toLowerCase() || '',

            race: currentOwnerType === 'borrower'
              ? loanData.borrower.race || ''
              : loanData.coborrower.race || '',

            raceTypes: currentOwnerType === 'borrower'
              ? Object.keys(loanData.borrower.hmda_race).filter(value => loanData.borrower.hmda_race[value] === true) || {}
              : Object.keys(loanData.coborrower?.hmda_race).filter(value => loanData.coborrower?.hmda_race[value] === true) || {},

            nativeTribe: currentOwnerType === 'borrower'
              ? loanData.borrower.hmda_race.hmda_american_indian_tribe || ''
              : loanData.coborrower.hmda_race.hmda_american_indian_tribe || '',

            submit: null,
          }}
          validationSchema={Yup.object().shape({
            military: Yup.string().required('This field is required'),
            militaryStatus: Yup.string().when('military', {
              is: 'true',
              then: Yup.string().required('This field is required')
            }),
            serviceExpiration: Yup.string().when('militaryStatus', {
              is: 'expiration',
              then: Yup.string().required('This field is required')
            }),
            ethnicity: Yup.string().required('This field is required'),
            ethnicityTypes: Yup.array().when('ethnicity', {
              is: 'hispanic',
              then: Yup.array().min(1, ' ')
            }),
            gender: Yup.string().required('This field is required'),
            race: Yup.string().required('This field is required'),
            raceTypes: Yup.array().when('race', {
              is: (race: string) => (race === 'asian' || race === 'pacificIslander'),
              then: Yup.array().min(1, ' ')
            }),
            nativeTribe: Yup.string().when('race', {
              is: 'native',
              then: Yup.string().required()
            })
          })}
          onSubmit={async (values, {
            setErrors,
            setStatus,
            setSubmitting
          }) => {
            try {
              let changedData;
              let hmda_ethnicity_object: any = currentOwnerType === 'borrower' ? loanData.borrower.hmda_ethnicity : loanData.coborrower.hmda_ethnicity;
              let hmda_gender_type: string = '';
              let hmda_race_object: any = currentOwnerType === 'borrower' ? loanData.borrower.hmda_race : loanData.coborrower.hmda_race;

              // adds each value from ethnicityTypes array to the hmda_ethnicity_object and sets the value to true
              values.ethnicityTypes.forEach((type: string) => (
                hmda_ethnicity_object[type] = true
              ))

              Object.keys(hmda_ethnicity_object).map((ethnicity: any) => {
                if (values.ethnicityTypes.includes(ethnicity)) {
                  hmda_ethnicity_object[ethnicity] = true
                } else {
                  delete hmda_ethnicity_object[ethnicity]
                }
              })

              if (values.ethnicity === 'notHispanic') {
                hmda_ethnicity_object['hmda_ethnicity_not_hispanic_latino_indicator'] = true
              } else if (values.ethnicity === 'defer') {
                hmda_ethnicity_object['hmda_ethnicity_do_not_wish_indicator'] = true
              }

              // adds each value from raceTypes to the hmda_race_object and sets the value to true
              values.raceTypes.forEach((type: string) => {
                hmda_race_object[type] = true
              })

              // for each key in the hmda_race_object if the key matches any of the values in the values.raceTypes array
              // set the key in the hmda_race_object to true
              // if it does not match, delete the key from the hmda_race_object
              Object.keys(hmda_race_object).map((race: any) => {
                if (values.raceTypes.includes(race)) {
                  hmda_race_object[race] = true
                } else {
                  delete hmda_race_object[race]
                }
              })

              // if statement that sets hmda race values for options that do not have specific types.
              if (values.nativeTribe) {
                hmda_race_object['hmda_american_indian_indicator'] = true
                hmda_race_object['hmda_american_indian_tribe'] = values.nativeTribe
              }
              else if (values.race === 'black') {
                hmda_race_object['hmda_african_american_indicator'] = true
              }
              else if (values.race === 'white') {
                hmda_race_object['hmda_white_indicator'] = true
              }
              else if (values.race === 'defer') {
                hmda_race_object['hmda_race_do_not_wish_provide_indicator'] = true
              }
              else if (values.race === 'notApplicable') {
                hmda_race_object['hmda_not_applicable_indicator'] = true
              }

              // if values.gender === to one of the gender options in genderOptions array, add the hmda value to hmda_gender_object and set to true
              genderOptions.forEach((gender: any) => {
                if (values.gender === gender.value) {
                  hmda_gender_type = gender.key
                }
              })

              currentOwnerType === 'borrower' && (changedData = {
                application: {
                  current_step: ApplicationRoutes[pathname as keyof typeof ApplicationRoutes] + (loanData.coborrower ? 1 : 2),
                  completed_steps: {
                    demographics_done: true
                  }
                },
                borrower: {
                  military_indicator: values.military,
                  military_status: values.militaryStatus,
                  service_expiration: values.serviceExpiration,
                  ethnicity: values.ethnicity,
                  hmda_ethnicity: hmda_ethnicity_object,
                  gender: values.gender,
                  hmda_gender: hmda_gender_type,
                  race: values.race,
                  hmda_race: hmda_race_object
                }
              });

              currentOwnerType === 'coborrower' && (changedData = {
                application: {
                  current_step: ApplicationRoutes[pathname as keyof typeof ApplicationRoutes] + 1,
                  completed_steps: {
                    demographics_coborrower_done: true
                  }
                },
                coborrower: {
                  military_indicator: values.military,
                  military_status: values.militaryStatus,
                  service_expiration: values.serviceExpiration,
                  ethnicity: values.ethnicity,
                  hmda_ethnicity: hmda_ethnicity_object,
                  gender: values.gender,
                  hmda_gender: hmda_gender_type,
                  race: values.race,
                  hmda_race: hmda_race_object
                }
              });

              const dataSubmit = _.merge(loanData, changedData)

              await loansService.updateLoan(activeLoan, dataSubmit);

              setStatus({ success: true })
              setSubmitting(false)

              handleNextClick();
            } catch (err: any) {
              console.error('Application error: ', err.message);
              setStatus({ success: false });
              setErrors({ submit: err.message })
              setSubmitting(false)
            }
          }}
        >
          {({
            errors,
            handleSubmit,
            handleBlur,
            handleChange,
            setFieldValue,
            isSubmitting,
            touched,
            values
          }) => (
            <form
              onSubmit={handleSubmit}
              className="application-form"
            >
              <div className="form-step">
                <h1 className="auth-title text-light-blue">Military</h1>
                <Form.Field>
                  <label>
                    Did you (or your deceased spouse if applicable) ever serve, or are you currently serving,
                    in the United States Armed Forces?
                  </label>
                  <div className={`radio-group ${showErrorContainer && values.military === '' && 'radio-error'}`}>
                    <Radio
                      id="militaryTrue"
                      className={errors.military && 'has-error'}
                      label="Yes"
                      name="military"
                      value='true'
                      checked={values.military === 'true'}
                      onChange={handleChange}
                    />
                    <Radio
                      id="militaryFalse"
                      className={errors.military && 'has-error'}
                      label="No"
                      name="military"
                      value='false'
                      checked={values.military === 'false'}
                      onClick={() =>
                        resetMilitaryStatus(values)
                      }
                      onChange={handleChange}
                    />
                  </div>
                </Form.Field>
                {values.military === 'true' && (
                  <>
                    <span className="subtitle bold">Select Military Status: </span>
                    <Form.Field className="nested">
                      <div className="choice-container military-status">
                        {militaryOptions.map((status: any, index: number) => (
                          <div
                            className={`${showErrorContainer && values.militaryStatus === '' ? 'military-error' : ''}`}
                            key={`${status.text}-${index}`}
                          >
                            {status.value.includes('expiration') ? (
                              <>
                                <button
                                  type="button"
                                  tabIndex={-1}
                                  id={`military-${status.key}`}
                                  className={`choice-answers unset ${militaryStatusSelected === index ? 'selected' : 'unselected'}`}
                                >
                                  <span
                                    className="unset"
                                    tabIndex={0}
                                    onClick={militaryStatusSelected !== index ? (
                                      () => {
                                        clearValues(values, ['serviceExpiration'])
                                        setFieldValue('militaryStatus', status.value)
                                        setMilitaryStatusSelected(index)
                                      }
                                      ) : (
                                        () => {
                                          setFieldValue('militaryStatus', status.value)
                                          setMilitaryStatusSelected(index)
                                        }
                                      )}
                                    onKeyPress={militaryStatusSelected !== index ? (
                                      () => {
                                        clearValues(values, ['serviceExpiration'])
                                        setFieldValue('militaryStatus', status.value)
                                        setMilitaryStatusSelected(index)
                                      }
                                    ) : (
                                      () => {
                                        setFieldValue('militaryStatus', status.value)
                                        setMilitaryStatusSelected(index)
                                      }
                                    )}
                                  >
                                    <i className={`icon small ${militaryStatusSelected === index || values.militaryStatus === status.value ? 'check selected' : 'angle right unselected'}`} />
                                    {status.text}
                                  </span>
                                  {/*
                                    ternary shows the checkmark if the option is clicked OR if user goes back to Demographics section,
                                    values.militaryStatus === status.value will "auto-fill" and display checkmark for value returned from Hive
                                  */}
                                </button>
                                {values.militaryStatus === 'expiration' && (
                                  <Form.Field className="nested date-picker">
                                    <span className="military-expiration-date">Enter expiration date of service: </span>
                                    <FormikDatePicker
                                      className="small"
                                      name="serviceExpiration"
                                      placeholder="MM/DD/YYYY"
                                      format="MM-DD-YYYY"
                                      icon="calendar alternate outline"
                                      value={values.serviceExpiration}
                                      minDate={Date.now()}
                                      error={!!(touched.serviceExpiration && errors.serviceExpiration) || !!(showErrorContainer && values.serviceExpiration === '')}
                                    />
                                  </Form.Field>
                                )}
                              </>
                            ) : (
                              <div
                                className={`choice-answers ${militaryStatusSelected === index ? 'selected' : 'unselected'}`}
                                key={`${status.text}-${index}`}
                              >
                                <button
                                  type="button"
                                  className="unset"
                                  tabIndex={0}
                                  onClick={() => {
                                    setFieldValue('militaryStatus', status.value)
                                    setMilitaryStatusSelected(index)
                                  }}
                                  onKeyPress={() => {
                                    setFieldValue('militaryStatus', status.value)
                                    setMilitaryStatusSelected(index)
                                  }}
                                >
                                  <i className={`icon small ${militaryStatusSelected === index || values.militaryStatus === status.value ? 'check selected' : 'angle right unselected'}`} />
                                  {status.text}
                                </button>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    </Form.Field>
                  </>
                )}
              </div>
              <div className="demographics-info-container">
                <h1 className="auth-title demographics-info-title text-light-blue">Demographic Information</h1>
                <Popup
                  className={`popup-container demographics-popup ${isMobileOnly ? 'is-mobile' : ''} ${isTablet ? 'is-tablet' : ''}`}
                  trigger={
                    <Icon
                      name="info"
                      className="icon info circle large"
                    />
                  }
                  basic
                  wide
                  on={['click']}
                  offset={[0, 20]}
                  position={isMobileOnly || isTablet ? 'bottom center' : 'bottom right'}
                >
                  <Header as="h2" content="Demographics Information" />
                  <p className="demo-instructions">
                    The purpose of collecting this information is to help ensure that all applicants are treated fairly and that the housing needs of communities
                    and neighborhoods are being fulfilled. For residential mortgage lending, Federal law requires that we ask applicants for their demographic
                    information (ethnicity, sex, and race) in order to monitor our compliance with equal credit opportunity, fair housing, and home mortgage
                    disclosure laws. You are not required to provide this information, but are encouraged to do so. You may select one or more designations for
                    "Ethnicity" and one or more designations for "Race." The law provides that we may not discriminate on the basis of this information, or on
                    whether you choose to provide it. However, if you choose not to provide the information and you have made this application in person, Federal
                    regulations require us to note your ethnicity, sex, and race on the basis of visual observation or surname. The law also provides that we may not
                    discriminate on the basis of age or marital status information you provide in this application. If you do not wish to provide some or all of this
                    information, please check below.
                  </p>
                </Popup>
              </div>

              <div className="form-step">
                <Form.Field>
                  <label>Ethnicity</label>
                  <FormikDropdown
                    className="large"
                    name="ethnicity"
                    fluid
                    selection
                    placeholder="Ethnicity"
                    options={ethnicityOptions}
                    value={values.ethnicity}
                    onFocus={() => values.ethnicityTypes = []}
                    error={!!(touched.ethnicity && errors.ethnicity) || !!(showErrorContainer && values.ethnicity === '')}
                  />
                </Form.Field>
              </div>
              {values.ethnicity === 'hispanic' && (
                <>
                  <div className="form-step">
                    <Form.Field
                      className={`checkbox ethnicity nested ${showErrorContainer && values.ethnicityTypes.length === 0 && 'has-error'}`}
                    >
                      <h2>Select all that apply:</h2>
                      {ethnicityHispanicOptions.map((ethnicity: any, index: number) => (
                        <label className="checkbox" key={`${ethnicity.text}-${index}`}>
                          <Field
                            type="checkbox"
                            name="ethnicityTypes"
                            value={ethnicity.key}
                          />
                          <span className="ethnicity-text">{ethnicity.text}</span>
                        </label>
                      ))}
                    </Form.Field>
                  </div>
                </>
              )}
              <div className="form-step">
                <Form.Field>
                  <label>Gender</label>
                  <FormikDropdown
                    className="large"
                    name="gender"
                    fluid
                    selection
                    placeholder="Gender"
                    options={genderOptions}
                    value={values.gender}
                    error={!!(touched.gender && errors.gender) || !!(showErrorContainer && values.gender === '')}
                  />
                </Form.Field>
              </div>
              <div className="form-step">
                <Form.Field>
                  <label>Race</label>
                  <FormikDropdown
                    className="large"
                    name="race"
                    fluid
                    selection
                    placeholder="Race"
                    options={raceOptions}
                    value={values.race}
                    onFocus={() => (values.raceTypes = [], values.nativeTribe = '')}
                    error={!!(touched.race && errors.race) || !!(showErrorContainer && values.race === '')}
                  />
                </Form.Field>
              </div>
              <div className="form-step">
                <Form.Field
                  className={`checkbox race nested ${showErrorContainer && values.raceTypes.length === 0 && 'has-error'}`}
                >
                  {
                    raceSwitchCase(
                      values,
                      handleBlur,
                      handleChange,
                      errors,
                      touched,
                      showErrorContainer
                    )
                  }
                </Form.Field>
              </div>
              <div className="application-step-footer">
                <Button
                  type="button"
                  className="form-previous-button"
                  onClick={handlePreviousClick}
                >
                  Previous
                </Button>
                <ErrorContainer
                  errors={errors}
                  showErrorContainer={showErrorContainer}
                  setDisableOnErrors={setDisableOnErrors}
                />
                <Button
                  disabled={isSubmitting || disableOnErrors}
                  className="save"
                  color="blue"
                  type="submit"
                  onClick={() => setShowErrorContainer(true)}
                >
                  {reviewMode ? "Back to Review" : "Save & Continue"}
                </Button>
              </div>
            </form>
          )}
        </Formik>
      )}
    </div>
  )
})

export default Demographics;
