const loanAmountCalc = ( purchasePrice:string, downPaymentAmount:string) =>{
  //STRIP MASKING AND CONVERT TO NUMBERS
  let purchasePriceNum = purchasePrice === '' ? 0 : parseFloat(purchasePrice.replace(/[$,]/g,''))
  let downPaymentAmountNum = downPaymentAmount === '' ? 0 : parseFloat(downPaymentAmount.replace(/[$,]/g,''))
  let loanAmount = purchasePriceNum - downPaymentAmountNum
  return loanAmount.toString()
}

 const downPaymentPercentCalc = ( purchasePrice:string, downPaymentAmount:string)=>{
  let downPaymentAmountNum = downPaymentAmount === '' ? 0 : parseFloat(downPaymentAmount.replace(/[$,]/g,''))
  let PurchasePriceNum = purchasePrice === '' ? 0 : parseFloat(purchasePrice.replace(/[$,]/g,''))
  let downPaymentPercent = ((downPaymentAmountNum/PurchasePriceNum) * 100).toFixed(2)
  return downPaymentPercent.toString()
}

 const downPaymentAmountCalc = ( downPaymentPercent:string, purchasePrice:string)=>{
  let downPaymentPercentNum = downPaymentPercent === '' ? 0 : parseFloat(downPaymentPercent.replace(/[%]/g,''))
  let purchasePriceNum = purchasePrice === '' ? 0 : parseFloat(purchasePrice.replace(/[$,]/g,''))
  let downPaymentAmount = ((downPaymentPercentNum / 100 ) * purchasePriceNum)
  return downPaymentAmount.toString()
}

 const downPaymentAmountCalc2 = ( loanAmount:string, purchasePrice:string)=>{
  let loanAmountNum = loanAmount === '' ? 0 : parseFloat(loanAmount.replace(/[$,]/g,''))
  let purchasePriceNum = purchasePrice === '' ? 0 : parseFloat(purchasePrice.replace(/[$,]/g,''))
  let downPaymentAmount = purchasePriceNum - loanAmountNum
  return downPaymentAmount.toString()
}

 const downPaymentPercentCalc2 = ( purchasePrice:string, loanAmount:string)=>{
  let loanAmountNum = loanAmount === '' ? 0 : parseFloat(loanAmount.replace(/[$,]/g,''))
  let PurchasePriceNum = purchasePrice === '' ? 0 : parseFloat(purchasePrice.replace(/[$,]/g,''))
  let downPaymentAmountNum = PurchasePriceNum - loanAmountNum
  let downPaymentPercent = (downPaymentAmountNum/PurchasePriceNum) * 100
  return downPaymentPercent.toString()
}

export const loanCalculator = ( field:string, setFieldValue:any, purchasePrice:string, downPaymentAmount:string, downPaymentPercent:string, loanAmount:string) => {
  let dpp, la, dp, dpa

    switch(field) {
      case 'downPaymentAmount':
        dpp = downPaymentPercentCalc(purchasePrice, downPaymentAmount)
        setFieldValue('downPaymentPercent', dpp)
        la = loanAmountCalc( purchasePrice, downPaymentAmount )
        setFieldValue('loanAmount', la)
        break;
      case 'downPaymentPercent':
        dpa = downPaymentAmountCalc( downPaymentPercent, purchasePrice)
        setFieldValue('downPaymentAmount', dpa)
        la = loanAmountCalc( purchasePrice, dpa )
        setFieldValue('loanAmount', la)
        break;
      case 'loanAmount':
        dpa = downPaymentAmountCalc2( loanAmount, purchasePrice)
        setFieldValue('downPaymentAmount', dpa)
        dpp = downPaymentPercentCalc2( purchasePrice, loanAmount)
        setFieldValue('downPaymentPercent', dpp)
        break;
      default:
        dpa = downPaymentAmountCalc( dpp? dpp : downPaymentPercent, purchasePrice)
        setFieldValue('downPaymentAmount', dpa)
        la = loanAmountCalc( purchasePrice, dpa )
        setFieldValue('loanAmount', la)
    }

}
