import React, {FC, useContext, useState} from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Button, Form, Input } from 'semantic-ui-react';
import { observer } from 'mobx-react-lite';
import { useStore } from '@jmjfinancial/apis/lib';
import { DataContext } from '../../context/dataContext';
import MaskedInput from 'react-text-mask';
import ErrorContainer from '../../components/ErrorContainer';
import { PHONE_INPUT_MASK, PHONE_MASK, PHONE_MATCH } from '../../helpers/regex-helper';

export type MyAccountFormType = 'name' | 'email' | 'phone' | 'password' | 'delete'

interface EditFormProps {
  editable: boolean
  setEditable: Function
  formType: MyAccountFormType
  isSavingChanges: boolean
  setIsSavingChanges: Function
}

const EditForm: FC<EditFormProps> = observer(({
  editable,
  setEditable,
  formType,
  isSavingChanges,
  setIsSavingChanges
}) => {
  const store = useStore();
  const { loansService } = store;
  const {
    user,
    customer,
    customerFirst,
    setCustomerFirst,
    customerLast,
    setCustomerLast,
    customerEmail,
    setCustomerEmail,
    customerPhone,
    setCustomerPhone
  } = useContext(DataContext)

  const [showErrorContainer, setShowErrorContainer] = useState<boolean>(false)
  const [disableOnErrors, setDisableOnErrors] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')

  const formSwitch = (
    values: any,
    touched: any,
    errors: any,
    handleBlur: any,
    handleChange: any
  ) => {
    switch (formType) {
      case 'name':
        return (
          <div className="name-fields">
            <Form.Field>
              <Input
                name="firstName"
                placeholder="First"
                type="text"
                value={values.firstName}
                onBlur={handleBlur}
                onChange={handleChange}
                error={!!(touched.firstName && errors.firstName) || !!(showErrorContainer && values.firstName === '')}
              />
            </Form.Field>
            <Form.Field>
              <Input
                name="lastName"
                placeholder="Last"
                type="text"
                value={values.lastName}
                onBlur={handleBlur}
                onChange={handleChange}
                error={!!(touched.lastName && errors.lastName) || !!(showErrorContainer && values.lastName === '')}
              />
            </Form.Field>
          </div>
        )
      case 'phone':
        return (
          <Form.Field>
            <Input error={!!(touched.phone && errors.phone) || !!(showErrorContainer && values.phone === '')}>
              <MaskedInput
                mask={PHONE_INPUT_MASK}
                guide={false}
                name="phone"
                placeholder="(888) 888-8888"
                type="text"
                value={values.phone}
                onBlur={handleBlur}
                onChange={handleChange}
              />
            </Input>
          </Form.Field>
        )
      case 'email':
        return (
          <Form.Field>
            <Input
              name="email"
              placeholder="info@jmj.me"
              type="text"
              value={values.email}
              onBlur={handleBlur}
              onChange={handleChange}
              error={!!(touched.email && errors.email) || !!(showErrorContainer && values.email === '')}
            />
          </Form.Field>
        )
    }
  }

  return (
    <div className="my-account-form-container">
      <Formik
        initialValues={{
          formType: formType,
          firstName: customerFirst || customer?.first_name,
          lastName: customerLast || customer?.last_name,
          email: customerEmail || customer?.email,
          phone: customerPhone || customer?.mobile_phone,
          submit: null
        }}
        validationSchema={Yup.object().shape({
          firstName: Yup.string().when('formType', {
            is: 'name',
            then: Yup.string().required('First name is required')
          }),
          lastName: Yup.string().when('formType', {
            is: 'name',
            then: Yup.string().required('Last name is required')
          }),
          email: Yup.string().when('formType', {
            is: 'email',
            then: Yup.string()
              .email('Invalid email')
              .required('Email is required')
          }),
          phone: Yup.string().when('formType', {
            is: 'phone',
            then: Yup.string()
              .matches(PHONE_MASK, 'Phone number must be 10 digits')
              .matches(PHONE_MATCH, 'Area code cannot start with 1')
              .required('Phone number is required')
          }),
        })}
        onSubmit={async (values, {
          setStatus,
          setErrors,
          setSubmitting
        }) => {
          try {

            setShowErrorContainer(true)
            // TODO: Figure out way to make fields update without re-render flashing

            // We can comment this back in if we want LoaderOverlay to display and get rid of text re-render flashing
            // setIsSavingChanges(true)

            const changedData = {
              customer: {
                id: customer?.id,
                first_name: values.firstName,
                last_name: values.lastName,
                email: values.email,
                mobile_phone: values.phone.replace(/\D/g, '')
              }
            }

            await loansService.updateCustomer(changedData)
              .then(async response => {
                if (response.data.success) {
                  setIsSavingChanges((isSavingChanges: boolean) => true)
                  setCustomerFirst((customerFirst: string) => values.firstName)
                  setCustomerLast((customerLast: string) => values.lastName)
                  setCustomerEmail((customerEmail: string) => values.email)
                  setCustomerPhone((customerPhone: string) => values.phone.replace(/\D/g, ''))
                  setTimeout(setIsSavingChanges((isSavingChanges: boolean) => false), 3000)
                  setEditable(!editable)
                }
              })
          } catch (err: any) {
            console.error('Application error: ', err.message)
            setErrors({ email: 'Could not update email address. It may already be taken.' })
            setStatus({ success: false })
            setSubmitting(false)
          }
        }}
      >
        {({
          values,
          errors,
          handleSubmit,
          handleBlur,
          handleChange,
          touched
        }) => (
          <>
            <form
              onSubmit={handleSubmit}
              className="my-account-form"
            >
              {formSwitch(
                values,
                touched,
                errors,
                handleBlur,
                handleChange
              )}
              <div className="form-controls">
                <Button
                  onClick={() => setEditable(!editable)}
                >
                  Cancel
                </Button>
                <ErrorContainer
                  errors={errors}
                  showErrorContainer={showErrorContainer}
                  setDisableOnErrors={setDisableOnErrors}
                  errorMessage={
                    (errors.firstName)
                    || (errors.lastName)
                    || (errors.email)
                    || (errors.phone)
                    || errors.submit
                  }
                />
                <Button
                  disabled={disableOnErrors}
                  type="submit"
                  color="blue"
                  className="alternate-button"
                  onClick={() => setShowErrorContainer(true)}
                >
                  Save
                </Button>
              </div>
            </form>
          </>
        )}
      </Formik>
    </div>
  )
})

export default EditForm
