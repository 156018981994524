import React, {FC} from 'react';
import {Icon} from 'semantic-ui-react';

const CompletedIcon: FC = () => {
  return (
    <div className="completed-icon">
      <Icon
        name="check"
        className="white"
      />
    </div>
  )
}

export default CompletedIcon
