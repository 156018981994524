import React, { FC, useContext, useEffect, useState } from 'react';
import AddRemoveTable, { HeaderLabels } from '../../../components/AddRemoveTable/AddRemoveTable';
import { observer } from 'mobx-react-lite';
import { useStore } from '@jmjfinancial/apis/lib';
import { DataContext } from '../../../context/dataContext';
import { useHistory } from 'react-router-dom';
import {ApplicationRoutes, LoanType} from '../index';
import _ from 'lodash';
import { getHeaderText, reduceDisplayedTableData } from '../../../helpers/table-helper';
import { Button } from 'semantic-ui-react';
import EditIncomeForm from './EditIncomeForm';
import { IncomeType } from './index';
import moment from 'moment';
import IncomeModal from '../../../components/IncomesModal/IncomeModal';
import {scrollToDefaultOptions} from '../../../helpers/scroll-to-options';

interface IncomesTableViewProps {
  loanData: any
  incomeTypes: IncomeType[]
  setIncomeTypes: Function
  setIncomeIndex: Function
  showDuplicateIncomeForm: boolean
  setShowDuplicateIncomeForm: Function
  setRefreshIncomesView: Function
  hasCoborrower: boolean
  setCoborrowerConcierge: Function
  conciergeMode?: boolean
  showEndDate?: boolean
  setShowEndDate: Function
  updateIncomeFormView: Function
  refIncomeIndex: any
}

const HEADER_LABELS: HeaderLabels[] = [
  {
    text: 'Borrower',
    value: 'owner'
  },
  {
    text: 'Income Source',
    value: 'income_type'
  },
  {
    text: 'Employer Name',
    value: 'employer_name'
  },
  {
    text: 'Monthly Gross Income',
    value: 'amount'
  }
]

const IncomesTableView: FC<IncomesTableViewProps> = observer(({
  loanData,
  incomeTypes,
  setIncomeTypes,
  setIncomeIndex,
  showDuplicateIncomeForm,
  setShowDuplicateIncomeForm,
  setRefreshIncomesView,
  hasCoborrower,
  setCoborrowerConcierge,
  conciergeMode,
  showEndDate,
  setShowEndDate,
  updateIncomeFormView,
  refIncomeIndex
}) => {
  const store = useStore();
  const { loansService } = store;

  const {
    activeLoan,
    pathname,
    reviewMode,
    setDarkThemeOverride,
    setOverrideTrackerVisibility,
    refLoanType
  } = useContext(DataContext);
  const [tableData, setTableData] = useState<any>([]);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [editFormData, setEditFormData] = useState<any>();
  const [editRowIndex, setEditRowIndex] = useState(0);
  const [showModal, setShowModal] = useState<boolean>(false);
  // Used for self-employment `tax_filed_year`
  const [borrowerData, setBorrowerData] = useState(loanData.borrower)
  const [coborrowerData, setCoborrowerData] = useState(loanData.coborrower)

  const history = useHistory();

  setDarkThemeOverride(false)
  setOverrideTrackerVisibility(false)

  const handlePreviousClick = () => {
    if (refLoanType.current === LoanType.refinance) {
      history.push('/application/residences')
    }
    else {
      history.push('/application/loan-details')
    }
  }

  const handleNextClick = () => {
    if (reviewMode) {
      history.push('/application/review-and-submit')
    }
    else {
      history.push('/application/assets')
    }
  }

  const handleAddRow = (row: any, borrowerData?: any) => {
    let newTableData = [...tableData]

    // In the case of retirement incomes, there can be multiple
    // rows passed at once, this iterates over each and pushes
    // the object to the table
    if (row.length) {
      for (const obj of row) {
        newTableData.push(obj)
      }
    }
    else {
      newTableData.push(row)
    }
    setTableData(newTableData)

    if (borrowerData) {
      row.owner === 'borrower' ? setBorrowerData(borrowerData) : setCoborrowerData(borrowerData)
    }
  }

  const handleRemoveRow = (index: number) => {
    let newTableData = [...tableData];
    newTableData[index].to_be_deleted = true
    setTableData(newTableData);
  }

  const handleShowForm = (visible: boolean) => {
    const emptyForm = { ...{ income_type: '' } };
    setEditFormData(emptyForm);
    setShowForm(visible);
  }

  const handleEditRow = (index: number) => {
    setEditRowIndex(index);
    setEditFormData({ ...tableData[index] });
    setShowForm(true);
  }

  const handleUpdateRow = (row: any, borrowerData?: any) => {
    const updatedTableData = [...tableData];
    updatedTableData[editRowIndex] = row;
    setTableData(updatedTableData);

    if (borrowerData) {
      row.owner === 'borrower' ? setBorrowerData(borrowerData) : setCoborrowerData(borrowerData)
    }
  }

  let totalBorrowerEmploymentMonths: number = 0;
  const handleShowTwoYearModal = (incomes: []) => {
    incomes.forEach((income: any) => {
      const endDateDuration = moment(moment(income.end_date)).diff(income.start_date, 'months');
      const currentDateDuration = moment(moment(new Date())).diff(income.start_date, 'months');

      if (income.owner === 'borrower' && (income.income_type === 'W-2 Wage Earner' || income.income_type === "Self Employed")) {
        if (income.end_date && income.to_be_deleted) {
          totalBorrowerEmploymentMonths -= endDateDuration;
        }
        if (!income.end_date && income.to_be_deleted) {
          totalBorrowerEmploymentMonths -= currentDateDuration;
        }
        if (income.end_date) {
          totalBorrowerEmploymentMonths += endDateDuration;
        }
        if (!income.end_date) {
          totalBorrowerEmploymentMonths += currentDateDuration;
        }
      }
    })
  }

  const handleSubmit = async (tableData: any) => {

    const incomesArray: any = [];
    const employmentsArray: any = [];

    tableData.forEach((income: any) => {
      if (income.income_type === 'W-2 Wage Earner' || income.income_type === "Self Employed") {
        employmentsArray.push(income)
      } else {
        incomesArray.push(income)
      }
    })

    handleShowTwoYearModal(tableData)

    const updatedLoan = loanData.coborrower ? {
      application: {
        current_step: ApplicationRoutes[pathname as keyof typeof ApplicationRoutes] + 1,
        completed_steps: {
          employment_and_income_done: true
        }
      },
      borrower: borrowerData,
      coborrower: coborrowerData,
      incomes: incomesArray,
      employments: employmentsArray
    } : {
      application: {
        current_step: ApplicationRoutes[pathname as keyof typeof ApplicationRoutes] + 1,
        completed_steps: {
          employment_and_income_done: true
        }
      },
      borrower: borrowerData,
      incomes: incomesArray,
      employments: employmentsArray
    }

    const dataSubmit = _.merge(loanData, updatedLoan)

    if (totalBorrowerEmploymentMonths < 24 && employmentsArray.length) {
      setShowModal(true)
    } else {
      await loansService.updateLoan(activeLoan, dataSubmit);
      handleNextClick();
    }
  }

  useEffect(() => {
    setTableData(loanData.incomes.concat(loanData.employments))
    handleShowTwoYearModal(loanData.employments);
  }, [loanData])

  useEffect(() => {
    if (!showForm && tableData.filter((row: any) => row.to_be_deleted).length === 0) {
      window.scrollTo(scrollToDefaultOptions)
    }
  }, [showForm])

  const modalHeaderText = "Quick Reminder!"
  const modalBodyText = "We recommend a two year employment history for easier approval. If you have any more forms of W2 or Self Employment, select 'Yes', then chose 'W-2 Wage Earner' or 'Self Employed' from the dropdown. If you would like to advance to the next page, select 'No'."

  return (
    <>
      <h1 className="title text-light-blue">Employment and Income</h1>
      <AddRemoveTable
        headerLabels={getHeaderText(HEADER_LABELS)}
        table={reduceDisplayedTableData(tableData, HEADER_LABELS)}
        removeTableRow={handleRemoveRow}
        addButtonLabel="Add additional employment and income"
        showForm={showForm}
        setShowForm={handleShowForm}
        editTableRow={handleEditRow}
        loanData={loanData}
        form={
          <div className="application-step-container">
            <EditIncomeForm
              loanData={loanData}
              incomeTypes={incomeTypes}
              setIncomeTypes={setIncomeTypes}
              refIncomeIndex={refIncomeIndex}
              setIncomeIndex={setIncomeIndex}
              showDuplicateIncomeForm={showDuplicateIncomeForm}
              setShowDuplicateIncomeForm={setShowDuplicateIncomeForm}
              setRefreshIncomesView={setRefreshIncomesView}
              conciergeMode={conciergeMode}
              hasCoborrower={hasCoborrower}
              setCoborrowerConcierge={setCoborrowerConcierge}
              setShowForm={handleShowForm}
              saveForm={editFormData?.income_type ? handleUpdateRow : handleAddRow}
              formData={editFormData}
              borrowerData={borrowerData}
              coborrowerData={coborrowerData}
              showEndDate={(editFormData?.income_type === '' || editFormData?.end_date) ? true : false}
              setShowEndDate={setShowEndDate}
              tableData={tableData}
            />
          </div>
        }
      />
      <IncomeModal
        showModal={showModal}
        setShowModal={setShowModal}
        setShowForm={handleShowForm}
        tableData={tableData}
        loanData={loanData}
        modalHeader={modalHeaderText}
        modalBody={modalBodyText}
        incomeTypes={incomeTypes}
        updateIncomeFormView={updateIncomeFormView}
        conciergeMode={conciergeMode}
      />
      {showForm ? null : (
        <div className="application-step-footer">
          <Button
            onClick={() => handlePreviousClick()}
            className="form-previous-button"
          >
            Previous
          </Button>
          <Button
            className="save"
            color="blue"
            onClick={() => handleSubmit(tableData)}
          >
            {reviewMode ? "Back to Review" : "Save & Continue"}
          </Button>
        </div>
      )}
    </>
  )
})

export default IncomesTableView
