import { observer } from 'mobx-react-lite';
import React, {FC, useContext, useEffect, useState} from 'react';
import DashItemComponent from '../../components/DashItem';
import { DataContext } from '../../context/dataContext';
import { useStore } from '@jmjfinancial/apis/lib';
import DownloadDocumentsModal from '../../components/Modals/DownloadDocumentsModal';
import { ApplicationRoutes } from '../LoanApplication';
import { useHistory } from 'react-router-dom';
import {DashDetail} from '../../components/DashItem/DashDetail';
export interface LoansTabProps {
  pastLoans?: boolean
}

const LoansTab: FC<LoansTabProps> = observer(({
  pastLoans
}) => {
  const {
    allLoans,
    activeLoan,
    setActiveLoan
  } = useContext(DataContext);
  const { loansService } = useStore();
  const history = useHistory();

  const [loans, setLoans] = useState([]);
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [showEditForm, setShowEditForm] = useState<boolean>(false)
  const [isSavingChanges, setIsSavingChanges] = useState<boolean>(false)
  const [loanData, setLoanData] = useState<any>({})
  const [borrowerPairId, setBorrowerPairId] = useState<number>(0);

  const myLoansContext = {
    ...useContext(DataContext),
    showEditForm,
    setShowEditForm,
    isSavingChanges,
    setIsSavingChanges,
    loanData,
    setLoanData
  }

  // TODO: Add logic to filter loans for "pastLoans"
  // if pastLoans is true, only display data where milestone ===  'closed' || milestone === 'cancelled'
  const buildLoans = (): [] => {
    const pastLoansArray = allLoans.filter((loan: any) => loan.loan?.milestone === 'closed' || loan.loan?.milestone === 'canceled');

    const loansArray = pastLoans ? pastLoansArray : allLoans;

    return loansArray.map((loan: any, index: any) => {
      const loanType = loan.loan.loan_purpose_type;
      const purchasePlan = loan.loan?.purchase_plan;
      const loanId = loan.loan?.id;
      const loanSubmitted = loan.application?.submitted === true
      const getDate = (date: any) => new Date(date).toLocaleDateString();

      return (
        {
          name: loanType === "refinance" || (loanType === "purchase" && purchasePlan === 'contract')
            ? (loan.property ? loan.property.address_street_line : 'Get Started')
            : (loan.property ? `${loan.property.address_city}, ${loan.property.address_state}` : `Get Started`),
          type: loan.loan.loan_purpose_type === "refinance" ? 'Refinance' : 'Purchase',
          createdDate: `Created: ${getDate(loan.application.created_at)}`,
          loanId: loanId,
          borrowerPairId: loan.application.id,
          loanCurrentStep: loan.application.current_step,
          updatedAt: loan.application.updated_at,
          loanAmount: loan?.loan?.base_loan_amount?.replace(/(\.[0-9]*?)0+/g, ""),
          //TODO: Add real data for loanType and interestRate
          loanType: "CONVENTIONAL 30 YEAR (fake)",
          interestRate: "Pending",
          propertyExists: loan.property !== null,
          loanSubmitted: loanSubmitted,
          loanData: loan
        }
      )
    });
  };

  const resumeLoan = (loanData: any) => {
    const loanCurrentStep = loanData.loanCurrentStep

    history.push(ApplicationRoutes[loanCurrentStep])
  }

  const downloadMyDocuments = (loan: any) => {
    setShowDownloadModal(showModal => !showModal)
    setBorrowerPairId(borrowerPairId => loan.borrowerPairId)
  }

  const handleLoanSelect = (loan: any) => {
    loansService.setActiveLoan(loan.loanId);
    setActiveLoan(loan.loanId);
    setShowEditForm(showEditForm => false)
    setBorrowerPairId(borrowerPairId => loan.borrowerPairId)
  }

  const handleEditLoanProperty = (loan: any) => {
    setLoanData((loanData: any) => loan.loanData)
    setShowEditForm(showEditForm => true)
  }

  const getDashItemDetails = (loan: any): DashDetail[] => {
    if (pastLoans) {
      return [
        {
          label: loan.type
        },
        {
          label: 'Documents',
          icon: 'download',
          onClick: () => downloadMyDocuments(loan)
        }
      ]
    } else {
      if (loan.loanId === activeLoan) {
        // TODO: Add real milestone data
        // {label: 'Loan Milestone'},
        // { label: loan.createdDate },

        let details: DashDetail[] = [
          (loan.propertyExists && loan.loanData.loan.loan_purpose_type !== 'refinance') && {
            label: 'Edit',
            icon: 'edit',
            onClick: () => handleEditLoanProperty(loan)
          },
          // Uncomment once actually implemented, if ever
          // {
          //   label: 'Delete',
          //   icon: 'delete',
          //   onClick: () => console.log("Delete Button Clicked")
          // }
        ]

        if (loan.loanSubmitted) {
          details.unshift({
            label: 'Documents',
            icon: 'download',
            onClick: () => downloadMyDocuments(loan)
          })
        } else {
          details.unshift({
            label: 'Resume Loan',
            icon: 'angle right',
            onClick: () => resumeLoan(loan)
          })
        }

        return details
      } else {
        return []
      }
    }

  }

  useEffect(() => {
    setLoans(loans => buildLoans().sort((a: any, b: any) => {
      return new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime();
    }));
  }, [pastLoans, activeLoan, isSavingChanges])

  return (
    <DataContext.Provider value={myLoansContext}>
      {loans.map((loan: any, index: number) => (
        <DashItemComponent
          key={`${loan.name}-${index}`}
          name={loan.name}
          // Since "Nocash_out_refinance" is sometimes being sent back after a loan is submitted we can check the string for refinance and return refinance otherwise we will return the loan.type
          subtext={loan.loanData.loan.loan_purpose_type?.includes("refinance") ? "Refinance" : loan.type}
          pastLoan={pastLoans}
          onClick={!pastLoans && !showEditForm || (loan.loanId !== activeLoan) ? () => handleLoanSelect(loan) : () => null}
          isLoan
          activeLoan={loan.loanId === activeLoan}
          showEditForm={showEditForm}
          setShowEditForm={setShowEditForm}
          details={getDashItemDetails(loan)}
          // Uncomment to add the DashSubDetailsComponent to the My Loans Tab
          // subDetails={(loan.loanId === activeLoan) ? ([
          //   {
          //     label: `LOAN AMOUNT: $${loan.loanAmount}`,
          //   },
          //   {
          //     label: loan.createdDate,
          //   },
          //   {
          //     label: `INTEREST RATE: ${loan.interestRate}`,
          //   }
          // ]) : []}
        />
      ))}
      {showDownloadModal && (
        <DownloadDocumentsModal
          showModal={showDownloadModal}
          setShowModal={setShowDownloadModal}
          borrowerPairId={borrowerPairId}
        />
      )}
    </DataContext.Provider>
  )
});

export default LoansTab;
