import React, { FC, useContext, useEffect, useState } from 'react'
import { DataContext } from '../../context/dataContext'
import { observer } from 'mobx-react-lite'
import LoaderOverlay from '../../components/LoaderOverlay/LoaderOverlay'
import SectionComponent from '../../components/Section'
import MyAccountItemComponent, {MyAccountItem} from './MyAccountItem'
import './my-account.scss'
import {useStore} from '@jmjfinancial/apis/lib';
import HelperPopup from '../../components/Popup/Popup'
import {scrollToDefaultOptions} from '../../helpers/scroll-to-options';

interface MyAccountSection {
  headerLabel: string
  items: MyAccountItem[]
}

const MyAccount: FC = observer(() => {
  const store = useStore()
  const { loansService } = store
  const { user, customer, setCustomer, clearStaleUser } = useContext(DataContext)

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSavingChanges, setIsSavingChanges] = useState<boolean>(false);

  const [customerFirst, setCustomerFirst] = useState<string>(customer?.first_name)
  const [customerLast, setCustomerLast] = useState<string>(customer?.last_name)
  const [customerPhone, setCustomerPhone] = useState<string>(customer?.mobile_phone)
  const [customerEmail, setCustomerEmail] = useState<string>(customer?.email)

  const customerContext = {
    ...useContext(DataContext),
    customerFirst,
    setCustomerFirst,
    customerLast,
    setCustomerLast,
    customerPhone,
    setCustomerPhone,
    customerEmail,
    setCustomerEmail
  }

  const myAccountSections: MyAccountSection[] = [
    {
      headerLabel: 'My Account',
      items: [
        {
          displayText: `${customerFirst || customer?.first_name} ${customerLast || customer?.last_name}`,
          buttonType: 'edit',
          formType: 'name',
          isSavingChanges: isSavingChanges,
          setIsSavingChanges: setIsSavingChanges
        },
        {
          displayText: `${customer?.email}`,
          buttonType: 'edit',
          formType: 'email',
          isSavingChanges: isSavingChanges,
          setIsSavingChanges: setIsSavingChanges
        },
        {
          displayText: `${customerPhone?.replace(/^.{2}(\d{3})(\d{3})(\d{4})/, "($1) $2-$3") || customer?.mobile_phone.replace(/^.{2}(\d{3})(\d{3})(\d{4})/, "($1) $2-$3")}`,
          buttonType: 'edit',
          formType: 'phone',
          isSavingChanges: isSavingChanges,
          setIsSavingChanges: setIsSavingChanges
        },
        {
          displayText: 'Account Password',
          buttonType: 'reset',
          formType: 'password',
          isSavingChanges: isSavingChanges,
          setIsSavingChanges: setIsSavingChanges
        },
        // NOTE: Removing this for now based off RR suggestion. Will re-add later.
        // {
        //   displayText: 'Delete Account',
        //   buttonType: 'delete',
        //   formType: 'delete',
        //   isSavingChanges: isSavingChanges,
        //   setIsSavingChanges: setIsSavingChanges
        // }
      ]
    },
    {
      headerLabel: 'Optional Notifications',
      items: [
        {
          toggleType: 'email',
          displayText: <>Email Notifications</>,
          toggle: true,
          toggleState: customer?.email_notifications_enabled,
          isSavingChanges: isSavingChanges,
          setIsSavingChanges: setIsSavingChanges,
          helper: <HelperPopup
            headerLabel="Email Notifications"
            additionalClass="dashboard-popup"
            content={
              <p>
                This setting only disables optional notifications.
                We will still send necessary notifications when needed.
              </p>
            }
          />
        }
      ]
    }
  ]

  useEffect(() => {
    setIsLoading(true)

    // This will eventually be moved to a HoC which checks for the stale user for ALL components
    loansService.getCustomer().then(customer => {
      if (customer.data) {
        const newCustomer = customer.data.customer
        setCustomer(newCustomer)
        setCustomerFirst(newCustomer.first_name)
        setCustomerLast(newCustomer.last_name)
        setCustomerEmail(newCustomer.email)
        setCustomerPhone(newCustomer.mobile_phone.replace(/^.{2}(\d{3})(\d{3})(\d{4})/, "($1) $2-$3"))
        setIsLoading(false)
      } else {
        clearStaleUser()
      }
    })
  }, [user, isSavingChanges])

  useEffect(() => {
    window.scrollTo(scrollToDefaultOptions)
  }, [])

  return (
    <DataContext.Provider value={customerContext}>
      <LoaderOverlay active={isLoading || isSavingChanges} label={isSavingChanges ? 'Saving Changes...' : 'Loading...'} />
      {!(isLoading || isSavingChanges) && (
        <div className="my-account-container">
          {myAccountSections.map((section, sectionIndex: number) => (
            <div className="section-column">
              <SectionComponent
                key={`${section.headerLabel}-${sectionIndex}`}
                headerLabel={section.headerLabel}
              >
                {section.items.map((item, itemIndex: number) => (
                  <MyAccountItemComponent
                    key={`${item.displayText.toString()}-${itemIndex}`}
                    displayText={item.displayText}
                    buttonType={item.buttonType}
                    toggle={item.toggle}
                    toggleState={item.toggleState}
                    toggleType={item.toggleType}
                    formType={item.formType}
                    isSavingChanges={item.isSavingChanges}
                    setIsSavingChanges={item.setIsSavingChanges}
                    helper={item.helper}
                  />
                ))}
              </SectionComponent>
            </div>
          ))}
        </div>
      )}
    </DataContext.Provider>
  )
})

export default MyAccount
