import React, { FC, useContext, useEffect, useState } from 'react';
import { Button, Radio } from 'semantic-ui-react';
import { DataContext } from '../../context/dataContext';
import PasswordResetModal from './PasswordResetModal';
import EditForm, {MyAccountFormType} from './EditForm';
import DeleteAccountModal from './DeleteAccountModal';
import { observer } from 'mobx-react-lite';
import { useStore } from '@jmjfinancial/apis/lib';
import HelperPopup from '../../components/Popup/Popup';

type ItemButtonType = 'edit' | 'reset' | 'delete' | undefined
type ToggleType = 'text' | 'email' | undefined

export interface MyAccountItem {
  displayText: string | JSX.Element
  buttonType?: ItemButtonType
  toggle?: boolean
  toggleState?: boolean
  toggleType?: ToggleType
  formType?: MyAccountFormType
  isSavingChanges: boolean
  setIsSavingChanges: Function
  helper?: JSX.Element
}

const MyAccountItemComponent: FC<MyAccountItem> = observer(({
  displayText,
  buttonType,
  toggle,
  toggleState,
  toggleType,
  formType,
  isSavingChanges,
  setIsSavingChanges,
  helper
}) => {
  const { loansService } = useStore();
  const { customer } = useContext(DataContext)

  const [editable, setEditable] = useState<boolean>(false)
  const [showPasswordModal, setShowPasswordModal] = useState<boolean>(false)
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState<boolean>(false)
  const [radioLabel, setRadioLabel] = useState<boolean>(toggleState ? toggleState : false)
  const [emailToggleStatus, setEmailToggleStatus] = useState<boolean>(customer?.email_notifications_enabled)

  const handleToggleClick = () => {
    setIsSavingChanges((isSavingChanges: boolean) => true)

    loansService.updateCustomer({
      customer: {
        ...customer,
        email_notifications_enabled: !emailToggleStatus
      }
    }).then(() => {
      setEmailToggleStatus(emailToggleStatus => !emailToggleStatus)
      setIsSavingChanges((isSavingChanges: boolean) => false)
    })
  }

  const MyAccountItemContent: FC = () => {
    if (formType && editable) {
      return (
        <EditForm
          editable={editable}
          setEditable={setEditable}
          formType={formType}
          isSavingChanges={isSavingChanges}
          setIsSavingChanges={setIsSavingChanges}
        />
      )
    }
    else {
      return (
        helper ? (
          <span className="label-container">
            <label className="item-label">{displayText}</label>
            {helper ? helper : null}
          </span>
        ) : (
            <label className="item-label">{displayText}</label>
          )
      )
    }
  }

  const MyAccountItemButton: FC = () => {
    // TODO: Add styles to buttons using styles already defined in "_buttons.scss"
    switch (buttonType) {
      case 'reset':
        return (
          <Button onClick={() => setShowPasswordModal(true)}>
            Change
          </Button>
        )
      case 'delete':
        return (
          <Button onClick={() => setShowDeleteAccountModal(true)}>
            Change
          </Button>
        )
      default:
        return (
          <Button onClick={() => setEditable(editable => !editable)}>
            {buttonType}
          </Button>
        )
    }
  }

  const MyAccountToggleButton: FC = () => {
    return (
      <div className="radio-container">
        <label className={`${radioLabel ? '' : 'notification-off'}`}>{radioLabel ? 'ON' : 'OFF'}</label>
        <Radio
          toggle
          checked={radioLabel}
          onClick={() => {
            setRadioLabel(radioLabel => !radioLabel)
            handleToggleClick()
          }}
        />
      </div>
    )
  }

  return (
    <>
      <div className="my-account-item">
        <MyAccountItemContent />
        {toggle ? <MyAccountToggleButton /> : !editable && <MyAccountItemButton />}
      </div>
      {buttonType === 'reset' && (
        <PasswordResetModal
          showModal={showPasswordModal}
          setShowModal={setShowPasswordModal}
        />
      )}
      {buttonType === 'delete' && (
        <DeleteAccountModal
          showModal={showDeleteAccountModal}
          setShowModal={setShowDeleteAccountModal}
        />
      )}
    </>
  )
})

export default MyAccountItemComponent
