import React, { FC, useContext, useEffect, useState } from 'react';
import AddRemoveTable, { HeaderLabels } from '../../../components/AddRemoveTable/AddRemoveTable';
import { observer } from 'mobx-react-lite';
import { useStore } from '@jmjfinancial/apis/lib';
import { DataContext } from '../../../context/dataContext';
import { useHistory } from 'react-router-dom';
import { getHeaderText, reduceDisplayedTableData } from '../../../helpers/table-helper';
import { Button } from 'semantic-ui-react';
import EditAddCoApplicantForm from './EditAddCoApplicantForm';
import { ApplicationRoutes } from '../index';
import _ from 'lodash';
import CoappModeSubtitle from '../../../components/CoappModeSubtitle/CoappModeSubtitle';
import {scrollToDefaultOptions} from '../../../helpers/scroll-to-options';


interface AddCoApplicantTableViewProps {
  loanData: any
  isSpouse: boolean
  setIsSpouse: Function
  setShowCoApplicantForm: Function
  coapplicantCount: number
  setCoapplicantCount: Function
  conciergeMode: boolean
  setRefreshAddCoApplicantView: Function
  setShowErrorModal: Function
  setModalErrorMessage: Function
  coapplicantMode: boolean
}

export interface CoApplicant {
  first_name: string;
  last_name: string;
  email: string;
  mobile_phone: string;
  spouse: string;
}

const HEADER_LABELS: HeaderLabels[] = [
  {
    text: 'First Name',
    value: 'first_name'
  },
  {
    text: 'Last Name',
    value: 'last_name'
  },
  {
    text: 'Email',
    value: 'email'
  },
  {
    text: 'Mobile',
    value: 'mobile_phone'
  }
];

const initialForm = {
  first_name: '',
  last_name: '',
  email: '',
  mobile_phone: '',
  spouse: '',
}

const AddCoApplicantTableView: FC<AddCoApplicantTableViewProps> = observer(({
  loanData,
  isSpouse,
  setIsSpouse,
  setShowCoApplicantForm,
  coapplicantCount,
  setCoapplicantCount,
  conciergeMode,
  setRefreshAddCoApplicantView,
  setShowErrorModal,
  setModalErrorMessage,
  coapplicantMode
}) => {
  const store = useStore();
  const { loansService } = store;

  const {
    activeLoan,
    pathname,
    setDarkThemeOverride,
    setOverrideTrackerVisibility
  } = useContext(DataContext);
  const [tableData, setTableData] = useState<any>([]);
  const [showForm, setShowForm] = useState(false);
  const [editFormData, setEditFormData] = useState(initialForm);
  const [disableAddRow, setDisableAddRow] = useState<boolean>(false);

  const history = useHistory();

  setDarkThemeOverride(false)
  setOverrideTrackerVisibility(false)

  const handlePreviousClick = () => {
    history.push('/application/auth-and-consent')
  }

  const handleNextClick = () => {
    let nextRoute: string

    if (coapplicantMode) {
      nextRoute = 'residences'
    }
    else {
      nextRoute = tableData.find((row: any) => row.spouse === true) ? 'auth-and-consent-co-borrower' : 'residences'
    }

    history.push(`/application/${nextRoute}`)
  }

  const handleAddRow = (row: CoApplicant) => {
    let newTableData = [...tableData, row];
    setTableData(newTableData);

    setDisableAddRow(newTableData.length >= 4)
  }

  const handleShowForm = (visible: boolean) => {
    const emptyForm = { ...initialForm };
    setEditFormData(emptyForm);
    setShowForm(visible);
  }

  const handleSubmit = async () => {
    // Filters out coapplicants that have already been added to loanData
    const newCoApplicants = tableData.filter((row: CoApplicant) => (
      !loanData.coapplicants.some((coapplicant: any) => coapplicant.email === row.email))
    )

    console.log('newCoApplicants: ', newCoApplicants)

    loansService.addBorrower(activeLoan, newCoApplicants).then((res: any) => {
      if (!res.success) {
        setModalErrorMessage(res.errors?.base[0] || res.error)
        setShowErrorModal(true)
      }
    })

    const updatedLoan = {
      application: {
        current_step: ApplicationRoutes[pathname as keyof typeof ApplicationRoutes] + 1,
        completed_steps: {
          add_co_applicant_done: true
        }
      },
      coapplicants: tableData
    }

    const dataSubmit = _.merge(loanData, updatedLoan)
    loansService.updateLoan(activeLoan, dataSubmit).then((res: any) => {
      if (!res.success) {
        setModalErrorMessage(res.errors?.base[0] || res.error)
        setShowErrorModal(true)
      }
    })

    handleNextClick()
  }



  useEffect(() => {
    console.log('Co applicant table use effect ran')
    console.log({ coapplicantMode })
    setTableData(loanData.coapplicants)
    setDisableAddRow(loanData.coapplicants.length >= 4)
  }, [loanData])

  useEffect(() => {
    if (!showForm) {
      window.scrollTo(scrollToDefaultOptions)
    }
  }, [])

  return (
    <>
      <h1 className={`title text-light-blue ${coapplicantMode ? 'has-subtitle' : ''}`}>Co-Applicants</h1>
      {coapplicantMode && <CoappModeSubtitle customText="Only the Primary Borrower can add Co-Applicants." />}
      <AddRemoveTable
        headerLabels={getHeaderText(HEADER_LABELS)}
        table={reduceDisplayedTableData(tableData, HEADER_LABELS)}
        addButtonLabel={disableAddRow ? "Max of four co-applicants allowed" : "Add additional co-applicant"}
        showForm={showForm}
        setShowForm={handleShowForm}
        loanData={loanData}
        disableAddRow={disableAddRow}
        coapplicantMode={coapplicantMode}
        form={
          <div className="application-step-container">
            <EditAddCoApplicantForm
              loanData={loanData}
              isSpouse={false}
              setIsSpouse={setIsSpouse}
              setShowCoApplicantForm={setShowCoApplicantForm}
              setShowForm={handleShowForm}
              saveForm={handleAddRow}
              formData={editFormData}
              coapplicantCount={coapplicantCount}
              setCoapplicantCount={setCoapplicantCount}
              conciergeMode={conciergeMode}
              tableData={tableData}
            />
          </div>
        }
      />
      {showForm ? null : (
        <div className="application-step-footer">
          <Button
            onClick={() => handlePreviousClick()}
            className="form-previous-button"
          >
            Previous
          </Button>
          <Button
            className="save"
            color="blue"
            onClick={handleSubmit}
          >
            {coapplicantMode ? 'Skip' : 'Save & Continue'}
          </Button>
        </div>
      )}
    </>
  )
})

export default AddCoApplicantTableView;
